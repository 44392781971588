import React, { useRef } from 'react';
import IframeResizer from 'iframe-resizer-react';
import { useGetAnalytics } from 'src/hooks/useAnalytics';

const Analytics = () => {
  const { data: dataAnalytics } = useGetAnalytics();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const urlWithRefresh = dataAnalytics?.url;

  return (
    <>
      <IframeResizer
        sizeHeight
        src={urlWithRefresh}
        forwardRef={iframeRef}
        checkOrigin={false}
        style={{
          width: '100%',
          height: '100%',
          minWidth: '100%',
          minHeight: '100%',
          border: 'none',
        }}
      />
    </>
  );
};

export default Analytics;
