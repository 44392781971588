import { FC } from 'react';
import { CheckIcon } from 'src/styles/assets/icons/Icons';

export const OnboardingComplete: FC = () => {
  const handleClick = () => {
    window.location.href = '/slices';
  };

  return (
    <div className="flex items-center justify-center h-screen mx-auto">
      <div className="text-center">
        <CheckIcon className="m-auto h-24 w-24" />
        <h1 className="text-4xl font-bold mb-2">Onboarding Completed</h1>
        <p>Your onboarding process is completed</p>
        <button className="w-[180px] h-[56px] border-1 border-core-yellow bg-core-yellow rounded" onClick={handleClick}>
          Go to dashboard
        </button>
      </div>
    </div>
  );
};
