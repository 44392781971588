import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import ModalAddSlice from './ModalAddSlice';
import ModalCreateListingEmbed from '../ModalCreateListingEmbed';

interface SelectionCounterProps {
  count: number;
  clearSelection: () => void;
  setShowLayout: (value: boolean) => void;
  setContinueButton: (value: boolean) => void;
  showLayout?: boolean;
  selectedItems?: any;
  continueButton?: boolean;
  isListingAction?: boolean;
}

const SelectionCounter: React.FC<SelectionCounterProps> = ({
  count,
  clearSelection,
  setShowLayout,
  showLayout,
  selectedItems,
  continueButton,
  setContinueButton,
  isListingAction,
}) => {
  const [visible, setVisible] = useState(true);
  const [isContinue, setIsContinue] = useState(false);
  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const handleClickCancel = () => {
    toggleVisibility();
    clearSelection();
    setShowLayout(false);
    setContinueButton(false);
  };

  const handleShowLayout = () => {
    handleClickCancel();
    localStorage.removeItem('selectedItems');
    setContinueButton(false);
  };

  const handleContinueButton = () => {
    setIsContinue(!isContinue);
  };

  const closeModal = () => {
    setIsContinue(false);
    handleClickCancel();
  };

  return (
    <>
      {!isContinue && (count > 0 || showLayout) ? (
        <div className="fixed z-20 left-[5%] md:left-[40%] w-fit bottom-20 md:bottom-7 flex justify-center items-center">
          <div className="bg-black rounded-xl w-96 p-3 flex gap-3 items-center text-left">
            <div className="w-6">
              <span
                className={`${
                  count === 0 ? 'bg-core-black text-core-yellow' : 'bg-core-yellow text-black'
                } rounded py-1 font-extrabold px-2 text-base border-1 border-core-yellow`}
              >
                {count}
              </span>
            </div>
            {isListingAction ? (
              <div className="w-full text-sm text-white ml-2">
                {count === 0 ? 'Select slices to create a Listing' : 'Slices selected'}
              </div>
            ) : (
              <div className="w-full text-sm text-white ml-2">
                {count === 0 ? 'Select policies to create a Slice' : 'Policies selected'}
              </div>
            )}

            <div className="w-fit flex justify-end">
              {count === 0 ? (
                <button onClick={handleClickCancel} className="text-white uppercase text-base m-auto">
                  CANCEL
                </button>
              ) : (
                <div className="flex gap-2">
                  {continueButton && (
                    <button className="text-core-yellow uppercase text-base m-auto" onClick={handleContinueButton}>
                      Continue
                    </button>
                  )}
                  <button>
                    <XMarkIcon className=" w-6 h-6 text-white" onClick={handleShowLayout} />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}

      <ModalAddSlice isOpen={isContinue && !isListingAction} onClose={closeModal} listingSlices={selectedItems} />

      <ModalCreateListingEmbed
        isOpen={isContinue && isListingAction === true}
        onClose={closeModal}
        slicesList={selectedItems}
      />
    </>
  );
};

export default SelectionCounter;
