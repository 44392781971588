import { useEffect, useState } from 'react';
import posthog from 'posthog-js';
import { useGetUser } from 'src/hooks/useOnboarding';
import { useLocation } from 'react-router';
import { useGetSubscription } from 'src/hooks';

const Posthog = () => {
  const { data: userResponse, isPending: isUserPending } = useGetUser();
  const { data: subscriptionResponse } = useGetSubscription();
  const [subscriptionName, setSubscriptionName] = useState<string>('Starter');
  const location = useLocation();

  useEffect(() => {
    if (subscriptionResponse) {
      setSubscriptionName(subscriptionResponse.userSubscription);
    }
  }, [subscriptionResponse]);

  useEffect(() => {
    if (location.hash !== '') {
      posthog.capture('$pageview', { page_url: location.pathname });
    } else {
      posthog.capture('$pageview', { page_url: location.pathname + location.hash });
    }
  }, [location]);

  useEffect(() => {
    posthog.init('phc_P9N3XORKwhOdSYeJMjrDuTYgwGIBssYIp47N7BwoCHM', {
      api_host: 'https://app.posthog.com',
      // configure as in https://posthog.com/docs/session-replay/privacy
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
          email: false,
          text: false,
        },
      },
    });

    if (!isUserPending && userResponse && userResponse.userProfile) {
      posthog.identify(userResponse.userProfile.email);
      if (userResponse.userProfile.selling) {
        posthog.people.set({ subcription: 'Seller' });
      } else {
        posthog.people.set({ subcription: subscriptionName });
      }
    }
  }, [isUserPending, userResponse, subscriptionName]);

  return null;
};

export default Posthog;
