import React from 'react';
import Breadcrumb from '../shared/components/Breadcrumb/Breadcrumb';
import BuyersCards from '../home/home-tabs/card-view/BuyersCards';

const BuyersDatabases = () => {
  return (
    <div className="p-3 w-full">
      <Breadcrumb title="Buyer Database" />

      <div className="bg-core-white rounded-lg w-full px-6 pb-0.5 pt-6 space-y-4 sm:space-y-0">
        <BuyersCards />
      </div>
    </div>
  );
};

export default BuyersDatabases;
