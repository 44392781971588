import React, { useEffect, useState } from 'react';
import { Onboarding } from '../components/Onboarding';
import { useFormik } from 'formik';
import { useGetBuyerProfile, usePatchBuyerProfile } from 'src/hooks/useBuyersProfile';
import { useHistory } from 'react-router';
import Input from 'src/app/shared/components/Inputs/Input';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';
import { premiumInfoSchema } from '../schemas/premiumFilterInfo.schema';

const PremiumBuyerProfile = () => {
  const history = useHistory();
  const { data: buyerProfileResponse, isPending } = useGetBuyerProfile();
  const [, setBuyerProfile] = useState(buyerProfileResponse);
  const { mutate, isSuccess } = usePatchBuyerProfile();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (buyerProfileResponse) {
      setBuyerProfile(buyerProfileResponse);
    }
  }, [buyerProfileResponse]);

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      totalPremiumMax: buyerProfileResponse?.buyerProfile.totalPremiumMax
        ? buyerProfileResponse?.buyerProfile.totalPremiumMax
        : '',
      totalPremiumMin: buyerProfileResponse?.buyerProfile.totalPremiumMin
        ? buyerProfileResponse?.buyerProfile.totalPremiumMin
        : '',
    },
    validationSchema: premiumInfoSchema,
    onSubmit: (values) => {
      const { totalPremiumMax, totalPremiumMin } = values;
      mutate({
        totalPremiumMax: Number(totalPremiumMax),
        totalPremiumMin: Number(totalPremiumMin),
      });
    },
  });

  useEffect(() => {
    const isValid = Object.keys(errors).length === 0;

    const requiredFieldsFilled: boolean =
      values.totalPremiumMax.toString().trim() !== '' && values.totalPremiumMin.toString().trim() !== '';
    setIsDisabled(!(isValid && requiredFieldsFilled));
  }, [errors, values.totalPremiumMax, values.totalPremiumMin]);

  useEffect(() => {
    if (isSuccess) {
      history.push('/onboarding/buyer-profile/readiness');
    }
  }, [isSuccess, history]);

  if (isPending) {
    return (
      <div className="w-full mb-12 z-0">
        <Skeleton type="step-onboarding" />
      </div>
    );
  }

  return (
    <Onboarding
      handleSubmit={handleSubmit}
      isContinue={true}
      isBack={true}
      isStep={true}
      numOfSteps={7}
      stepPosition={4}
      isDisabled={isDisabled}
    >
      <div className="w-full md:w-3/5 mx-auto block py-20 px-8 md:px-32 text-left gap-8 mt-10">
        <div className="w-full">
          <p className="text-center text-sm text-gray-600 font-bold mx-0 p-0 mb-8">BUILD YOUR BUYER PROFILE</p>
        </div>

        <div className="w-full mb-12 mt-8">
          <h1 className="text-core-black text-3xl md:text-5xl font-medium text-center">
            How much premium are you looking to buy?
          </h1>
          <p className="font-medium text-gray-700 text-base mt-4 mb-0 p-0 text-center">
            Please enter a premium range below. It doesn’t have to be exact and you can always change this later.
          </p>
        </div>

        <div className="w-full mt-11 flex md:flex-row flex-col">
          <div className="md:w-1/2 w-full">
            <Input
              type="number"
              labelInput="MINIMUM PREMIUM"
              placeholder="$"
              name="totalPremiumMin"
              isRequiredInput={true}
              value={values.totalPremiumMin.toString()}
              onChange={(e) => {
                handleChange(e);
              }}
              errorText={errors.totalPremiumMin}
            />
          </div>

          <div className="md:w-fit w-full px-8 my-4 md:my-auto">
            <p className="text-base font-bold text-gray-700 text-center p-0 m-0">TO</p>
          </div>
          <div className="w-full md:w-1/2">
            <Input
              type="number"
              labelInput="MAXIMUM PREMIUM"
              placeholder="$"
              isRequiredInput={true}
              name="totalPremiumMax"
              value={values.totalPremiumMax.toString()}
              onChange={(e) => {
                handleChange(e);
              }}
              errorText={errors.totalPremiumMax}
            />
          </div>
        </div>
      </div>
    </Onboarding>
  );
};

export default PremiumBuyerProfile;
