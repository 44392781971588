import React, { useState } from 'react';
import cakeLogo from '../../../styles/assets/logos/cake-logo-black.png';
import Input from 'src/app/shared/components/Inputs/Input';
import { Form, Formik } from 'formik';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import Button from 'src/app/shared/components/Buttons/Button';
import { ConfirmforgotPassword } from 'src/api/user';

const ChangePasswordFormV2 = () => {
  const [confirmEmail] = useState(localStorage.getItem('confirmUserEmail') || '');
  const { alerts, createToast } = useAlert();
  const [, setIsPendingButton] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);

  const initialValues = {
    code: '',
    password: '',
    email: confirmEmail,
  };

  const onSubmit = async (values: any) => {
    setIsPendingButton(true);
    try {
      setIsPendingButton(true);
      const response = await ConfirmforgotPassword(values.email, values.code, values.password);
      if (response.message) {
        createToast({ text: 'Your password has been updated correctly', variant: 'success' });
        setTimeout(() => {
          window.location.href = '/login';
        }, 5000);
      } else {
        createToast({ text: response.error, variant: 'danger' });
      }
    } catch (error) {
      console.log(error);
      createToast({
        text: 'Invalid verification code provided, please try again.',
        variant: 'danger',
      });
    }
    setIsPendingButton(false);
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize>
      {({ values, handleChange }) => (
        <>
          <Form className="h-fit w-full min-h-screen bg-white">
            <div className="w-full h-full absolute top-0">
              <div className="w-full top-0">
                <div className="float-left py-4 px-10">
                  <img src={cakeLogo} alt="Cake logo" className="w-[60px] h-[24]" />
                </div>
              </div>

              <div className="w-full md:w-1/2 mx-auto my-40 space-y-3">
                <h1 className="text-xl md:text-5xl font-medium text-center">Recover Password</h1>

                <p className="font-medium text-sm md:text-base text-core-grey">
                  A verification code has been sent to <strong className="text-core-black">{confirmEmail}</strong>.{' '}
                  <br />
                  Please enter below.
                </p>

                <div className="w-1/2 mx-auto flex pt-8 space-y-2 flex-col">
                  <Input
                    type="text"
                    labelInput="verification code"
                    placeholder="Enter your code"
                    isRequiredInput={true}
                    name="code"
                    maxlength={6}
                    onChange={(e) => {
                      handleChange(e);
                      setButtonsDisabled(false);
                    }}
                    value={values.code || ''}
                  />

                  <Input
                    type="password"
                    labelInput="password"
                    placeholder="Enter your new password"
                    isRequiredInput={true}
                    name="password"
                    onChange={(e) => {
                      handleChange(e);
                      setButtonsDisabled(false);
                    }}
                    value={values.password}
                  />
                </div>

                <div className="w-56 mx-auto my-4">
                  <Button variant="fill" color="primary" type="submit" disabled={buttonsDisabled}>
                    Reset Password
                  </Button>
                </div>
              </div>
            </div>
            {alerts}
          </Form>
        </>
      )}
    </Formik>
  );
};

export default ChangePasswordFormV2;
