import React, { useEffect, useState } from 'react';
import Avatar from 'src/app/shared/components/Avatar/Avatar';
import { ReactComponent as ArrowUpRight } from '../../../../styles/assets/icons/outline/arrowUpRight.svg';
import { ReactComponent as ChatBubble } from '../../../../styles/assets/icons/outline/chatBubbleLeft.svg';
import { ReactComponent as FilterIcon } from '../../../../styles/assets/icons/solid/filter.svg';
import ModalRightSide from 'src/app/shared/components/ModalRightSide/ModalRightSide';
import { useGetAllBuyerProfiles, useGetBuyerProfile, usePatchBuyerProfile } from 'src/hooks/useBuyersProfile';
import { BProfile, BuyerProfiles } from 'src/types/BuyerProfile';
import { UsStatesOptions } from 'src/app/shared/ListingEnums';
import Tooltip from 'src/app/shared/components/Tooltip/Tooltip';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import { LoadingIcon } from 'src/styles/assets/icons/Icons';
import { ReactComponent as CheckCircle } from '../../../../styles/assets/icons/solid/checkCircle.svg';
import { useHistory } from 'react-router';
import { createMessage } from 'src/api/messages-new';
import { useGetUser } from 'src/hooks/useOnboarding';
import HomeListingFiltersBar, { PriceFilter } from 'src/app/shared/components/Filters/FiltersBar';
import EmptyState from 'src/app/shared/components/emptyState/EmptyState';
import Button from 'src/app/shared/components/Buttons/Button';
import CakeBPIcon from '../../../../styles/assets/logos/cakeBPIcon.svg';
import { useGetSubscription } from 'src/hooks';

const BuyersCards = () => {
  const { data: subscriptionResponse, isPending: subscriptionPending, error: subscriptionError } = useGetSubscription();
  const { data: buyerProfilesResponse, error, isPending, refetch: refetchBuyersProfiles } = useGetAllBuyerProfiles();
  const { data: userResponse, isPending: userIsPending, error: userError } = useGetUser();
  const { data: buyerProfileResponse, refetch } = useGetBuyerProfile();
  const { mutate: updateUser, isSuccess } = usePatchBuyerProfile();

  const [buyerProfiles, setBuyerProfiles] = useState<BuyerProfiles>({ buyerProfiles: [] });

  const [page, setPage] = useState(1);
  const [visibleProfiles, setVisibleProfiles] = useState<BuyerProfiles>({ buyerProfiles: [] });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserUid, setSelectedUserUid] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreProfiles, setHasMoreProfiles] = useState(true);
  const { alerts, createToast } = useAlert();
  const [loadingButton, setLoadingButton] = useState<string | null>(null);
  const [successButton, setSuccessButton] = useState<string | null>(null);
  const [clickedButton, setClickedButton] = useState<string | null>(null);
  const [username, setUsername] = useState<string>('');
  const [totalPremium, setTotalPremium] = useState<PriceFilter>({ min: '', max: '' });
  const [listingState, setListingState] = useState<Array<string>>([]);
  const [lineOfBusiness, setLineOfBusiness] = useState<Array<string>>([]);
  const [carrier, setCarrier] = useState<Array<string>>([]);
  const [buyerLocation, setBuyerLocation] = useState<Array<string>>([]);
  const history = useHistory();
  const [subscriptionName, setSubscriptionName] = useState<string>('Starter');

  const userUid = userResponse?.userProfile?.uid;

  useEffect(() => {
    if (!userError && !userIsPending && userResponse) {
      const userName = userResponse.userProfile?.username;
      setUsername(userName);
    }
  }, [userError, userIsPending, userResponse]);

  const openModalContactBuyer = (buyerProfile: any) => {
    setSelectedUserUid(buyerProfile.userUid);
    setIsModalOpen(true);
  };

  const handleGotoInbox = () => {
    history.push('/inbox');
  };

  const handleContactBuyer = async (buyerProfileUid: string) => {
    const messageThreadUid = buyerProfileUid;
    setClickedButton(messageThreadUid);
    setLoadingButton(messageThreadUid);
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const messageContent = `Request sent from ${username}`;
      const sliceUid = null;
      await createMessage(messageContent, messageThreadUid, sliceUid, null);
      createToast({ text: 'Request sent! Check the inbox', variant: 'success' });
      setSuccessButton(messageThreadUid);
      setTimeout(() => {
        setSuccessButton(null);
      }, 10000);
    } catch (error) {
      console.error('Error sending message:', error);
      createToast({ text: 'Error sending request', variant: 'warning' });
    } finally {
      setLoadingButton(null);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (buyerProfilesResponse) {
      setBuyerProfiles(buyerProfilesResponse);
    } else if (error) {
      console.error(error);
    }
  }, [buyerProfilesResponse, error]);

  useEffect(() => {
    if (!subscriptionPending && !subscriptionError && subscriptionResponse) {
      setSubscriptionName(subscriptionResponse.userSubscription);
    }
  }, [subscriptionPending, subscriptionError, subscriptionResponse]);

  useEffect(() => {
    const filteredProfiles = buyerProfiles.buyerProfiles.filter((profile) => {
      const isCarrierMatch = carrier.length === 0 || carrier.some((c) => profile.carrierFilters.includes(c));

      const isListingStateMatch =
        listingState.length === 0 ||
        listingState.some((state) => getStateFullNames(profile.stateFilters)?.includes(state));

      const isLineOfBusinessMatch =
        lineOfBusiness.length === 0 || lineOfBusiness.some((lob) => profile.lobFilters.includes(lob));

      const isTotalPremiumInRange =
        (!totalPremium.min || profile.totalPremiumMin >= Number(totalPremium.min)) &&
        (!totalPremium.max || profile.totalPremiumMax <= Number(totalPremium.max));

      const isBuyerLocationMatch =
        buyerLocation.length === 0 ||
        buyerLocation.some((buyerState) => {
          const fullStateName = buyerState;

          return (
            profile.state &&
            (profile.state.includes(buyerState) ||
              (fullStateName && getStateFullName(profile.state).includes(fullStateName)))
          );
        });

      return (
        isCarrierMatch && isListingStateMatch && isLineOfBusinessMatch && isTotalPremiumInRange && isBuyerLocationMatch
      );
    });

    const sortedProfiles = filteredProfiles.sort((a, b) => {
      return a.userUid === userUid ? -1 : b.userUid === userUid ? 1 : 0;
    });

    const startIndex = 0;
    const endIndex = buyerProfileResponse?.buyerProfile.profilePublished ? 6 : 5;
    setVisibleProfiles({
      buyerProfiles: sortedProfiles.slice(startIndex, page * endIndex),
    });
  }, [
    buyerProfiles,
    page,
    carrier,
    listingState,
    lineOfBusiness,
    totalPremium,
    buyerLocation,
    buyerProfileResponse,
    userUid,
  ]);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (scrollTop + clientHeight >= scrollHeight - 10 && !isLoading && hasMoreProfiles) {
      setIsLoading(true);

      setTimeout(() => {
        const newPage = page + 1;
        setPage(newPage);

        const startIndex = (newPage - 1) * 6;
        const endIndex = startIndex + 3;
        const newVisibleProfiles = buyerProfiles.buyerProfiles.slice(startIndex, endIndex);

        if (newVisibleProfiles.length > 0) {
          setVisibleProfiles({
            buyerProfiles: [...visibleProfiles.buyerProfiles, ...newVisibleProfiles],
          });
        } else {
          setHasMoreProfiles(false);
        }

        setIsLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const getTotalBuyerProfiles = () => {
    return visibleProfiles.buyerProfiles.length;
  };

  const getInitials = (firstName: string, lastName: string): string => {
    const firstInitial = firstName.charAt(0);
    const lastInitial = lastName.charAt(0);
    return `${firstInitial}${lastInitial}`;
  };

  const getStateFullName = (stateCode: string): string => {
    return UsStatesOptions[stateCode as keyof typeof UsStatesOptions];
  };

  const getStateFullNames = (stateCodes: string[]): string[] => {
    return stateCodes.map((stateCode) => UsStatesOptions[stateCode as keyof typeof UsStatesOptions]);
  };

  const transformStateFilters = (stateFilters: string[]): string[] => {
    return stateFilters.map((state) => {
      if (state.length > 2) {
        const foundCode = Object.keys(UsStatesOptions).find(
          (code) => UsStatesOptions[code as keyof typeof UsStatesOptions] === state
        );

        return foundCode || state;
      }

      return state;
    });
  };

  const formatPremiumRange = (min: number, max: number) => {
    const formatValue = (value: number) => {
      if (value < 1000000) {
        return `$${(value / 1000).toFixed(1)}k`;
      } else {
        return `$${(value / 1000000).toFixed(1)}m`;
      }
    };

    const formattedMin = formatValue(min);
    const formattedMax = formatValue(max);

    return `${formattedMin} - ${formattedMax}`;
  };

  const handleTotalPremiumChange = (value: PriceFilter): void => {
    setTotalPremium(value);
  };

  const handleStateChange = (value: Array<string>): void => {
    setListingState(value);
  };

  const handleLineOfBusinessChange = (value: Array<string>): void => {
    setLineOfBusiness(value);
  };

  const handleCarrierChange = (value: Array<string>): void => {
    setCarrier(value);
  };

  const handleBuyerLocationChange = (value: Array<string>): void => {
    setBuyerLocation(value);
  };

  const getBuyingOption = (buyingSlices: string) => {
    if (buyingSlices === 'Yes') {
      return 'Slices & Full Books';
    } else {
      return 'Full Books Only';
    }
  };

  const handleGotoPricingPage = () => {
    history.push(`/account/pricing`);
  };

  const showSuccessMessage = () => {
    createToast({ text: 'Account has been updated successfully', variant: 'success' });
  };

  const handlePublicMyProfile = async (publicValue: boolean) => {
    const updatedProfilePublished = publicValue;
    try {
      const transformedData: BProfile = {
        uid: buyerProfileResponse?.buyerProfile.uid || '',
        buyingTimeline: buyerProfileResponse?.buyerProfile.buyingTimeline || '',
        buyingSlices: buyerProfileResponse?.buyerProfile.buyingSlices || '',
        profileDescription: buyerProfileResponse?.buyerProfile.profileDescription || '',
        profileDescriptionApproved: buyerProfileResponse?.buyerProfile.profileDescriptionApproved || false,
        totalPremiumMin: buyerProfileResponse?.buyerProfile.totalPremiumMin || 0,
        totalPremiumMax: buyerProfileResponse?.buyerProfile.totalPremiumMax || 0,
        profilePublished: updatedProfilePublished,
        stateFilters: buyerProfileResponse?.buyerProfile.stateFilters || [],
        carrierFilters: buyerProfileResponse?.buyerProfile.carrierFilters || [],
        lobFilters: buyerProfileResponse?.buyerProfile.lobFilters || [],
      };

      updateUser(transformedData);
      showSuccessMessage();
    } catch (error) {
      createToast({ text: 'We have not been able to update the data', variant: 'danger' });
      console.error('Error data:', error);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      refetchBuyersProfiles();
      refetch();
    }
  }, [isSuccess]);

  if (isPending) {
    return (
      <div className="w-full mb-12 z-0">
        <Skeleton type="buyerTab" />
      </div>
    );
  }

  return (
    <div>
      <div className="w-full my-3">
        <HomeListingFiltersBar
          handleTotalPremiumChange={handleTotalPremiumChange}
          handleStateChange={handleStateChange}
          handleLineOfBusinessChange={handleLineOfBusinessChange}
          handleCarrierChange={handleCarrierChange}
          handleBuyerLocationChange={handleBuyerLocationChange}
          buyerLocation={true}
          moreFilter={false}
        />
      </div>
      <div className="w-full flex mb-4">
        <div className="w-1/2 flex justify-start">
          <span className="text-core-accentText text-sm font-normal">
            Showing <strong className="text-core-black">{getTotalBuyerProfiles()}</strong> of
            <strong className="text-core-black"> {buyerProfilesResponse?.buyerProfiles.length}</strong> profiles
          </span>
        </div>

        {visibleProfiles.buyerProfiles.length > 0 && (
          <div className="w-1/2 flex justify-end">
            {(carrier.length > 0 ||
              listingState.length > 0 ||
              lineOfBusiness.length > 0 ||
              totalPremium.min ||
              totalPremium.max ||
              buyerLocation.length > 0) && (
              <span className="text-core-accentBlue uppercase text-sm font-bold flex gap-2">
                <FilterIcon className="h-4 w-4 my-auto" />
                Filter selections within listings
              </span>
            )}
          </div>
        )}
      </div>

      {visibleProfiles.buyerProfiles.length === 0 ? (
        <EmptyState text="There are no listings matching this criteria" help={false} />
      ) : (
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 relative">
          {!buyerProfileResponse?.buyerProfile.profilePublished && (
            <div className="border-1 min-h-[380px] border-neutral-200 rounded-[4px] p-4 border-dashed bg-core-lightGrey flex flex-col justify-center">
              <img src={CakeBPIcon} className="h-[70px] w-[70px] mx-auto block" />
              <h1 className="text-center text-2xl mt-6 mb-3 font-medium text-core-black">Your Profile here</h1>
              <p className="text-center text-xs font-medium text-core-black">
                Boost your visibility and connect with Sellers.
              </p>
              {subscriptionName === 'Pro' || userResponse?.userProfile.hasActiveListing ? (
                <div className="w-fit mx-auto block">
                  <Button variant="fill" color="primary" onClick={() => handlePublicMyProfile(true)}>
                    Publish my profile
                  </Button>
                </div>
              ) : (
                <div className="w-fit mx-auto block">
                  <Button variant="fill" color="primary" onClick={handleGotoPricingPage}>
                    Upgrade to Pro
                  </Button>
                </div>
              )}
            </div>
          )}

          {visibleProfiles.buyerProfiles.map((profile) => (
            <div className="relative">
              <div
                key={profile.userUid}
                className="bg-white z-0 rounded hover:z-50 p-4 border-1 hover:border-blue-600 !border-tableBorder h-[460px] min-h-[381px] flex flex-col justify-between group cursor-pointer hover:shadow-md transition ease-in"
                onClick={(event) => {
                  if (event.target instanceof Element && event.target.closest && !event.target.closest('button')) {
                    openModalContactBuyer(profile);
                  }
                }}
              >
                <div className="w-full flex mb-3 gap-4">
                  <div className="w-fit flex justify-start my-auto">
                    <Avatar size="md" userName={getInitials(profile.firstName, profile.lastName)} />
                  </div>
                  <div className="w-full flex flex-col justify-between text-left">
                    <h3 className="text-core-black text-lg font-medium m-0 p-0">
                      {profile.firstName} {profile.lastName}
                    </h3>
                    <p className="text-core-black text-xs font-medium m-0 p-0">{profile.agencyName}</p>
                    <p className="text-core-accentText uppercase m-0 p-0 text-2xs">
                      {getStateFullName(profile.state)} • {profile.yearsExperience} YEARS EXP.
                    </p>
                  </div>
                  <div className="flex justify-end h-fit w-fit">
                    <button
                      className="transition ease-in hover:bg-gray-50 border rounded-full p-2"
                      onClick={() => openModalContactBuyer(profile)}
                    >
                      <ArrowUpRight className="h-5 w-5" />
                    </button>
                  </div>
                </div>

                <div className="flex gap-2 w-full mb-4">
                  <div className="rounded py-1 px-2 uppercase bg-blue-50 text-core-textNavy text-2xs text-left">
                    {profile.buyingTimeline}
                  </div>

                  <div className="rounded py-1 px-2 uppercase bg-blue-50 text-core-textNavy text-2xs text-left">
                    {getBuyingOption(profile.buyingSlices)}
                  </div>
                </div>

                <div className="border-t border-neutral-50 grid grid-cols-2 pt-4 pr-3 gap-6 text-left">
                  <div>
                    <span className="uppercase text-core-accentText text-2xs">PREMIUM RANGE</span>
                    <p className="text-core-black text-sm font-medium">
                      {formatPremiumRange(profile.totalPremiumMin, profile.totalPremiumMax)}
                    </p>
                  </div>

                  <div>
                    <span className="uppercase text-core-accentText text-2xs">LINES</span>
                    <div
                      className={`text-sm font-medium ${
                        profile.lobFilters.length > 0 ? 'text-core-black' : 'text-gray-500'
                      }`}
                    >
                      {profile.lobFilters.length > 0 && profile.lobFilters.some((lob) => lob.trim() !== '') ? (
                        (() => {
                          const filteredLobFilters = profile.lobFilters.filter((lob) => lob.trim() !== '');
                          const sortedLobFilters = filteredLobFilters.slice();
                          const firstLob = sortedLobFilters.shift();

                          return (
                            <span>
                              {String(firstLob)}
                              {sortedLobFilters.length > 0 ? (
                                <Tooltip
                                  title="INCLUDES"
                                  position="left-top"
                                  list={sortedLobFilters.slice().join(', ')}
                                >
                                  <strong
                                    className={`text-xs font-semibold ml-1 ${
                                      sortedLobFilters
                                        .slice(1)
                                        .some((lob) => lineOfBusiness && sortedLobFilters.includes(lob)) &&
                                      firstLob &&
                                      lineOfBusiness.length > 1 &&
                                      filteredLobFilters.includes(firstLob)
                                        ? 'text-core-accentBlue'
                                        : 'text-gray-500'
                                    }`}
                                  >
                                    {` +${String(sortedLobFilters.length)}`}
                                  </strong>
                                </Tooltip>
                              ) : null}
                            </span>
                          );
                        })()
                      ) : (
                        <span className="text-gray-500">Open to All</span>
                      )}
                    </div>
                  </div>

                  <div>
                    <span className="uppercase text-core-accentText text-2xs">STATES</span>
                    <div
                      className={`text-sm font-medium ${
                        profile.stateFilters.length > 0 ? 'text-core-black' : 'text-gray-500'
                      }`}
                    >
                      {profile.stateFilters.length > 0 && profile.stateFilters.some((state) => state.trim() !== '') ? (
                        (() => {
                          const filteredStates = transformStateFilters(
                            profile.stateFilters.filter((state) => state.trim() !== '')
                          );
                          const sortedStates = filteredStates.slice();
                          const firstState = sortedStates.shift();

                          return (
                            <span>
                              {getStateFullName(firstState ? firstState : '')}
                              {sortedStates.length > 0 ? (
                                <Tooltip
                                  title="INCLUDES"
                                  position="left-top"
                                  list={sortedStates.map((state) => getStateFullName(state)).join(', ')}
                                >
                                  <strong
                                    className={`text-xs font-semibold ml-1 ${
                                      sortedStates
                                        .slice(1)
                                        .some((state) => listingState && filteredStates.includes(state)) &&
                                      firstState &&
                                      listingState.length > 0 &&
                                      filteredStates.includes(firstState)
                                        ? 'text-core-accentBlue'
                                        : 'text-gray-500'
                                    }`}
                                  >
                                    {` +${String(sortedStates.length)}`}
                                  </strong>
                                </Tooltip>
                              ) : null}
                            </span>
                          );
                        })()
                      ) : (
                        <span className="text-gray-500">Open to All</span>
                      )}
                    </div>
                  </div>

                  <div>
                    <span className="uppercase text-core-accentText text-2xs">CARRIERS</span>
                    <div
                      className={`text-sm font-medium ${
                        profile.carrierFilters.length > 0 ? 'text-core-black' : 'text-gray-500'
                      }`}
                    >
                      {profile.carrierFilters.length > 0 &&
                      profile.carrierFilters.some((carrier) => carrier.trim() !== '') ? (
                        (() => {
                          const filteredCarriers = profile.carrierFilters.filter((carrier) => carrier.trim() !== '');
                          const sortedCarriers = filteredCarriers.slice();
                          const firstCarrier = sortedCarriers.shift();

                          return (
                            <span>
                              {String(firstCarrier)}{' '}
                              {sortedCarriers.length > 0 ? (
                                <Tooltip title="INCLUDES" position="left-top" list={sortedCarriers.slice().join(', ')}>
                                  <strong
                                    className={`text-xs font-semibold ml-1 ${
                                      sortedCarriers
                                        .slice(1)
                                        .some((carrier) => carrier && filteredCarriers.includes(carrier)) &&
                                      firstCarrier &&
                                      carrier.length > 1 &&
                                      filteredCarriers.includes(firstCarrier)
                                        ? 'text-core-accentBlue'
                                        : 'text-gray-500'
                                    }`}
                                  >
                                    {` +${String(sortedCarriers.length)}`}
                                  </strong>
                                </Tooltip>
                              ) : null}
                            </span>
                          );
                        })()
                      ) : (
                        <span className="text-gray-500">Open to All</span>
                      )}
                    </div>
                  </div>
                </div>

                {profile.profileDescriptionApproved ? (
                  <div className="w-full mt-6 text-left">
                    <span className="uppercase text-core-accentText text-2xs">Buyer bio</span>
                    <p className="text-core-black text-xs font-medium line-clamp-2" style={{ whiteSpace: 'pre-line' }}>
                      {profile.profileDescription.replace(/<br\s*\/?>/g, '\n').replace(/<\/?[^>]+(>|$)/g, '')}
                    </p>
                  </div>
                ) : null}

                <div className="w-full mt-auto flex justify-end">
                  {userUid !== profile.userUid && (
                    <button
                      className="w-fit flex border border-core-black text-core-black rounded gap-2 text-xs px-3 py-2 whitespace-nowrap"
                      onClick={() => {
                        clickedButton === profile.userUid ? handleGotoInbox() : handleContactBuyer(profile.userUid);
                      }}
                      disabled={loadingButton === profile.userUid || successButton === profile.userUid}
                    >
                      {loadingButton === profile.userUid ? (
                        <LoadingIcon className="w-4 h-4 text-gray-200 animate-spin fill-core-black mx-auto" />
                      ) : successButton === profile.userUid ? (
                        <CheckCircle className="w-4 h-4 text-core-black mx-auto" />
                      ) : (
                        <>
                          <ChatBubble className="w-4 h-4 my-auto z-10" />
                          {clickedButton === profile.userUid ? 'Go to Inbox' : 'Contact Buyer'}
                        </>
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {alerts}

      {isLoading && visibleProfiles.buyerProfiles.length !== 0 && (
        <div className="w-full flex justify-center mt-4">
          <Skeleton type="loading-more" />
        </div>
      )}

      {!hasMoreProfiles && (
        <div className="w-full flex justify-center mt-5">
          <p className="text-core-accentText mt-3 text-md font-medium">No more results available</p>
        </div>
      )}

      <ModalRightSide
        isOpen={isModalOpen}
        onClose={closeModal}
        buyerProfile={buyerProfiles.buyerProfiles.find((profile) => profile.userUid === selectedUserUid)}
      />
    </div>
  );
};

export default BuyersCards;
