import React, { useEffect, useState } from 'react';
import Input from 'src/app/shared/components/Inputs/Input';
import { useGetUser } from 'src/hooks/useOnboarding';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';
import Button from 'src/app/shared/components/Buttons/Button';
import { ReactComponent as Info } from '../../../../styles/assets/icons/outline/notificationOutline.svg';
import Tooltip from 'src/app/shared/components/Tooltip/Tooltip';
import { Form, Formik } from 'formik';
import { changePasswordUser } from 'src/api/user';
import useAlert from 'src/app/shared/components/Toast/useAlert';

const Security = () => {
  const { data: userResponse, isError, isPending } = useGetUser();
  const [, setLoading] = useState(true);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const { alerts, createToast } = useAlert();

  const [userData, setUserData] = useState(userResponse);

  useEffect(() => {
    if (userResponse && 'userProfile' in userResponse && 'agencyInfo' in userResponse) {
      setUserData(userResponse);
    } else {
      console.log(isError);
    }
    setLoading(false);
  }, [userResponse]);

  if (isPending) {
    return (
      <div className="w-full mb-12 z-0">
        <Skeleton type="form-details" />
      </div>
    );
  }

  const initialValues = {
    oldPassword: '',
    newPassword: '',
  };

  const showSuccessMessage = () => {
    setButtonsDisabled(true);
    createToast({ text: 'Your password has been updated successfully', variant: 'success' });
  };

  const onSubmit = async (values: any, { resetForm }: any) => {
    try {
      await changePasswordUser(values.oldPassword, values.newPassword);
      setButtonsDisabled(true);
      resetForm();
      showSuccessMessage();
    } catch (error: any) {
      console.error('Error data:', error);
      if (error.response && error.response.status === 500) {
        if (error.response.data && error.response.data.detail) {
          createToast({ text: `Confirm the requirements for change your password`, variant: 'warning' });
        } else {
          createToast({ text: 'Error sending your request', variant: 'warning' });
        }
      } else {
        createToast({
          text: 'Old password incorrect. Please update and try again.',
          variant: 'danger',
        });
      }
    }
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize>
      {({ values, handleChange }) => (
        <>
          <Form>
            <div className="w-full flex mb-12 z-0">
              <div className="w-1/2 flex justify-start">
                <h1 className="text-core-black text-2xl text-left">Login Details</h1>
              </div>

              <div className="justify-end w-1/2 flex gap-3">
                <div>
                  <Button variant="fill" color="secondary" type="submit" disabled={buttonsDisabled}>
                    Save
                  </Button>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="text-lg font-medium text-core-black mb-8 text-left">Email Address</h2>

              <div className="w-full">
                <Input
                  type="email"
                  placeholder="User email"
                  labelInput="EMAIL ADDRESS"
                  isRequiredInput={true}
                  value={userData?.userProfile?.email || ''}
                  disabled={true}
                />
              </div>
            </div>

            <div className="w-full flex flex-col mt-12">
              <h2 className="text-lg font-medium text-core-black mb-8 text-left flex gap-2">
                Change your Password
                <div className="mt-1.5">
                  <Tooltip
                    title="Create New Password"
                    text="Password requirements Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                    position="right-full"
                  >
                    <Info className="h-5 w-5 my-auto" />
                  </Tooltip>
                </div>
              </h2>

              <div className="w-full flex gap-4 flex-col md:flex-row">
                <div className="md:w-1/2 w-full">
                  <Input
                    type="password"
                    placeholder="Enter password"
                    labelInput="OLD PASSWORD"
                    variant={false}
                    isRequiredInput={true}
                    name="oldPassword"
                    value={values.oldPassword}
                    onChange={(e) => {
                      handleChange(e);
                      setButtonsDisabled(false);
                    }}
                  />
                </div>
                <div className="md:w-1/2 w-full">
                  <Input
                    type="password"
                    placeholder="Enter password"
                    variant={false}
                    labelInput="NEW PASSWORD"
                    isRequiredInput={true}
                    name="newPassword"
                    value={values.newPassword}
                    onChange={(e) => {
                      handleChange(e);
                      setButtonsDisabled(false);
                    }}
                  />
                </div>
              </div>
            </div>
            {alerts}
          </Form>
        </>
      )}
    </Formik>
  );
};

export default Security;
