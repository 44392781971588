import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Onboarding } from '../components/Onboarding';
import { useHistory } from 'react-router';

const BookUpload = () => {
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState('');
  const buttonClass =
    'border-1 rounded py-6 px-8 text-core-gray hover:bg-yellow-100 hover:border-black hover:text-core-black transition ease-in whitespace-nowrap';
  const activeClass = 'text-core-black bg-yellow-100 border-core-black ' + buttonClass;

  const { handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      bookUpload: '',
    },
    onSubmit: () => {
      history.push('/onboarding/interests');
    },
  });

  const handleButtonClick = (value: string) => {
    setSelectedOption(value);
    setFieldValue('buyingSlices', value);
  };
  return (
    <Onboarding handleSubmit={handleSubmit} isBack={true} isContinue={true}>
      <div className="w-full md:w-3/5 mx-auto block py-20 px-8 md:px-32 text-left gap-8 mt-10">
        <div className="w-full">
          <p className="text-center text-sm text-gray-600 font-bold mx-0 p-0 mb-8">Book Upload</p>
        </div>

        <div className="w-full">
          <h1 className="text-core-black text-3xl md:text-5xl font-medium text-center">
            Would you like to upload your book?
          </h1>
        </div>

        <div className="w-full mt-4">
          <p className="text-center text-sm text-gray-600 font-bold mx-0 p-0 mb-8">Benefits of book upload include:</p>
        </div>

        <div className="w-full md:px-0 px-2 md:w-[510px] block mx-auto mt-10">
          <div className="w-full rounded bg-yellow-50 justify-center mx-auto p-4 flex flex-col space-y-4">
            <div className="w-full flex">
              <div className="w-fit">
                <span
                  className="text-core-yellow text-5xl text-center mx-2"
                  style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}
                >
                  *
                </span>
              </div>
              <div className="w-full">
                <p className="text-core-black text-base font-medium p-0 m-0">
                  Your Buyer Profile is automatically filled based on your book's characteristics for easy listing
                  discovery and timely notifications.
                </p>
              </div>
            </div>
            <div className="w-full flex">
              <div className="w-fit">
                <span
                  className="text-core-yellow text-5xl text-center mx-2"
                  style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}
                >
                  *
                </span>
              </div>
              <div className="w-full">
                <p className="text-core-black text-base font-medium p-0 m-0">
                  Explore a breakdown of your book and ways to optimize it with our Book Insights tools.
                </p>
              </div>
            </div>
            <div className="w-full flex !-mb-4">
              <div className="w-fit">
                <span
                  className="text-core-yellow text-5xl text-center mx-2"
                  style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}
                >
                  *
                </span>
              </div>
              <div className="w-full">
                <p className="text-core-black text-base font-medium p-0 m-0">
                  Quickly value your book using our Book Valuation tool.
                </p>
              </div>
            </div>
            <div className="w-full flex !-mb-4">
              <div className="w-fit">
                <span
                  className="text-core-yellow text-5xl text-center mx-2"
                  style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}
                >
                  *
                </span>
              </div>
              <div className="w-full">
                <p className="text-core-black text-base font-medium p-0 m-0">
                  List all or parts of your book quickly and easily.
                </p>
              </div>
            </div>
            <div className="w-full flex">
              <div className="w-fit">
                <span
                  className="text-core-yellow text-5xl text-center mx-2"
                  style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}
                >
                  *
                </span>
              </div>
              <div className="w-full">
                <p className="text-core-black text-base font-medium p-0 m-0">
                  No sensitive customer data is stored in Cake.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full my-12 grid grid-cols-2 gap-2">
          <div className="flex justify-end">
            <button
              className={selectedOption === 'Yes' ? activeClass : buttonClass}
              onClick={() => handleButtonClick('Yes')}
            >
              Yes
            </button>
          </div>
          <div className="flex justify-start">
            <button
              className={selectedOption === 'No' ? activeClass : buttonClass}
              onClick={() => handleButtonClick('No')}
            >
              No
            </button>
          </div>

          <div className="hidden">
            <input
              type="text"
              value={values.bookUpload}
              onChange={(e) => {
                setFieldValue('bookUpload', e.target.value);
              }}
            />
          </div>
        </div>

        <div className="w-full px-20 mb-20 mt-12">
          {selectedOption === 'Yes' && (
            <p className="text-base text-center text-core-grey font-normal">
              Thanks for your interest. We're working hard to deliver this feature, but it's not quite ready. We'll let
              you know when it is!
            </p>
          )}
        </div>
      </div>
    </Onboarding>
  );
};

export default BookUpload;
