import { BASEREQUESTOPTIONS } from 'src/api/_constants';
import { MapFieldsData } from 'src/types/MapFields';

export const getMapFields = async (): Promise<MapFieldsData> => {
  const url = `/trantor/map-fields`;

  const options = {
    ...BASEREQUESTOPTIONS,
    method: 'GET',
  };

  const response = await fetch(url, options);
  return response.json();
};

export const patchMapFields = async (combinedList: any[]) => {
  const carrierItems = combinedList
    .filter((item) => item.indicator === 'CARRIER')
    .map((item) => ({ uid: item.uid, mapped: item.mapped || item.selectedMapped }));

  const lobItems = combinedList
    .filter((item) => item.indicator === 'LINE')
    .map((item) => ({ uid: item.uid, mapped: item.mapped || item.selectedMapped }));

  const data = { carrier: carrierItems, lob: lobItems };

  const url = `/trantor/map-fields`;
  const body = {
    carrier: data.carrier,
    lob: data.lob,
  };

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'PATCH',
    body: JSON.stringify(body),
  };

  const response = await fetch(url, requestOptions);
  return response.json();
};
