import { BASEREQUESTOPTIONS } from 'src/api/_constants';
import { Analytics } from 'src/types/Analytics';

export const getAnalytics = async (): Promise<Analytics> => {
  const url = `/trantor/analytics/link`;

  const options = {
    ...BASEREQUESTOPTIONS,
    method: 'GET',
  };

  const response = await fetch(url, options);
  return response.json();
};
