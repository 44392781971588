import React from 'react';
import { SlicesGroup } from 'src/types/Policies';

interface GroupSectionProps {
  filteredSlicesData: SlicesGroup[];
}

const GroupSection: React.FC<GroupSectionProps> = ({ filteredSlicesData }) => {
  return (
    <>
      {filteredSlicesData.map((slicesGroup, index) => (
        <div key={index} className="w-full bg-neutral-50 py-4 flex gap-3">
          <div className="w-full flex justify-start items-center gap-3">
            <div className="pl-4 pr-0 py-3">
              <div
                className={`h-2.5 w-2.5 bg-core-black border-core-black -mb-2.5 border-2 z-10 relative rounded-tl-full`}
              ></div>
              <div className="h-5 w-5 rounded-full border-2 border-gray-400 border-dashed z-0"></div>
            </div>
            <div className="w-fit text-left">
              {slicesGroup.groups.length > 0 && (
                <>
                  <h3 className="font-medium text-base p-0 m-0">{slicesGroup.groups[0].name}</h3>
                  <div className="flex w-fit text-gray-600 uppercase text-xs font-bold gap-1">
                    <p className="p-0 m-0">{`${slicesGroup.groups[0].totalPremium.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })} Total Premium`}</p>
                    <p className="text-gray-400 p-0 m-0">•</p>
                    <p className="p-0 m-0">{`${slicesGroup.groups[0].totalPolicies} Policies`}</p>
                    <p className="text-gray-400 p-0 m-0">•</p>
                    <p className="p-0 m-0">{`${slicesGroup.groups[0].totalCustomers} Customers`}</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export default GroupSection;
