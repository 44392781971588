import { useQuery } from '@tanstack/react-query';
import { fetchSliceDetails, getMarketplaceListingById, getMarketplaceListings } from 'src/api/marketplace';

export const useGetMarketplaceListings = () => {
  return useQuery({ queryKey: ['getMarketplaceListings'], queryFn: () => getMarketplaceListings() });
};

export const useGetSliceDetails = (uid: string) => {
  return useQuery({
    queryKey: ['getSliceDetails', uid],
    queryFn: () => fetchSliceDetails(uid),
    enabled: !!uid,
  });
};

export const useGetMarketplaceListingByIdFree = (uid: string) => {
  return useQuery({
    queryKey: ['getMarketplaceListingById', uid],
    queryFn: () => getMarketplaceListingById(uid),
  });
};
