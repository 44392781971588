import React, { useEffect, useState } from 'react';
import { Onboarding } from '../components/Onboarding';
import ModalFilter from 'src/app/shared/components/Filters/ModalFilter';
import { CommercialListValues, PersonalListValues } from 'src/app/shared/ListingEnums';
import { FilterOptions } from 'src/types/domain/Filters';
import InputMultiple from 'src/app/shared/components/Inputs/InputMultiple';
import { useFormik } from 'formik';
import { useGetBuyerProfile, usePatchBuyerProfile } from 'src/hooks/useBuyersProfile';
import { useHistory } from 'react-router';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';

type EnumType = { [key: string]: string };

const LobBuyerProfile = () => {
  const history = useHistory();
  const { mutate, isSuccess } = usePatchBuyerProfile();
  const { data: buyerProfileResponse, isPending } = useGetBuyerProfile();
  const [, setBuyerProfile] = useState(buyerProfileResponse);
  const [selectedValuesLob, setSelectedValuesLob] = useState<string[]>([]);
  const allListValues = [...CommercialListValues, ...PersonalListValues];

  useEffect(() => {
    if (buyerProfileResponse) {
      setBuyerProfile(buyerProfileResponse);
      setSelectedValuesLob(buyerProfileResponse.buyerProfile.lobFilters || []);
    }
  }, [buyerProfileResponse]);

  const determineListValues = (): string[] => {
    return allListValues.map((item) => item.description);
  };

  const renderCustomSelectOptions = (listingsEnumObj: EnumType, showCode = false): FilterOptions[] => {
    const entries = Object.entries(listingsEnumObj);
    const listValues = determineListValues();

    return entries.map(([value, label]) => {
      const item = allListValues.find((c) => c.code === value);

      if (item && item.description && showCode) {
        return {
          label: `${value} | ${label}`,
          value: listValues.includes(item.description) ? item.description : undefined,
        };
      } else {
        return {
          label,
          value: listValues.includes(label) ? label : undefined,
        };
      }
    });
  };

  function convertArrayToEnumType(arr: { code: string; description: string }[]): EnumType {
    const enumType: EnumType = {};
    arr.forEach((item) => {
      enumType[item.code] = item.description;
    });
    return enumType;
  }

  const commercialEnum: EnumType = convertArrayToEnumType(CommercialListValues);
  const personalEnum: EnumType = convertArrayToEnumType(PersonalListValues);

  const renderAllOptions = (): FilterOptions[] => {
    return renderCustomSelectOptions({ ...commercialEnum, ...personalEnum }, true);
  };

  const customSelectFilterOptions = {
    type: renderAllOptions(),
  };

  const handleAddFilters = (selectedFilters: string[], filterType: string) => {
    if (filterType === 'lineOfBusinessFilters') {
      setSelectedValuesLob(selectedFilters);
    }
  };

  const handleSaveBuyerProfile = (selectedItems: any, filterType: string) => {
    if (filterType === 'lineOfBusinessFilters') {
      setSelectedValuesLob(selectedItems.map((item: any) => item.value));
    }
    if (selectedItems && selectedItems.length > 0) {
      if (selectedItems[0].type === 'lineOfBusinessFilters') {
        setSelectedValuesLob(selectedItems.map((item: any) => item.value));
      }
    }
  };

  useEffect(() => {
    setValues({
      lobFilters: selectedValuesLob as never[],
    });
  }, [selectedValuesLob]);

  const { handleChange, handleSubmit, setValues, values } = useFormik({
    initialValues: {
      lobFilters: selectedValuesLob,
    },
    onSubmit: (values) => {
      mutate(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      history.push('/onboarding/buyer-profile/premium-range');
    }
  }, [isSuccess, history]);

  if (isPending) {
    return (
      <div className="w-full mb-12 z-0">
        <Skeleton type="step-onboarding" />
      </div>
    );
  }

  return (
    <Onboarding
      handleSubmit={handleSubmit}
      isContinue={true}
      isBack={true}
      isStep={true}
      numOfSteps={7}
      stepPosition={3}
    >
      <div className="w-full md:w-3/5 mx-auto block py-20 px-8 md:px-32 text-left gap-8 mt-10">
        <div className="w-full">
          <p className="text-center text-sm text-gray-600 font-bold mx-0 p-0 mb-8">BUILD YOUR BUYER PROFILE</p>
        </div>

        <div className="w-full mb-12 mt-8">
          <h1 className="text-core-black text-3xl md:text-5xl font-medium text-center">
            What lines of business are you interested in?
          </h1>
        </div>

        <div className="w-full">
          <ModalFilter
            title="Add Lines of Business"
            filterName="Lines of Business"
            filterType={'lineOfBusinessFilters'}
            options={customSelectFilterOptions.type}
            handleAddFiltersLocalStorage={() => handleAddFilters(selectedValuesLob, 'lineOfBusinessFilters')}
            variant="Card"
            CardSubtitle="Lines of Business"
            buyerPillsSelected={selectedValuesLob}
            onSaveBuyerProfile={handleSaveBuyerProfile}
          >
            <div className="w-full">
              {selectedValuesLob.length > 0 && !selectedValuesLob.every((value) => value === '') ? (
                <InputMultiple
                  values={values.lobFilters}
                  name="lineOfBusinessFilters"
                  onChange={handleChange}
                  variant="basic"
                />
              ) : null}
            </div>
          </ModalFilter>
        </div>

        <div className="w-full mt-6">
          <p className="text-gray-700 text-base font-medium p-0 m-0 text-center">
            Open to all or have no preference? Click <strong className="text-core-black">‘Continue’</strong>.
          </p>
        </div>
      </div>
    </Onboarding>
  );
};

export default LobBuyerProfile;
