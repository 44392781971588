import React, { FC, useEffect, useState } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import PivotTableLib from './PivotTableLib';
import { ListingPolicies, Policy } from 'src/types/ListingPolicies';

interface ModalPivotTableProps {
  isOpen: boolean;
  onCloseModalPivotTable: () => void;
  sliceData?: any | undefined;
  sliceUid?: string;
  listingData?: ListingPolicies | undefined;
}

const ModalPivotTable: FC<ModalPivotTableProps> = ({
  onCloseModalPivotTable,
  isOpen,
  sliceData,
  sliceUid,
  listingData,
}) => {
  const [policies, setPolicies] = useState<Policy[]>([]);

  useEffect(() => {
    if (listingData && listingData.listing && listingData.listing.policies) {
      setPolicies(listingData.listing.policies);
    }
  }, [listingData]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className="fixed top-0 p-3 sm:px-0 left-0 w-full h-full backdrop-blur-md bg-black/50 flex justify-center justify-items-center z-[100]"
      onClick={onCloseModalPivotTable}
    >
      <div
        className="bg-white rounded md:h-[96vh] w-full md:w-[80%] h-[99vh] fixed md:right-3"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="w-full flex">
          <div className="w-full flex p-6 gap-3">
            <div className="w-fit flex justify-start">
              <button
                className="my-auto p-3 rounded-full bg-neutral-50 cursor-pointer hover:bg-neutral-100 transition ease-in"
                onClick={onCloseModalPivotTable}
              >
                <ArrowLeftIcon className={`my-auto w-3 h-3 text-core-black group-hover:text-white`} />
              </button>
            </div>
            <div className="w-full flex flex-col text-left justify-start">
              <h1 className="font-medium text-2xl text-core-black my-auto">Advanced Drilldown</h1>
              <div className="w-full flex items-center gap-2">
                <p className="text-gray-600 font-bold text-sm p-0 m-0 uppercase">{listingData?.listing?.name}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex px-6 mt-2 max-h-[80vh]">
          <div className="w-full overflow-auto">
            <PivotTableLib groupsData={policies} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPivotTable;
