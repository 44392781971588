import React from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as InfoIcon } from '../../../../styles/assets/icons/outline/notificationOutline.svg';
import { Button } from '../Buttons/Button';

interface EmptyStateProps {
  text?: string;
  help?: boolean;
  url?: string;
  image?: string;
  onClick?: () => void;
  upload?: boolean;
  createSlice?: boolean;
}

const EmptyState: React.FC<EmptyStateProps> = ({ text, url, help, image, onClick, upload, createSlice }) => {
  const history = useHistory();

  return (
    <div className="flex flex-col items-center justify-center h-[400px] w-60 mx-auto">
      {image !== undefined && image !== null ? (
        <img src={image} alt="image empty component" className="mb-3 h-auto w-20" />
      ) : (
        <InfoIcon className="w-6 h-6 mb-2 mx-auto block" />
      )}
      <p className="text-lg font-medium text-neutral-700">{text}</p>

      {upload && (
        <div className="w-fit mx-auto block">
          <Button onClick={onClick} variant="fill" color="primary">
            Import
          </Button>
        </div>
      )}

      {createSlice && (
        <div className="w-fit mx-auto block">
          <Button onClick={onClick} variant="fill" color="primary">
            Create a slice
          </Button>
        </div>
      )}

      {url && (
        <button
          className="bg-core-black rounded-md mb-12 text-white px-4 py-2 text-base"
          onClick={(): void => history.push(url)}
        >
          Create Listing
        </button>
      )}
      {help && (
        <span className="text-xs text-neutral-500">
          Need help?{' '}
          <a className="text-decoration-underline text-black" href="https://www.haveyourcake.com/contact-cake">
            Send us a message
          </a>{' '}
          and someone will be in contact shortly.
        </span>
      )}
    </div>
  );
};
export default EmptyState;
