import { ArrowLeftIcon, MagnifyingGlassIcon, TrashIcon } from '@heroicons/react/24/solid';
import React, { FC, useState } from 'react';
import { Button } from '../Buttons/Button';
import { CarrierListingOptions, CommercialListValues, PersonalListValues } from '../../ListingEnums';
import { FilterOptions } from 'src/types/domain/Filters';
import EmptyState from '../emptyState/EmptyState';

interface FilterModalSelectorProps {
  type: string;
  uidOption: string;
  onClose: () => void;
  onBack: () => void;
  onSave: (uidOption: string, selectedOption: string) => void;
}

const FilterModalSelector: FC<FilterModalSelectorProps> = ({ onClose, onBack, type, uidOption, onSave }) => {
  const listValuesLine = [...CommercialListValues, ...PersonalListValues];
  const listValuesCarrier = Object.values(CarrierListingOptions);
  const placeholderForSearch = `Search ${type === 'LINE' ? 'Line of Business' : 'Carrier'}`;
  const [searchValue, setSearchValue] = useState('');
  const listValuesLineWithFilterOptions = listValuesLine.map((item) => ({
    label: item.description,
    value: item.code,
  }));
  const listValuesCarrierWithFilterOptions = listValuesCarrier.map((item) => ({
    label: item,
    value: item,
  }));

  const [filteredLineOptions, setFilteredLineOptions] = useState<FilterOptions[]>(listValuesLineWithFilterOptions);
  const [filteredCarrierOptions, setFilteredCarrierOptions] = useState<FilterOptions[]>(
    listValuesCarrierWithFilterOptions
  );

  const [selectedOption, setSelectedOption] = useState<string>('');
  const isDisabled = !selectedOption;

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearchValue(inputValue);

    const filteredLine = listValuesLine.filter((option) =>
      option.description.toLowerCase().includes(inputValue.toLowerCase())
    );

    setFilteredLineOptions(
      filteredLine.map((item) => ({
        label: item.description,
        value: item.code,
      }))
    );

    const filteredCarrier = listValuesCarrier.filter((option) =>
      option.toLowerCase().includes(inputValue.toLowerCase())
    );

    setFilteredCarrierOptions(
      filteredCarrier.map((item) => ({
        label: item,
        value: item,
      }))
    );
  };

  const handleTrashClick = () => {
    setSearchValue('');
    setFilteredLineOptions(listValuesLineWithFilterOptions);
    setFilteredCarrierOptions(listValuesCarrierWithFilterOptions);
  };

  const handleOptionSelect = (value: string) => {
    setSelectedOption(value);
  };

  const handleSave = () => {
    onSave(uidOption, selectedOption);
    onBack();
  };

  return (
    <div className="bg-white rounded shadow-lg border w-[822px]">
      <div className="py-6 px-8 text-left border-b">
        <div className="flex w-full gap-2 items-center">
          <button
            type="button"
            className="rounded-full bg-gray-50 p-2 my-auto h-fit hover:bg-gray-100 transition ease-in"
            onClick={onBack}
          >
            <ArrowLeftIcon className="h-3 w-3" />
          </button>

          <h1 className="text-core-black text-2xl font-medium items-center my-auto">
            Select {type === 'LINE' ? 'Line of Business' : 'Carrier'}
          </h1>
        </div>

        <div className="w-full my-2">
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <MagnifyingGlassIcon className="w-4 h-4 text-gray-500" />
            </div>
            <input
              type="text"
              name="search"
              className="block w-full py-2 pl-10 pr-20 rounded border text-black placeholder:text-gray-400"
              placeholder={placeholderForSearch}
              value={searchValue}
              onChange={handleSearchChange}
            />

            {searchValue && (
              <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                <TrashIcon
                  className="w-5 h-6 text-gray-500 hover:text-black transition ease-in cursor-pointer"
                  onClick={handleTrashClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="py-2 max-h-[400px] overflow-y-auto min-h-[400px]">
        {type === 'LINE' ? (
          <>
            {filteredLineOptions.length > 0 ? (
              <ul className="w-[95%] text-left">
                {filteredLineOptions.map((item, index) => (
                  <li key={index} className="border-t py-3 px-1 first:border-t-0 items-center">
                    <div className="flex items-center gap-3 w-full">
                      <input
                        id={item.label}
                        type="radio"
                        value={item.label}
                        name="colored-radio"
                        className="w-4 h-4 my-auto bg-gray-100 border-gray-300 cursor-pointer"
                        onChange={() => handleOptionSelect(item.label)}
                      />
                      <label htmlFor={item.label} className="w-full cursor-pointer">
                        <span className="text-core-black text-lg font-medium my-auto item-center">
                          <strong className="text-gray-700 font-medium text-base uppercase mr-4">{item.value}</strong>
                          {item.label}
                        </span>
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <EmptyState text="There are no Line of Business matching this criteria" help={false} />
            )}
          </>
        ) : (
          <>
            {filteredCarrierOptions.length > 0 ? (
              <ul className="w-[95%] text-left">
                {filteredCarrierOptions.map((item, index) => (
                  <li key={index} className="border-t py-3 px-1 first:border-t-0">
                    <div className="flex items-center gap-3 w-full">
                      <input
                        id={item.label}
                        type="radio"
                        value={item.label}
                        name="colored-radio"
                        className="w-4 h-4 my-auto bg-gray-100 border-gray-300 cursor-pointer"
                        onChange={() => handleOptionSelect(item.label)}
                      />
                      <label htmlFor={item.label} className="w-full cursor-pointer">
                        <span className="text-core-black text-lg font-medium">{item.label}</span>
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <EmptyState text="There are no Carriers matching this criteria" help={false} />
            )}
          </>
        )}
      </div>

      <div className="py-6 px-8 border-t">
        <div className="w-full flex gap-2 justify-end">
          <div>
            <Button color="terceary" variant="outline" onClick={onClose}>
              Cancel
            </Button>
          </div>

          <div>
            <Button color="secondary" variant="fill" disabled={isDisabled} onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterModalSelector;
