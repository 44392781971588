import { createAsyncThunk } from '@reduxjs/toolkit';
import { signOutUser } from 'src/api/auth';
import { ActionType } from 'typesafe-actions';
import { getUser } from 'src/api/user';

export const USER_STORE_NAME = '@@User';

export const fetchUserThunk = createAsyncThunk(`${USER_STORE_NAME}/fetchUserThunk`, async () => {
  try {
    return await getUser();
  } catch (error) {
    return error;
  }
});

export const signOutThunk = createAsyncThunk(`${USER_STORE_NAME}/signOutThunk`, async () => {
  try {
    return (await signOutUser()).json();
  } catch (error) {
    return error;
  }
});

export const UserStoreActions = {
  fetchUserThunk,
  signOutThunk,
};

export type UserStoreAction = ActionType<typeof UserStoreActions>;
