import React, { useEffect, useState } from 'react';
import { Onboarding } from '../components/Onboarding';
import ModalFilter from 'src/app/shared/components/Filters/ModalFilter';
import { CarrierListingOptions } from 'src/app/shared/ListingEnums';
import { FilterOptions } from 'src/types/domain/Filters';
import InputMultiple from 'src/app/shared/components/Inputs/InputMultiple';
import { useFormik } from 'formik';
import { useGetBuyerProfile, usePatchBuyerProfile } from 'src/hooks/useBuyersProfile';
import { useHistory } from 'react-router';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';

type EnumType = { [key: string]: string };

const CarrersBuyerProfile = () => {
  const history = useHistory();
  const { mutate, isSuccess } = usePatchBuyerProfile();
  const { data: buyerProfileResponse, isPending } = useGetBuyerProfile();
  const [, setBuyerProfile] = useState(buyerProfileResponse);
  const [selectedValuesCarriers, setSelectedValuesCarriers] = useState<string[]>([]);

  useEffect(() => {
    if (buyerProfileResponse) {
      setBuyerProfile(buyerProfileResponse);
      setSelectedValuesCarriers(buyerProfileResponse.buyerProfile.carrierFilters || []);
    }
  }, [buyerProfileResponse]);

  const renderCustomSelectOptions = (listingsEnumObj: EnumType): FilterOptions[] => {
    const entries = Object.entries(listingsEnumObj);

    return entries.map(([value, label]) => {
      return {
        value,
        label: `${label}`,
      };
    });
  };

  const customSelectFilterOptions = {
    carriers: renderCustomSelectOptions(CarrierListingOptions),
  };

  const handleAddFilters = (selectedFilters: string[], filterType: string) => {
    if (filterType === 'carrierFilters') {
      setSelectedValuesCarriers(selectedFilters);
    }
  };

  const handleSaveBuyerProfile = (selectedItems: any, filterType: string) => {
    if (filterType === 'carrierFilters') {
      setSelectedValuesCarriers(selectedItems.map((item: any) => item.value));
    }
    if (selectedItems && selectedItems.length > 0) {
      if (selectedItems[0].type === 'carrierFilters') {
        setSelectedValuesCarriers(selectedItems.map((item: any) => item.value));
      }
    }
  };

  useEffect(() => {
    setValues({
      carrierFilters: selectedValuesCarriers as never[],
    });
  }, [selectedValuesCarriers]);

  const { handleChange, handleSubmit, setValues, values } = useFormik({
    initialValues: {
      carrierFilters: selectedValuesCarriers,
    },
    onSubmit: (values) => {
      mutate(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      history.push('/onboarding/buyer-profile/lob');
    }
  }, [isSuccess, history]);

  if (isPending) {
    return (
      <div className="w-full mb-12 z-0">
        <Skeleton type="step-onboarding" />
      </div>
    );
  }

  return (
    <Onboarding
      handleSubmit={handleSubmit}
      isContinue={true}
      isBack={true}
      isStep={true}
      numOfSteps={7}
      stepPosition={2}
    >
      <div className="w-full md:w-3/5 mx-auto block py-20 px-8 md:px-32 text-left gap-8 mt-10">
        <div className="w-full">
          <p className="text-center text-sm text-gray-600 font-bold mx-0 p-0 mb-8">BUILD YOUR BUYER PROFILE</p>
        </div>

        <div className="w-full mb-12 mt-8">
          <h1 className="text-core-black text-3xl md:text-5xl font-medium text-center">
            What carriers are you interested in?
          </h1>
        </div>

        <div className="w-full">
          <ModalFilter
            title="Add Carriers"
            filterName="Carrier"
            filterType={'carrierFilters'}
            options={customSelectFilterOptions.carriers}
            handleAddFiltersLocalStorage={() => handleAddFilters(selectedValuesCarriers, 'carriersFilters')}
            variant="Card"
            CardSubtitle="Carriers"
            buyerPillsSelected={selectedValuesCarriers}
            onSaveBuyerProfile={handleSaveBuyerProfile}
          >
            <div className="w-full">
              {selectedValuesCarriers.length > 0 && !selectedValuesCarriers.every((value) => value === '') ? (
                <InputMultiple
                  values={values.carrierFilters}
                  name="carrierFilters"
                  onChange={handleChange}
                  variant="basic"
                />
              ) : null}
            </div>
          </ModalFilter>
        </div>

        <div className="w-full mt-6">
          <p className="text-gray-700 text-base font-medium p-0 m-0 text-center">
            Open to all or have no preference? Click <strong className="text-core-black">‘Continue’</strong>.
          </p>
        </div>
      </div>
    </Onboarding>
  );
};

export default CarrersBuyerProfile;
