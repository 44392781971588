import React, { useEffect, useState } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/app/hooks';
import Routes from 'src/app/Routes';
import SideBarNav from 'src/app/shared/components/sideBarNav/SideBarNav';
import { fetchUserThunk } from 'src/app/user/UserStoreActions';
import { userStateSelector } from 'src/app/user/UserStoreSelectors';
import { ReactComponent as LoadingIcon } from '../styles/assets/icons/solid/loadingSolid.svg';
import Posthog from './shared/components/PostHog/posthog';
import Analytics from './book-management/book-tabs/Analytics';
import 'src/app/App.scss';

function App(): React.ReactElement | null {
  const dispatch = useDispatch();

  const { isUserLoggedIn } = useAppSelector(userStateSelector);
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const [url, setUrl] = useState('');
  const [hash, setHash] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        await dispatch(fetchUserThunk());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [dispatch]);

  useEffect(() => {
    setUrl(window.location.pathname);
  }, []);

  const urlContainsCreateAccount = window.location.pathname.includes('/create-account');
  const backgroundImageStyles = isUserLoggedIn
    ? 'App'
    : urlContainsCreateAccount
    ? ['App', 'm-0'].join(' ')
    : ['App', 'cakeBackgroundImage', 'm-0'].join(' ');

  return (
    <BrowserRouter>
      <Posthog />
      <div
        className={`min-h-screen flex w-full ${isLoading ? '' : backgroundImageStyles} ${
          urlContainsCreateAccount ? 'bg-white' : 'bg-core-lightGrey'
        } `}
      >
        <div className="w-full flex">
          {isUserLoggedIn && !pathname.includes('/onboarding') && <SideBarNav />}
          {isLoading ? (
            <div className="flex items-center justify-center absolute inset-0">
              <LoadingIcon className="w-12 h-12 text-gray-200 animate-spin fill-yellow-600" />
            </div>
          ) : (
            <div className="w-full flex flex-col">
              <Routes
                isLoading={isLoading}
                isUserLoggedIn={isUserLoggedIn}
                onUrlChange={setUrl}
                showAnalytics={setHash}
              />

              <div
                className={`${
                  url.includes('/book-management') && hash ? 'h-[100%] block' : 'hidden'
                } w-full px-3 -mt-5`}
              >
                <Analytics />
              </div>
            </div>
          )}
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
