import { useMutation, useQuery } from '@tanstack/react-query';
import { getUser, patchUser } from '../api/user';

export const useGetUser = () => {
  return useQuery({ queryKey: ['getUser'], queryFn: () => getUser() });
};

export const usePatchUser = () => {
  return useMutation({ mutationKey: ['patchUser'], mutationFn: patchUser });
};
