import React, { FC, useEffect, useState } from 'react';
import { Onboarding } from '../components/Onboarding';
import { useHistory } from 'react-router';
import { INTERESTS_TITLE, INTERESTS_TITLE2 } from 'src/constants/labels';
import { usePatchUser } from 'src/hooks';
import { useOnboardingStore } from 'src/app/store/store';

interface CheckboxStates {
  [key: string]: boolean;
}

const labelsList = [{ label: 'Buying' }, { label: 'Selling' }, { label: 'Book valuation' }, { label: 'Not sure yet' }];

export const Interests: FC = () => {
  const history = useHistory();
  const { userInfo, setUserInfo } = useOnboardingStore();

  const { mutate, isSuccess, error, isPending } = usePatchUser();

  const [checkboxStates, setCheckboxStates] = useState<CheckboxStates>({
    ['Buying']: userInfo?.userProfile.buying,
    ['Book valuation']: userInfo?.userProfile.bookValuation,
    ['Selling']: userInfo?.userProfile.selling,
    ['Not sure yet']: false,
  } as CheckboxStates);

  const handleSubmit = () => {
    mutate({
      buying: checkboxStates['Buying'],
      selling: checkboxStates['Selling'],
      bookValuation: checkboxStates['Book valuation'],
      onboardingComplete: true,
    });
  };

  const handleCheckboxChange = (label: string) => {
    setCheckboxStates((prevStates) => {
      const newStates = { ...prevStates, [label]: !prevStates[label] };

      if (label === 'Not sure yet' && newStates['Not sure yet']) {
        Object.keys(newStates).forEach((key) => {
          if (key !== 'Not sure yet') {
            newStates[key] = false;
          }
        });
      } else if (newStates['Not sure yet'] && label !== 'Not sure yet') {
        newStates['Not sure yet'] = false;
      }

      return newStates;
    });
  };

  useEffect(() => {
    if (isSuccess) {
      history.push('/onboarding/next-steps');
      setUserInfo();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (userInfo?.userProfile.onboardingComplete) {
      history.push('/onboarding/completed');
    }
  }, [userInfo]);

  return (
    <Onboarding handleSubmit={handleSubmit} isBack={true} isLoading={isPending} isContinue={true}>
      <div className="flex flex-col lg:w-10/12 sm:w-12/12 m-auto h-full">
        <div className="flex flex-col xl:6/12 lg:w-10/12 md:8/12 md:m-auto mx-auto mb-36">
          <div className="flex flex-col w-8/12 sm:w-full mx-auto mt-32 md:mt-0 h-full text-center">
            <h3 className="m-auto lg:text-5xl sm:text-4xl">{INTERESTS_TITLE}</h3>
            <p className="text-base text-core-grey mt-3 lg:px-20 sm:px-8 xs:px-0">{INTERESTS_TITLE2}</p>
          </div>
          <form className="w-full mt-4 md:px-10">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {labelsList.map((item, index) => (
                <div
                  key={index}
                  className={`p-10 border-1  flex ${
                    checkboxStates[item.label] ? '!border-core-black' : '!border-gray-400'
                  }
                rounded group cursor-pointer hover:border-core-black transition ease-in`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCheckboxChange(item.label);
                  }}
                >
                  <div className="flex items-center justify-between w-full">
                    <p className="md:text-2xl text-lg font-medium m-0">{item.label}</p>
                    <input
                      type="checkbox"
                      className="accent-core-black ml-auto h-6 w-6 group-hover:cursor-pointer"
                      checked={checkboxStates[item.label]}
                    />
                  </div>
                </div>
              ))}
            </div>
          </form>
        </div>
        <div className="flex w-full">
          <span className="text-system-negative text-sm ml-auto mr-auto mt-2">{error?.message}</span>
        </div>
      </div>
    </Onboarding>
  );
};
