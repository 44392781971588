import React from 'react';
import Breadcrumb from 'src/app/shared/components/Breadcrumb/Breadcrumb';
import { ReactComponent as Check } from '../../../../../styles/assets/icons/outline/check.svg';
import { Button } from 'src/app/shared/components/Buttons/Button';

const SucessPurchase = () => {
  const renderListItem = (text: string) => (
    <li key={text} className="font-normal text-lg flex gap-2 items-center">
      <Check className="my-auto h-4 w-4" />
      {text}
    </li>
  );

  const handleGotoHome = () => {
    window.location.href = '/slices';
  };

  const itemsPro = ['Explore Listings', 'Connect with Sellers', 'Publish your buyer profile'];

  return (
    <div className="p-3 w-full mb-20 md:mb-0">
      <Breadcrumb title="Account" backButton={true} backUrl="/slices" />

      <div className="mt-16 w-full flex flex-col items-center justify-center">
        <h1 className="text-core-black text-center text-5xl font-medium">Your purchase is complete!</h1>

        <div className="bg-white rounded p-6 w-full md:w-[400px] text-left my-auto mt-4">
          <p className="text-gray-700 text-center text-base font-medium">
            Welcome to your Pro membership. Here's what you can do next:
          </p>
          <ul className="m-0 p-0 space-y-4">{itemsPro.map(renderListItem)}</ul>

          <div className="mt-8">
            <Button variant="fill" color="secondary" onClick={handleGotoHome}>
              Back to Home
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SucessPurchase;
