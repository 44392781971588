import React, { useEffect, useState } from 'react';
import Breadcrumb from 'src/app/shared/components/Breadcrumb/Breadcrumb';
import { ReactComponent as SparKles } from '../../../../../styles/assets/icons/outline/sparkles.svg';
import { ReactComponent as Check } from '../../../../../styles/assets/icons/outline/check.svg';
import { Button } from 'src/app/shared/components/Buttons/Button';
// import { useGetUser } from 'src/hooks/useOnboarding';
import { useGetCheckout, useGetSubscription } from 'src/hooks/useSubscription';
// import { UserInfo } from 'src/types/User';

const Pricing = () => {
  const [isMonthlyActive, setMonthlyActive] = useState(true);
  // const [userData, setUserData] = useState<UserInfo>();
  const { data: subscriptionResponse, error: subscriptionError } = useGetSubscription();
  // const { data: userResponse, error: userError } = useGetUser();

  const handlePaymentClick = (isMonthly: boolean) => {
    setMonthlyActive(isMonthly);
  };

  const stackEnvFromLocalStorage = localStorage.getItem('stackEnv');
  const isProduction = stackEnvFromLocalStorage === 'prod';

  const getProduct = isProduction
    ? isMonthlyActive
      ? 'price_1OWP5gCCJeuPYIkqEdO3jIwt'
      : 'price_1OceL7CCJeuPYIkqcx8O2sjp'
    : isMonthlyActive
    ? 'price_1OWKsGCCJeuPYIkqvTrDBU88'
    : 'price_1OceBbCCJeuPYIkqMgCvmrsr';

  const getReturnUrl = isProduction
    ? 'https://app.haveyourcake.com/account#subscriptions'
    : 'https://app.demo.haveyourcake.ai/account#subscriptions';

  const { data: checkoutResponse, error: checkoutError } = useGetCheckout(getReturnUrl, getProduct);

  useEffect(() => {
    if (subscriptionResponse) {
      console.log(subscriptionResponse);
    }
    if (subscriptionError) {
      console.error('Error fetching subscription info:', subscriptionError);
    }
  }, [subscriptionResponse, subscriptionError]);

  useEffect(() => {
    if (checkoutResponse) {
      console.log(checkoutResponse);
    }
    if (subscriptionError) {
      console.error('Error fetching checkout info:', checkoutError);
    }
  }, [checkoutResponse, checkoutError]);

  const handleCheckoutClick = () => {
    console.log('Customer link:', checkoutResponse?.redirect);
    window.open(checkoutResponse?.redirect, '_blank');
  };

  const renderListItem = (text: string) => (
    <li key={text} className="font-normal text-lg flex gap-2 items-center">
      <Check className="my-auto h-4 w-4" />
      {text}
    </li>
  );

  const itemsFree = ['View public listings', 'Value your book', 'Create listings', 'Sell your book'];
  const itemsPro = ['Everything in Starter', 'Buyer Profile', 'Private and secure chat', 'Personal Deal Room'];

  return (
    <div className="p-3 w-full mb-20 md:mb-0 h-fit">
      <Breadcrumb title="Account" backButton={true} />

      <div className="mt-16 w-full flex flex-col">
        <h1 className="text-core-black text-center text-5xl font-medium">Choose a plan</h1>
        <p className="text-gray-700 text-center text-base font-medium mt-4">
          Unlock full access to Cake's comprehensive suite of features and tools, <br /> empowering you to grow your
          agency.
        </p>
        <div className="mt-8 mb-4 block mx-auto">
          <div className="rounded border-2 bg-white h-[40px] w-[290px] p-1 gap-1 flex">
            <button
              className={`w-1/2 text-sm font-bold transition ease-in ${
                isMonthlyActive ? 'bg-core-black text-white rounded' : 'text-gray-400 hover:text-core-black'
              }`}
              onClick={() => handlePaymentClick(true)}
            >
              PAY MONTHLY
            </button>
            <button
              className={`w-1/2 text-gray-400 text-sm font-bold transition ease-in  ${
                isMonthlyActive ? 'text-gray-400 hover:text-core-black' : 'bg-core-black text-white rounded'
              }`}
              onClick={() => handlePaymentClick(false)}
            >
              PAY ANNUALLY
            </button>
          </div>
        </div>
      </div>

      <div className="w-full md:w-[824px] md:flex grid grid-cols-1 gap-6 mx-auto ">
        <div className="bg-white rounded p-6 w-full md:w-1/2 text-left">
          <div className="w-full flex flex-col text-left">
            <h3 className="text-lg  font-medium mb-4">Starter</h3>
            <span className="font-medim text-[32px]">Free</span>
          </div>

          <p className="text-sm font-medium mb-4">Now you can list, sell, and get a valuation for your book.</p>
          <ul className="m-0 p-0 space-y-4">{itemsFree.map(renderListItem)}</ul>

          <div className="mt-8">
            <Button variant="outline" color="primary" disabled={true}>
              Currently on this plan
            </Button>
          </div>
        </div>

        <div className="bg-white rounded p-6 w-full md:w-1/2 text-left">
          <div className="w-full flex flex-col text-left">
            <div className="w-full flex">
              <div className="w-full flex justify-star">
                <h3 className="text-lg  font-medium mb-4">Pro</h3>
              </div>

              {!isMonthlyActive ? (
                <div className="w-fit h-fit justify-end rounded-full bg-yellow-100 mt-0 text-gray-900 py-2 px-3 text-sm flex gap-2 mx-auto whitespace-nowrap">
                  <SparKles className="h-4 w-4 my-auto" />
                  <p className="p-0 m-0">You save 10% off</p>
                </div>
              ) : null}
            </div>
            <span className="font-medim text-[32px]">
              ${isMonthlyActive ? 40 : 432}
              <strong className="text-gray-500 text-lg font-medium">/ {isMonthlyActive ? 'month' : 'year'}</strong>
            </span>
          </div>

          <p className="text-sm font-medium mb-4">For agents looking to attract sellers and grow via acquisition.</p>
          <ul className="m-0 p-0 space-y-4">{itemsPro.map(renderListItem)}</ul>

          <div className="mt-8">
            <Button variant="fill" color="primary" onClick={handleCheckoutClick}>
              Upgrade to Pro
            </Button>
          </div>
        </div>
      </div>

      <div className="my-8 w-full">
        <p className="p-0 m-0 text-base text-neutral-gray-700">
          Checkout is handled by <strong>Stripe</strong>
        </p>
      </div>
    </div>
  );
};

export default Pricing;
