import { InformationCircleIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useState } from 'react';
import { UsStatesOptions } from 'src/app/shared/ListingEnums';
import Pagination from 'src/app/shared/components/Pagination/Pagination';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';
import Tooltip from 'src/app/shared/components/Tooltip/Tooltip';
import EmptyState from 'src/app/shared/components/emptyState/EmptyState';
import { formatCurrency, formatcalculateDaysAgo } from 'src/app/shared/utils/formatters';
import { useGetMarketplaceListings } from 'src/hooks/useGetMarketplaceListings';
import { ReactComponent as RightIcon } from '../../../../styles/assets/icons/mini/chevronRight.svg';
import HomeListingFiltersBar, { PriceFilter } from 'src/app/shared/components/Filters/FiltersBar';
import { useGetSubscription } from 'src/hooks';
import FlyoutMatches from './FlyoutMatches/FlyoutMatches';
import { useHistory, useLocation } from 'react-router';
import DisclaimerModal from './FlyoutMatches/DisclaimerModal';
import { StarIcon as OutlineStarIcon } from '@heroicons/react/24/outline';
import { StarIcon as SolidStarIcon } from '@heroicons/react/24/solid';
import { postAddFavorite, postRemoveFavorite } from 'src/api/marketplace';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import { MarketplaceListing } from 'src/types/MarketPlaceListings';

const defaultItemsPerPage = 20;
const YourMatches = () => {
  const { data: listingResponse, isPending } = useGetMarketplaceListings();
  const { data: subscriptionResponse } = useGetSubscription();

  const isEnterprise = subscriptionResponse?.userSubscription === 'Enterprise';

  const tableRowClass = 'p-3 text-core-tableHeader text-xs uppercase text-left border-bottom whitespace-nowrap';
  const tableBodyRowClass = 'p-3 text-core-black text-sm capitalize text-left whitespace-nowrap';

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [sortField, setSortField] = useState<string | null>('createdAt');

  const RightArrowIcon = <RightIcon className="h-3 w-3" />;
  const [totalPremium, setTotalPremium] = useState<PriceFilter>({ min: '', max: '' });
  const [listingState, setListingState] = useState<Array<string>>([]);
  const [lineOfBusiness, setLineOfBusiness] = useState<Array<string>>([]);
  const [carrier, setCarrier] = useState<Array<string>>([]);
  const [insuranceType, setInsuranceType] = useState<string>();
  const [sliceUid, setSliceUid] = useState<string>();
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [porcentage, setPorcentage] = useState<string>();
  const [createdAt, setCreateAt] = useState<string>();
  const [createdByUser, setCreatedByUser] = useState<string>();
  const [buyerUid, setBuyerUid] = useState<string>();
  const location = useLocation();
  const history = useHistory();
  const [showMessage, setShowMessage] = useState(false);
  const { alerts, createToast } = useAlert();
  const [sortByFavorites, setSortByFavorites] = useState(false);
  const [currentItems, setCurrentItems] = useState<MarketplaceListing[]>([]);
  const [filteredCount, setFilteredCount] = useState<number>(0);
  const [groupedListings, setGroupedListings] = useState<MarketplaceListing[]>();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handleAddFavoriteClick = async (uid: string) => {
    try {
      await postAddFavorite(uid);
      createToast({ text: 'Listing added to favorites', variant: 'success' });
      const updatedItems = currentItems.map((item) => (item.uid === uid ? { ...item, isFavorite: true } : item));
      const updatedGroupedListings = groupedListings?.map((item) =>
        item.uid === uid ? { ...item, isFavorite: true } : item
      );
      setCurrentItems(updatedItems);
      setGroupedListings(updatedGroupedListings);
    } catch (error) {
      console.error(error);
      createToast({
        text: 'Error adding to favorites, try later.',
        variant: 'danger',
      });
    }
  };

  const handleRemoveFavoriteClick = async (uid: string) => {
    try {
      await postRemoveFavorite(uid);
      createToast({ text: 'Listing removed to favorites', variant: 'success' });
      const updatedItems = currentItems.map((item) => (item.uid === uid ? { ...item, isFavorite: false } : item));
      const updatedGroupedListings = groupedListings?.map((item) =>
        item.uid === uid ? { ...item, isFavorite: false } : item
      );
      setCurrentItems(updatedItems);
      setGroupedListings(updatedGroupedListings);
    } catch (error) {
      console.error(error);
      createToast({
        text: 'Error removing from favorites, try later.',
        variant: 'danger',
      });
    }
  };

  const getStateFullName = (stateCode: string): string => {
    if (UsStatesOptions[stateCode as keyof typeof UsStatesOptions]) {
      return UsStatesOptions[stateCode as keyof typeof UsStatesOptions];
    }
    return stateCode;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const uid = queryParams.get('uid');
    if (uid === 'null') {
      setShowMessage(true);
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setShowMessage(false);
      if (uid) {
        const item = listingResponse && listingResponse.marketplaceListings.find((item) => item.uid === uid);

        if (item) {
          handleOpenDetailSlide(
            item.uid,
            formatNumber(item.matchScore ?? 0),
            item.createdAt,
            item.createdByUser.uid,
            item.createdByUser.username
          );
        }
      }
    }
  }, [location.search, listingResponse]);

  useEffect(() => {
    isEnterprise ? setSortField('Match') : null;
  }, [listingResponse]);

  useEffect(() => {
    setCurrentPage(1);
  }, [listingResponse]);

  const handleItemsPerPageChange = (val: number) => {
    setItemsPerPage(val);
    setCurrentPage(1);
  };

  const handleOpenDetailSlide = (
    uid: string,
    porcentage: string,
    createdAt: string,
    buyerUid: string,
    createdByUser: string
  ): void => {
    setSliceUid(uid);
    setPorcentage(porcentage);
    setCreateAt(createdAt);
    setIsOpenDetails(!isOpenDetails);
    setBuyerUid(buyerUid);
    setCreatedByUser(createdByUser);
  };

  const handleOnCloseModal = () => {
    setIsOpenDetails(false);
    setSliceUid('');
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('uid');
    history.replace({ search: queryParams.toString() });
  };

  const formatNumber = (value: number) => {
    const multipliedValue = value * 100;
    return Math.round(multipliedValue).toString();
  };

  const SortIcon = (
    <ChevronUpDownIcon className="h-3 w-3 text-core-black my-auto hidden group-hover:block transition ease-in" />
  );

  const textInfo = (
    <p className="p-0 m-0 text-justify">
      Match score is Cake’s estimate <br /> of how well this listing matches <br /> your Listing Appetite from your
      <br /> Buyer Profile.
      <br />
      <br />
      It is not a valuation and should <br />
      be used as a starting point.
      <br />
    </p>
  );

  const getMatchScoreClasses = (score: string) => {
    const numericScore = parseFloat(score);

    if (numericScore >= 80) {
      return 'bg-[#276EF1] text-white';
    } else if (numericScore >= 60) {
      return 'bg-[#7DA8F7] text-white';
    } else if (numericScore >= 50) {
      return 'bg-[#BED4FB] text-core-black';
    } else {
      return 'bg-[#E8E8E8] text-core-gray';
    }
  };

  const displayListOrCount = (list: string[], maxToShow = 1) => {
    if (list.length === 0) {
      return '';
    }

    const frequencyMap: Record<string, number> = {};
    list.forEach((item) => {
      frequencyMap[item] = (frequencyMap[item] || 0) + 1;
    });

    const sortedList = Object.keys(frequencyMap).sort((a, b) => frequencyMap[b] - frequencyMap[a]);

    if (sortedList.length === 1) {
      const key = sortedList[0] as keyof typeof UsStatesOptions;
      return UsStatesOptions[key] || sortedList[0];
    }

    if (sortedList.length <= maxToShow) {
      return sortedList
        .map((code) => {
          const key = code as keyof typeof UsStatesOptions;
          return UsStatesOptions[key] || code;
        })
        .join(', ');
    }

    const displayedList = sortedList
      .slice(0, maxToShow)
      .map((code) => {
        const key = code as keyof typeof UsStatesOptions;
        return UsStatesOptions[key] || code;
      })
      .join(', ');
    const remainingCount = sortedList.length - maxToShow;
    const fullList = sortedList
      .slice(maxToShow)
      .map((code) => {
        const key = code as keyof typeof UsStatesOptions;
        return UsStatesOptions[key] || code;
      })
      .join(', ');

    return (
      <div className="inline-block w-fit">
        <span className="inline-block align-middle">
          <div className="max-w-[150px] truncate">{displayedList}</div>
        </span>
        <span className="inline-block text-core-accentText md:-mt-[20px] z-10 align-middle">
          <Tooltip title="INCLUDES" position="left-top" list={fullList}>
            <span className="text-core-accentText text-sm pl-1">{`+${remainingCount}`}</span>
          </Tooltip>
        </span>
      </div>
    );
  };

  const columns = [
    ...(isEnterprise
      ? [
          {
            label: 'Match',
            className: `w-20 ${tableRowClass}`,
            key: 'Match',
          },
        ]
      : []),
    {
      label: 'Listing ID',
      className: `w-30 ${tableRowClass}`,
      key: 'ListingNum',
    },
    {
      label: 'Carrier',
      className: `w-fit ${tableRowClass}`,
      key: 'carrier',
    },
    {
      label: 'Premium',
      className: `w-32 ${tableRowClass}`,
      key: 'totalPremium',
    },
    {
      label: 'State',
      className: `w-fit ${tableRowClass}`,
      key: 'usState',
    },
    {
      label: 'Line of business',
      className: `w-fit ${tableRowClass}`,
      key: 'lineOfBusiness',
    },
    {
      label: 'Age',
      className: `w-20 ${tableRowClass}`,
      key: 'createdAt',
    },
    { label: '', className: `w-16 ${tableRowClass}` },
  ];

  useEffect(() => {
    if (groupedListings) {
      const sortedData = sortData(sortField, sortOrder);
      const items = sortedData.slice(indexOfFirstItem, indexOfLastItem);
      setCurrentItems(items);
    }
  }, [currentPage, itemsPerPage, groupedListings, sortField, sortOrder]);

  useEffect(() => {
    if (listingResponse) {
      const grouped = listingResponse.marketplaceListings.reduce((acc: MarketplaceListing[], listing) => {
        const existingListing = acc.find((item) => item.uid === listing.uid);
        if (existingListing) {
          existingListing.slices.push(...listing.slices);
        } else {
          acc.push({ ...listing, slices: [...listing.slices] });
        }
        return acc;
      }, []);
      setCurrentItems(grouped);
      setGroupedListings(grouped.sort((a, b) => (b.matchScore ?? 0) - (a.matchScore ?? 0)));
      setCurrentPage(1);
    }
  }, [listingResponse]);

  const handleSort = (field: string) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
  };

  const sortData = (field: string | null, order: 'asc' | 'desc') => {
    const sortedData = groupedListings ? [...groupedListings] : [];

    if (field) {
      sortedData.sort((a, b) => {
        let aValue, bValue;

        field === 'isFavorite' ? setSortByFavorites(true) : setSortByFavorites(false);

        switch (field) {
          case 'isFavorite':
            aValue = a.isFavorite ? 1 : 0;
            bValue = b.isFavorite ? 1 : 0;
            break;
          case 'Match':
            aValue = a.matchScore ?? 0;
            bValue = b.matchScore ?? 0;
            break;
          case 'ListingNum':
            aValue = a.listingNum ?? 0;
            bValue = b.listingNum ?? 0;
            break;
          case 'carrier':
            aValue = a.slices[0]?.carrier.toLowerCase() ?? '';
            bValue = b.slices[0]?.carrier.toLowerCase() ?? '';
            break;
          case 'totalPremium':
            aValue = a.slices.reduce((acc, slice) => acc + slice.totalPremium, 0);
            bValue = b.slices.reduce((acc, slice) => acc + slice.totalPremium, 0);
            break;
          case 'usState':
            aValue = getStateFullName(a.slices[0]?.usState) ?? '';
            bValue = getStateFullName(b.slices[0]?.usState) ?? '';
            break;
          case 'lineOfBusiness':
            aValue = a.slices[0]?.lineOfBusiness.toLowerCase() ?? '';
            bValue = b.slices[0]?.lineOfBusiness.toLowerCase() ?? '';
            break;
          case 'createdAt':
            aValue = new Date(a.createdAt).getTime();
            bValue = new Date(b.createdAt).getTime();
            break;
          default:
            aValue = '';
            bValue = '';
        }

        return order === 'asc' ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
      });
    }

    return sortedData;
  };

  const applyFilters = () => {
    let filteredListings = listingResponse && listingResponse.marketplaceListings;

    if (insuranceType !== '') {
      if (insuranceType === 'Personal') {
        filteredListings = filteredListings?.filter((listing) => {
          return listing.slices.some((slice) => slice.isPersonal);
        });
      } else if (insuranceType === 'Commercial') {
        filteredListings = filteredListings?.filter((listing) => {
          return listing.slices.some((slice) => !slice.isPersonal);
        });
      }
    }

    if (Object.keys(totalPremium).length > 0) {
      filteredListings = filteredListings?.filter((listing) => {
        const sliceTotalPremium = Number(listing.totalPremium);
        const minCondition = !totalPremium.min || sliceTotalPremium >= Number(totalPremium.min);
        const maxCondition = !totalPremium.max || sliceTotalPremium <= Number(totalPremium.max);

        return minCondition && maxCondition;
      });
    }

    if (carrier.length > 0) {
      filteredListings = filteredListings?.filter((listing) => {
        return listing.slices.some((slice) => carrier.includes(slice.carrier));
      });
    }

    if (listingState.length > 0) {
      filteredListings = filteredListings?.filter((listing) => {
        return listing.slices.some((slice) => listingState.includes(getStateFullName(slice.usState)));
      });
    }

    if (lineOfBusiness.length > 0) {
      filteredListings = filteredListings?.filter((listing) => {
        return listing.slices.some((slice) => lineOfBusiness.includes(slice.lineOfBusiness));
      });
    }

    setGroupedListings(filteredListings?.sort((a, b) => (b.matchScore ?? 0) - (a.matchScore ?? 0)));
    filteredListings = filteredListings?.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(filteredListings as MarketplaceListing[]);
  };

  useEffect(() => {
    if (!isPending) {
      applyFilters();
    }
  }, [
    isPending,
    listingResponse,
    carrier,
    listingState,
    lineOfBusiness,
    totalPremium,
    insuranceType,
    currentPage,
    itemsPerPage,
  ]);

  const handleTotalPremiumChange = (value: PriceFilter): void => {
    setTotalPremium(value);
  };

  const handleStateChange = (value: Array<string>): void => {
    setListingState(value);
  };

  const handleLineOfBusinessChange = (value: Array<string>): void => {
    setLineOfBusiness(value);
  };

  const handleCarrierChange = (value: Array<string>): void => {
    setCarrier(value);
  };

  const handleInsuranceTypeChange = (type: string): void => {
    setInsuranceType(type);
  };

  return (
    <div>
      {!isEnterprise && (
        <HomeListingFiltersBar
          handleTotalPremiumChange={handleTotalPremiumChange}
          handleStateChange={handleStateChange}
          handleLineOfBusinessChange={handleLineOfBusinessChange}
          handleCarrierChange={handleCarrierChange}
          onInsuranceTypeChange={handleInsuranceTypeChange}
          moreFilter={true}
        />
      )}

      {isPending ? (
        <div className="w-full pb-4">
          <Skeleton type="table" />
        </div>
      ) : (
        <div className="w-full mx-auto">
          {currentItems && currentItems.length === 0 ? (
            <EmptyState text="There are no listings matching this criteria" help={false} />
          ) : (
            <>
              <div className="w-full flex justify-start mt-0 md:mt-0 border rounded-lg overflow-auto sm:overflow-visible md:overflow-auto lg:overflow-hidden xl:overflow-auto">
                {alerts}
                <table className="w-full bg-white rounded-lg">
                  <thead className="rounded-lg">
                    <tr className="rounded-lg">
                      <th className="p-3 text-core-tableHeader text-xs uppercase text-left border-bottom whitespace-nowrap">
                        <div className="flex gap-4 justify-start w-full my-auto">
                          {sortByFavorites ? (
                            <SolidStarIcon
                              className="w-[20px] h-[20px] text-yellow-500 transition ease-in hover:text-yellow-700 cursor-pointer"
                              onClick={() => {
                                handleSort('isFavorite');
                              }}
                            />
                          ) : (
                            <OutlineStarIcon
                              className="w-[20px] h-[20px] text-gray-500 transition ease-in hover:text-core-black cursor-pointer"
                              onClick={() => {
                                handleSort('isFavorite');
                              }}
                            />
                          )}
                        </div>
                      </th>

                      {columns.map((column) => (
                        <th
                          key={column.key}
                          className={column.className}
                          onClick={() => column.key && handleSort(column.key)}
                        >
                          <span className={`flex w-full cursor-pointer group ${column.label ? '' : 'hidden'}`}>
                            {column.label}

                            {column.label === 'Match' && (
                              <span>
                                <Tooltip position="left-top" body={textInfo}>
                                  <InformationCircleIcon className="h-4 w-4 my-auto" />
                                </Tooltip>
                              </span>
                            )}

                            {column.key === sortField && SortIcon}
                          </span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems &&
                      currentItems.map((listing) => (
                        <tr
                          key={listing.uid}
                          onClick={() =>
                            handleOpenDetailSlide(
                              listing.uid,
                              formatNumber(listing.matchScore ?? 0),
                              listing.createdAt,
                              listing.createdByUser.uid,
                              listing.createdByUser.username
                            )
                          }
                          className="hover:bg-yellow-50 transition ease-in rounded-lg cursor-pointer"
                        >
                          <td
                            className={tableBodyRowClass}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <button
                              onClick={() =>
                                listing.isFavorite
                                  ? handleRemoveFavoriteClick(listing.uid)
                                  : handleAddFavoriteClick(listing.uid)
                              }
                              className="relative w-[24px] h-[24px] flex items-center justify-center focus:outline-none"
                            >
                              {listing.isFavorite ? (
                                <SolidStarIcon className="w-[20px] h-[20px] text-yellow-500 transition ease-in hover:text-yellow-700" />
                              ) : (
                                <OutlineStarIcon className="w-[20px] h-[20px] text-gray-500 transition ease-in hover:text-core-black" />
                              )}
                            </button>
                          </td>

                          {isEnterprise && (
                            <td className={tableBodyRowClass}>
                              <span
                                className={`py-2 font-bold w-16 block text-xs text-center px-3 rounded-full ${getMatchScoreClasses(
                                  formatNumber(listing.matchScore ?? 0)
                                )}`}
                              >
                                {`${formatNumber(listing.matchScore ?? 0)}%`}
                              </span>
                            </td>
                          )}
                          <td className={tableBodyRowClass}>{listing.listingNum}</td>
                          <td className={tableBodyRowClass}>
                            {displayListOrCount(
                              listing.slices.map((slice) => slice.carrier),
                              1
                            )}
                          </td>
                          <td className={tableBodyRowClass}>
                            {formatCurrency(listing.slices.reduce((acc, slice) => acc + slice.totalPremium, 0))}
                          </td>
                          <td className={tableBodyRowClass}>
                            {displayListOrCount(
                              listing.slices.map((slice) => slice.usState),
                              2
                            )}
                          </td>
                          <td className={tableBodyRowClass}>
                            {displayListOrCount(
                              listing.slices.map((slice) => slice.lineOfBusiness),
                              1
                            )}
                          </td>
                          <td className={tableBodyRowClass}>{formatcalculateDaysAgo(listing.createdAt)}</td>
                          <td className={tableBodyRowClass}>
                            <button
                              className="p-1 bg-gray-50 hover:bg-core-yellow transition ease-in rounded-sm mr-2"
                              onClick={() =>
                                handleOpenDetailSlide(
                                  listing.uid,
                                  formatNumber(listing.matchScore ?? 0),
                                  listing.createdAt,
                                  listing.createdByUser.uid,
                                  listing.createdByUser.username
                                )
                              }
                            >
                              {RightArrowIcon}
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              {groupedListings && groupedListings.length > defaultItemsPerPage && (
                <Pagination
                  currentPage={currentPage}
                  totalItems={groupedListings?.length ?? 0}
                  itemsPerPage={itemsPerPage}
                  onPageChange={setCurrentPage}
                  onItemsPerPageChange={handleItemsPerPageChange}
                />
              )}

              {isOpenDetails && (
                <FlyoutMatches
                  sliceUid={sliceUid ? sliceUid : '0'}
                  onClose={handleOnCloseModal}
                  porcentage={porcentage}
                  createdAt={createdAt}
                  buyerUid={buyerUid ? buyerUid : '0'}
                  isEnterprise={isEnterprise}
                  createdByUser={createdByUser}
                />
              )}

              {showMessage && <DisclaimerModal />}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default YourMatches;
