import React from 'react';

const DisclaimerModal = () => {
  return (
    <div className="fixed top-0 z-50 left-0 w-full h-full flex justify-center items-center backdrop-blur-md bg-black/50">
      <div className="bg-white p-6 rounded shadow-lg w-[500px]">
        <div className="w-full flex">
          <h1 className="text-core-black font-medium text-xl p-0 m-0 whitespace-wrap">
            This listing is no longer published or has been deleted
          </h1>
        </div>
      </div>
    </div>
  );
};

export default DisclaimerModal;
