import { BASEREQUESTOPTIONS } from 'src/api/_constants';
import { ListingType, MarketPlaceByUid, MarketplaceListingsResponse } from 'src/types/MarketPlaceListings';

export const getMarketplaceListings = async (): Promise<MarketplaceListingsResponse> => {
  const response = await fetch('/trantor/marketplace-listings', BASEREQUESTOPTIONS);
  return response.json();
};

export const fetchSliceDetails = async (uid: string): Promise<ListingType> => {
  const url = `/trantor/marketplace-listings`;
  const queryParams = encodeURIComponent(uid);
  const fullUrl = `${url}/${queryParams}/detail`;

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'GET',
  };

  const response = await fetch(fullUrl, requestOptions);
  return response.json();
};

export const getMarketplaceListingById = async (uid: string): Promise<MarketPlaceByUid> => {
  const url = '/trantor/marketplace-listings';
  const queryParams = encodeURIComponent(uid);
  const fullUrl = `${url}/${queryParams}`;

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'GET',
  };

  const response = await fetch(fullUrl, requestOptions);
  return response.json();
};

export const postAddFavorite = async (uid: string) => {
  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
  };

  const url = `/trantor/marketplace-listings/${uid}/add-favorite`;

  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    throw new Error(`Error: ${response.status} ${response.statusText}`);
  }

  return response.json();
};

export const postRemoveFavorite = async (uid: string) => {
  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
  };

  const url = `/trantor/marketplace-listings/${uid}/remove-favorite`;

  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    throw new Error(`Error: ${response.status} ${response.statusText}`);
  }

  return response.json();
};
