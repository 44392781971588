import React, { useState } from 'react';
import HomePolicyFiltersBar, { PriceFilter } from 'src/app/shared/components/Filters/PolicyFiltersBar';

interface BookFilterProps {
  onFiltersChange: (filters: {
    policyNumber: string[];
    listingState: string[];
    lineOfBusiness: string[];
    carrier: string[];
    sliceName: string;
    insuranceType: string;
    totalPremium: PriceFilter;
  }) => void;
  filters: {
    policyNumber: string[];
    listingState: string[];
    lineOfBusiness: string[];
    carrier: string[];
    sliceName: string;
    insuranceType: string;
    totalPremium: PriceFilter;
  };
}

const BookFilter = ({ onFiltersChange }: BookFilterProps) => {
  const [policyNumber] = useState<string[]>([]);
  const [listingState] = useState<string[]>([]);
  const [lineOfBusiness] = useState<string[]>([]);
  const [carrier] = useState<string[]>([]);
  const [sliceName] = useState<string>('');
  const [insuranceType, setInsuranceType] = useState<string>('');
  const [totalPremium, setTotalPremium] = useState<PriceFilter>({ min: '', max: '' });

  const handlePolicyNumberChange = (value: Array<string>): void => {
    onFiltersChange({
      policyNumber: value,
      listingState,
      lineOfBusiness,
      carrier,
      sliceName,
      insuranceType,
      totalPremium,
    });
  };

  const handleStateChange = (value: Array<string>): void => {
    onFiltersChange({
      policyNumber,
      listingState: value,
      lineOfBusiness,
      carrier,
      sliceName,
      insuranceType,
      totalPremium,
    });
  };

  const handleLineOfBusinessChange = (value: Array<string>): void => {
    onFiltersChange({
      policyNumber,
      listingState,
      lineOfBusiness: value,
      carrier,
      sliceName,
      insuranceType,
      totalPremium,
    });
  };

  const handleCarrierChange = (value: Array<string>): void => {
    onFiltersChange({
      policyNumber,
      listingState,
      lineOfBusiness,
      carrier: value,
      sliceName,
      insuranceType,
      totalPremium,
    });
  };

  const handleSliceNameChange = (value: string): void => {
    onFiltersChange({
      policyNumber,
      listingState,
      lineOfBusiness,
      carrier,
      sliceName: value,
      insuranceType,
      totalPremium,
    });
  };

  const handleInsuranceTypeChange = (type: string): void => {
    setInsuranceType(type);
    onFiltersChange({
      policyNumber,
      listingState,
      lineOfBusiness,
      carrier,
      sliceName,
      insuranceType: type,
      totalPremium,
    });
  };

  const handleTotalPremiumChange = (value: PriceFilter): void => {
    setTotalPremium(value);
    onFiltersChange({
      policyNumber,
      listingState,
      lineOfBusiness,
      carrier,
      sliceName,
      insuranceType,
      totalPremium: value,
    });
  };

  return (
    <>
      {/* <div className="w-full flex justify-end h-fit -mb-10">
        <button className="p-2 border rounded-[4px]">
          <MagnifyingGlassIcon className="h-5 w-5" />
        </button>
      </div> */}

      <div className="w-full flex">
        <div className="w-full flex justify-start">
          <div className="w-full">
            <HomePolicyFiltersBar
              handlePolicyNumberChange={handlePolicyNumberChange}
              handleStateChange={handleStateChange}
              handleLineOfBusinessChange={handleLineOfBusinessChange}
              handleCarrierChange={handleCarrierChange}
              handleTotalPremiumChange={handleTotalPremiumChange}
              handleSliceNameFiltersChange={handleSliceNameChange}
              handleInsuranceTypeChange={handleInsuranceTypeChange}
              sliceFilter={true}
              moreFilter={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BookFilter;
