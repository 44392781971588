import { FC } from 'react';
import { Onboarding } from '../components/Onboarding';
import { FrameIcon, NextIcon } from '../../../styles/assets/icons/Icons';

export const NextSteps: FC = () => {
  const steps = [
    {
      title: 'Build your Buyer Profile',
      description: 'Define your interests so Cake can recommend listings and better match you with sellers.',
      url: '/onboarding/buyer-profile',
      button: (
        <a href="/onboarding/buyer-profile" className="hover:cursor-default">
          <NextIcon className="mx-auto rounded-full border-1 h-7 w-7 p-2 border-core-black hover:cursor-pointer group-hover:bg-core-yellow transition ease-in group-hover:border-core-yellow" />
        </a>
      ),
    },
    {
      title: 'Create a Listing',
      description: 'We’ll bring you into the Cake platform and walk you through how to upload a book of business.',
      url: '/book-management',
      button: (
        <a href="/book-management" className="hover:cursor-default">
          <FrameIcon className="mx-auto rounded-full border-1 h-7 w-7 p-2 border-core-black hover:cursor-pointer group-hover:bg-core-yellow transition ease-in group-hover:border-core-yellow" />
        </a>
      ),
    },
    {
      title: 'Value my Book',
      description: 'We’ll bring you into the Cake platform and walk you through how to value a book of business.',
      url: '/book-management',
      button: (
        <a href="/book-management" className="hover:cursor-default">
          <FrameIcon className="mx-auto rounded-full border-1 h-7 w-7 p-2 border-core-black hover:cursor-pointer group-hover:bg-core-yellow transition ease-in group-hover:border-core-yellow" />
        </a>
      ),
    },
  ];

  return (
    <Onboarding isContinue={false} isBack={true}>
      <div className="w-full py-16 px-7 flex lg:flex-row sm:flex-col xs:flex-col mt-10">
        <div className="lg:w-6/12 xs:w-full h-full px-4">
          <h4 className="mt-12 lg:text-5xl xs:text-3xl px-2 md:px-6">What do you want to </h4>
          <h4 className="lg:text-5xl xs:text-3xl px-6 justify-start">do next?</h4>
        </div>
        <div className="lg:w-6/12 xs:w-full flex lg:px-24 xs:p-0 flex-column mt-8 h-fit mb-10">
          {steps.map(
            (item, key) =>
              item && (
                <a href={item.url} className="no-underline text-black">
                  <div
                    className="w-full p-4 flex lg:mt-5 xs:mt-2 flex-row border-1 hover:border-core-black group rounded"
                    key={key}
                  >
                    <div className="w-10/12 justify-start text-left h-full p-2.5">
                      <h3 className="text-2xl">{item.title}</h3>
                      <p className="text-sm text-color-[#4D4D4D]">{item.description}</p>
                    </div>
                    <div className="w-2/12 h-full my-auto">{item.button}</div>
                  </div>
                </a>
              )
          )}
        </div>
      </div>
    </Onboarding>
  );
};
