import { BASEREQUESTOPTIONS } from 'src/api/_constants';
import { ListingByUid, ListingsResponse } from 'src/types/Listing';

export const getListingById = async (uid: string): Promise<ListingByUid> => {
  const url = '/trantor/listings';
  const queryParams = encodeURIComponent(uid);
  const fullUrl = `${url}/${queryParams}`;

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'GET',
  };

  const response = await fetch(fullUrl, requestOptions);
  return response.json();
};

export const getListings = async (): Promise<ListingsResponse> => {
  const response = await fetch('/trantor/listings', BASEREQUESTOPTIONS);
  return response.json();
};
