import { BASEREQUESTOPTIONS } from 'src/api/_constants';
import { BProfile, BuyerProfileResponse, BuyerProfiles } from 'src/types/BuyerProfile';
import { routes } from './routes';

export const getAllBuyerProfiles = async (): Promise<BuyerProfiles> => {
  const response = await fetch('/trantor/buyer-profile', BASEREQUESTOPTIONS);
  return response.json();
};

export const getBuyerProfile = async (): Promise<BuyerProfileResponse> => {
  const response = await fetch('/trantor/buyer-profile/me', BASEREQUESTOPTIONS);
  return response.json();
};

export const patchBuyerProfile = async (user: Partial<BProfile>) => {
  const response = await fetch(routes.pathBuyerProfile, {
    ...BASEREQUESTOPTIONS,
    method: 'PATCH',
    body: JSON.stringify(user),
  });
  return await response.json();
};
