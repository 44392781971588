import React, { FC, useEffect, useRef, useState } from 'react';
import { CheckIcon, ChevronRightIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import EmptyState from 'src/app/shared/components/emptyState/EmptyState';
import { formatCurrency, formatcalculateDaysAgo } from 'src/app/shared/utils/formatters';
import { useGetPolicies } from 'src/hooks/usePolicies';
import cakeUpload from 'src/styles/assets/logos/cakeUpload.svg';
import UploadModal from './components/uploadModal';
import { useHistory } from 'react-router';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';
import Pagination from 'src/app/shared/components/Pagination/Pagination';
import ModalJobFormRequest from './components/ModalJobFormRequest';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import ModalDeleteListing from './components/ModalDeleteListings';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import ModalDetailsListing from './components/ModalDetailsListing';
import ModalPivotTable from 'src/app/shared/components/Graphs/PivotTables/ModalPivotTable';
import { EditListing } from 'src/api/policies';
import { useGetListingById, useGetListings } from 'src/hooks/useGetListings';
import ModalCreateListingEmbed from './components/ModalCreateListingEmbed';
import LayoutEditSlices from './components/layoutEditSlices';
import { usePoliciesByListingId } from 'src/hooks/usePolicies';

interface ListingProps {
  isSaved?: boolean;
  onSelectListings: (selectedListings: string) => void;
  setShowLayout: (value: boolean) => void;
  showLayout: boolean | false;
  continueButton: boolean | false;
  setContinueButton: (value: boolean) => void;
}

const Listings: FC<ListingProps> = ({ onSelectListings }) => {
  const { data: dataListings, isPending, refetch } = useGetListings();
  const { data: policiesData } = useGetPolicies();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const history = useHistory();
  const [isModalUploadOpen, setisModalUploadOpen] = useState(false);
  const tableRowClass = 'p-3 text-core-tableHeader text-xs uppercase text-left border-bottom whitespace-nowrap';
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [sortField, setSortField] = useState('createdAt');

  const [openJotFormRequest, setOpenJotFormRequest] = useState(false);
  const [listingUid, setListingUid] = useState<string | null>(null);
  const { data: listingPolicyData } = usePoliciesByListingId(listingUid || '');
  const [listingName, setListingNameRequest] = useState<string | null>(null);
  const { alerts, createToast } = useAlert();
  const [openSideLayoutDetailsListing, setOpenSideLayoutDetailsListing] = useState(false);
  const [openSideLayoutDetailsSlices, setOpenSideLayoutDetailsSlices] = useState(false);

  useEffect(() => {
    setCurrentPage(1);
  }, [dataListings]);

  const handleItemsPerPageChange = (itemsPerPage: number) => {
    setItemsPerPage(itemsPerPage);
    setCurrentPage(1);
  };

  const handleUpload = () => {
    setisModalUploadOpen(true);
  };

  const closeModal = () => {
    setisModalUploadOpen(false);
    setOpenJotFormRequest(false);
    setListingNameRequest('');
    setListingUid('');
  };

  const [modalDetailsUid, setModalDetailsUid] = useState('');

  const handleDetailListing = (uid: string, listingName: string): void => {
    setModalDetailsUid(uid);
    setOpenSideLayoutDetailsListing(true);
    setListingUid(uid);
    setListingNameRequest(listingName);
  };

  const handleOnCloseModalDetails = () => {
    setOpenSideLayoutDetailsListing(false);
  };

  const [openModalPivotTable, setOpenModalPivotTable] = useState(false);

  const handleOpenModalPivotTable = () => {
    setOpenModalPivotTable(!openModalPivotTable);
  };

  const onCloseModalPivotTable = () => {
    setIsModalOpen(true);
    setOpenModalPivotTable(!openModalPivotTable);
  };

  const handleOpenJotFormRequest = () => {
    setOpenJotFormRequest(true);
    setOpenSideLayoutDetailsListing(false);
  };

  const tableColumns = [
    { label: 'Listing Name', className: `w-36 ${tableRowClass}`, key: 'name' },
    { label: 'Listing Id', className: `w-36 ${tableRowClass}`, key: 'listingNum' },
    { label: 'Views', className: `w-36 ${tableRowClass}`, key: 'views' },
    { label: 'Requests', className: `w-36 ${tableRowClass}`, key: 'requests' },
    { label: 'Premium', className: `w-40 ${tableRowClass}`, key: 'totalPremium' },
    { label: 'Status', className: `w-28 ${tableRowClass}`, key: 'isPublished' },
    { label: 'Age', className: `w-20 ${tableRowClass}`, key: 'createdAt' },
    { label: '', className: `w-16 ${tableRowClass}` },
  ];

  const handleCreateSlice = () => {
    const currentUrl = location.pathname + location.hash;
    if (currentUrl !== '/book-management#book') {
      history.push('/book-management#book');
    }
  };

  const SortIcon = (
    <ChevronUpDownIcon className="h-3 w-3 text-core-black my-auto hidden group-hover:block transition ease-in" />
  );

  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const [quickListActionUid, setQuickListActionUid] = useState('');
  const quickListRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (quickListRef.current && !quickListRef.current.contains(event.target)) {
      setQuickListActionUid('');
    }
  };

  const handleOpenQuickListAction = (uid: string) => {
    setQuickListActionUid(quickListActionUid === uid ? '' : uid);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUid, setSelectedUid] = useState<string | null>(null);

  const openModal = (uid: string) => {
    setSelectedUid(uid);
    setIsModalOpen(true);
  };

  const handleOpenModalDelete = () => {
    setSelectedUid(modalDetailsUid);
    setIsModalOpen(true);
    setOpenModalEmbed(false);
    setOpenSideLayoutDetailsListing(false);
  };

  const { data: listingDetail } = useGetListingById(modalDetailsUid);

  const handleEditDetails = () => {
    setOpenModalEmbed(!openModalEmbed);
    setOpenSideLayoutDetailsListing(false);
  };

  const handleEditSlices = () => {
    setOpenSideLayoutDetailsSlices(true);
    setOpenModalEmbed(false);
    setOpenSideLayoutDetailsListing(false);
  };

  const handleCloseEditSlices = () => {
    setOpenSideLayoutDetailsSlices(false);
  };

  const handleActivateRequest = async () => {
    try {
      await EditListing(
        modalDetailsUid,
        listingDetail?.listing.name ?? '',
        listingDetail?.listing.network ?? '',
        listingDetail?.listing.description ?? '',
        !listingDetail?.listing.isPublished
      );
      createToast({ text: 'Slice Updated', variant: 'success' });
    } catch (error) {
      console.log(error);
      createToast({
        text: 'Invalid action please try again.',
        variant: 'danger',
      });
    }
    setOpenSideLayoutDetailsListing(false);
    refetch();
  };

  const closeModalConfirmation = () => {
    setIsModalOpen(false);
    setSelectedUid('');
  };

  const handleDelete = () => {
    closeModalConfirmation();
    setSelectedUid('');
    createToast({ text: 'Slice deleted', variant: 'success' });
    refetch();
  };

  const handleSuccessEditListings = () => {
    createToast({ text: 'List Updated', variant: 'success' });
  };

  const sortData = (field: string, order: 'asc' | 'desc') => {
    return dataListings?.listings.slice().sort((a, b) => {
      switch (field) {
        case 'name':
          return order === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        case 'listingNum':
          return order === 'asc' ? a.listingNum - b.listingNum : b.listingNum - a.listingNum;
        case 'totalPremium':
          return order === 'asc' ? a.totalPremium - b.totalPremium : b.totalPremium - a.totalPremium;
        case 'isPublished':
          return order === 'asc' ? (a.isPublished ? -1 : 1) : b.isPublished ? -1 : 1;
        case 'createdAt':
          return order === 'asc'
            ? new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            : new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        default:
          return 0;
      }
    });
  };

  const sortedData = sortField ? sortData(sortField, sortOrder) : dataListings?.listings;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentSlice = sortedData?.slice(indexOfFirstItem, indexOfLastItem);
  const [openModalEmbed, setOpenModalEmbed] = useState(false);

  const handleOpenModalEmbed = () => {
    setOpenModalEmbed(!openModalEmbed);
    refetch();
  };

  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleCheckboxChange = (index: any, uid: string) => {
    if (selectedIndex === index) {
      setSelectedIndex(null);
      setSelectedUid(null);
      onSelectListings('');
    } else {
      setSelectedIndex(index);
      setSelectedUid(uid);
      onSelectListings(uid);
    }
  };

  const showEmptyState =
    policiesData && policiesData.myPolicies && policiesData.myPolicies.length > 0
      ? { text: 'Before you can create a listing, you need to create a slice', createSlice: true }
      : { text: 'Before you can create a listing, you need to create a slice', upload: true };

  if (isPending) {
    return (
      <div className="w-full flex justify-start border rounded-lg overflow-auto sm:overflow-visible md:overflow-auto lg:overflow-hidden xl:overflow-auto">
        <div className="w-full">
          <Skeleton type="table" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="w-full flex justify-start border rounded-lg overflow-auto sm:overflow-visible md:overflow-auto lg:overflow-hidden xl:overflow-auto flex-col">
        <table className="w-full bg-white rounded-lg">
          <thead className="rounded-lg">
            <tr className="rounded-lg">
              <th className="p-3 text-core-tableHeader text-xs uppercase text-left border-bottom whitespace-nowrap">
                <div className="flex gap-4 justify-start w-full my-auto">{/* empty for checkbox action */}</div>
              </th>

              {tableColumns.map((column, index) => (
                <th
                  onClick={() => column.key && handleSort(column.key)}
                  key={`${column.key !== undefined ? column.key : ''}_${index}`}
                  className={column.className}
                >
                  <span className={`flex w-full cursor-pointer group ${column.label ? '' : 'hidden'}`}>
                    {column.label} {column.key === sortField && SortIcon}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          {dataListings && dataListings?.listings.length > 0 ? (
            <tbody>
              {currentSlice?.map((listings, index) => (
                <tr
                  key={index}
                  className="hover:bg-yellow-50 transition ease-in rounded-lg cursor-pointer"
                  onClick={() => handleDetailListing(listings.uid, listings.name)}
                >
                  <td className="p-3 text-core-grey text-sm font-semibold w-[70px] text-left truncate px-3">
                    <label
                      key={index}
                      htmlFor={`checkbox-${index}`}
                      className="flex gap-4 justify-start w-full my-auto group items-center cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <input
                        type="checkbox"
                        className="hidden"
                        id={`checkbox-${index}`}
                        checked={selectedIndex === index}
                        onChange={() => handleCheckboxChange(index, listings.uid)}
                      />
                      <div
                        className={`h-5 w-5 group flex items-center justify-center border-2 border-gray-600 rounded-[4px] ${
                          selectedIndex === index ? 'bg-core-black border-black' : 'bg-white'
                        }`}
                      >
                        {selectedIndex === index && <CheckIcon className="w-3 h-3 text-white stroke-2" />}
                      </div>
                    </label>
                  </td>
                  <td className="p-3 text-core-grey text-sm font-semibold max-w-[200px] text-left truncate capitalize px-3">
                    {listings.name}
                  </td>
                  <td className="p-3 text-core-grey text-sm font-semibold max-w-[200px] text-left truncate capitalize px-3">
                    {listings.listingNum}
                  </td>
                  <td className="p-3 text-core-grey text-sm font-semibold max-w-[50px] text-left truncate capitalize px-3">
                    {listings.activity.views}
                  </td>
                  <td className="p-3 text-core-grey text-sm font-semibold max-w-[50px] text-left truncate capitalize px-3">
                    {listings.activity.requests}
                  </td>
                  <td className="p-3 text-core-grey text-sm font-semibold max-w-[200px] text-left truncate px-3">
                    {formatCurrency(listings.totalPremium)}
                  </td>
                  <td className="p-3 text-core-grey text-xs font-semibold max-w-[200px] text-left truncate px-3">
                    {listings.isPublished ? (
                      <span className="text-green-500 uppercase bg-green-50 rounded-[1px] p-1">Live</span>
                    ) : (
                      <span className="text-core-black uppercase bg-gray-50 rounded-[1px] p-1">Inactive</span>
                    )}
                  </td>
                  <td className="p-3 text-core-grey text-sm font-semibold max-w-[200px] text-left truncate px-3">
                    {formatcalculateDaysAgo(listings.createdAt)}
                  </td>

                  <td className="m-auto flex gap-3 items-center h-14">
                    <button
                      onClick={(event) => {
                        event.stopPropagation(), handleOpenQuickListAction(listings.uid);
                      }}
                      className={`my-auto rounded 'border-0' hover:border-1 transition ease-in`}
                    >
                      <EllipsisHorizontalIcon className="text-gray-400 h-8 w-8 hover:text-core-black cursor-pointer transition ease-in" />
                    </button>

                    {quickListActionUid === listings.uid && (
                      <div
                        className="bg-white rounded right-20 border-1 border-blue-600 shadow absolute mt-28 z-30"
                        ref={quickListRef}
                      >
                        <ul className="w-[140px] p-2 m-0 whitespace-nowrap text-xs font-medium text-left">
                          <li
                            className="text-red-500 p-2 py-2 cursor-pointer hover:bg-neutral-50 rounded-[4px] transition ease-in"
                            onClick={(event) => {
                              event.stopPropagation(), openModal(listings.uid);
                            }}
                          >
                            Delete
                          </li>
                        </ul>
                      </div>
                    )}

                    <button
                      onClick={() => handleDetailListing(listings.uid, listings.name)}
                      className="p-1 my-auto bg-gray-50 hover:bg-core-yellow transition ease-in rounded-sm mr-2"
                    >
                      <ChevronRightIcon className="h-3 w-3" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody className="w-full">
              <tr>
                <td colSpan={7}>
                  <EmptyState
                    text={showEmptyState.text}
                    help={false}
                    image={cakeUpload}
                    onClick={showEmptyState.createSlice ? handleCreateSlice : handleUpload}
                    createSlice={showEmptyState.createSlice}
                    upload={!showEmptyState.createSlice}
                  />
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>

      <div className="sm:mb-4 mb-20">
        {dataListings && dataListings.listings.length > itemsPerPage && (
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={dataListings?.listings.length}
            onPageChange={(page) => setCurrentPage(page)}
            onItemsPerPageChange={handleItemsPerPageChange}
            nameListing="listings"
          />
        )}
      </div>

      {isModalUploadOpen && <UploadModal isOpen={isModalUploadOpen} onClose={closeModal} />}

      {isModalOpen && selectedUid && (
        <ModalDeleteListing uid={selectedUid} onClose={closeModalConfirmation} onDelete={handleDelete} />
      )}

      {openSideLayoutDetailsListing && (
        <ModalDetailsListing
          uid={modalDetailsUid}
          onClose={handleOnCloseModalDetails}
          openPivotTable={handleOpenModalPivotTable}
          openDeleteModal={handleOpenModalDelete}
          onRequestActivate={handleActivateRequest}
          onEditDetails={handleEditDetails}
          onRequestActivateJotform={handleOpenJotFormRequest}
          onEditSlices={handleEditSlices}
        />
      )}

      {openJotFormRequest && (
        <ModalJobFormRequest
          isOpen={openJotFormRequest}
          listingUid={listingUid}
          listingName={listingName}
          onClose={closeModal}
        />
      )}

      {listingPolicyData && openModalPivotTable && (
        <ModalPivotTable
          // sliceData={listingUid}
          onCloseModalPivotTable={onCloseModalPivotTable}
          isOpen={openModalPivotTable}
          listingData={listingPolicyData}
        />
      )}

      {openModalEmbed && (
        <ModalCreateListingEmbed
          isEdit={true}
          uid={modalDetailsUid}
          isOpen={openModalEmbed}
          onClose={handleOpenModalEmbed}
          slicesList={[]}
        />
      )}

      {openSideLayoutDetailsSlices && (
        <LayoutEditSlices uid={modalDetailsUid} onClose={handleCloseEditSlices} onSuccess={handleSuccessEditListings} />
      )}

      {alerts}
    </>
  );
};

export default Listings;
