import React, { FC, ReactElement } from 'react';
import { Card, NavLink, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { signOutThunk } from 'src/app/user/UserStoreActions';
import { userStateSelector } from 'src/app/user/UserStoreSelectors';
import keyIcon from 'src/styles/assets/icons/key.svg';
// TODO
// import emailIcon from 'src/styles/assets/icons/email.svg';
// import dollarSignIcon from 'src/styles/assets/icons/dollar_sign.svg';
import signOutIcon from 'src/styles/assets/icons/sign_out.svg';
import { resetAppStoreState } from 'src/app/shared/utils/resetAppStoreState';
import styles from 'src/app/user/settings/components/UserSettingsCard.module.scss';

export const UserSettingsCard: FC = (): ReactElement => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { userProfile } = useAppSelector(userStateSelector);

  const handleSignOut = async (): Promise<void> => {
    await dispatch(signOutThunk());
    resetAppStoreState(dispatch);
  };

  const handleChangePassword = (): void => {
    history.push('/forgot#change-password');
  };

  return (
    <Card className={styles.userSettingsCard}>
      <Card.Header className={styles.userSettingsHeader}>
        <Row className={styles.userSettingsHeaderRow}>
          <h1>
            {userProfile?.firstname} {userProfile?.lastname}
          </h1>
          <h3>{userProfile.email}</h3>
        </Row>
      </Card.Header>
      <Card.Body className={styles.userSettingsCardBody}>
        <Row className={styles.userSettingsCardRow}>
          <NavLink onClick={handleChangePassword}>
            Change Password
            <img src={keyIcon} />
          </NavLink>
        </Row>
        <Row className={styles.userSettingsCardRow}>
          <NavLink onClick={handleSignOut}>
            Sign Out
            <img src={signOutIcon} />
          </NavLink>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default UserSettingsCard;
