import React, { useEffect, useState } from 'react';
import { Onboarding } from '../components/Onboarding';
import { useFormik } from 'formik';
import { useGetBuyerProfile, usePatchBuyerProfile } from 'src/hooks/useBuyersProfile';
import { useHistory } from 'react-router';
import TextareaComponent from 'src/app/shared/components/Textarea/Textarea';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';

const BuyerProfileDescription = () => {
  const history = useHistory();
  const { mutate, isSuccess } = usePatchBuyerProfile();
  const { data: buyerProfileResponse, isPending } = useGetBuyerProfile();
  const [, setBuyerProfile] = useState(buyerProfileResponse);

  useEffect(() => {
    if (buyerProfileResponse) {
      setBuyerProfile(buyerProfileResponse);
    }
  }, [buyerProfileResponse]);

  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      profileDescription: buyerProfileResponse?.buyerProfile.profileDescription
        ? buyerProfileResponse?.buyerProfile.profileDescription
        : '',
    },
    onSubmit: (values) => {
      mutate(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      history.push('/onboarding/buyer-profile/preview');
    }
  }, [isSuccess, history]);

  if (isPending) {
    return (
      <div className="w-full mb-12 z-0">
        <Skeleton type="step-onboarding" />
      </div>
    );
  }

  return (
    <Onboarding
      handleSubmit={handleSubmit}
      isContinue={true}
      isBack={true}
      isStep={true}
      numOfSteps={7}
      stepPosition={7}
      isFinish={true}
    >
      <div className="w-full md:w-3/5 mx-auto block py-20 px-8 md:px-32 text-left gap-8 mt-10">
        <div className="w-full">
          <p className="text-center text-sm text-gray-600 font-bold mx-0 p-0 mb-8">BUILD YOUR BUYER PROFILE</p>
        </div>

        <div className="w-full mb-12 mt-8">
          <h1 className="text-core-black text-3xl md:text-5xl font-medium text-center">
            What else would you want sellers to know about you?
          </h1>
          <p className="font-medium text-gray-700 text-base mt-4 mb-0 p-0 text-center">
            Give sellers an idea of your experience and who are you are.
          </p>
        </div>

        <div className="w-full">
          <div className="w-full mt-10">
            <TextareaComponent
              height="h-40"
              labelInput="DESCRIPTION"
              placeholderText="Enter a description here"
              maxCharacters={750}
              showCharacterCount={true}
              name="profileDescription"
              value={values.profileDescription}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
        </div>
      </div>
    </Onboarding>
  );
};

export default BuyerProfileDescription;
