import { ChevronDownIcon, ChevronUpIcon, MinusIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'src/app/shared/components/Buttons/Button';
import PieChart from 'src/app/shared/components/Graphs/PieChart';
import { UsStatesOptions } from 'src/app/shared/ListingEnums';
import { formatLastSyncShort } from 'src/app/shared/utils/formatters';
import { useGetListingById } from 'src/hooks/useGetListings';

interface ModalDetailsListingProps {
  uid: string;
  onClose: () => void;
  openPivotTable: () => void;
  openDeleteModal: () => void;
  onRequestActivate: () => void;
  onEditDetails: () => void;
  onRequestActivateJotform: () => void;
  onEditSlices: () => void;
}

const ModalDetailsListing: React.FC<ModalDetailsListingProps> = ({
  uid,
  onClose,
  openPivotTable,
  openDeleteModal,
  onRequestActivate,
  onEditDetails,
  onRequestActivateJotform,
  onEditSlices,
}) => {
  const { data: listingDetail, isPending } = useGetListingById(uid || '');
  const formattedDate = listingDetail?.listing.createdAt ? formatLastSyncShort(listingDetail?.listing.createdAt) : '';
  const [openData, setOpenData] = useState(true);
  const [showManageOptions, setShowManageOptions] = useState(false);
  const selectorRef = useRef<HTMLDivElement>(null);
  const [expandDataDescription, setExpandDataDescription] = useState(true);
  const [allDescription, setAllDescription] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (descriptionRef.current) {
      setIsOverflowing(descriptionRef.current.scrollHeight > 64);
    }
  }, [listingDetail]);

  const toggleDescription = () => {
    setAllDescription(!allDescription);
  };

  const handleExpandData = () => {
    setOpenData(!openData);
  };

  const handleExpandDataDescription = () => {
    setExpandDataDescription(!expandDataDescription);
  };

  const getStateFullName = (stateCode: string): string | undefined => {
    return UsStatesOptions[stateCode as keyof typeof UsStatesOptions];
  };

  const handleShowManageOptions = () => {
    setShowManageOptions(!showManageOptions);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target as Node)) {
        setShowManageOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const formatPremiumRange = (totalPremium: number) => {
    const formatValue = (value: number) => {
      if (value < 1000000) {
        return `$${(value / 1000).toFixed(1)}k`;
      } else {
        return `$${(value / 1000000).toFixed(1)}m`;
      }
    };

    const formattedTotalPremium = formatValue(totalPremium);

    return `${formattedTotalPremium}`;
  };

  const carrier = listingDetail?.listing.carrierSummaries?.[0]?.carrier || '-';
  const carrierPercentage = listingDetail?.listing.carrierSummaries?.[0]?.percentage
    ? '(' + listingDetail?.listing.carrierSummaries?.[0]?.percentage + '%)'
    : '';

  const state = listingDetail?.listing.stateSummaries?.[0]?.state || '-';
  const statePercentage = listingDetail?.listing.stateSummaries?.[0]?.percentage
    ? '(' + listingDetail?.listing.stateSummaries?.[0]?.percentage + '%)'
    : '';

  const lobName = listingDetail?.listing.lobSummaries?.[0]?.lob || '-';
  const lobPercentage = listingDetail?.listing.lobSummaries?.[0]?.percentage
    ? '(' + listingDetail?.listing.lobSummaries?.[0]?.percentage + '%)'
    : '';

  const insuranceTypes = listingDetail?.listing.insuranceType || [];
  const maxPercentageType =
    insuranceTypes.length > 0
      ? insuranceTypes.reduce((prev, current) => (prev.percentage > current.percentage ? prev : current))
      : { type_of_insurance: '', percentage: 0 };

  return (
    <div
      onClick={onClose}
      className="fixed top-0 z-50 left-0 w-full h-full flex justify-center items-center backdrop-blur-md bg-black/50"
    >
      <div
        className="bg-white rounded md:h-[96vh] w-full md:w-[536px] h-[99vh] fixed md:right-3"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="w-full flex flex-col">
          <div className="w-full flex p-6">
            <div className="w-full flex flex-col text-left justify-start">
              {isPending ? (
                <>
                  <div className="bg-neutral-100 w-[150px] p-2.5 animate-pulse"></div>
                  <div className="bg-neutral-100 w-[300px] p-2 mt-2 animate-pulse"></div>
                </>
              ) : (
                <>
                  <h1 className="font-medium text-2xl text-core-black my-auto capitalize">
                    {listingDetail?.listing.name}
                  </h1>
                  <div className="w-full gap-2 flex text-left mt-1 text-sm font-bold uppercase my-auto">
                    {listingDetail?.listing.isPublished ? (
                      <span className="text-green-500 uppercase bg-green-50 rounded-[1px] p-1 !text-xs">Live</span>
                    ) : (
                      <span className="text-core-black uppercase bg-gray-50 rounded-[1px] p-1 !text-xs">Inactive</span>
                    )}
                    <span className="bg-gray-400 h-1 w-1 my-auto rounded-full"></span>
                    <p className="m-0 p-0 text-gray-500">ID {listingDetail?.listing.listingNum}</p>
                    <span className="bg-gray-400 h-1 w-1 my-auto rounded-full"></span>
                    <p className="m-0 p-0 text-gray-500">{listingDetail?.listing.groups.length} Slices</p>
                    <span className="bg-gray-400 h-1 w-1 my-auto rounded-full"></span>
                    <p className="m-0 p-0 text-gray-500">{listingDetail?.listing.activity?.views} Views</p>
                    <span className="bg-gray-400 h-1 w-1 my-auto rounded-full"></span>
                    <p className="m-0 p-0 text-gray-500">Listed {formattedDate}</p>
                  </div>
                </>
              )}
            </div>
            <div className="w-fit flex justify-end">
              <button
                className="my-auto p-2 rounded-full bg-neutral-50 cursor-pointer hover:bg-neutral-100 transition ease-in"
                onClick={onClose}
              >
                <XMarkIcon className={`my-auto w-4 h-4 text-core-black group-hover:text-white`} />
              </button>
            </div>
          </div>

          <div className="w-full overflow-y-auto md:max-h-[70vh] max-h-[80vh]">
            {isPending ? (
              <div className="grid grid-cols-3 gap-3 px-6 pb-6">
                {[...Array(3)].map((_, indexCard) => (
                  <div
                    className="border-[1px] border-gray-300 rounded-lg p-10 bg-neutral-100 animate-pulse"
                    key={indexCard}
                  ></div>
                ))}
              </div>
            ) : (
              <div className="w-full grid grid-cols-3 md:grid-cols-3 gap-3 px-6 pb-6">
                <div className="bg-white border-[1px] border-gray-300 rounded-lg p-4 flex justify-start text-left flex-col text-core-black">
                  <h5 className="text-xl md:text-3xl font-medium">
                    {formatPremiumRange(listingDetail?.listing.totalPremium || 0)}
                  </h5>
                  <p className="p-0 m-0 text-xs md:text-sm font-medium">Premium</p>
                </div>
                <div className="bg-white border-[1px] border-gray-300 rounded-lg p-4 flex justify-start text-left flex-col text-core-black">
                  <h5 className="text-xl md:text-3xl font-medium">{listingDetail?.listing.totalPolicies}</h5>
                  <p className="p-0 m-0 text-xs md:text-sm font-medium">Policies</p>
                </div>
                <div className="bg-white border-[1px] border-gray-300 rounded-lg p-4 flex justify-start text-left flex-col text-core-black">
                  <h5 className="text-xl md:text-3xl font-medium">{listingDetail?.listing.totalCustomers}</h5>
                  <p className="p-0 m-0 text-xs md:text-sm font-medium">Customers</p>
                </div>
              </div>
            )}

            <div className="w-full flex flex-col px-6 py-2">
              <div className="w-full flex py-0 items-center cursor-pointer" onClick={handleExpandData}>
                <div className="w-1/2 text-left">
                  <p className="text-core-black text-base font-medium">Key Attributes</p>
                </div>
                <div className="w-1/2 flex justify-end">
                  {openData ? (
                    <ChevronUpIcon className="h-4 w-4 my-auto" />
                  ) : (
                    <ChevronDownIcon className="h-4 w-4 my-auto" />
                  )}
                </div>
              </div>

              <div className="border-b border-gray-200 w-full">
                {openData && (
                  <div className="grid grid-cols-3 gap-2 text-left pb-3">
                    <div className="mb-6">
                      <h4 className="text-gray-600 text-2xs uppercase font-bold mb-2">Carrier</h4>
                      <p className="text-core-black text-sm font-normal ">
                        {carrier}

                        <span className="text-gray-600 text-xs ml-2">{carrierPercentage}</span>
                      </p>
                    </div>

                    <div className="mb-6">
                      <h4 className="text-gray-600 text-2xs uppercase font-bold mb-2">State</h4>
                      <p className="text-core-black text-sm font-normal">
                        {state !== '-' ? getStateFullName(state) : '-'}
                        <span className="text-gray-600 text-xs ml-2">{statePercentage}</span>
                      </p>
                    </div>

                    <div>
                      <h4 className="text-gray-600 text-2xs uppercase font-bold mb-2">Lines</h4>
                      <p className="text-core-black text-sm font-normal">
                        {lobName}

                        <span className="text-gray-600 text-xs ml-2">{lobPercentage}</span>
                      </p>
                    </div>

                    <div>
                      <h4 className="text-gray-600 text-2xs uppercase font-bold mb-2">Type</h4>
                      <p className="text-core-black text-sm font-normal">
                        {maxPercentageType.type_of_insurance}
                        <span className="text-gray-600 text-xs ml-2">({maxPercentageType.percentage}%)</span>
                      </p>
                    </div>

                    <div>
                      <h4 className="text-gray-600 text-2xs uppercase font-bold mb-2">Network</h4>
                      <p className="text-core-black text-sm font-normal">{listingDetail?.listing.network}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="w-full flex flex-col px-6 py-2">
              <div className="w-full flex py-0 items-center cursor-pointer" onClick={handleExpandDataDescription}>
                <div className="w-1/2 text-left">
                  <p className="text-core-black text-base font-medium">Description</p>
                </div>
                <div className="w-1/2 flex justify-end">
                  {expandDataDescription ? (
                    <ChevronUpIcon className="h-4 w-4 my-auto" />
                  ) : (
                    <ChevronDownIcon className="h-4 w-4 my-auto" />
                  )}
                </div>
              </div>

              <div className="border-b border-gray-200 w-full">
                {expandDataDescription && (
                  <div
                    className={`w-full ${allDescription ? 'max-h-fit' : 'max-h-16 overflow-hidden text-ellipsis'}`}
                    ref={descriptionRef}
                  >
                    <p className="text-gray-500 font-medium text-sm text-left">{listingDetail?.listing.description}</p>
                  </div>
                )}
                {isOverflowing && (
                  <button
                    onClick={toggleDescription}
                    className="my-2 text-core-black text-left font-medium text-sm flex gap-2"
                  >
                    {allDescription ? (
                      <MinusIcon className="h-3 w-3 my-auto" />
                    ) : (
                      <PlusIcon className="h-3 w-3 my-auto" />
                    )}
                    {allDescription ? 'Show less' : 'Show more'}
                  </button>
                )}
              </div>
            </div>

            <div className="w-full mt-6 px-6">
              {listingDetail && (
                <PieChart
                  sliceTotalPoliciesData={listingDetail.listing}
                  onCloseModal={onClose}
                  handleOpenPivotTable={openPivotTable}
                />
              )}
            </div>
          </div>

          <div className="w-full border-t border-gray-400 py-3 px-4 md:rounded-b absolute bottom-2 bg-white">
            {showManageOptions && (
              <div
                ref={selectorRef}
                className="bg-white border-1 border-blue-500 rounded-[3px] absolute bottom-16 w-[91%]"
              >
                <ul className="px-2 py-1 text-center m-0 text-sm">
                  <li
                    className="w-full p-3 hover:bg-neutral-50 transition ease-in cursor-pointer"
                    onClick={onEditSlices}
                  >
                    Edit Slices
                  </li>
                  <li
                    className="w-full border-t border-b p-3 hover:bg-neutral-50 transition ease-in cursor-pointer"
                    onClick={onEditDetails}
                  >
                    Edit Details
                  </li>

                  {!listingDetail?.listing.isPublished && (
                    <li
                      className="w-full border-b p-3 hover:bg-neutral-50 transition ease-in cursor-pointer"
                      onClick={onRequestActivateJotform}
                    >
                      Publish
                    </li>
                  )}

                  {listingDetail?.listing.isPublished && (
                    <li
                      className="w-full border-b p-3 hover:bg-neutral-50 transition ease-in cursor-pointer"
                      onClick={onRequestActivate}
                    >
                      Unpublish
                    </li>
                  )}
                  <li
                    className="w-full p-3 text-red-500 hover:bg-neutral-50 transition ease-in cursor-pointer"
                    onClick={openDeleteModal}
                  >
                    Delete
                  </li>
                </ul>
              </div>
            )}
            <Button variant="outline" color="primary" onClick={handleShowManageOptions}>
              Manage Listing
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDetailsListing;
