import { BASEREQUESTOPTIONS } from './_constants';

export const DeleteSlices = async (groupUids: string[]): Promise<void> => {
  const requestBody = {
    groupUids: groupUids,
  };

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(`/trantor/groups/delete`, requestOptions);
  return response.json();
};
