export interface UserProfile {
  uid: string;
  email: string;
  createdAt: string;
  firstname: string;
  lastname: string;
  username: string;
  receivePromotions: boolean;
}

export interface UserProfileResponse {
  userProfile: UserProfile;
}

export const initialUserState = {
  isLoading: false,
  isUserLoggedIn: false,
  userProfile: {} as UserProfile,
};

export type UserState = typeof initialUserState;
