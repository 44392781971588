import React from 'react';
import { LoadingIcon } from 'src/styles/assets/icons/Icons';

type SkeletonProps = {
  type:
    | 'table'
    | 'card'
    | 'list'
    | 'form-details'
    | 'subscriptionTab'
    | 'buyerTab'
    | 'loading-more'
    | 'listView'
    | 'screenTwoSections'
    | 'status-card'
    | 'onboarding-preview'
    | 'step-onboarding'
    | 'list-simple'
    | 'book-valuation'
    | 'bookValuationWidget'
    | 'manageMapping'
    | 'graphFlyout';
};

const Skeleton: React.FC<SkeletonProps> = ({ type }) => {
  const renderMultiplyDivs = () => {
    return Array.from({ length: 5 }, (_, index) => (
      <div className="multiply flex items-center justify-between border-b p-3.5" key={index}>
        <div className="w-20 h-3 bg-neutral-50 rounded-full"></div>
      </div>
    ));
  };

  const renderSkeletonContent = () => {
    switch (type) {
      case 'loading-more':
        return (
          <div className="w-full flex flex-col py-20">
            <LoadingIcon className="w-16 h-16 text-gray-200 animate-spin fill-blue-500 mx-auto" />
            <p className="mt-3 text-core-black text-xl font-medium text-center">Loading more...</p>
          </div>
        );

      case 'graphFlyout':
        return (
          <div className="w-full">
            <div className="w-full md:flex gap-6 mt-4 animate-pulse">
              {[...Array(2)].map((_, indexCard) => (
                <div key={indexCard} className="w-full md:w-1/2 bg-white rounded-lg p-6">
                  <div className="bg-neutral-100 w-[140px] p-[10px]"></div>
                  <div className="w-[240px] h-[240px] mx-auto my-6 rounded-full border-[40px] border-neutral-100">
                    <div className="bg-core-white h-[45px] w-[1px] p-1 -mt-10 ml-[70px]"></div>
                    <div className="bg-core-white h-[45px] w-[1px] p-1 -mt-3 ml-[150px] rotate-[50deg]"></div>
                    <div className="bg-core-white h-[45px] w-[1px] p-1 -mt-[80px] ml-[100px] rotate-[12deg]"></div>
                    <div className="bg-core-white h-[45px] w-[1px] p-1 mt-[155px] ml-[80px]"></div>
                    <div className="bg-core-white h-[45px] w-[1px] p-1 -mt-[225px] ml-[20px] rotate-[140deg]"></div>
                    <div className="bg-core-white h-[45px] w-[1px] p-1 mt-[105px] ml-[5px] rotate-[220deg]"></div>
                  </div>
                  {[...Array(3)].map((_, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-3 py-2 gap-3 my-2 border-b-[1px] border-gray-200 last:border-b-[0px]"
                    >
                      {[...Array(3)].map((_, innerIndex) => (
                        <div key={innerIndex} className="w-full p-[10px] bg-neutral-100"></div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div className="w-full space-y-4">
              <div className="w-full rounded-lg bg-white p-6 animate-pulse">
                <div className="bg-neutral-100 w-[140px] p-[10px]"></div>
                {[...Array(3)].map((_, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-3 py-2 gap-3 my-2 border-b-[1px] border-gray-200 last:border-b-[0px]"
                  >
                    <div className="w-full md:w-80 p-[10px] bg-neutral-100"></div>
                    {[...Array(2)].map((_, innerIndex) => (
                      <div key={innerIndex} className="w-full md:w-40 p-[10px] bg-neutral-100"></div>
                    ))}
                  </div>
                ))}
              </div>

              <div className="w-full rounded-lg bg-white p-6 animate-pulse">
                <div className="bg-neutral-100 w-[140px] p-[10px]"></div>
                <div className="w-full mt-10 md:flex">
                  <div className="w-full md:w-[30%]">
                    <div className="h-[250px] w-[250px] rounded-full border-[40px] border-neutral-100 bg-core-lightGrey">
                      <div className="bg-core-white h-[45px] w-[1px] p-1 -mt-[24px] ml-[20px] rotate-[140deg]"></div>
                      <div className="bg-core-white h-[45px] w-[1px] p-1 mt-[130px] ml-[20px] rotate-[2200deg]"></div>
                    </div>
                  </div>
                  <div className="w-full md:w-[70%] my-auto">
                    {[...Array(3)].map((_, index) => (
                      <div
                        key={index}
                        className="grid grid-cols-3 py-2 gap-3 my-2 border-b-[1px] border-gray-200 last:border-b-[0px]"
                      >
                        <div className="w-full md:w-52 p-[10px] bg-neutral-100"></div>
                        {[...Array(2)].map((_, innerIndex) => (
                          <div key={innerIndex} className=" w-32 p-[10px] bg-neutral-100"></div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 'manageMapping':
        return (
          <div className="w-full px-6 mt-6 animate-pulse">
            <div className="w-[200px] h-6 bg-neutral-100 rounded-full mb-2"></div>
            <div className="w-[400px] h-6 bg-neutral-100 rounded-full"></div>

            <div className="flex my-8 w-full">
              <div className="w-2/5">
                <div className="bg-neutral-100 w-full rounded h-14"></div>
              </div>

              <div className="w-1/5">
                <div className="bg-neutral-100 h-7 w-7 rounded-full mx-auto mt-3"></div>
              </div>

              <div className="w-2/5">
                <div className="bg-neutral-100 w-full rounded h-14"></div>
              </div>
            </div>

            <div className="flex my-8 w-full">
              <div className="w-2/5">
                <div className="bg-neutral-100 w-full rounded h-14"></div>
              </div>

              <div className="w-1/5">
                <div className="bg-neutral-100 h-7 w-7 rounded-full mx-auto mt-3"></div>
              </div>

              <div className="w-2/5">
                <div className="bg-neutral-100 w-full rounded h-14"></div>
              </div>
            </div>
          </div>
        );
      case 'bookValuationWidget':
        return (
          <div className="w-full animate-pulse">
            <div className="w-full">
              <div className="h-5 w-32 bg-neutral-100 rounded-full mb-3"></div>
              <div className="h-10 w-full bg-neutral-100 rounded-full mb-3"></div>
            </div>
            <div className="w-full grid grid-cols-3 gap-2">
              <div className="border rounded-[4px] h-[100px]"></div>
              <div className="border rounded-[4px] h-[100px]"></div>
              <div className="border rounded-[4px] h-[100px]"></div>
            </div>
          </div>
        );
      case 'book-valuation':
        return (
          <div className="w-full grid grid-cols-4 gap-3">
            <div className="rounded-[4px] p-3 h-[130px] border-1 border-neutral-50 text-left bg-neutral-50"></div>
            <div className="rounded-[4px] p-3 h-[130px] border-1 border-neutral-50 text-left bg-white"></div>
            <div className="rounded-[4px] p-3 h-[130px] border-1 border-neutral-50 text-left bg-white"></div>
            <div className="rounded-[4px] p-3 h-[130px] border-1 border-neutral-50 text-left bg-white"></div>
          </div>
        );
      case 'step-onboarding':
        return (
          <div className="w-full py-20 px-4 animate-pulse space-y-10">
            <div className="w-1/2 mx-auto h-4 rounded bg-neutral-100"></div>
            <div className="w-1/2 mx-auto h-12 rounded bg-neutral-100"></div>
            <div className="w-1/2 border mx-auto rounded h-[150px] bg-neutral-100"></div>
          </div>
        );
      case 'list-simple':
        return (
          <div className="w-full bg-white p-1 space-y-3">
            <div className="w-full bg-gray-100 rounded-[4px] h-8"></div>
            <div className="w-full bg-gray-100 rounded-[4px] h-8"></div>
            <div className="w-full bg-gray-100 rounded-[4px] h-8"></div>
          </div>
        );
      case 'onboarding-preview':
        return (
          <div className="w-full py-20 h-fit animate-pulse flex gap-4 md:flex-row flex-col px-20 sm:px-32">
            <div className="w-full md:w-2/5 bg-neutral-100 border h-[600px] rounded"></div>
            <div className="w-full md:w-3/5 bg-neutral-100 border h-[600px] rounded"></div>
          </div>
        );
      case 'status-card':
        return (
          <div className="w-full border px-10 py-8 rounded bg-white flex flex-col sm:flex-row animate-pulse">
            <div className="w-full md:w-1/2 gap-2 flex flex-col">
              <div className="bg-neutral-100 w-44 h-5"></div>
              <div className="bg-neutral-100 w-full h-5"></div>
              <div className="bg-neutral-100 w-full h-5"></div>
            </div>

            <div className="md:w-1/2 flex md:justify-end mt-2">
              <div className="bg-neutral-100 w-36 h-10 rounded"></div>
            </div>
          </div>
        );
      case 'form-details':
        return (
          <>
            <div className="w-full animate-pulse flex">
              <div className="w-1/2">
                <div className="w-[150px] h-10 bg-gray-300 rounded-full"></div>
              </div>

              <div className="w-1/2 flex justify-end gap-2">
                <div className="w-[90px] h-10 bg-gray-300 rounded"></div>
                <div className="w-[90px] h-10 bg-gray-300 rounded"></div>
              </div>
            </div>

            <div className="w-full grid grid-cols-2 gap-4 mt-10 animate-pulse">
              <div className="w-full h-10 rounded bg-gray-300"></div>
              <div className="w-full h-10 rounded bg-gray-300"></div>
              <div className="w-full h-10 rounded bg-gray-300"></div>
              <div className="w-full h-10 rounded bg-gray-300"></div>
              <div className="w-full h-10 rounded bg-gray-300"></div>
              <div className="w-full h-10 rounded bg-gray-300"></div>
            </div>
          </>
        );
      case 'screenTwoSections':
        return (
          <>
            <div className="w-full animate-pulse flex flex-col">
              <div className="bg-neutral-100 rounded-full h-12 w-[35%] mb-4"></div>
              <div className="w-full flex gap-5">
                <div className="w-[65%] bg-neutral-100 h-52 rounded"></div>
                <div className="w-[35%] h-32 bg-neutral-100 rounded"></div>
              </div>
            </div>
          </>
        );
      case 'subscriptionTab':
        return (
          <>
            <div className="w-full animate-pulse flex">
              <div className="w-full">
                <div className="w-[150px] h-10 bg-gray-300 rounded-full"></div>
              </div>
            </div>

            <div className="w-full grid grid-cols-1 gap-4 mt-10 animate-pulse">
              <div className="w-full h-20 rounded bg-gray-300"></div>
            </div>
          </>
        );
      case 'buyerTab':
        return (
          <>
            <div className="w-full animate-pulse grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
              <div className="w-full bg-gray-50 rounded min-h-[381px]"></div>
              <div className="w-full bg-gray-50 rounded min-h-[381px]"></div>
              <div className="w-full bg-gray-50 rounded min-h-[381px]"></div>
            </div>
          </>
        );
      case 'table':
        return (
          <div className="w-full animate-pulse">
            <div className="w-full bg-white border rounded-t-lg p-4"></div>
            <div className="w-full rounded-b-lg border bg-white">{renderMultiplyDivs()}</div>
          </div>
        );
      case 'card':
        return (
          <div className="w-full">
            <div className="w-full animate-pulse rounded border bg-white p-5">
              <div className="flex w-full gap-3">
                <div className="h-10 w-10 rounded-full bg-gray-300"></div>
                <div className="my-auto h-4 w-24 rounded-full bg-gray-200"></div>
              </div>

              <div className="w-full">
                <div className="mt-5 h-3 w-full rounded-full bg-gray-200"></div>
                <div className="mt-5 h-3 w-full rounded-full bg-gray-200"></div>
              </div>
            </div>
          </div>
        );
      case 'list':
        return (
          <div className="w-full">
            <div className="w-full animate-pulse rounded border bg-white">
              <div className="p-5 border-b">
                <div className="h-2.5 w-full rounded-full bg-gray-300"></div>
              </div>
              <div className="p-5 border-b">
                <div className="h-2.5 w-full rounded-full bg-gray-300"></div>
              </div>
              <div className="p-5">
                <div className="h-2.5 w-full rounded-full bg-gray-300"></div>
              </div>
            </div>
          </div>
        );
      case 'listView':
        return (
          <>
            <div className="mt-4 py-4 border-t w-full">
              <div className="w-full mb-3">
                <div className="h-4 w-32 bg-neutral-100"></div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="gap-3 flex flex-col">
                  <div className="h-4 w-32 bg-neutral-100"></div>
                  <div className="h-5 w-full bg-neutral-100"></div>
                </div>

                <div className="gap-3 flex flex-col">
                  <div className="h-4 w-32 bg-neutral-100"></div>
                  <div className="h-5 w-full bg-neutral-100"></div>
                </div>

                <div className="gap-3 flex flex-col">
                  <div className="h-4 w-32 bg-neutral-100"></div>
                  <div className="h-5 w-full bg-neutral-100"></div>
                </div>

                <div className="gap-3 flex flex-col">
                  <div className="h-4 w-32 bg-neutral-100"></div>
                  <div className="h-5 w-full bg-neutral-100"></div>
                </div>

                <div className="gap-3 flex flex-col">
                  <div className="h-4 w-32 bg-neutral-100"></div>
                  <div className="h-5 w-full bg-neutral-100"></div>
                </div>

                <div className="gap-3 flex flex-col">
                  <div className="h-4 w-32 bg-neutral-100"></div>
                  <div className="h-5 w-full bg-neutral-100"></div>
                </div>
              </div>
            </div>

            <div className="mb-4 py-4 border-t w-full">
              <div className="w-full mb-3">
                <div className="h-4 w-32 bg-neutral-100"></div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="gap-3 flex flex-col">
                  <div className="h-4 w-32 bg-neutral-100"></div>
                  <div className="h-5 w-full bg-neutral-100"></div>
                </div>

                <div className="gap-3 flex flex-col">
                  <div className="h-4 w-32 bg-neutral-100"></div>
                  <div className="h-5 w-full bg-neutral-100"></div>
                </div>

                <div className="gap-3 flex flex-col">
                  <div className="h-4 w-32 bg-neutral-100"></div>
                  <div className="h-5 w-full bg-neutral-100"></div>
                </div>

                <div className="gap-3 flex flex-col">
                  <div className="h-4 w-32 bg-neutral-100"></div>
                  <div className="h-5 w-full bg-neutral-100"></div>
                </div>

                <div className="gap-3 flex flex-col">
                  <div className="h-4 w-32 bg-neutral-100"></div>
                  <div className="h-5 w-full bg-neutral-100"></div>
                </div>

                <div className="gap-3 flex flex-col">
                  <div className="h-4 w-32 bg-neutral-100"></div>
                  <div className="h-5 w-full bg-neutral-100"></div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return <div>{renderSkeletonContent()}</div>;
};

export default Skeleton;
