export const networkValues = [
  '',
  'AAI',
  'Advantage Partners Network',
  'Agentero',
  'ASNOA',
  'Associated Risk Managers – Kentucky & Ohio',
  'Associated Risk Managers International, Inc.',
  'Brightway Insurance',
  'Career Insurance Agents',
  'Combined Agents of America',
  'Couri Insurance Associates',
  'Direct Access Insurance Service',
  'Diversified Commercial Insurers',
  'Eagle Alliance',
  'Evolution Insurance Partners Marketing Inc.',
  'Fiesta Auto Insurance',
  'Firefly Agency',
  'FirstChoice, a MarshBerry Company',
  'Fortified',
  'GlobalGreen',
  'GreatFlorida Insurance',
  'Heffernan Network Insurance Brokers',
  'Horizon Agency Systems',
  'IAN Insurance Agency Network',
  'IAOA Market Access',
  'Indium',
  'Insural Insurance Solutions Inc.',
  'Insurance Associates of America',
  'Insurance Producers Network',
  'Insurors Group LLC',
  'Integra Partner Network',
  'Iroquois Group',
  'ISG United',
  'ISU Insurance Agency Network',
  'Keystone Insurers Group Inc.',
  'MIAN Madison Independent Agency Network',
  'NavSav',
  'One80 Network Solutions, a division of One80 Intermediaries',
  'Pacific Crest Services',
  'Pacific Interstate Insurance Brokers',
  'PacWest Alliance Insurance Services Inc.',
  'Panorama Insurance Associates',
  'Premier Group Insurance',
  'Renaissance',
  'SBMP Insurance Group',
  'Secured Advantage',
  'SecureRisk LLC',
  'SIAA',
  'Smart Choice',
  'Strategic Agency Partners',
  'The Agency Collective',
  'The Insurance Alliance Network',
  'TWFG Holding Company, LLC',
  'United Agencies Inc.',
  'Voldico Insurance',
  'XPT Specialty',
];
