import React, { FC, useEffect, useRef, useState } from 'react';
import {
  ArrowLeftIcon,
  ArrowTopRightOnSquareIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Button } from '../Buttons/Button';
import EditSliceForm from 'src/app/book-management/book-tabs/components/filter/EditSliceForm';
import { useHistory } from 'react-router';
import { useGetSliceDetail, useGetSliceGroupDetail } from 'src/hooks/usePolicies';
import { UsStatesOptions } from '../../ListingEnums';
import BookValuationWidget from 'src/app/book-management/book-tabs/components/bookValuationWidget';
import PieChart from '../Graphs/PieChart';
import TopCarriers from './TopCarriers';
import BarChart from '../Graphs/BarChart';

interface ModalRightSideProps {
  isOpen: boolean;
  onClose: () => void;
  groupDetail: any | undefined;
  openPivotTable: () => void;
}

const ModalRightSlices: FC<ModalRightSideProps> = ({ isOpen, onClose, openPivotTable, groupDetail = {} }) => {
  document.body.style.overflow = isOpen ? 'hidden' : 'auto';

  if (!isOpen) {
    return null;
  }

  const history = useHistory();
  const [openData, setOpenData] = useState(true);
  const [showManageOptions, setShowManageOptions] = useState(false);
  const [showFormEdit, setShowFormEdit] = useState(false);
  const selectorRef = useRef<HTMLDivElement>(null);
  const { data: sliceDetailData } = useGetSliceDetail(groupDetail.uid);
  const { data: sliceTotalPoliciesData } = useGetSliceGroupDetail(groupDetail.uid);
  const insuranceTypes = sliceTotalPoliciesData?.group.insurance_type || [];
  const maxPercentageType =
    insuranceTypes.length > 0
      ? insuranceTypes.reduce((prev, current) => (prev.percentage > current.percentage ? prev : current))
      : { type_of_insurance: '', percentage: 0 };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target as Node)) {
        setShowManageOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const date = groupDetail?.createdDate ? new Date(groupDetail.createdDate) : null;
  const formattedDate = date
    ? `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().substr(-2)}`
    : '';

  const handleExpandData = () => {
    setOpenData(!openData);
  };

  const getStateFullName = (stateCode: string): string | undefined => {
    return UsStatesOptions[stateCode as keyof typeof UsStatesOptions];
  };

  const handleShowManageOptions = () => {
    setShowManageOptions(!showManageOptions);
  };

  const formatPremiumRange = (totalPremium: number) => {
    const formatValue = (value: number) => {
      if (value < 1000000) {
        return `$${(value / 1000).toFixed(1)}k`;
      } else {
        return `$${(value / 1000000).toFixed(1)}m`;
      }
    };

    const formattedTotalPremium = formatValue(totalPremium);

    return `${formattedTotalPremium}`;
  };

  const handleEditName = () => {
    setShowManageOptions(false);
    setShowFormEdit(!showFormEdit);
  };

  const handleBackStep = () => {
    setShowFormEdit(!showFormEdit);
  };

  const handleEditSuccess = (success: boolean) => {
    if (success) {
      onClose();
    }
  };

  const handleEditSliceGroup = (sliceName: string) => {
    localStorage.setItem('sliceNameFilters', `"${sliceName}"`);
    onClose();
    history.push('/book-management#book');
  };

  const carrier = sliceTotalPoliciesData?.group.carrier_summaries?.[0]?.carrier || '-';
  const carrierPercentage = sliceTotalPoliciesData?.group.carrier_summaries?.[0]?.percentage
    ? '(' + sliceTotalPoliciesData?.group.carrier_summaries?.[0]?.percentage + '%)'
    : '';

  const state = sliceTotalPoliciesData?.group.state_summaries?.[0]?.state || '-';
  const statePercentage = sliceTotalPoliciesData?.group.state_summaries?.[0]?.percentage
    ? '(' + sliceTotalPoliciesData?.group.state_summaries?.[0]?.percentage + '%)'
    : '';

  const lobName = sliceTotalPoliciesData?.group.lob_summaries?.[0]?.lob || '-';
  const lobPercentage = sliceTotalPoliciesData?.group.lob_summaries?.[0]?.percentage
    ? '(' + sliceTotalPoliciesData?.group.lob_summaries?.[0]?.percentage + '%)'
    : '';

  return (
    <div
      className="fixed top-0 p-3 sm:px-0 left-0 w-full h-full backdrop-blur-md bg-black/50 flex justify-center justify-items-center z-[100]"
      onClick={onClose}
    >
      <div
        className="bg-white rounded md:h-[96vh] w-full md:w-[536px] h-[99vh] fixed md:right-3"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {showFormEdit ? (
          <>
            <div className="w-full flex p-6 gap-3">
              <div className="w-fit flex justify-start">
                <button
                  className="my-auto p-2 rounded-full bg-neutral-50 cursor-pointer hover:bg-neutral-100 transition ease-in"
                  onClick={handleBackStep}
                >
                  <ArrowLeftIcon className={`my-auto w-4 h-4 text-core-black group-hover:text-white`} />
                </button>
              </div>
              <div className="w-full flex flex-col text-left justify-start">
                <h1 className="font-medium text-2xl text-core-black my-auto">Edit Slice Name</h1>
                <div className="w-full flex items-center gap-2">
                  <p className="text-gray-600 font-bold text-sm p-0 m-0">{sliceDetailData?.group.name}</p>
                </div>
              </div>
            </div>

            <div className="w-full px-6">
              <EditSliceForm sliceData={groupDetail} onSuccess={handleEditSuccess} />
            </div>
          </>
        ) : (
          <>
            <div className="w-full flex p-6">
              <div className="w-full flex flex-col text-left justify-start">
                <h1 className="font-medium text-2xl text-core-black my-auto">{sliceDetailData?.group.name}</h1>
                <div className="w-full flex items-center gap-2">
                  {/* <p className="text-gray-600 font-bold text-sm p-0 m-0">IN 1 LISTING</p>
              <div className="my-auto h-1 w-1 rounded-full bg-gray-400"></div> */}
                  <p className="text-gray-600 font-bold text-sm p-0 m-0">CREATED {formattedDate}</p>
                  <div className="my-auto h-1 w-1 rounded-full bg-gray-400"></div>
                  <span className="border bg-white rounded text-gray-700 text-2xs h-fit font-bold py-1 px-2 uppercase">
                    My book
                  </span>
                </div>
              </div>
              <div className="w-fit flex justify-end">
                <button
                  className="my-auto p-2 rounded-full bg-neutral-50 cursor-pointer hover:bg-neutral-100 transition ease-in"
                  onClick={onClose}
                >
                  <XMarkIcon className={`my-auto w-4 h-4 text-core-black group-hover:text-white`} />
                </button>
              </div>
            </div>

            {/* scrollable section */}
            <div className="w-full flex flex-col px-6 max-h-[71vh] overflow-y-auto">
              {groupDetail.totalPolicies > 0 && sliceDetailData && (
                <div className="w-full flex mb-6">
                  <BookValuationWidget
                    calculatedUniqueCustomers={sliceTotalPoliciesData?.group.total_customers}
                    policies={sliceDetailData.group.policies}
                  />
                </div>
              )}

              <div className="grid w-full grid-cols-3 gap-2 text-left mb-4">
                <div className="border rounded p-3 h-fit">
                  <h3 className="text-core-black mb-4 font-medium">{formatPremiumRange(groupDetail.totalPremium)}</h3>
                  <p className="text-core-black text-sm m-0 p-0">Premium</p>
                </div>
                <div className="border rounded p-3 h-fit">
                  <h3 className="text-core-black mb-4 font-medium">{groupDetail.totalPolicies}</h3>
                  <p className="text-core-black text-sm m-0 p-0">Policies</p>
                </div>
                <div className="border rounded p-3 h-fit">
                  <h3 className="text-core-black mb-4 font-medium">{groupDetail.totalCustomers}</h3>
                  <p className="text-core-black text-sm m-0 p-0">Customers</p>
                </div>
              </div>

              <div className="w-full flex flex-col">
                <div className="w-full flex py-0 items-center cursor-pointer" onClick={handleExpandData}>
                  <div className="w-1/2 text-left">
                    <p className="text-core-black text-base font-medium">Key Attributes</p>
                  </div>
                  <div className="w-1/2 flex justify-end">
                    {openData ? (
                      <ChevronUpIcon className="h-4 w-4 my-auto" />
                    ) : (
                      <ChevronDownIcon className="h-4 w-4 my-auto" />
                    )}
                  </div>
                </div>

                <div className="border-b border-gray-200 w-full">
                  {openData && (
                    <div className="grid grid-cols-2 gap-2 text-left pb-3">
                      <div className="mb-6">
                        <h4 className="text-gray-600 text-2xs uppercase font-bold mb-2">Carrier</h4>
                        <p className="text-core-black text-sm font-normal ">
                          {carrier}

                          <span className="text-gray-600 text-xs ml-2">{carrierPercentage}</span>
                        </p>
                      </div>

                      <div className="mb-6">
                        <h4 className="text-gray-600 text-2xs uppercase font-bold mb-2">State</h4>
                        <p className="text-core-black text-sm font-normal">
                          {state !== '-' ? getStateFullName(state) : '-'}
                          <span className="text-gray-600 text-xs ml-2">{statePercentage}</span>
                        </p>
                      </div>

                      <div>
                        <h4 className="text-gray-600 text-2xs uppercase font-bold mb-2">Lines</h4>
                        <p className="text-core-black text-sm font-normal">
                          {lobName}

                          <span className="text-gray-600 text-xs ml-2">{lobPercentage}</span>
                        </p>
                      </div>

                      <div>
                        <h4 className="text-gray-600 text-2xs uppercase font-bold mb-2">Type</h4>
                        <p className="text-core-black text-sm font-normal">
                          {maxPercentageType.type_of_insurance}
                          <span className="text-gray-600 text-xs ml-2">({maxPercentageType.percentage}%)</span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full mt-6">
                {sliceTotalPoliciesData && (
                  <PieChart
                    sliceTotalPoliciesData={sliceTotalPoliciesData}
                    onCloseModal={onClose}
                    handleOpenPivotTable={openPivotTable}
                  />
                )}
              </div>

              <div className="w-full mt-6">
                {sliceDetailData && <TopCarriers totalCarriersData={sliceDetailData} />}
              </div>

              <div className="w-full mt-6 mb-10">
                {sliceDetailData && <BarChart policies={sliceDetailData.group.policies} />}
              </div>
            </div>

            {/* button section */}
            <div className="w-full border-t border-gray-400 py-3 px-4 rounded-b absolute bottom-2 bg-white">
              {showManageOptions && (
                <div
                  ref={selectorRef}
                  className="bg-white border-1 border-blue-500 rounded-[3px] absolute bottom-16 w-[91%]"
                >
                  <ul className="px-2 py-1 text-center m-0 text-sm">
                    <li
                      className="flex justify-center p-3 w-full hover:bg-neutral-50 transition ease-in rounded cursor-pointer gap-2"
                      onClick={() => handleEditSliceGroup(groupDetail.name)}
                    >
                      Edit Slice
                      <ArrowTopRightOnSquareIcon className="h-4 w-4 my-auto stroke-1" />
                    </li>
                    <li
                      className="w-full border-t border-b p-3 hover:bg-neutral-50 transition ease-in rounded cursor-pointer"
                      onClick={handleEditName}
                    >
                      Edit Name
                    </li>
                    <li className="w-full p-3 text-red-500 hover:bg-neutral-50 transition ease-in rounded cursor-pointer">
                      Delete
                    </li>
                  </ul>
                </div>
              )}
              <Button variant="outline" color="primary" onClick={handleShowManageOptions}>
                Manage Slice
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ModalRightSlices;
