import { useQuery } from '@tanstack/react-query';
import {
  getPolicies,
  getPoliciesByListingId,
  getPoliciesByMarketplaceListingId,
  getSlice,
  getSliceGroupDetail,
  getSlicesGroup,
} from 'src/api/policies';

export const useGetPolicies = () => {
  return useQuery({ queryKey: ['getPolicies'], queryFn: () => getPolicies() });
};

export const useGetSlicesGroup = () => {
  return useQuery({ queryKey: ['getSlicesGroup'], queryFn: () => getSlicesGroup() });
};

export const useGetSliceDetail = (uid: string) => {
  return useQuery({
    queryKey: ['getSlice', uid],
    queryFn: () => getSlice(uid),
  });
};

export const useGetSliceGroupDetail = (uid: string) => {
  return useQuery({
    queryKey: ['getSliceGroupDetail', uid],
    queryFn: () => getSliceGroupDetail(uid),
  });
};

export const usePoliciesByListingId = (uid: string) => {
  return useQuery({
    queryKey: ['getPoliciesByListingId', uid],
    queryFn: () => getPoliciesByListingId(uid),
  });
};

export const usePoliciesByMarketplaceListingId = (uid: string) => {
  return useQuery({
    queryKey: ['getPoliciesByMarketplaceListingId', uid],
    queryFn: () => getPoliciesByMarketplaceListingId(uid),
  });
};
