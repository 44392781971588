import React, { FC } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {
  children: React.ReactNode;
  isLoading: boolean;
  isUserLoggedIn: boolean;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const { isLoading, isUserLoggedIn } = rest;

  const location = useLocation();

  return (
    <Route
      {...rest}
      render={() =>
        isLoading ? (
          <></>
        ) : !isUserLoggedIn ? (
          <Redirect to={{ pathname: '/login', state: { referrer: location } }} />
        ) : (
          children
        )
      }
    />
  );
};
