import React, { useState } from 'react';
import { ReactComponent as EyeSlashIcon } from '../../../../styles/assets/icons/outline/eyeSlashOutline.svg';
import { ReactComponent as Eye } from '../../../../styles/assets/icons/outline/Eyes.svg';

interface InputProps {
  type: string;
  placeholder?: string;
  value?: string | number;
  id?: string;
  labelInput?: string;
  disabled?: boolean | false;
  isRequiredInput?: boolean | false;
  variant?: boolean | false;
  name?: string;
  errorText?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  maxlength?: number;
  onClick?: () => void;
  readOnly?: boolean; // Nueva propiedad para indicar si el input debe ser de solo lectura
}

const Input: React.FC<InputProps> = ({
  type,
  placeholder,
  value,
  id,
  labelInput,
  isRequiredInput,
  disabled,
  variant,
  name,
  onChange,
  onFocus,
  onBlur,
  onClick,
  errorText,
  maxlength,
  readOnly,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="w-full h-fit z-0 isolate">
      <label
        htmlFor={id}
        className={`flex justify-start relative z-1 uppercase ${
          !variant ? 'bg-white text-neutral-700' : 'bg-black text-white'
        } px-2 mx-2 -mb-2 w-fit relative text-xs font-semibold gap-1`}
      >
        {labelInput}

        {isRequiredInput && <span className="text-red-500">*</span>}
      </label>

      <div className="relative">
        <input
          className={`w-full ${
            !variant
              ? 'border-gray-700 placeholder:text-gray-600 text-core-black'
              : 'border-white placeholder:text-white text-white bg-black'
          } !rounded border-1 p-2.5 text-sm disabled:cursor-not-allowed ${
            errorText ? 'border-red-500' : ' border-gray-300'
          }`}
          type={type === 'password' && showPassword ? 'text' : type}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          name={name}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
          id={id}
          required={isRequiredInput}
          autoComplete="off"
          maxLength={maxlength}
          readOnly={readOnly}
        />

        {type === 'password' && (
          <button
            type="button"
            className={`absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer ${
              !variant ? 'text-gray-600' : 'text-white'
            }`}
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <Eye className="w-5 h-5 -mt-1" /> : <EyeSlashIcon className="w-5 h-5 -mt-1" />}
          </button>
        )}

        <div className="flex w-full">
          <span className="text-system-negative text-xs text-start ml-2 mt-2">{errorText}</span>
        </div>
      </div>
    </div>
  );
};

export default Input;
