import { useQuery } from '@tanstack/react-query';
import { getStripeCheckout, getStripeCustomerPortal, getSubscription } from '../api/user';

export const useGetSubscription = () => {
  return useQuery({ queryKey: ['getSubscription'], queryFn: () => getSubscription() });
};

export const useGetPortalSubscription = (successUrl: string) => {
  return useQuery({
    queryKey: ['getStripeCustomerPortal', successUrl],
    queryFn: () => getStripeCustomerPortal(successUrl),
  });
};

export const useGetCheckout = (successUrl: string, productId: string) => {
  return useQuery({
    queryKey: ['getStripeCheckout', successUrl, productId],
    queryFn: () => getStripeCheckout(successUrl, productId),
  });
};
