import React, { FC, useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Input from 'src/app/shared/components/Inputs/Input';
import Select from 'src/app/shared/components/Inputs/Select';
import TextareaComponent from 'src/app/shared/components/Textarea/Textarea';
import Button from 'src/app/shared/components/Buttons/Button';
import { networkValues } from 'src/constants/networkValues';
import { Form, Formik } from 'formik';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import { createNewListing, EditListing } from 'src/api/policies';
import { useHistory } from 'react-router';
import { useGetListingById } from 'src/hooks/useGetListings';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';

interface CreateListingEmbedProps {
  isOpen: boolean;
  onClose: () => void;
  slicesList: any[];
  isEdit?: boolean;
  uid?: string;
}

const ModalCreateListingEmbed: FC<CreateListingEmbedProps> = ({ isOpen, onClose, slicesList, isEdit, uid }) => {
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const { alerts, createToast } = useAlert();
  const history = useHistory();
  const { data: listingDetail } = useGetListingById(uid || '');
  const [listingIsPublished] = useState(listingDetail?.listing.isPublished || false);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
  }, [isOpen]);

  const [listingUid, setListingUid] = useState(uid || '');

  useEffect(() => {
    uid && setListingUid(uid);
  }, [uid]);

  const transformedNetworkValues = networkValues.map((value) => ({ value, label: value }));
  const initialValues = {
    listingName: listingDetail?.listing.name || '',
    network: listingDetail?.listing.network || '',
    description: listingDetail?.listing.description || '',
  };

  const onSubmit = async (values: any) => {
    setButtonsDisabled(true);

    try {
      const response = !isEdit
        ? await createNewListing(values.listingName, values.network, values.description, slicesList)
        : await EditListing(listingUid, values.listingName, values.network, values.description, listingIsPublished);

      if (response) {
        createToast({
          text: !isEdit ? 'Listing created!' : 'Listing updated!',
          variant: 'success',
        });
        setTimeout(() => {
          history.push('/book-management#listings');
          onClose();
        }, 3000);
      }
    } catch (error) {
      console.error(error);
      createToast({
        text: 'Error creating the Listing. Please try again later.',
        variant: 'danger',
      });
    } finally {
      setButtonsDisabled(false);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div
          onClick={onClose}
          className="fixed top-0 z-50 left-0 w-full h-full flex justify-center items-center backdrop-blur-md bg-black/50"
        >
          <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize>
            {({ values, handleChange }) => (
              <>
                <Form
                  className={`bg-white p-6 rounded w-full fixed shadow-lg ${
                    isEdit ? 'md:h-[96vh] md:w-[536px] h-[99vh] md:right-3' : 'md:w-[550px]'
                  } `}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="w-full flex">
                    <div className="w-full gap-3">
                      <div className="w-full flex gap-3">
                        {isEdit && (
                          <div className="w-1/12 flex justify-start my-auto items-center">
                            <button
                              onClick={onClose}
                              className="my-auto p-3 rounded-full bg-neutral-50 cursor-pointer hover:bg-neutral-100 transition ease-in"
                            >
                              <ArrowLeftIcon className="my-auto w-3 h-3 text-core-black group-hover:text-white" />
                            </button>
                          </div>
                        )}
                        <div className="w-11/12 gap-3 items-center text-left">
                          <h1 className="text-core-black font-medium text-xl p-0 mx-0 whitespace-nowrap text-left my-auto">
                            {isEdit ? 'Edit Listing Details' : 'Finish your listing'}
                          </h1>
                          {isEdit && (
                            <span className="text-gray-600 text-sm font-bold capitalize">
                              {listingDetail?.listing.name}
                            </span>
                          )}
                        </div>

                        {!isEdit && (
                          <div className="w-1/12 flex justify-end mb-3">
                            <button
                              onClick={onClose}
                              className="rounded-full p-2 h-8 w-8 bg-neutral-50 hover:bg-neutral-100 text-core-black transition ease-in"
                            >
                              <XMarkIcon className="h-4 w-4" />
                            </button>
                          </div>
                        )}
                      </div>

                      {isEdit && (
                        <div className="w-full mt-8 mb-2">
                          <p className="m-0 text-left text-core-black text-lg font-medium">Details</p>
                        </div>
                      )}

                      <div className="flex w-full mt-2 flex-wrap">
                        <div className="w-full">
                          <Input
                            type="text"
                            labelInput="Listing name"
                            placeholder="Enter the listing name"
                            isRequiredInput={true}
                            name="listingName"
                            onChange={(e) => {
                              handleChange(e);
                              setButtonsDisabled(false);
                            }}
                            value={values.listingName || ''}
                          />
                          <p className="text-core-grey text-left text-xs font-medium p-0 my-0 mx-3">
                            Buyer will not see this information
                          </p>
                        </div>

                        <div className="w-full my-3">
                          <Select
                            options={transformedNetworkValues}
                            name="network"
                            positionLast={false}
                            labelInput="Network"
                            placeholderOption="Select one network"
                            value={values.network || ''}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </div>

                        <div className="w-full ">
                          <TextareaComponent
                            height="h-40"
                            placeholderText="Enter the description"
                            maxCharacters={750}
                            showCharacterCount={true}
                            name="description"
                            labelInput="Description"
                            value={values.description || ''}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </div>

                        <div
                          className={`${
                            isEdit
                              ? 'border-t border-gray-400 py-3 px-4 rounded-b absolute bottom-1 bg-white right-0'
                              : ''
                          } w-full flex justify-end gap-2`}
                        >
                          <div className={`${isEdit ? 'w-1/2' : 'w-fit'}`}>
                            <Button color="primary" variant="outline" onClick={handleCancel}>
                              Cancel
                            </Button>
                          </div>
                          <div className={`${isEdit ? 'w-1/2' : 'w-fit'}`}>
                            <Button
                              type="submit"
                              color={isEdit ? 'secondary' : 'primary'}
                              variant="fill"
                              disabled={buttonsDisabled && !isEdit}
                            >
                              {!isEdit ? ' Create' : 'Save'}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {alerts}
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default ModalCreateListingEmbed;
