import React, { useEffect, useState } from 'react';
import { Onboarding } from '../components/Onboarding';
import { useFormik } from 'formik';
import { useGetBuyerProfile, usePatchBuyerProfile } from 'src/hooks/useBuyersProfile';
import { useHistory } from 'react-router';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';

const ReadinessBuyerProfile = () => {
  const history = useHistory();
  const { data: buyerProfileResponse, isPending } = useGetBuyerProfile();
  const [, setBuyerProfile] = useState(buyerProfileResponse);
  const { mutate, isSuccess } = usePatchBuyerProfile();
  const [selectedOption, setSelectedOption] = useState('');
  const buttonClass =
    'border-1 rounded py-6 px-8 text-core-gray hover:bg-yellow-100 hover:border-black hover:text-core-black transition ease-in whitespace-nowrap';
  const activeClass = 'text-core-black bg-yellow-100 border-core-black ' + buttonClass;

  useEffect(() => {
    if (buyerProfileResponse) {
      setBuyerProfile(buyerProfileResponse);
      setSelectedOption(buyerProfileResponse?.buyerProfile.buyingTimeline);
    }
  }, [buyerProfileResponse]);

  const { handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      buyingTimeline: buyerProfileResponse?.buyerProfile.buyingTimeline
        ? buyerProfileResponse?.buyerProfile.buyingTimeline
        : '',
    },
    onSubmit: (values) => {
      mutate(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      history.push('/onboarding/buyer-profile/buy-slices');
    }
  }, [isSuccess, history]);

  const handleButtonClick = (value: string) => {
    setSelectedOption(value);
    setFieldValue('buyingTimeline', value);
  };

  if (isPending) {
    return (
      <div className="w-full mb-12 z-0">
        <Skeleton type="step-onboarding" />
      </div>
    );
  }

  return (
    <Onboarding
      handleSubmit={handleSubmit}
      isContinue={true}
      isBack={true}
      isStep={true}
      numOfSteps={7}
      stepPosition={5}
    >
      <div className="w-full md:w-3/5 mx-auto block py-20 px-8 md:px-32 text-left gap-8 mt-10">
        <div className="w-full">
          <p className="text-center text-sm text-gray-600 font-bold mx-0 p-0 mb-8">BUILD YOUR BUYER PROFILE</p>
        </div>

        <div className="w-full mb-12 mt-8">
          <h1 className="text-core-black text-3xl md:text-5xl font-medium text-center">
            How soon are you looking to make an acquisition?
          </h1>
          <p className="font-medium text-gray-700 text-base mt-4 mb-0 p-0 text-center">
            Signal your readiness to sellers. Don’t worry, you can always change this later!
          </p>
        </div>

        <div className="w-full mt-12 grid grid-cols-1 md:grid-cols-3 md:gap-1 gap-4">
          <div className="flex justify-center md:justify-end">
            <button
              className={selectedOption === 'Ready to buy' ? activeClass : buttonClass}
              onClick={() => handleButtonClick('Ready to buy')}
            >
              Ready to buy
            </button>
          </div>
          <div className="flex justify-center">
            <button
              className={selectedOption === 'Exploring options' ? activeClass : buttonClass}
              onClick={() => handleButtonClick('Exploring options')}
            >
              Exploring options
            </button>
          </div>

          <div className="flex justify-center md:justify-start">
            <button
              className={selectedOption === 'No timeline' ? activeClass : buttonClass}
              onClick={() => handleButtonClick('No timeline')}
            >
              No timeline
            </button>
          </div>

          <div className="hidden">
            <input
              type="text"
              value={values.buyingTimeline}
              onChange={(e) => {
                setFieldValue('buyingTimeline', e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </Onboarding>
  );
};

export default ReadinessBuyerProfile;
