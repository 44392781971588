import { useQuery } from '@tanstack/react-query';

import { getListingById, getListings } from 'src/api/listings';

export const useGetListingById = (uid: string) => {
  return useQuery({
    queryKey: ['getListingById', uid],
    queryFn: () => getListingById(uid),
    enabled: !!uid,
  });
};

export const useGetListings = () => {
  return useQuery({
    queryKey: ['getListings'],
    queryFn: () => getListings(),
  });
};
