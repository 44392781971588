import React, { useState } from 'react';
import './Tooltip.scss';

interface TooltipProps {
  text?: string;
  children: React.ReactNode;
  title?: string;
  list?: string;
  position?: string;
  body?: string | React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children, position = 'top', title, list, body }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);

  const tooltipClassName = `tooltip-container absolute mt-2 ${
    position === 'left-top' ? 'left-0' : 'left-1/2 transform -translate-x-1/2'
  }`;

  const arrowClassName = `tooltip-arrow-border mx-auto border-t-4 border-solid border-transparent ${
    position === 'left-top' ? 'right-full' : ''
  }`;

  return (
    <div className="relative md:absolute inline-block">
      <div className="cursor-pointer" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
        {children}
      </div>
      {isTooltipVisible && (
        <div className={tooltipClassName}>
          <div className={arrowClassName}></div>
          {text && (
            <>
              <div className="tooltip-content bg-core-black text-white p-2.5 whitespace-nowrap z-50 rounded-md shadow-md text-xs min-w-[280px]">
                <p className="text-md text-gray-400 mb-2">{title}</p>
                <p className="p-0 m-0 w-full whitespace-break-spaces">{text}</p>
              </div>
            </>
          )}

          {list && (
            <div className="tooltip-content bg-core-black p-2.5 whitespace-nowrap z-50 rounded-md shadow-md">
              <p className="text-md text-gray-400 mb-2">{title}</p>
              <ul className="p-0 m-0 text-core-white text-sm">
                {list
                  ?.split(',')
                  .slice(0, 5)
                  .map((item, index) => (
                    <li key={index}>{item.trim()}</li>
                  ))}
              </ul>

              {list && list.split(',').length > 5 ? (
                <span className="text-sm text-gray-300">+{list.split(',').length - 5} more</span>
              ) : null}
            </div>
          )}

          {body && (
            <div className="tooltip-content bg-core-black p-2.5 whitespace-nowrap z-50 rounded-md shadow-md">
              <p className="text-core-white p-0 m-0 text-xs capitalize">{body}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
