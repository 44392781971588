import { ArrowTopRightOnSquareIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { createPostMessage } from 'src/api/messages-new';
import Avatar from 'src/app/shared/components/Avatar/Avatar';
import { Button } from 'src/app/shared/components/Buttons/Button';
import TextareaComponent from 'src/app/shared/components/Textarea/Textarea';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import { useGetUser } from 'src/hooks';
import { MessageThread } from 'src/types/messages';
import { UserInfo } from 'src/types/User';

interface SideBarMessagesProps {
  isOpen: boolean;
  onClose: () => void;
  messagesThread: MessageThread | undefined;
  onContactBuyerSuccess: () => void;
}

interface MessagePartner {
  uid: string;
  username: string;
}

const getMessagePartner = (thread: MessageThread, currentUserUid: string): MessagePartner => {
  if (thread.originatorUid == currentUserUid) {
    return { uid: thread.recipientUid, username: thread.recipientUsername };
  }
  return { uid: thread.originatorUid, username: thread.originatorUsername };
};

const SideBarMessages: FC<SideBarMessagesProps> = ({ isOpen, onClose, messagesThread, onContactBuyerSuccess }) => {
  const history = useHistory();
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const { data: userResponse }: { data: UserInfo | undefined } = useGetUser();
  const [userName, setUserName] = useState<string>('');
  const [messageContentInput, setMessageContent] = useState<string>('');
  const isMessageValid = messageContentInput.length >= 3;
  const { alerts, createToast } = useAlert();

  useEffect(() => {
    if (userResponse) {
      setUserName(userResponse.userProfile.username);
    }
  }, [userName]);

  useEffect(() => {
    if (messagesContainerRef.current) {
      const container = messagesContainerRef.current;
      container.scrollTop = container.scrollHeight;
    }
  }, []);

  useEffect(() => {
    if (messagesContainerRef.current) {
      const container = messagesContainerRef.current;
      container.scrollTop = container.scrollHeight;
    }
  }, [messagesThread, messageContentInput]);

  let lastMessageDate: string | null = null;

  document.body.style.overflow = isOpen ? 'hidden' : 'auto';

  if (!isOpen) {
    return null;
  }

  const handleDetailSlide = (uid: string): void => {
    history.push('/slices?uid=' + uid);
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageContent(e.target.value);
  };

  const isCurrentUser = (createdByUsername: string): boolean => {
    return userName === createdByUsername;
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return 'TODAY';
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'YESTERDAY';
    } else {
      const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);
      const formattedDateWithoutCommas = formattedDate.replace(/,{2}/g, ',');
      return formattedDateWithoutCommas;
    }
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInMinutes = (now.getTime() - date.getTime()) / (1000 * 60);
    if (diffInMinutes < 10) {
      return 'Just now';
    } else {
      const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: '2-digit', hour12: true };
      const formattedTime = date.toLocaleTimeString('en-US', options);
      return formattedTime;
    }
  };

  const handleContactBuyer = async () => {
    try {
      const messageContent = messageContentInput;
      const messageThreadUid = messagesThread?.uid;
      if (!messageThreadUid) {
        console.error('Error sending message: no messageThreadUid');
        return;
      }
      await createPostMessage(messageContent, messageThreadUid);
      createToast({ text: 'Message sent successfully', variant: 'success' });
      onContactBuyerSuccess();
      setMessageContent('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <div
      onClick={onClose}
      className="fixed top-0 p-3 sm:px-0 left-0 w-full h-full backdrop-blur-md bg-black/50 flex justify-center justify-items-center z-[100]"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="bg-white w-full rounded md:h-[96vh] md:w-[536px] h-[99vh] fixed md:right-3"
      >
        <div className="bg-white rounded-t p-4 w-full flex flex-col justify-start items-center gap-3">
          <div className="flex w-full">
            <div className="flex w-9/12 gap-3 items-center whitespace-nowrap">
              <Avatar
                size="md"
                userName={
                  messagesThread &&
                  getMessagePartner(messagesThread, userResponse?.userProfile.uid ?? '')
                    .username.charAt(0)
                    .toUpperCase()
                }
              />
              <p className="text-core-black font-medium text-2xl p-0 m-0 max-w-[300px] truncate">
                {(!!messagesThread &&
                  (messagesThread.listingListingNum
                    ? 'Listing ' + messagesThread.listingListingNum
                    : getMessagePartner(messagesThread, userResponse?.userProfile.uid ?? '').username)) ??
                  'unknown'}
              </p>
            </div>
            <div className="flex justify-end w-3/12">
              <button
                onClick={onClose}
                className="rounded-full p-2 h-8 w-8 bg-neutral-50 hover:bg-neutral-100 text-core-black transition ease-in"
              >
                <XMarkIcon className="h-4 w-4" />
              </button>
            </div>
          </div>
          {messagesThread?.listingUid !== null && (
            <div className="w-full">
              <div className="w-full rounded-lg border-2 border-gray-200 bg-white p-3 flex gap-2 text-left items-center transition ease-in hover:border-gray-400">
                <span className="text-core-black text-sm font-medium">Listing Details</span>
                <ArrowTopRightOnSquareIcon
                  onClick={() => handleDetailSlide(messagesThread!.listingUid)}
                  className="h-4 w-4 my-auto cursor-pointer hover:text-core-black text-gray-700"
                />
              </div>
            </div>
          )}

          {/* scrollable section */}
          <div
            className="absolute bottom-[185px] top-36 h-auto w-full px-4 py-3 overflow-y-scroll"
            ref={messagesContainerRef}
          >
            {(messagesThread?.messages ?? []).map((message: any) => {
              const messageDate = formatDate(message.createdAt);
              const showDate = lastMessageDate !== messageDate;
              lastMessageDate = messageDate;
              return (
                <>
                  <div className="w-full flex flex-col">
                    {showDate && <div className="text-gray-400 text-xs w-full text-center my-3">{messageDate}</div>}
                    <div
                      className={`w-full flex ${
                        isCurrentUser(message.createdByUsername) ? 'justify-end' : 'justify-start'
                      }`}
                    >
                      <div
                        className={`p-4 my-2 ${
                          isCurrentUser(message.createdByUsername) ? 'bg-blue-50' : 'bg-gray-50'
                        } rounded w-[85%]`}
                      >
                        <div className="w-full flex mb-2">
                          <div className="flex justify-start w-[80%] items-center">
                            <p className="p-0 m-0 text-core-black text-sm font-bold">
                              {message.createdByUsername}{' '}
                              {isCurrentUser(message.createdByUsername) && <span>(YOU)</span>}
                            </p>
                          </div>
                          <div className="flex justify-end w-[20%] items-center gap-1">
                            {formatTime(message.createdAt) === 'Just now' && (
                              <div className="h-2 w-2 bg-blue-600 rounded-full"></div>
                            )}

                            <span
                              className={`${
                                formatTime(message.createdAt) === 'Just now' ? ' text-blue-600' : 'text-gray-600'
                              } text-xs`}
                            >
                              {formatTime(message.createdAt)}
                            </span>
                          </div>
                        </div>
                        <div className="w-full flex justify-start">
                          <span
                            className="text-base text-core-black text-left"
                            dangerouslySetInnerHTML={{ __html: message.content.replace(/\n/g, '<br>') }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          <div className="absolute bottom-0 bg-white p-6 w-full rounded-b border-t border-gray-200">
            <div className="w-full">
              <TextareaComponent
                height="h-24"
                placeholderText="Type a message"
                variant="gray"
                showCharacterCount={false}
                value={messageContentInput}
                onChange={handleMessageChange}
              />
            </div>
            <div className="w-full flex gap-2">
              <div className="w-full">
                <Button variant="fill" color="primary" disabled={!isMessageValid} onClick={handleContactBuyer}>
                  Reply
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {alerts}
    </div>
  );
};

export default SideBarMessages;
