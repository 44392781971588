import React, { FC, useState, useEffect, useRef } from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import './PivotTable.scss';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

interface PivotTableLibProps {
  groupsData: any[];
}

const PivotTableLib: FC<PivotTableLibProps> = ({ groupsData }) => {
  const [pivotState, setPivotState] = useState<any>({
    rows: ['Type', 'State', 'Carrier', 'Line of Business'],
    attributes: ['uid', 'client_id'],
    rendererName: 'Table',
    aggregatorName: 'Sum',
    vals: ['Total Premium'],
    hiddenAttributes: [
      'policy_number',
      'insured_zip_code',
      'payment_plan',
      'agency_id',
      'unknown_lob',
      'unknown_carrier',
      'source',
      'policy_status',
      'num_of_terms',
      'last_modified',
      'has_conflict',
      'naic_code',
      'num_of_claims',
    ],
  });

  const [friendlyData, setFriendlyData] = useState<any[]>([]);
  const [activeButton, setActiveButton] = useState<string>('');
  const [showHeadMap, setShowHeadMap] = useState(true);

  const friendlyNameMapping: { [key: string]: string } = {
    type_of_insurance: 'Type',
    insured_state: 'State',
    carrier: 'Carrier',
    line_of_business: 'Line of Business',
    total_premium: 'Total Premium',
    uid: 'uid',
  };

  useEffect(() => {
    const transformedData = groupsData.map((item) => {
      const newItem: { [key: string]: any } = {};
      for (const key in item) {
        if (friendlyNameMapping[key]) {
          newItem[friendlyNameMapping[key]] = item[key];
        } else {
          newItem[key] = item[key];
        }
      }
      newItem['Total Premium'] = item.total_premium;
      return newItem;
    });

    setFriendlyData(transformedData);
  }, [groupsData]);

  const onChange = (newState: any) => {
    setPivotState(newState);
  };

  const handleShowHeadMap = () => {
    setShowHeadMap(!showHeadMap);
  };

  const setAggregator = (aggregatorName: string, vals: string[], buttonName: string) => {
    setPivotState((prevState: any) => ({
      ...prevState,
      aggregatorName,
      vals,
    }));
    setActiveButton(buttonName);
  };

  const [openTotalTypeOptions, setOpenTotalTypeOptions] = useState(false);
  const [openTotalFormatOptions, setOpenTotalFormatOptions] = useState(false);
  const [optionTotalTypeSelect, setOptionTotalTypeSelect] = useState('');
  const [optionTotalFormatSelect, setOptionTotalFormatSelect] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOpenTotalType = () => {
    setOpenTotalTypeOptions(!openTotalTypeOptions);
  };

  const handleOpenTotalFormat = () => {
    setOpenTotalFormatOptions(!openTotalFormatOptions);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpenTotalTypeOptions(false);
        setOpenTotalFormatOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const pivotTable = document.querySelector('.pvtTable thead') as HTMLElement;
    if (showHeadMap) {
      if (pivotTable) pivotTable.style.display = 'table-header-group';
    } else {
      if (pivotTable) pivotTable.style.display = 'none';
    }
  }, [showHeadMap]);

  return (
    <div className="pr-6 pb-6">
      <div className="w-[240px] block pl-2 pr-3 space-y-3 relative mt-8 -mb-[290px]">
        <h2 className="text-[#727272] font-bold text-left text-xs uppercase">Total Type</h2>

        <div className="w-full">
          <button
            onClick={handleOpenTotalType}
            className="w-full p-2.5 border-[1px] bg-white border-[#DDD] rounded-lg text-sm flex text-core-black font-medium items-center"
          >
            <span className="flex justify-start w-48">
              {optionTotalTypeSelect === '' ? 'Select an option' : optionTotalTypeSelect}
            </span>
            <ChevronDownIcon className="flex justify-end h-3 w-3 my-auto" />
          </button>

          {openTotalTypeOptions && (
            <div
              ref={dropdownRef}
              className="w-[220px] mt-1 absolute rounded-sm bg-white px-2 py-2 shadow-sm border z-10"
            >
              <ul className="p-0 m-0 text-left text-sm text-core-black space-y-1">
                <li
                  onClick={() => {
                    setAggregator('Sum', ['Total Premium'], 'Total Premium');
                    setOptionTotalTypeSelect('Total Premium');
                    setOptionTotalFormatSelect('');
                    handleOpenTotalType();
                  }}
                  className="w-full cursor-pointer rounded hover:bg-neutral-50 p-2 transition ease-in"
                >
                  Total Premium
                </li>

                <li
                  onClick={() => {
                    setAggregator('Count', ['uid'], 'Total Policies');
                    setOptionTotalTypeSelect('Total Policies');
                    setOptionTotalFormatSelect('');
                    handleOpenTotalType();
                  }}
                  className="w-full cursor-pointer rounded hover:bg-neutral-50 p-2 transition ease-in"
                >
                  Total Policies
                </li>

                <li
                  onClick={() => {
                    setAggregator('Count Unique Values', ['client_id'], 'Total Customers');
                    setOptionTotalTypeSelect('Total Customers');
                    setOptionTotalFormatSelect('');
                    handleOpenTotalType();
                  }}
                  className="w-full cursor-pointer rounded hover:bg-neutral-50 p-2 transition ease-in"
                >
                  Total Customers
                </li>
              </ul>
            </div>
          )}
        </div>

        <h2 className="text-[#727272] font-bold text-left text-xs uppercase">Total Format</h2>

        <div className="w-full">
          <div className="p-0 m-0 text-left text-sm text-core-black space-y-1">
            <label className="w-full cursor-pointer rounded hover:bg-neutral-50 p-2 transition ease-in flex items-center">
              <input
                type="radio"
                name="totalFormat"
                value="% of Policies"
                checked={optionTotalFormatSelect === '% of Policies'}
                onChange={() => {
                  setAggregator('Count as Fraction of Total', ['uid'], '% of Policies');
                  setOptionTotalFormatSelect('% of Policies');
                  setOptionTotalTypeSelect('');
                  handleOpenTotalFormat();
                }}
                className="mr-2 accent-black w-5 h-5 cursor-pointer"
              />
              % of Policies
            </label>

            <label className="w-full cursor-pointer rounded hover:bg-neutral-50 p-2 transition ease-in flex items-center">
              <input
                type="radio"
                name="totalFormat"
                value="% of Customers"
                checked={optionTotalFormatSelect === '% of Customers'}
                onChange={() => {
                  setAggregator('Count as Fraction of Total', ['client_id'], '% of Customers');
                  setOptionTotalFormatSelect('% of Customers');
                  setOptionTotalTypeSelect('');
                  handleOpenTotalFormat();
                }}
                className="mr-2 accent-black w-5 h-5 cursor-pointer"
              />
              % of Customers
            </label>
          </div>

          <div className="w-full z-0">
            <div className="grid grid-cols-2 items-center my-4">
              <span className="text-core-black text-xs font-semibold text-left uppercase">Show Headers</span>
              <div
                className={`cursor-pointer relative w-[60px] h-8 rounded-full ${
                  showHeadMap ? 'bg-core-black' : 'bg-gray-200'
                }`}
                onClick={handleShowHeadMap}
              >
                <div
                  className={`absolute top-0.5 start-[2px] bg-white border-gray-300 border rounded-full h-7 w-7 transition-all ${
                    showHeadMap ? 'translate-x-full' : ''
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PivotTableUI data={friendlyData} onChange={onChange} {...pivotState} />
    </div>
  );
};

export default PivotTableLib;
