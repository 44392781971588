import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ROLE_QUESTION } from '../../../constants/labels';
import { Onboarding } from '../components/Onboarding';
import { AgencyOwnerIcon, OtherIcon, AgentIcon } from 'src/styles/assets/icons/Icons';
import { usePatchUser } from 'src/hooks';
import { User } from 'src/types/User';
import { useOnboardingStore } from 'src/app/store/store';

export const Role: FC = () => {
  const history = useHistory();
  const { userInfo } = useOnboardingStore();

  const { mutate, isSuccess, error, isPending } = usePatchUser();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const [role, setRole] = useState<string>(userInfo?.userProfile.roleAtAgency || '');

  const handleSubmit = () => {
    if (!role) {
      setErrorMessage('Please select a role');
      return;
    }
    const user: Partial<User> = { roleAtAgency: role };
    mutate(user);
  };

  useEffect(() => {
    if (isSuccess) {
      history.push('/onboarding/basic-info');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (userInfo?.userProfile.onboardingComplete) {
      history.push('/onboarding/completed');
    }
  }, [userInfo]);

  return (
    <Onboarding handleSubmit={handleSubmit} isLoading={isPending} isContinue={true} isDisabled={isDisabled}>
      <div className="flex 2xl:10/12 lg:w-12/12 md:12/12 xs:w-12/12 m-auto h-full items-center">
        <div className="flex flex-col min-[1536px]:w-6/12 lg:w-8/12 md:12/12 xs:w-full m-auto md:px-0 px-6">
          <div className="flex lg:w-8/12 md:w-full mx-auto text-center mt-20 md:mt-0">
            <h3 className="m-auto lg:text-5xl sm:text-4xl">{ROLE_QUESTION}</h3>
          </div>
          <ul className="flex md:flex-row xs:flex-col w-full mt-4 m-auto space-y-4 md:space-y-2  lg:space-x-4 md:space-x-2 xs:space-x-0 md:px-12 xs:px-12">
            <li className="lg:w-4/12 xs:w-full xs:mt-2">
              <input
                type="radio"
                id="agent"
                name="role"
                value="Agent"
                className="hidden peer"
                onChange={(e) => {
                  setRole(e.target.value);
                  setIsDisabled(false);
                }}
                required
              />
              <label
                htmlFor="agent"
                className={`${
                  role === 'agent' ? 'border-core-black bg-yellow-50' : ''
                } inline-flex items-center justify-between border-1 w-full p-4 md:p-1 xs:p-3 text-gray-500 hover:border-core-black hover:bg-yellow-50 transition ease-in border-gray-200 rounded-lg cursor-pointer peer-checked:border-core-black peer-checked:text-core-black peer-checked:bg-yellow-100 hover:text-gray-600`}
              >
                <div className="block w-full m-auto text-center">
                  <AgentIcon className="md:w-[80px] w-[50px] h-[50px] md:h-[80px] text-core-black m-auto" />
                  <div className="text-xl md:text-2xl font-semibold m-auto text-center pt-6">Agent</div>
                </div>
              </label>
            </li>
            <li className="lg:w-4/12 xs:w-full xs:mt-2">
              <input
                type="radio"
                id="agency-owner"
                name="role"
                value="Agency Owner"
                className="hidden peer"
                onChange={(e) => {
                  setRole(e.target.value);
                  setIsDisabled(false);
                }}
              />
              <label
                htmlFor="agency-owner"
                className={`${
                  role === 'agency-owner' ? 'border-core-black bg-yellow-50' : ''
                } inline-flex items-center justify-between border-1 w-full p-4 md:p-1 xs:p-3 text-gray-500 hover:border-core-black hover:bg-yellow-50 transition ease-in border-gray-200 rounded-lg cursor-pointer peer-checked:border-core-black peer-checked:text-core-black peer-checked:bg-yellow-100 hover:text-gray-600`}
              >
                <div className="block w-full m-auto text-center">
                  <AgencyOwnerIcon className="md:w-[80px] w-[50px] h-[50px] md:h-[80px] text-core-black m-auto" />
                  <div className="text-xl md:text-2xl font-semibold m-auto text-center pt-6">Agency Owner</div>
                </div>
              </label>
            </li>
            <li className="lg:w-4/12 xs:w-full xs:mt-2">
              <input
                type="radio"
                id="other"
                name="role"
                value="Other"
                className="hidden peer"
                onChange={(e) => {
                  setRole(e.target.value);
                  setIsDisabled(false);
                }}
              />
              <label
                htmlFor="other"
                className={`${
                  role === 'other' ? 'border-core-black bg-yellow-50' : ''
                } inline-flex items-center justify-between border-1 w-full p-4 md:p-1 xs:p-3 text-gray-500 hover:border-core-black hover:bg-yellow-50 transition ease-in border-gray-200 rounded-lg cursor-pointer peer-checked:border-core-black peer-checked:text-core-black peer-checked:bg-yellow-100 hover:text-gray-600`}
              >
                <div className="block w-full m-auto text-center">
                  <OtherIcon className="md:w-[80px] w-[50px] h-[50px] md:h-[80px] text-core-black m-auto" />
                  <div className="text-xl md:text-2xl font-semibold m-auto text-center pt-6">Other</div>
                </div>
              </label>
            </li>
          </ul>
          <div className="flex w-full">
            <span className="text-system-negative text-sm ml-auto mr-auto mt-2">{errorMessage}</span>
          </div>
        </div>
      </div>
    </Onboarding>
  );
};
