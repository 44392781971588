import React, { useState, useEffect, useRef } from 'react';
import Breadcrumb from '../shared/components/Breadcrumb/Breadcrumb';
import Tabs from '../shared/components/Tabs/Tabs';
import { useHistory } from 'react-router';
import { Button } from '../shared/components/Buttons/Button';
import CSeller from '../../styles/assets/logos/CSeller.svg';
import AllDataTable from './componentsV2/AllDataTable';
import { useGetSubscription, useGetUser } from 'src/hooks';

const InboxV2 = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { data: subscriptionResponse, isPending: subscriptionPending, error: subscriptionError } = useGetSubscription();
  const [subscriptionName, setSubscriptionName] = useState<string>('Starter');
  const { data: userResponse } = useGetUser();

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (dropdownRef.current && !(dropdownRef.current as HTMLDivElement).contains(e.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!subscriptionPending && !subscriptionError && subscriptionResponse) {
      if (subscriptionResponse.userSubscription === 'null') {
        setSubscriptionName('Starter');
      } else {
        setSubscriptionName(subscriptionResponse.userSubscription);
      }
    }
  }, [subscriptionPending, subscriptionError, subscriptionResponse]);

  const handleTabClick = (index: number) => {
    const labelUrl = tabs[index].labelUrl;
    history.push(`#${labelUrl}`);
    setActiveTab(index);
  };

  const handleGotoPricingPage = () => {
    history.push(`/account/pricing`);
  };

  const tabs = [
    {
      label: 'All',
      content: (
        <div>
          <AllDataTable subscriptionName={subscriptionName} />
        </div>
      ),
      labelUrl: 'all',
    },
  ];

  return (
    <div className="p-3 w-full">
      <Breadcrumb title="Inbox" />

      <div className="bg-core-white rounded-lg w-full px-6 pb-0.5 pt-6 space-y-4 sm:space-y-0">
        {/* <div className="w-full flex -mb-10 justify-end">
          <div className="w-fit z-20" ref={dropdownRef}>
            <button
              className={`border-1 border-gray-400 rounded px-3 py-2 text-base ${
                dropdownVisible ? 'border-core-accentBlue' : 'border-gray-400'
              }`}
              onClick={handleShowActions}
            >
              <span className="w-full flex gap-2">
                Actions
                {dropdownVisible ? (
                  <ChevronUpIcon className="h-5 w-5 my-auto" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5 my-auto" />
                )}
              </span>
            </button>

            {dropdownVisible ? (
              <div className="bg-white absolute mt-1 right-10 rounded p-2 shadow border-1 border-core-accentBlue">
                <ul className="p-0 m-0 text-left">
                  <li className="text-base font-medium rounded px-2 py-3 hover:bg-neutral-50 transition ease-in cursor-pointer">
                    Delete all
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
        </div> */}
        <div className="w-full flex">
          <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} variant={false} type="pills" />
        </div>
        {subscriptionName !== 'Pro' && !userResponse?.userProfile.hasActiveListing ? (
          <div className="w-full flex flex-col space-y-4 py-20">
            <img src={CSeller} alt="connect with sellers" className="h-auto w-14 mx-auto" />
            <h1 className="text-core-black text-2xl font-medium">Connect with sellers</h1>
            <p className="text-gray-700 text-sm text-center">
              Upgrade to Pro to unlock full access to our suite <br /> of tools, including private and secure chat.
            </p>
            <div className="w-fit mx-auto">
              <Button variant="fill" color="primary" onClick={handleGotoPricingPage}>
                <span>Upgrade to Pro</span>
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default InboxV2;
