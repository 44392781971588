import React, { useEffect, useState } from 'react';
import cakeLogoBlack from '../../../styles/assets/logos/cake-logo-black.png';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import Input from 'src/app/shared/components/Inputs/Input';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import { ReactComponent as CheckIconMini } from '../../../styles/assets/icons/mini/checkMini.svg';
import Button from 'src/app/shared/components/Buttons/Button';
import { LoadingIcon } from 'src/styles/assets/icons/Icons';
import { ReactComponent as LeftArrowMini } from '../../../styles/assets/icons/mini/rightArrow.svg';
import { userRegistration } from 'src/api/user';
import ModalDisclaimer from 'src/app/shared/components/Modals/ModalDisclaimer';
import carrouselImg01 from '../../../styles/assets/carrouselBackgrounds/UISection01.svg';
import carrouselImg02 from '../../../styles/assets/carrouselBackgrounds/UISection02.svg';
import carrouselImg03 from '../../../styles/assets/carrouselBackgrounds/UISection03.svg';

interface loginProps {
  isLoading: boolean;
  isUserLoggedIn: boolean;
}

interface RedirectLocationState {
  referrer: Location;
}

const CreateAccountV2 = (props: loginProps): React.ReactElement => {
  const { isLoading, isUserLoggedIn } = props;
  const { state: locationState } = useLocation();
  const history = useHistory();
  const { alerts, createToast } = useAlert();
  const [checked, setChecked] = useState(false);
  const [isPendingButton, setIsPendingButton] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isPasswordClicked, setIsPasswordClicked] = useState(false);
  const [, setCompletedItems] = useState([false, false, false, false, false]);
  const [isNumOfCompleted, setIsMinLengthValid] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isNumber, setIsNumbers] = useState(false);
  const [isSpecialChar, setIsSpecialChars] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInvalidUsername, setIsInvalidUsername] = useState(false);

  const onSubmit = async (values: any) => {
    setIsPendingButton(true);
    try {
      setIsPendingButton(true);
      const response = await userRegistration(values.email, values.password, values.username, values.receivepromotions);
      if (response.uid !== null) {
        localStorage.setItem('confirmUserEmail', values.email);
        history.push({ pathname: '/confirm-account-2' });
      }
    } catch (error) {
      console.error(error);
      createToast({
        text: 'The provided email or dispay name is already in use. Please enter a different value and try again.',
        variant: 'danger',
      });
    }
    setIsPendingButton(false);
  };

  useEffect(() => {
    setIsPasswordInvalid(!isNumOfCompleted || !isLowerCase || !isUpperCase || !isSpecialChar || !isNumber);
  }, [isNumOfCompleted, isLowerCase, isUpperCase, isSpecialChar, isNumber]);

  const initialValues = {
    email: '',
    password: '',
    username: '',
    receivepromotions: false,
  };

  useEffect(() => {
    if (!isLoading && isUserLoggedIn) {
      if (locationState) {
        const { referrer } = locationState as RedirectLocationState;
        history.push(`${referrer.pathname}${referrer.search}`);
      } else {
        history.push('/slices');
      }
    }
  }, [isLoading, isUserLoggedIn, history]);

  const updateCompletedItems = (value: string) => {
    const hasMinLength = value.length >= 8;
    setIsMinLengthValid(hasMinLength);
    const newCompletedItems = [
      hasMinLength,
      /[a-z]/.test(value),
      /[A-Z]/.test(value),
      /\d/.test(value),
      /[!@#$%^&*(),.?":{}|<>]/.test(value),
    ];
    setCompletedItems(newCompletedItems);
  };

  const updateCompletedItemsTwo = (value: string) => {
    const hasLowercase = /[a-z]/.test(value);
    setIsLowerCase(hasLowercase);
    const newCompletedItemsTwo = [
      hasLowercase,
      /[A-Z]/.test(value),
      /\d/.test(value),
      /[!@#$%^&*(),.?":{}|<>]/.test(value),
    ];
    setCompletedItems(newCompletedItemsTwo);
  };

  const updateCompletedItemsThree = (value: string) => {
    const hasUppercase = /[A-Z]/.test(value);
    setIsUpperCase(hasUppercase);
    const newCompletedItemsThree = [
      /[a-z]/.test(value),
      hasUppercase,
      /\d/.test(value),
      /[!@#$%^&*(),.?":{}|<>]/.test(value),
    ];
    setCompletedItems(newCompletedItemsThree);
  };

  const updateCompletedItemsFour = (value: string) => {
    const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    setIsSpecialChars(hasSpecialChars);
    const newCompletedItemsFour = [hasSpecialChars, /\d/.test(value)];
    setCompletedItems(newCompletedItemsFour);
  };

  const updateCompletedItemsFive = (value: string) => {
    const hasNumbers = /\d/.test(value);
    setIsNumbers(hasNumbers);
    const newCompletedItemsFour = [hasNumbers];
    setCompletedItems(newCompletedItemsFour);
  };

  const handleOpenModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCheckSpecialChars = (value: string) => {
    const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
    setIsInvalidUsername(specialCharsRegex.test(value));
  };

  const [currentSection, setCurrentSection] = useState(0);

  const sections = [
    {
      text: 'Seamlessly upload and manage books',
      image: (
        <div className="w-full flex justify-end">
          <img
            src={carrouselImg01}
            alt="section 1"
            className="object-right w-4/5 md:w-auto max-h-[80vh]"
            style={{ margin: 0 }}
          />
        </div>
      ),
    },
    {
      text: 'Instantly unlock your valuation',
      image: (
        <div className="w-full flex justify-start">
          <img
            src={carrouselImg02}
            alt="section 2"
            className="object-left w-4/5 md:w-auto max-h-[80vh]"
            style={{ margin: 0 }}
          />
        </div>
      ),
    },
    {
      text: 'Build your profile and browse listings',
      image: (
        <div className="w-full flex justify-end">
          <img
            src={carrouselImg03}
            alt="section 3"
            className="object-right w-4/5 md:w-auto max-h-[80vh]"
            style={{ margin: 0 }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSection((prevSection) => (prevSection + 1) % sections.length);
    }, 10000);

    return () => clearInterval(interval);
  }, [sections.length]);

  return (
    <div className="w-full overflow-x-hidden">
      <Formik onSubmit={onSubmit} enableReinitialize initialValues={initialValues}>
        {({ values, handleChange }) => (
          <>
            <Form className="w-full">
              <div className="h-full w-full top-0 z-50 gap-8 sm:p-2 p-0 flex flex-col-reverse md:flex-row">
                <div className="block sm:min-h-[98vh] bg-white mt-0 md:mt-0 rounded-xl md:block w-full md:w-2/5 px-14 py-4">
                  <div className="w-full mb-20 hidden md:block">
                    <img className="aspect-[557/217] flex justify-start w-20" src={cakeLogoBlack} />
                  </div>
                  <div className="w-full">
                    <h1 className="text-core-black text-3xl md:text-4xl font-medium text-center md:!text-left">
                      Create a free account
                    </h1>
                  </div>
                  <div className="w-full flex gap-2 mb-14 items-center justify-center md:items-start md:justify-start">
                    <p className="text-gray-900 text-base p-0 m-0 font-medium">Already have an account?</p>
                    <strong
                      className="text-core-black text-base p-0 m-0 font-medium underline cursor-pointer hover:text-gray-500 transition ease-in"
                      onClick={(): void => history.push('/login')}
                    >
                      Log in
                    </strong>
                  </div>

                  <div className="w-full space-y-3">
                    <Input
                      type="email"
                      placeholder="Enter your email address"
                      name="email"
                      labelInput="Email address"
                      isRequiredInput={true}
                      variant={false}
                      onChange={(e) => {
                        handleChange(e);
                        updateCompletedItems(e.target.value);
                        setButtonsDisabled(false);
                      }}
                      value={values.email || ''}
                    />

                    <div className="w-full relative">
                      <Input
                        type="password"
                        placeholder="Create a Password"
                        name="password"
                        labelInput="Password"
                        isRequiredInput={true}
                        variant={false}
                        onClick={() => setIsPasswordClicked(true)}
                        onChange={(e) => {
                          handleChange(e);
                          updateCompletedItems(e.target.value);
                          updateCompletedItemsTwo(e.target.value);
                          updateCompletedItemsThree(e.target.value);
                          updateCompletedItemsFour(e.target.value);
                          updateCompletedItemsFive(e.target.value);
                          setButtonsDisabled(false);
                        }}
                        value={values.password || ''}
                        onFocus={() => setIsPasswordFocused(true)}
                        onBlur={() => setIsPasswordFocused(false)}
                      />

                      {isPasswordClicked &&
                        (!isNumOfCompleted || !isLowerCase || !isUpperCase || !isSpecialChar || !isNumber) && (
                          <p className="text-left text-xs text-red-600 p-0 m-0">Invalid password</p>
                        )}

                      {isPasswordFocused &&
                        (!isNumOfCompleted || !isLowerCase || !isUpperCase || !isSpecialChar || !isNumber) && (
                          <div className="w-full p-3 absolute z-10 top-13 border-1 border-neutral-50 bg-neutral-50 rounded-b-[4px] rounded-t-[2px] shadow">
                            <div className="w-full grid grid-cols-5 gap-4">
                              {[...Array(5)].map((_, index) => (
                                <div
                                  key={index}
                                  className={`rounded-full h-2 ${
                                    (index === 0 && isNumOfCompleted) ||
                                    (index === 1 && isLowerCase) ||
                                    (index === 2 && isUpperCase) ||
                                    (index === 3 && isSpecialChar) ||
                                    (index === 4 && isNumber)
                                      ? 'bg-yellow-500'
                                      : 'bg-neutral-600'
                                  }`}
                                ></div>
                              ))}
                            </div>

                            <div className="w-full -mb-4">
                              <h4 className="font-semibold text-core-black text-base my-3 text-left">
                                Your password must contain:
                              </h4>
                              <ul className="text-left px-0 mx-0 text-xs text-core-black space-y-2">
                                <li className={`${isNumOfCompleted ? 'text-green-600' : ''}`}>
                                  Minimum number of characters is 8.
                                </li>
                                <li className={`${isLowerCase ? 'text-green-600' : ''}`}>Should contain lowercase.</li>
                                <li className={`${isUpperCase ? 'text-green-600' : ''}`}>Should contain uppercase.</li>
                                <li className={`${isNumber ? 'text-green-600' : ''}`}>Should contain numbers.</li>
                                <li className={`${isSpecialChar ? 'text-green-600' : ''}`}>
                                  Should contain special characters.
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                    </div>

                    <Input
                      type="text"
                      placeholder="Choose a display name"
                      name="username"
                      labelInput="Display name"
                      isRequiredInput={true}
                      variant={false}
                      onChange={(e) => {
                        handleChange(e);
                        setButtonsDisabled(false);
                        handleCheckSpecialChars(e.target.value);
                      }}
                      value={values.username || ''}
                    />
                    <p className="text-left text-xs text-gray-800 p-0 m-0">
                      This name identifies your listings and messages on the site. Avoid using emails or business info
                      for privacy.
                    </p>
                    {isInvalidUsername && (
                      <p className="text-left text-xs text-red-600 p-0 m-0">
                        Invalid display name. No special characters allowed
                      </p>
                    )}
                  </div>

                  <div className="w-full mt-10 mb-6">
                    <div className="flex gap-4">
                      <input
                        type="checkbox"
                        className="hidden"
                        id="checkbox"
                        name="receivepromotions"
                        onChange={(e) => {
                          handleChange(e);
                          setChecked(e.target.checked);
                        }}
                        value={checked ? 1 : 0}
                      />
                      <label
                        htmlFor="checkbox"
                        className={`h-5 md:w-5 w-6 my-auto group flex items-center justify-center cursor-pointer border-1 border-gray-700 rounded-[4px] ${
                          checked ? 'bg-core-black' : 'bg-white'
                        }`}
                      >
                        {checked && <CheckIconMini className="w-3 h-3  text-white stroke-2" />}
                      </label>

                      <p className="p-0 m-0 text-gray-700 text-sm cursor-pointer text-left">
                        I want to receive news, features, updates, discounts, and offers.
                      </p>
                    </div>
                  </div>

                  <div className="w-full">
                    <Button
                      variant="fill"
                      color="primary"
                      type="submit"
                      disabled={buttonsDisabled || isPasswordInvalid || isInvalidUsername}
                    >
                      {isPendingButton ? (
                        <LoadingIcon className="m-auto w-8 h-8 text-gray-200 animate-spin fill-yellow-600" />
                      ) : (
                        'Create Account'
                      )}
                    </Button>
                  </div>

                  <div className="w-full mt-6 mb-6">
                    <p className="text-gray-400 text-sm p-0 m-0 font-medium text-left">
                      By creating an account, you acknowledge that you have read and agree to the
                      <strong
                        onClick={() => window.open('https://www.haveyourcake.com/cake-privacy-policy', '_blank')}
                        className="ml-1 cursor-pointer text-core-black font-medium underline hover:text-gray-800 transition ease-in"
                      >
                        Privacy Policy
                      </strong>
                      .
                    </p>
                  </div>

                  <div className="w-full mt-24 hidden md:block">
                    <button
                      className="font-semibold text-base flex gap-2 text-core-black"
                      onClick={(): void => history.push('/login')}
                    >
                      <LeftArrowMini className="my-auto h-4 w-4" />
                      Back
                    </button>
                  </div>
                </div>

                <div className="md:w-3/5 w-full md:p-2">
                  <div className="w-4/5 flex justify-end flex-wrap md:pt-10 pb-0 pr-0 bg-cake md:rounded-2xl md:mt-4">
                    <div className="w-full flex md:hidden my-8 px-4">
                      <div className="w-1/2 flex justify-start">
                        <img className="aspect-[557/217] flex justify-start w-12" src={cakeLogoBlack} />
                      </div>
                    </div>

                    <div className="w-full px-10">
                      <h2 className="text-core-black font-medium text-center text-3xl md:!text-left">
                        {sections[currentSection].text}
                      </h2>
                    </div>

                    <div className="w-full block mx-auto md:mx-0 md:flex my-4 gap-2 px-10">
                      {sections.map((_, index) => (
                        <button
                          key={index}
                          type="button"
                          className={`rounded-full mx-2 md:!mx-0 border-[1px] h-3 w-3 border-core-black transition ease-in ${
                            index === currentSection ? 'bg-black' : 'hover:bg-black'
                          }`}
                          onClick={() => setCurrentSection(index)}
                        ></button>
                      ))}
                    </div>

                    {sections[currentSection].image}
                  </div>
                </div>
              </div>
              {alerts}
            </Form>
          </>
        )}
      </Formik>

      {isModalOpen && <ModalDisclaimer onClose={handleOpenModal} />}
    </div>
  );
};

export default CreateAccountV2;
