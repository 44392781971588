import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Bars2Icon,
  CheckIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  Cog6ToothIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import EmptyState from 'src/app/shared/components/emptyState/EmptyState';
import { formatCurrency, formatLastSync } from 'src/app/shared/utils/formatters';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';
import { useGetPolicies, useGetSlicesGroup } from 'src/hooks/usePolicies';
import UploadModal from './components/uploadModal';
import { UsStatesOptions } from 'src/app/shared/ListingEnums';
import Pagination from 'src/app/shared/components/Pagination/Pagination';
import BookFilter from './components/filter/BookFilter';
import SelectionCounter from './components/filter/selectionCounter';
import GroupSection from './components/filter/GroupSection';
import { Policies, SlicesGroup } from 'src/types/Policies';
import { getSlice } from 'src/api/policies';
import BookValuation from './components/bookValuation';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import EmptyStateCarousel from 'src/app/shared/components/emptyState/EmptyStateCarousel';
import cakeUpload from 'src/styles/assets/logos/cakeIntegration.png';
import Tooltip from 'src/app/shared/components/Tooltip/Tooltip';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { PriceFilter } from 'src/app/shared/components/Filters/PolicyFiltersBar';
import ModalDataPolicy from './components/ModalDataPolicy';

interface BookProps {
  showLayout: boolean | false;
  setShowLayout: (value: boolean) => void;
  onSelectPolicies: (selectedPolicies: string[]) => void;
  continueButton: boolean | false;
  setContinueButton: (value: boolean) => void;
  onUpdateFilteredSlidesData: (filteredSlidesData: SlicesGroup[]) => void;
  isDelete: boolean | false;
  onEmptyStateChange: (isEmpty: boolean) => void;
  onSetIsDelete: (value: boolean) => void;
  integrationStatus?: string;
  refetchIntegration?: boolean | false;
  setRefetchIntegration: (value: boolean) => void;
  integrationlastSync?: string;
  onNumberChange: (value: number) => void;
}

interface SelectedItem {
  index: number;
  uid: string;
}

const getStateFullName = (stateCode: string): string => {
  if (UsStatesOptions[stateCode as keyof typeof UsStatesOptions]) {
    return UsStatesOptions[stateCode as keyof typeof UsStatesOptions];
  }
  return stateCode;
};

const Book: React.FC<BookProps> = ({
  showLayout,
  setShowLayout,
  onSelectPolicies,
  continueButton,
  setContinueButton,
  onUpdateFilteredSlidesData,
  isDelete,
  onEmptyStateChange,
  onSetIsDelete,
  integrationStatus,
  refetchIntegration,
  setRefetchIntegration,
  integrationlastSync,
  onNumberChange,
}) => {
  const { data: policiesData, isPending, refetch } = useGetPolicies();

  const { data: slicesData, refetch: refetchGroup } = useGetSlicesGroup();
  const [filteredSlicesData, setFilteredSlicesData] = useState<SlicesGroup[]>([]);
  const { alerts, createToast } = useAlert();

  const [isModalUploadOpen, setisModalUploadOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isFilterbySliceName, setIsFilterbySliceName] = useState(false);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [filteredPoliciesData, setFilteredPoliciesData] = useState(policiesData?.myPolicies || []);
  const [currentItems, setCurrentItems] = useState(filteredPoliciesData.slice(indexOfFirstItem, indexOfLastItem));
  const [AllChecked, setAllChecked] = useState(false);
  const [selectedIndexes, setSelectedIndexes] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [allSelectedItems, setAllSelectedItems] = useState<string[]>([]);
  const tableRowClass = 'p-3 text-core-tableHeader text-xs uppercase text-left border-bottom whitespace-nowrap';
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [isPendingList, setIsPendingList] = useState(false);
  const [isPendingFilter, setIsPendingFilter] = useState(false);
  const [sliceUidFilter, setSliceUid] = useState<string | null>(null);
  const [showColumsOptions, setShowColumsOptions] = useState(false);
  const [startStep, setStartStep] = useState<string>('');
  const removeDoubleQuotes = (str: any) => {
    return str ? str.replace(/["]+/g, '') : '';
  };

  const filterName = localStorage.getItem('sliceNameFilters');
  const filterNameSecure = removeDoubleQuotes(filterName);

  const fetchSliceDetail = async (sliceUid: string) => {
    try {
      setIsPendingFilter(true);
      const sliceDetailData = await getSlice(sliceUid);
      if (sliceDetailData && sliceDetailData.group && sliceDetailData.group.policies) {
        const formattedPolicies = sliceDetailData.group.policies.map((policy) => ({
          uid: policy.uid,
          policyNumber: policy.policy_number,
          carrier: policy.carrier,
          clientId: policy.client_id,
          lineOfBusiness: policy.line_of_business,
          totalPremium: policy.total_premium,
          insuredState: policy.insured_state,
          typeOfInsurance: policy.type_of_insurance,
          insuredZipCode: policy.insured_zip_code,
          policyStatus: policy.policy_status,
          naicCode: policy.naic_code,
          numOfClaims: policy.num_of_claims,
          numOfTerms: policy.num_of_terms,
          paymentPlan: policy.payment_plan,
          lastModified: policy.last_modified,
        }));

        setFilteredPoliciesData(formattedPolicies);
        let filteredData = formattedPolicies;
        if (filterLoBParsed.length > 0) {
          filteredData = filteredData.filter((policy) => filterLoBParsed.includes(policy.lineOfBusiness));
        }

        if (filterListingStatesParsed.length > 0) {
          filteredData = filteredData.filter((policy) =>
            filterListingStatesParsed.includes(getStateFullName(policy.insuredState))
          );
        }
        if (insuranceTypeFilters && insuranceTypeFilters.length > 0) {
          filteredData = filteredData.filter(
            (policy) => insuranceTypeFilters && insuranceTypeFilters.includes(policy.typeOfInsurance)
          );
        }

        if (savedTotalPremiumFilters) {
          const filters = JSON.parse(savedTotalPremiumFilters);
          filteredData = filteredData.filter((policy) => {
            const minCondition = filters.min === '' || policy.totalPremium >= parseFloat(filters.min);
            const maxCondition = filters.max === '' || policy.totalPremium <= parseFloat(filters.max);

            return minCondition && maxCondition;
          });
        }

        if (filterCarrierParsed.length > 0) {
          filteredData = filteredData.filter((policy) => filterCarrierParsed.includes(policy.carrier));
        }
        setFilteredPoliciesData(filteredData);
      }
      setIsPendingFilter(false);
    } catch (error) {
      console.log(error);
      createToast({
        text: 'Error retrieving policy listings',
        variant: 'warning',
      });
    }
  };

  const history = useHistory();

  const handleOpenIntegration = () => {
    history.push('/account#integrations');
  };

  const [filters, setFilters] = useState<{
    policyNumber: string[];
    listingState: string[];
    carrier: string[];
    lineOfBusiness: string[];
    sliceName: string;
    insuranceType: string;
    totalPremium: PriceFilter;
  }>({
    policyNumber: [],
    listingState: [],
    carrier: [],
    lineOfBusiness: [],
    sliceName: '',
    insuranceType: '',
    totalPremium: { min: '', max: '' },
  });

  useEffect(() => {
    onUpdateFilteredSlidesData(filteredSlicesData);
    if (filteredSlicesData.length === 0) {
      onEmptyStateChange(true);
    } else {
      onEmptyStateChange(false);
    }
  }, [filteredSlicesData, onUpdateFilteredSlidesData, onEmptyStateChange]);

  useEffect(() => {
    if (isDelete && sliceUidFilter) {
      refetch();
      refetchGroup();
      fetchSliceDetail(sliceUidFilter);
      const newFilteredSlicesDatabyName = slicesData?.groups.filter((slice) => slice.name === filterNameSecure) || [];
      setFilteredSlicesData([{ groups: newFilteredSlicesDatabyName }]);
      setSliceUid(null);
      onSetIsDelete(false);
      window.location.reload();
    }
  }, [isDelete, slicesData, filterNameSecure, sliceUidFilter, onSetIsDelete]);

  const filterListingStates = localStorage.getItem('usStateFilters');
  const filterListingStatesParsed = filterListingStates ? JSON.parse(filterListingStates) : [];

  const filterLoB = localStorage.getItem('lineOfBusinessFilters');
  const filterLoBParsed = filterLoB ? JSON.parse(filterLoB) : [];

  const insuranceTypeFilters = localStorage.getItem('insuranceTypeFilters');

  const savedTotalPremiumFilters = localStorage.getItem('premiumRangeFilters');

  const filterCarrier = localStorage.getItem('carrierFilters');
  const filterCarrierParsed = filterCarrier ? JSON.parse(filterCarrier) : [];
  const filteredPoliciesParsed = JSON.parse(localStorage.getItem('policyNumberFilters') ?? '[]');

  const applyFilters = () => {
    let filteredData = policiesData ? policiesData.myPolicies : [];

    if (filterNameSecure !== '') {
      setIsFilterbySliceName(true);
      const newFilteredSlicesData = slicesData?.groups?.filter((slice) => slice.name === filterNameSecure) || [];
      setFilteredSlicesData([{ groups: newFilteredSlicesData }]);
      if (newFilteredSlicesData.length > 0) {
        const sliceUid = newFilteredSlicesData[0].uid;
        setSliceUid(sliceUid);
        fetchSliceDetail(sliceUid);
      }
      const sliceGroup = slicesData?.groups?.find((group) => group.name === filterNameSecure);

      if (sliceGroup) {
        const slicePolicies = sliceGroup.uid;
        filteredData = filteredData.filter((policy) => policy.uid === slicePolicies);
      }
    }

    if (insuranceTypeFilters && insuranceTypeFilters.length > 0) {
      filteredData = filteredData.filter(
        (policy) => insuranceTypeFilters && insuranceTypeFilters.includes(policy.typeOfInsurance)
      );
    }

    if (savedTotalPremiumFilters) {
      const filters = JSON.parse(savedTotalPremiumFilters);
      filteredData = filteredData.filter((policy) => {
        const minCondition = filters.min === '' || policy.totalPremium >= parseFloat(filters.min);
        const maxCondition = filters.max === '' || policy.totalPremium <= parseFloat(filters.max);

        return minCondition && maxCondition;
      });
    }

    if (filterLoBParsed.length > 0) {
      filteredData = filteredData.filter((policy) => filterLoBParsed.includes(policy.lineOfBusiness));
    }

    if (filterListingStatesParsed.length > 0) {
      filteredData = filteredData.filter((policy) =>
        filterListingStatesParsed.includes(getStateFullName(policy.insuredState))
      );
    }

    if (filterCarrierParsed.length > 0) {
      filteredData = filteredData.filter((policy) => filterCarrierParsed.includes(policy.carrier));
    }

    if (filteredPoliciesParsed.length > 0) {
      filteredData = filteredData.filter((policy) => filteredPoliciesParsed.includes(policy.policyNumber));
    }

    setFilteredPoliciesData(filteredData);
    setIsPendingList(false);
    setCurrentItems(filteredData.slice(indexOfFirstItem, indexOfLastItem));

    return filteredData;
  };

  useEffect(() => {
    setSelectedIndexes([]);
    setAllChecked(false);
  }, [currentPage]);

  useEffect(() => {
    if (!isPending && !isPendingList && policiesData) {
      applyFilters();
    }
  }, [isPending, filters, isPendingList, policiesData]);

  useEffect(() => {
    const newCurrentItems = filteredPoliciesData.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(newCurrentItems);
  }, [filteredPoliciesData, currentPage, itemsPerPage]);

  const handleFiltersChange = (filters: {
    policyNumber: string[];
    listingState: string[];
    lineOfBusiness: string[];
    carrier: string[];
    sliceName: string;
    insuranceType: string;
    totalPremium: PriceFilter;
  }) => {
    setIsPendingList(true);
    const filterNameSlice = localStorage.getItem('sliceNameFilters');
    if (!filterNameSlice) {
      setFilteredSlicesData([]);
    }

    setFilters(filters);
    setCurrentPage(1);
    setIsPendingList(false);
  };

  const handleItemsPerPageChange = (itemsPerPage: number) => {
    setItemsPerPage(itemsPerPage);
    setCurrentPage(1);
  };

  const handleMasterCheckboxChange = () => {
    const newAllChecked = !AllChecked;
    setAllChecked(newAllChecked);
    setSelectedIndexes(newAllChecked ? currentItems.map((item, index) => ({ index, uid: item.uid })) : []);
    setSelectedItems(newAllChecked ? [...selectedItems, ...currentItems.map((item) => item.uid)] : []);
    onSelectPolicies(newAllChecked ? [...selectedItems, ...currentItems.map((item) => item.uid)] : []);
    if (!newAllChecked) {
      localStorage.removeItem('selectedItems');
    }
  };

  useEffect(() => {
    if (selectedItems.length === 0) {
      localStorage.setItem('selectedItems', JSON.stringify(selectedItems));
      return;
    }

    const newAllSelectedItems = [...allSelectedItems];

    if (selectedItems.length > 0) {
      selectedItems.forEach((item) => {
        if (!newAllSelectedItems.includes(item)) {
          newAllSelectedItems.push(item);
        }
      });
      setAllSelectedItems(newAllSelectedItems);
      localStorage.setItem('selectedItems', JSON.stringify(selectedItems));
    } else {
      if (localStorage.getItem('selectedItems')?.length) {
        setSelectedItems(JSON.parse(localStorage.getItem('selectedItems') || '[]'));
      }
    }
  }, [selectedItems]);

  const settingsColumns = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (settingsColumns.current && !settingsColumns.current.contains(event.target as Node)) {
        setShowColumsOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = (selectedItem: SelectedItem) => {
    const initialSelectedItems = localStorage.getItem('selectedItems') || '[]';
    let newSelectedItems: string[] = [];

    if (initialSelectedItems?.length > 0) {
      newSelectedItems = JSON.parse(initialSelectedItems);
    }

    const isSelected = newSelectedItems.includes(selectedItem.uid);

    if (isSelected) {
      newSelectedItems = newSelectedItems.filter((uid) => uid !== selectedItem.uid);
    } else {
      newSelectedItems = [...newSelectedItems, selectedItem.uid];
    }

    setSelectedItems(newSelectedItems);

    const selectedPolicies = newSelectedItems;
    onSelectPolicies(selectedPolicies);

    let newSelectedIndexes: SelectedItem[] = [];
    let newAllChecked = false;

    if (newSelectedItems.length === currentItems.length) {
      newAllChecked = true;
      newSelectedIndexes = currentItems.map((item, index) => ({ index, uid: item.uid }));
    } else {
      newSelectedIndexes = newSelectedItems.map((uid) => ({
        index: currentItems.findIndex((item) => item.uid === uid),
        uid,
      }));
    }

    setSelectedIndexes(newSelectedIndexes);
    setAllChecked(newAllChecked);
  };

  useEffect(() => {
    const newCurrentItems = filteredPoliciesData.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(newCurrentItems);

    const newSelectedIndexes = selectedItems
      .filter((item) => newCurrentItems.map((it) => it.uid).includes(item))
      .map((uid) => ({
        index: newCurrentItems.findIndex((it) => it.uid === uid),
        uid,
      }));
    setSelectedIndexes(newSelectedIndexes);
    setAllChecked(newSelectedIndexes.length === newCurrentItems.length);
  }, [filteredPoliciesData, currentPage, itemsPerPage]);

  useEffect(() => {
    const newSelectedItems = selectedItems.concat(selectedIndexes.map((item) => item.uid));
    setSelectedItems(Array.from(new Set(newSelectedItems)));
    setAllSelectedItems(newSelectedItems);
  }, [selectedIndexes]);

  const handleUpload = (stepType: string) => {
    setisModalUploadOpen(true);
    setStartStep(stepType);
  };

  const closeModal = () => {
    setisModalUploadOpen(false);
    setRefetchIntegration(!refetchIntegration);
  };

  const clearSelection = () => {
    setSelectedIndexes([]);
    setSelectedItems([]);
    onSelectPolicies([]);
    setAllChecked(false);
  };

  const setShowLayoutState = (value: boolean) => {
    setShowLayout(value);
  };

  const countUniqueClientIDs = (policies: Policies): number => {
    const uniqueClientIDs = new Set<string>();
    policies.myPolicies.forEach((policy) => {
      if (policy.clientId && !uniqueClientIDs.has(policy.clientId)) {
        uniqueClientIDs.add(policy.clientId);
      }
    });
    return uniqueClientIDs.size;
  };

  const handleOpenColumsOptions = () => {
    setShowColumsOptions(!showColumsOptions);
  };

  const initialTableColumns = [
    {
      label: 'Policy ID',
      className: `w-36 ${tableRowClass}`,
      key: 'policyNumber',
      visibility: 'enabled',
      required: true,
    },
    {
      label: 'Premium',
      className: `w-36 ${tableRowClass}`,
      key: 'totalPremium',
      visibility: 'enabled',
      required: true,
    },
    { label: 'Carrier', className: `w-36 ${tableRowClass}`, key: 'carrier', visibility: 'enabled', required: true },
    { label: 'State', className: `w-36 ${tableRowClass}`, key: 'insuredState', visibility: 'enabled', required: true },
    {
      label: 'Line of Business',
      className: `w-36 ${tableRowClass}`,
      key: 'lineOfBusiness',
      visibility: 'enabled',
      required: true,
    },
    {
      label: 'Type',
      className: `w-36 ${tableRowClass}`,
      key: 'typeOfInsurance',
      visibility: 'enabled',
      required: true,
    },
    {
      label: 'Zip Code',
      className: `w-36 ${tableRowClass}`,
      key: 'insuredZipCode',
      visibility: 'disabled',
      required: false,
    },
    {
      label: 'Policy Status',
      className: `w-36 ${tableRowClass}`,
      key: 'policyStatus',
      visibility: 'disabled',
      required: false,
    },
    {
      label: 'Client ID',
      className: `w-36 ${tableRowClass}`,
      key: 'clientId',
      visibility: 'disabled',
      required: false,
    },
    {
      label: 'NAIC Code',
      className: `w-36 ${tableRowClass}`,
      key: 'naicCode',
      visibility: 'disabled',
      required: false,
    },
    {
      label: 'No. of Claim',
      className: `w-36 ${tableRowClass}`,
      key: 'numOfClaims',
      visibility: 'disabled',
      required: false,
    },
    {
      label: 'No. of Terms',
      className: `w-36 ${tableRowClass}`,
      key: 'numOfTerms',
      visibility: 'disabled',
      required: false,
    },
    {
      label: 'Payment Plan',
      className: `w-36 ${tableRowClass}`,
      key: 'paymentPlan',
      visibility: 'disabled',
      required: false,
    },
    {
      label: 'Last Update',
      className: `w-36 ${tableRowClass}`,
      key: 'lastModified',
      visibility: 'disabled',
      required: false,
    },
  ];

  const initialTableColumnsWithIndex = initialTableColumns.map((column, index) => ({
    ...column,
    index,
  }));

  const [tableColumns, setTableColumns] = useState(initialTableColumnsWithIndex);
  const visibleColumns = tableColumns.filter((column) => column.visibility === 'enabled');
  const [columnStatus, setColumnStatus] = useState(() => {
    const initialStatus: { [key: string]: boolean } = {};
    tableColumns.forEach((column) => {
      initialStatus[column.key] = column.visibility === 'enabled';
    });
    return initialStatus;
  });

  const handleCheckboxVisibilityChange = (key: any) => {
    const newStatus = { ...columnStatus };
    newStatus[key] = !newStatus[key];
    setColumnStatus(newStatus);

    const updatedTableColumns = tableColumns.map((column) => ({
      ...column,
      visibility: newStatus[column.key] ? 'enabled' : 'disabled',
    }));

    setTableColumns(updatedTableColumns);
  };

  const handleSort = (field: string) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
    const sortedData = sortData(field, order);
    setFilteredPoliciesData(sortedData);
  };

  const sortData = (field: string, order: 'asc' | 'desc') => {
    const sortedData = [...filteredPoliciesData];
    switch (field) {
      case 'policyNumber':
        sortedData.sort((a, b) =>
          order === 'asc' ? a.policyNumber.localeCompare(b.policyNumber) : b.policyNumber.localeCompare(a.policyNumber)
        );
        break;
      case 'totalPremium':
        sortedData.sort((a, b) =>
          order === 'asc' ? a.totalPremium - b.totalPremium : b.totalPremium - a.totalPremium
        );
        break;
      case 'carrier':
        sortedData.sort((a, b) =>
          order === 'asc' ? a.carrier.localeCompare(b.carrier) : b.carrier.localeCompare(a.carrier)
        );
        break;
      case 'insuredState':
        sortedData.sort((a, b) =>
          order === 'asc' ? a.insuredState.localeCompare(b.insuredState) : b.insuredState.localeCompare(a.insuredState)
        );
        break;
      case 'lineOfBusiness':
        sortedData.sort((a, b) =>
          order === 'asc'
            ? a.lineOfBusiness.localeCompare(b.lineOfBusiness)
            : b.lineOfBusiness.localeCompare(a.lineOfBusiness)
        );
        break;
      case 'typeOfInsurance':
        sortedData.sort((a, b) =>
          order === 'asc'
            ? a.typeOfInsurance.localeCompare(b.typeOfInsurance)
            : b.typeOfInsurance.localeCompare(a.typeOfInsurance)
        );
        break;
      default:
        break;
    }
    return sortedData;
  };

  const [columns, setColumns] = useState(initialTableColumnsWithIndex.filter((column) => column));

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const updatedVisibleColumns = Array.from(tableColumns);

    const [movedColumn] = updatedVisibleColumns.splice(result.source.index, 1);

    updatedVisibleColumns.splice(result.destination.index, 0, movedColumn);

    const reorderedColumns = updatedVisibleColumns.map((column, index) => ({
      ...column,
      index,
    }));

    setTableColumns(reorderedColumns);

    setColumns(reorderedColumns);
  };

  const [openModalDataPolicy, setOpenModalDataPolicy] = useState(false);

  const [policie, setPolicie] = useState<string | undefined>(undefined);

  const handleOpenModalDataPolicy = (policie?: any) => {
    setOpenModalDataPolicy(true);
    setPolicie(policie);
  };

  const handleCloseModalDataPolicy = () => {
    setOpenModalDataPolicy(false);
    // TODO: Fix this to refetch only if something changed, instead of every time the modal closes
    refetch();
  };

  useEffect(() => {
    if (policiesData && policiesData.myPolicies) {
      const policiesWithConflictCount = policiesData.myPolicies.filter((policie) => policie.hasConflict).length;
      onNumberChange(policiesWithConflictCount);
    }
  }, [policiesData, onNumberChange]);

  const SortIcon = (
    <ChevronUpDownIcon className="h-3 w-3 text-core-black my-auto hidden group-hover:block transition ease-in" />
  );

  if (isPending) {
    return (
      <div className="w-full">
        <div className="w-full my-4">
          <Skeleton type="book-valuation" />
        </div>
        <div className="w-full flex justify-start border rounded-lg overflow-auto sm:overflow-visible md:overflow-auto lg:overflow-hidden xl:overflow-auto">
          <div className="w-full">
            <Skeleton type="table" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <>
        {policiesData && policiesData.myPolicies.length > 0 ? (
          <BookValuation
            lineItems={policiesData.myPolicies.map((item) => ({
              state: getStateFullName(item.insuredState),
              lob: item.lineOfBusiness,
              annualPremium: item.totalPremium,
              carrier: item.carrier,
              numPolicies: 1,
              overrideCommission: 0,
              numCustomers: 1,
              typeOfInsurance: item.typeOfInsurance,
            }))}
            uniqueValues={countUniqueClientIDs({ myPolicies: policiesData.myPolicies })}
          />
        ) : null}
      </>

      <div className="w-[100px] absolute right-10" ref={settingsColumns}>
        <div className="w-full flex justify-end">
          <Tooltip body="Manage columns">
            <button
              className={`p-1 border rounded-[4px] ${
                showColumsOptions ? 'bg-neutral-50' : 'bg-core-white'
              } hover:bg-neutral-50 transition ease-in cursor-pointer`}
              onClick={handleOpenColumsOptions}
            >
              <Cog6ToothIcon className="w-5 h-5 text-gray-700" />
            </button>
          </Tooltip>
        </div>

        {showColumsOptions && (
          <div className="py-3 bg-white rounded-[4px] w-[300px] border-1 right-0 shadow mt-10 border-core-accentBlue absolute">
            <h2 className="text-sm uppercase font-bold text-gray-500 mb-2 px-3">Manage Columns</h2>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable" direction="vertical">
                {(provided) => (
                  <ul
                    className="max-h-[400px] overflow-y-auto w-full px-3 m-0"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {columns.map((column, index) => (
                      <Draggable key={index} draggableId={`column-${column.key}`} index={index}>
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="flex p-2 mx-0 mb-2 gap-2 hover:border-gray-400 border-white border-1 hover:bg-neutral-100 transition ease-in bg-white rounded-[3px]"
                          >
                            <div className="flex justify-start w-[10%]">
                              <label
                                htmlFor={`column-${column.key}`}
                                className={`flex gap-4 justify-start w-full my-auto group items-center cursor-pointer`}
                              >
                                <input
                                  type="checkbox"
                                  className="hidden"
                                  id={`column-${column.key}`}
                                  checked={columnStatus[column.key]}
                                  onChange={() => handleCheckboxVisibilityChange(column.key)}
                                  disabled={column.required === true}
                                />
                                <div
                                  className={`h-5 w-5 group flex items-center justify-center border-2 border-gray-600 rounded-[4px] ${
                                    columnStatus[column.key]
                                      ? column.required
                                        ? 'bg-gray-700 border-gray-700 cursor-not-allowed'
                                        : 'bg-core-black border-black'
                                      : 'bg-white'
                                  }`}
                                >
                                  {columnStatus[column.key] && <CheckIcon className="w-3 h-3 text-white stroke-2" />}
                                </div>
                              </label>
                            </div>
                            <div className="items-center flex justify-between w-4/5">
                              <p className="text-sm font-medium text-core-black m-0 p-0 !capitalize">{column.label}</p>
                            </div>
                            <div className="flex justify-end w-[10%]">
                              <Bars2Icon className="text-gray-500 h-5 w-5 my-auto" />
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}

                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
      </div>

      <BookFilter filters={filters} onFiltersChange={handleFiltersChange} />

      <div className="w-full flex justify-start border rounded-lg overflow-auto sm:overflow-visible md:overflow-auto xl:overflow-auto flex-col">
        {filteredPoliciesData.length > 0 && (
          <table className="w-full bg-white rounded-lg">
            <thead className="rounded-lg">
              {isFilterbySliceName && (
                <tr>
                  <th colSpan={9}>
                    <GroupSection filteredSlicesData={filteredSlicesData} />
                  </th>
                </tr>
              )}
              <tr className="rounded-lg">
                <th className="p-3 text-core-tableHeader text-xs uppercase text-left border-bottom whitespace-nowrap">
                  <div className="flex gap-4 justify-start w-full my-auto">
                    <input
                      type="checkbox"
                      className="hidden"
                      id="checkbox-all"
                      checked={AllChecked && filteredPoliciesData.length > 0}
                      onClick={handleMasterCheckboxChange}
                      disabled={filteredPoliciesData.length === 0}
                    />
                    <label
                      htmlFor="checkbox-all"
                      className={`h-5 w-5 my-auto group flex items-center justify-center cursor-pointer border-2 border-gray-600 rounded-[4px] ${
                        AllChecked && filteredPoliciesData.length > 0 ? 'bg-core-black border-black' : 'bg-white'
                      } ${filteredPoliciesData.length === 0 ? '!cursor-not-allowed' : ''}`}
                    >
                      {AllChecked && <CheckIcon className="w-3 h-3 text-white stroke-2" />}
                    </label>
                  </div>
                </th>

                <th className="p-3 border-bottom">{/* empty for alert structure  */}</th>

                {visibleColumns.map((column, index) => (
                  <th key={`${column.key !== undefined ? column.key : ''}_${index}`} className={column.className}>
                    <span
                      onClick={() => column.key && handleSort(column.key)}
                      className={`flex w-full cursor-pointer group ${column.label ? '' : 'hidden'}`}
                    >
                      {column.label} {column.key === sortField && SortIcon}
                    </span>
                  </th>
                ))}

                <th className="w-16 p-3 border-bottom">{/* empty for action structure  */}</th>
              </tr>
            </thead>
            {filteredPoliciesData.length > 0 && !isPendingList ? (
              <tbody>
                {currentItems.map((policie, index) => (
                  <tr
                    key={index}
                    className="hover:bg-yellow-50 transition ease-in rounded-lg cursor-pointer"
                    // onClick={() => handleOpenModalDataPolicy(policie)}
                  >
                    <td className="p-3 text-core-grey text-sm font-semibold w-[70px] text-left truncate px-3">
                      <label
                        htmlFor={`checkbox-${index}`}
                        className={`flex gap-4 justify-start w-full my-auto group items-center cursor-pointer`}
                      >
                        <input
                          type="checkbox"
                          className="hidden"
                          id={`checkbox-${index}`}
                          checked={selectedItems.includes(currentItems[index].uid)}
                          onChange={() => handleCheckboxChange({ index, uid: currentItems[index].uid })}
                        />
                        <div
                          className={`h-5 w-5 group flex items-center justify-center border-2 border-gray-600 rounded-[4px] ${
                            selectedIndexes.some((item) => item.index === index)
                              ? 'bg-core-black border-black'
                              : 'bg-white'
                          }`}
                        >
                          {selectedIndexes.some((item) => item.index === index) && (
                            <CheckIcon className="w-3 h-3 text-white stroke-2" />
                          )}
                        </div>
                      </label>
                    </td>

                    <td className="py-3 text-sm font-semibold w-[0px] max-w-[70px] text-left truncate px-3">
                      {policie.hasConflict && (
                        <span onClick={handleOpenIntegration}>
                          <ExclamationTriangleIcon className="text-core-warning w-5 h-5" />
                        </span>
                      )}
                    </td>

                    {visibleColumns.map((column) => (
                      <td
                        key={column.key}
                        className={`p-3 text-core-grey text-sm font-semibold max-w-[200px] text-left truncate px-3`}
                        onClick={() => handleOpenModalDataPolicy(policie)}
                      >
                        {column.key === 'totalPremium' ? (
                          formatCurrency(policie.totalPremium)
                        ) : column.key === 'typeOfInsurance' ? (
                          <span
                            className={`p-1 rounded uppercase text-xs ${
                              policie.typeOfInsurance === 'Personal'
                                ? 'text-orange-400 bg-orange-50'
                                : 'text-blue-400 bg-blue-50'
                            }`}
                          >
                            {policie.typeOfInsurance}
                          </span>
                        ) : column.key === 'insuredState' ? (
                          getStateFullName(policie.insuredState)
                        ) : column.key === 'lastModified' ? (
                          formatLastSync(policie.lastModified)
                        ) : (
                          policie[column.key]
                        )}
                      </td>
                    ))}

                    {/* Doesn't work so hide until we fix the hover state 
                      <td className="my-auto pl-5">
                      <button className="p-1 bg-gray-50 hover:bg-core-yellow transition ease-in rounded-sm mr-2">
                        <ChevronRightIcon className="h-3 w-3" />
                      </button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            ) : null}

            {isPendingList ||
              (isPendingFilter && (
                <tbody>
                  <tr>
                    <td colSpan={9}>
                      <div className="w-full">
                        <Skeleton type="table"></Skeleton>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>
        )}
        <>
          {filteredPoliciesData.length === 0 &&
          !isPendingList &&
          (integrationStatus === '' || integrationStatus === 'Disabled') ? (
            <div className="w-full mx-auto">
              {policiesData && policiesData?.myPolicies.length > 0 ? (
                <EmptyState text="There are no Policies matching this criteria" help={false} />
              ) : (
                <>
                  <EmptyStateCarousel onClick={handleUpload} />
                </>
              )}
            </div>
          ) : null}

          {integrationStatus === 'Enabled' && integrationlastSync === '' && filteredPoliciesData.length === 0 && (
            <EmptyState
              image={cakeUpload}
              text="Your book import is in progress. It can take up to 24 hours for your data to show up in your Account. Check back soon."
              help={false}
            />
          )}
        </>
      </div>

      {alerts}

      <SelectionCounter
        count={selectedItems.length}
        selectedItems={selectedItems}
        clearSelection={clearSelection}
        setShowLayout={setShowLayoutState}
        showLayout={showLayout}
        continueButton={continueButton}
        setContinueButton={setContinueButton}
      />

      {openModalDataPolicy && (
        <ModalDataPolicy isOpen={openModalDataPolicy} onClose={handleCloseModalDataPolicy} policie={policie} />
      )}

      {isModalUploadOpen && <UploadModal isOpen={isModalUploadOpen} onClose={closeModal} startStep={startStep} />}

      {filteredPoliciesData.length > 10 && (
        <div className={`${selectedIndexes.length > 0 ? 'mb-32' : 'mb-20'} sm:mb-4`}>
          <Pagination
            totalItems={filteredPoliciesData.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
            onItemsPerPageChange={(itemsPerPage) => handleItemsPerPageChange(itemsPerPage)}
            nameListing="policies"
          />
        </div>
      )}
    </>
  );
};

export default Book;
