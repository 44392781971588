export const amsValues = [
  'Agency Matrix',
  'AgencyPro',
  'Applied Epic',
  'Applied TAM',
  'EbixASP',
  'eVo',
  'EZLynx',
  'Hawksoft',
  'INSTAR',
  'Insurancepro',
  'NASA Eclipse',
  'Newton',
  'Nexsure',
  'NowCerts',
  'Partner Platform',
  'Quomation',
  'SIS Partner XE',
  'Special Agent',
  'The Agency Advantage',
  'Vertafore AMS360',
  'Vertafore QQCatalyst',
  'Vertafore Sagitta',
  'Veruna',
  'Xanatek',
  'Other',
  'Other Paper Based',
];
