import { BASEREQUESTOPTIONS } from 'src/api/_constants';
import { MessageThreads, createMessageResponse, postMessageResponse } from 'src/types/messages';

export const getMessages = async (): Promise<MessageThreads> => {
  const response = await fetch('/trantor/message-threads', BASEREQUESTOPTIONS);
  return response.json();
};

export const createMessage = async (
  messageContent: string,
  recipientUid: string,
  sliceUid: string | null,
  listingUid: string | null
): Promise<createMessageResponse> => {
  const body = {
    messageThread: {
      recipientUid,
      sliceUid,
      listingUid,
      message: {
        content: messageContent,
      },
    },
  };

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
    body: JSON.stringify(body),
  };
  const response = await fetch(`/trantor/message-threads`, requestOptions);
  return response.json();
};

export const createPostMessage = async (
  messageContent: string,
  messageThreadUid: string
): Promise<postMessageResponse> => {
  const body = {
    message: {
      content: messageContent,
    },
  };

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
    body: JSON.stringify(body),
  };
  const response = await fetch(`/trantor/message-threads/${messageThreadUid}/messages`, requestOptions);
  return response.json();
};

export const archiveMessages = async (messageThreadUid: string): Promise<createMessageResponse> => {
  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
  };
  const response = await fetch(`/trantor/message-threads/${messageThreadUid}/archive`, requestOptions);
  return response.json();
};
