import React from 'react';
import { Button } from '../Buttons/Button';

const ModalDisclaimer = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="fixed top-0 p-0 left-0 w-full h-full backdrop-blur-md bg-black/50 flex justify-center justify-items-center z-[100]">
      <div className="bg-white rounded-lg w-full sm:w-1/2 h-fit my-auto p-6">
        <h1 className="text-4xl font-medium text-center">Sorry, we are working on this feature</h1>
        <p className="text-base font-medium p-0 m-0 text-center">
          Thanks for your interest. We're working hard to deliver this feature, but it's not quite ready. We'll let you
          know when it is!
        </p>
        <div className="mx-auto mt-4 w-fit">
          <Button onClick={onClose} variant="fill" color="secondary">
            Back to Create account
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalDisclaimer;
