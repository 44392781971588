// States.tsx
import React from 'react';
import { ReactComponent as Alabama } from '../../../../styles/assets/icons/states/AL.svg';
import { ReactComponent as Alaska } from '../../../../styles/assets/icons/states/AK.svg';
import { ReactComponent as Arizona } from '../../../../styles/assets/icons/states/AZ.svg';
import { ReactComponent as Arkansas } from '../../../../styles/assets/icons/states/AR.svg';
import { ReactComponent as California } from '../../../../styles/assets/icons/states/CA.svg';
import { ReactComponent as Colorado } from '../../../../styles/assets/icons/states/CO.svg';
import { ReactComponent as Connecticut } from '../../../../styles/assets/icons/states/CT.svg';
import { ReactComponent as Delaware } from '../../../../styles/assets/icons/states/DE.svg';
import { ReactComponent as Florida } from '../../../../styles/assets/icons/states/FL.svg';
import { ReactComponent as Georgia } from '../../../../styles/assets/icons/states/GA.svg';
import { ReactComponent as Hawaii } from '../../../../styles/assets/icons/states/HI.svg';
import { ReactComponent as Idaho } from '../../../../styles/assets/icons/states/ID.svg';
import { ReactComponent as Illinois } from '../../../../styles/assets/icons/states/IL.svg';
import { ReactComponent as Indiana } from '../../../../styles/assets/icons/states/IN.svg';
import { ReactComponent as Iowa } from '../../../../styles/assets/icons/states/IA.svg';
import { ReactComponent as Kansas } from '../../../../styles/assets/icons/states/KS.svg';
import { ReactComponent as Kentucky } from '../../../../styles/assets/icons/states/KY.svg';
import { ReactComponent as Louisiana } from '../../../../styles/assets/icons/states/LA.svg';
import { ReactComponent as Maine } from '../../../../styles/assets/icons/states/ME.svg';
import { ReactComponent as Maryland } from '../../../../styles/assets/icons/states/MD.svg';
import { ReactComponent as Massachusetts } from '../../../../styles/assets/icons/states/MA.svg';
import { ReactComponent as Michigan } from '../../../../styles/assets/icons/states/MI.svg';
import { ReactComponent as Minnesota } from '../../../../styles/assets/icons/states/MN.svg';
import { ReactComponent as Mississippi } from '../../../../styles/assets/icons/states/MS.svg';
import { ReactComponent as Missouri } from '../../../../styles/assets/icons/states/MO.svg';
import { ReactComponent as Montana } from '../../../../styles/assets/icons/states/MT.svg';
import { ReactComponent as Nebraska } from '../../../../styles/assets/icons/states/NE.svg';
import { ReactComponent as Nevada } from '../../../../styles/assets/icons/states/NV.svg';
import { ReactComponent as NewHampshire } from '../../../../styles/assets/icons/states/NH.svg';
import { ReactComponent as NewJersey } from '../../../../styles/assets/icons/states/NJ.svg';
import { ReactComponent as NewMexico } from '../../../../styles/assets/icons/states/NM.svg';
import { ReactComponent as NewYork } from '../../../../styles/assets/icons/states/NY.svg';
import { ReactComponent as NorthCarolina } from '../../../../styles/assets/icons/states/NC.svg';
import { ReactComponent as NorthDakota } from '../../../../styles/assets/icons/states/ND.svg';
import { ReactComponent as Ohio } from '../../../../styles/assets/icons/states/OH.svg';
import { ReactComponent as Oklahoma } from '../../../../styles/assets/icons/states/OK.svg';
import { ReactComponent as Oregon } from '../../../../styles/assets/icons/states/OR.svg';
import { ReactComponent as Pennsylvania } from '../../../../styles/assets/icons/states/PA.svg';
import { ReactComponent as RhodeIsland } from '../../../../styles/assets/icons/states/RI.svg';
import { ReactComponent as SouthCarolina } from '../../../../styles/assets/icons/states/SC.svg';
import { ReactComponent as SouthDakota } from '../../../../styles/assets/icons/states/SD.svg';
import { ReactComponent as Tennessee } from '../../../../styles/assets/icons/states/TN.svg';
import { ReactComponent as Texas } from '../../../../styles/assets/icons/states/TX.svg';
import { ReactComponent as Utah } from '../../../../styles/assets/icons/states/UT.svg';
import { ReactComponent as Vermont } from '../../../../styles/assets/icons/states/VT.svg';
import { ReactComponent as Virginia } from '../../../../styles/assets/icons/states/VA.svg';
import { ReactComponent as Washington } from '../../../../styles/assets/icons/states/WA.svg';
import { ReactComponent as WestVirginia } from '../../../../styles/assets/icons/states/WV.svg';
import { ReactComponent as Wisconsin } from '../../../../styles/assets/icons/states/WI.svg';
import { ReactComponent as Wyoming } from '../../../../styles/assets/icons/states/WY.svg';
import { ReactComponent as US } from '../../../../styles/assets/icons/states/USA.svg';

interface StatesProps {
  label: string;
}

const States: React.FC<StatesProps> = ({ label }) => {
  switch (label) {
    case 'Alabama':
      return <Alabama className="w-full h-full" />;
    case 'Alaska':
      return <Alaska className="w-full h-full" />;
    case 'Arizona':
      return <Arizona className="w-full h-full" />;
    case 'Arkansas':
      return <Arkansas className="w-full h-full" />;
    case 'California':
      return <California className="w-full h-full" />;
    case 'Colorado':
      return <Colorado className="w-full h-full" />;
    case 'Connecticut':
      return <Connecticut className="w-full h-full" />;
    case 'Delaware':
      return <Delaware className="w-full h-full" />;
    case 'Florida':
      return <Florida className="w-full h-full" />;
    case 'Georgia':
      return <Georgia className="w-full h-full" />;
    case 'Hawaii':
      return <Hawaii className="w-full h-full" />;
    case 'Idaho':
      return <Idaho className="w-full h-full" />;
    case 'Illinois':
      return <Illinois className="w-full h-full" />;
    case 'Indiana':
      return <Indiana className="w-full h-full" />;
    case 'Iowa':
      return <Iowa className="w-full h-full" />;
    case 'Kansas':
      return <Kansas className="w-full h-full" />;
    case 'Kentucky':
      return <Kentucky className="w-full h-full" />;
    case 'Louisiana':
      return <Louisiana className="w-full h-full" />;
    case 'Maine':
      return <Maine className="w-full h-full" />;
    case 'Maryland':
      return <Maryland className="w-full h-full" />;
    case 'Massachusetts':
      return <Massachusetts className="w-full h-full" />;
    case 'Michigan':
      return <Michigan className="w-full h-full" />;
    case 'Minnesota':
      return <Minnesota className="w-full h-full" />;
    case 'Mississippi':
      return <Mississippi className="w-full h-full" />;
    case 'Missouri':
      return <Missouri className="w-full h-full" />;
    case 'Montana':
      return <Montana className="w-full h-full" />;
    case 'Nebraska':
      return <Nebraska className="w-full h-full" />;
    case 'Nevada':
      return <Nevada className="w-full h-full" />;
    case 'New Hampshire':
      return <NewHampshire className="w-full h-full" />;
    case 'New Jersey':
      return <NewJersey className="w-full h-full" />;
    case 'New Mexico':
      return <NewMexico className="w-full h-full" />;
    case 'New York':
      return <NewYork className="w-full h-full" />;
    case 'North Carolina':
      return <NorthCarolina className="w-full h-full" />;
    case 'North Dakota':
      return <NorthDakota className="w-full h-full" />;
    case 'Ohio':
      return <Ohio className="w-full h-full" />;
    case 'Oklahoma':
      return <Oklahoma className="w-full h-full" />;
    case 'Oregon':
      return <Oregon className="w-full h-full" />;
    case 'Pennsylvania':
      return <Pennsylvania className="w-full h-full" />;
    case 'Rhode Island':
      return <RhodeIsland className="w-full h-full" />;
    case 'South Carolina':
      return <SouthCarolina className="w-full h-full" />;
    case 'South Dakota':
      return <SouthDakota className="w-full h-full" />;
    case 'Tennessee':
      return <Tennessee className="w-full h-full" />;
    case 'Texas':
      return <Texas className="w-full h-full" />;
    case 'Utah':
      return <Utah className="w-full h-full" />;
    case 'Vermont':
      return <Vermont className="w-full h-full" />;
    case 'Virginia':
      return <Virginia className="w-full h-full" />;
    case 'Washington':
      return <Washington className="w-full h-full" />;
    case 'West Virginia':
      return <WestVirginia className="w-full h-full" />;
    case 'Wisconsin':
      return <Wisconsin className="w-full h-full" />;
    case 'Wyoming':
      return <Wyoming className="w-full h-full" />;
    default:
      return <US className="w-full h-full" />;
  }
};

export default States;
