import React from 'react';

interface AvatarProps {
  src?: string | '';
  alt?: string;
  size: 'sm' | 'md' | 'md2' | 'lg' | 'lg2' | 'xl';
  userName?: string;
}

const sizeClasses = {
  sm: 'w-5 h-5',
  md2: 'w-8 h-8',
  md: 'w-10 h-10',
  lg: 'w-14 h-14',
  lg2: 'md:w-16 md:h-16 w-10 h-10',
  xl: 'w-20 h-20',
};

const Avatar: React.FC<AvatarProps> = ({ src, alt, size, userName }) => {
  const avatarSizeClasses = sizeClasses[size];

  return (
    <>
      {!src ? (
        <div
          className={`relative inline-flex items-center justify-center ${avatarSizeClasses} overflow-hidden bg-yellow-500 rounded-full`}
        >
          <span
            className={`${
              size === 'md' || size == 'md2' || size === 'lg2' ? 'text-sm' : 'text-xl'
            } font-bold text-core-black uppercase`}
          >
            {userName}
          </span>
        </div>
      ) : (
        <div className={`${avatarSizeClasses}`}>
          <img className={`rounded-full object-cover ${avatarSizeClasses}`} src={src} alt={alt} />
        </div>
      )}
    </>
  );
};

export default Avatar;
