import React, { useEffect, useState } from 'react';
import { ReactComponent as EyeSlashIcon } from '../../../../styles/assets/icons/outline/eyeSlashOutline.svg';
import { ReactComponent as EyeIcon } from '../../../../styles/assets/icons/outline/eyeOutline.svg';
import { Button } from 'src/app/shared/components/Buttons/Button';
import TextareaComponent from 'src/app/shared/components/Textarea/Textarea';
import Input from 'src/app/shared/components/Inputs/Input';
import Select from 'src/app/shared/components/Inputs/Select';
import { useGetBuyerProfile, usePatchBuyerProfile } from 'src/hooks/useBuyersProfile';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';
import { BProfile } from 'src/types/BuyerProfile';
import { Form, Formik } from 'formik';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import InputMultiple from 'src/app/shared/components/Inputs/InputMultiple';
import ModalFilter from 'src/app/shared/components/Filters/ModalFilter';
import {
  CarrierListingOptions,
  CommercialListValues,
  PersonalListValues,
  UsStatesOptions,
} from 'src/app/shared/ListingEnums';
import { FilterOptions } from 'src/types/domain/Filters';
import { useGetSubscription } from 'src/hooks/useSubscription';
import { useHistory } from 'react-router';
import { useGetUser } from 'src/hooks/useOnboarding';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

type EnumType = { [key: string]: string };

interface FormValues {
  totalPremiumMin: number;
  totalPremiumMax: number;
  profileDescription: string;
  buyingTimeline: string;
  buyingSlices: string;
  profilePublished: boolean;
  stateFilters: string[];
  carrierFilters: string[];
  lobFilters: string[];
}

const BuyerProfile = () => {
  const history = useHistory();
  const { data: buyerProfileResponse, isPending, isFetching, refetch } = useGetBuyerProfile();
  const { mutate: updateUser, isSuccess, isPending: isPendingUpdateUser } = usePatchBuyerProfile();
  const [formEdited, setFormEdited] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const { alerts, createToast } = useAlert();
  const [selectedValuesStates, setSelectedValuesStates] = useState<string[]>(
    buyerProfileResponse?.buyerProfile.stateFilters ? buyerProfileResponse?.buyerProfile.stateFilters : []
  );
  const [selectedValuesCarriers, setSelectedValuesCarriers] = useState<string[]>(
    buyerProfileResponse?.buyerProfile.carrierFilters ? buyerProfileResponse?.buyerProfile.carrierFilters : []
  );
  const [selectedValuesLob, setSelectedValuesLob] = useState<string[]>(
    buyerProfileResponse?.buyerProfile.lobFilters ? buyerProfileResponse?.buyerProfile.lobFilters : []
  );
  const [selectedValuesStatesShort, setSelectedValuesStatesShort] = useState<string[]>(
    buyerProfileResponse?.buyerProfile.stateFilters ? buyerProfileResponse?.buyerProfile.stateFilters : []
  );
  const [isFilterReset, setIsFilterReset] = useState(false);
  const [refreshDropdownComponent, setRefreshDropdownComponent] = useState(false);
  const allListValues = [...CommercialListValues, ...PersonalListValues];
  const [selectedItems, setSelectedItems] = useState([]);

  const { data: userResponse, isPending: userIsPending, error: userError } = useGetUser();
  const { data: subscriptionResponse, isPending: subscriptionPending, error: subscriptionError } = useGetSubscription();
  const [subscriptionName, setSubscriptionName] = useState<string>('Starter');

  useEffect(() => {
    if (!isPending && buyerProfileResponse) {
      setSelectedValuesStates(buyerProfileResponse?.buyerProfile.stateFilters || []);
      setSelectedValuesCarriers(buyerProfileResponse?.buyerProfile.carrierFilters || []);
      setSelectedValuesLob(buyerProfileResponse?.buyerProfile.lobFilters || []);
    }
  }, [isPending, buyerProfileResponse]);

  const showSuccessMessage = () => {
    setButtonsDisabled(true);
    createToast({ text: 'Account has been updated successfully', variant: 'success' });
  };

  useEffect(() => {
    if (!subscriptionPending && !subscriptionError && subscriptionResponse) {
      setSubscriptionName(subscriptionResponse.userSubscription);
    }
  }, [subscriptionPending, subscriptionError, subscriptionResponse]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  const optionsReadliness = [
    { value: 'Ready to buy', label: 'Ready to buy' },
    { value: 'Exploring options', label: 'Exploring options' },
    { value: 'No timeline', label: 'No timeline' },
  ];

  const optionsOpenToSlices = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  if (isPending && subscriptionPending) {
    return (
      <div className="w-full mb-12 z-0">
        <Skeleton type="form-details" />
      </div>
    );
  }

  const initialValues = {
    totalPremiumMin: buyerProfileResponse?.buyerProfile.totalPremiumMin || 0,
    totalPremiumMax: buyerProfileResponse?.buyerProfile.totalPremiumMax || 0,
    profileDescription: buyerProfileResponse?.buyerProfile.profileDescription as string,
    buyingTimeline: buyerProfileResponse?.buyerProfile.buyingTimeline as string,
    buyingSlices: buyerProfileResponse?.buyerProfile.buyingSlices as string,
    profilePublished: buyerProfileResponse?.buyerProfile.profilePublished || false,
    stateFilters: buyerProfileResponse?.buyerProfile.stateFilters || [],
    carrierFilters: buyerProfileResponse?.buyerProfile.carrierFilters || [],
    lobFilters: buyerProfileResponse?.buyerProfile.lobFilters || [],
  };

  const onSubmit = async (values: FormValues) => {
    try {
      const transformedData: BProfile = {
        uid: buyerProfileResponse?.buyerProfile.uid || '',
        buyingTimeline: values.buyingTimeline,
        buyingSlices: values.buyingSlices,
        profileDescription: values.profileDescription,
        profileDescriptionApproved: buyerProfileResponse?.buyerProfile.profileDescriptionApproved || false,
        totalPremiumMin: values.totalPremiumMin || 0,
        totalPremiumMax: values.totalPremiumMax || 0,
        profilePublished: values.profilePublished,
        stateFilters: selectedValuesStates.map((shortState) => {
          for (const [shortCode, shortName] of Object.entries(UsStatesOptions)) {
            if (shortName === shortState) {
              return shortCode;
            }
          }
          return '';
        }),

        carrierFilters: selectedValuesCarriers,
        lobFilters: selectedValuesLob,
      };

      updateUser(transformedData);
      setFormEdited(false);
      showSuccessMessage();
    } catch (error) {
      createToast({ text: 'We have not been able to update the data', variant: 'danger' });
      console.error('Error data:', error);
    }
  };

  const determineListValues = (): string[] => {
    return allListValues.map((item) => item.description);
  };

  const handleGotoPricingPage = () => {
    history.push(`/account/pricing`);
  };

  const renderCustomSelectOptions = (listingsEnumObj: EnumType, showCode = false): FilterOptions[] => {
    const entries = Object.entries(listingsEnumObj);
    const listValues = determineListValues();

    return entries.map(([value, label]) => {
      const item = allListValues.find((c) => c.code === value);

      if (item && item.description && showCode) {
        return {
          label: `${value} | ${label}`,
          value: listValues.includes(item.description) ? item.description : undefined,
        };
      } else {
        return {
          label,
          value: listValues.includes(label) ? label : undefined,
        };
      }
    });
  };

  function convertArrayToEnumType(arr: { code: string; description: string }[]): EnumType {
    const enumType: EnumType = {};
    arr.forEach((item) => {
      enumType[item.code] = item.description;
    });
    return enumType;
  }

  const commercialEnum: EnumType = convertArrayToEnumType(CommercialListValues);
  const personalEnum: EnumType = convertArrayToEnumType(PersonalListValues);

  const renderAllOptions = (): FilterOptions[] => {
    return renderCustomSelectOptions({ ...commercialEnum, ...personalEnum }, true);
  };

  const customSelectFilterOptions = {
    type: renderAllOptions(),
    carrier: renderCustomSelectOptions(CarrierListingOptions, true),
    usState: renderCustomSelectOptions(UsStatesOptions),
  };

  const handlePublicMyProfile = async (publicValue: boolean) => {
    const updatedProfilePublished = publicValue;

    try {
      const transformedData: BProfile = {
        uid: buyerProfileResponse?.buyerProfile.uid || '',
        buyingTimeline: buyerProfileResponse?.buyerProfile.buyingTimeline || '',
        buyingSlices: buyerProfileResponse?.buyerProfile.buyingSlices || '',
        profileDescription: buyerProfileResponse?.buyerProfile.profileDescription || '',
        profileDescriptionApproved: buyerProfileResponse?.buyerProfile.profileDescriptionApproved || false,
        totalPremiumMin: buyerProfileResponse?.buyerProfile.totalPremiumMin || 0,
        totalPremiumMax: buyerProfileResponse?.buyerProfile.totalPremiumMax || 0,
        profilePublished: updatedProfilePublished,
        stateFilters: buyerProfileResponse?.buyerProfile.stateFilters || [],
        carrierFilters: buyerProfileResponse?.buyerProfile.carrierFilters || [],
        lobFilters: buyerProfileResponse?.buyerProfile.lobFilters || [],
      };

      updateUser(transformedData);
      showSuccessMessage();
    } catch (error) {
      createToast({ text: 'We have not been able to update the data', variant: 'danger' });
      console.error('Error data:', error);
    }
  };

  const handleAddFilters = (selectedFilters: string[], filterType: string) => {
    if (filterType === 'usStateFilters') {
      setSelectedValuesStatesShort(selectedItems.map((item: any) => item.value));
      setSelectedValuesStates(selectedItems.map((item: any) => item.value));
    } else if (filterType === 'carrierFilters') {
      setSelectedValuesCarriers(selectedFilters);
    } else if (filterType === 'lineOfBusinessFilters') {
      setSelectedValuesLob(selectedFilters);
    }
    setIsFilterReset(true);
    setRefreshDropdownComponent(true);
  };

  const handleSaveBuyerProfile = (selectedItems: any, filterType: string) => {
    setFormEdited(true);
    setButtonsDisabled(false);
    setSelectedItems(selectedItems);
    if (filterType === 'usStateFilters') {
      setSelectedValuesStates(selectedItems.map((item: any) => item.value));
    } else if (filterType === 'carrierFilters') {
      setSelectedValuesCarriers(selectedItems.map((item: any) => item.value));
    } else if (filterType === 'lineOfBusinessFilters') {
      setSelectedValuesLob(selectedItems.map((item: any) => item.value));
    }
    if (selectedItems && selectedItems.length > 0) {
      if (selectedItems[0].type === 'usStateFilters') {
        setSelectedValuesStates(selectedItems.map((item: any) => item.value));
      } else if (selectedItems[0].type === 'carrierFilters') {
        setSelectedValuesCarriers(selectedItems.map((item: any) => item.value));
      } else if (selectedItems[0].type === 'lineOfBusinessFilters') {
        setSelectedValuesLob(selectedItems.map((item: any) => item.value));
      }
    }
  };

  const renderFullStateName = (shortState: string) => {
    if (shortState in UsStatesOptions) {
      return UsStatesOptions[shortState as keyof typeof UsStatesOptions];
    } else {
      return shortState;
    }
  };

  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize>
        {({ values, handleChange }) => (
          <>
            <Form>
              <input type="hidden" value={formEdited ? '1' : '0'} />
              <div className="w-full flex mb-6 z-0">
                <div className="w-1/2 flex justify-start">
                  <h1 className="text-core-black text-2xl text-left ">Buyer Profile</h1>
                </div>

                <div className="flex justify-end w-1/2">
                  <div>
                    <Button variant="fill" color="secondary" type="submit" disabled={buttonsDisabled}>
                      Save
                    </Button>
                  </div>
                </div>
              </div>

              {!buttonsDisabled && (
                <div className="w-full p-3 bg-[#FFF6E8] border-l-4 border-l-system-warning1 rounded-[4px] mb-4 relative">
                  <div className="w-full flex gap-2 relative">
                    <ExclamationTriangleIcon className="text-core-black h-5 w-5 my-auto" />
                    <p className="text-core-black p-0 m-0 text-left text-sm font-bold">You have unsaved changes</p>
                  </div>
                </div>
              )}

              <div className="w-full flex flex-col">
                <h2 className="text-lg font-medium text-core-black mb-8 text-left">Status</h2>

                {isPendingUpdateUser || isFetching ? (
                  <Skeleton type="status-card" />
                ) : (
                  <div className="w-full border rounded border-neutral-200 px-10 py-8 text-left flex flex-col md:flex-row">
                    <div className="md:w-1/2 flex flex-col w-full">
                      {buyerProfileResponse?.buyerProfile.profilePublished ? (
                        <div className="my-auto">
                          <p className="flex gap-2 text-2xl font-medium m-0 p-0">
                            <EyeIcon className="h-6 w-6 my-auto" /> Published
                          </p>
                        </div>
                      ) : (
                        <>
                          <p className="flex gap-2 text-2xl font-medium">
                            <EyeSlashIcon className="h-6 w-6 my-auto" /> Unpublished
                          </p>

                          {subscriptionName !== 'Pro' && !userResponse?.userProfile.hasActiveListing ? (
                            <>
                              <span>
                                Your profile is currently invisible to sellers and not included in our matching engine.
                                <strong className="ml-1">Upgrade to Pro</strong> and publish your profile to start
                                connecting with sellers!
                              </span>
                            </>
                          ) : (
                            <>
                              {userResponse?.userProfile.hasActiveListing || subscriptionName === 'Pro' ? (
                                <span>
                                  Your profile is currently invisible to sellers and not included in our matching
                                  engine. Publish your profile to start connecting with sellers!
                                </span>
                              ) : (
                                <span>
                                  Your profile is currently invisible to sellers and not included in our matching
                                  engine.
                                  <strong className="ml-1">Upgrade to Pro</strong> and publish your profile to start
                                  connecting with sellers!
                                </span>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>

                    {subscriptionName === 'Pro' || userResponse?.userProfile.hasActiveListing ? (
                      <>
                        {buyerProfileResponse?.buyerProfile.profilePublished ? (
                          <div className="md:w-1/2 w-full flex md:justify-end sm:mt-3 my-auto">
                            <Button
                              variant="outline"
                              color="primary"
                              width="w-fit"
                              onClick={() => handlePublicMyProfile(false)}
                            >
                              Unpublish my Profile
                            </Button>
                          </div>
                        ) : (
                          <div className="md:w-1/2 w-full flex md:justify-end mt-3 md:mt-0">
                            <Button
                              variant="fill"
                              color="primary"
                              width="w-fit"
                              onClick={() => handlePublicMyProfile(true)}
                            >
                              Publish my Profile
                            </Button>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="md:w-1/2 w-full flex md:justify-end mt-3 md:mt-0">
                        <Button variant="fill" color="primary" width="w-fit" onClick={handleGotoPricingPage}>
                          Upgrade to Pro
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="w-full flex-col mt-12 space-y-10">
                <div className="w-full">
                  <h2 className="text-lg font-medium text-core-black mb-8 text-left">Slice Preferences</h2>
                </div>

                <div className="w-full flex">
                  <div className="text-left w-full flex flex-col justify-start">
                    <h3 className="text-neutral-700 font-bold text-xs">STATES</h3>
                    <div className="w-1/2">
                      {selectedValuesStates.length > 0 && !selectedValuesStates.every((value) => value === '') ? (
                        <InputMultiple
                          values={selectedValuesStates.map(renderFullStateName)}
                          name="stateFilters"
                          onChange={(e: any) => {
                            handleChange(e);
                            setFormEdited(true);
                            setButtonsDisabled(false);
                          }}
                        />
                      ) : (
                        <span className="text-xs text-core-black font-light">Open to All</span>
                      )}
                    </div>
                  </div>

                  <div className="w-fit flex justify-end">
                    <ModalFilter
                      title="Edit"
                      filterName="States"
                      filterReset={isFilterReset}
                      refreshDropdownComponent={refreshDropdownComponent}
                      filterType={'usStateFilters'}
                      options={customSelectFilterOptions.usState}
                      onRefreshComplete={() => {
                        setRefreshDropdownComponent(false);
                        setIsFilterReset(false);
                      }}
                      handleAddFiltersLocalStorage={() => handleAddFilters(selectedValuesStates, 'usStateFilters')}
                      variant="Button"
                      onSaveBuyerProfile={handleSaveBuyerProfile}
                      buyerPillsSelected={selectedValuesStates.map(renderFullStateName)}
                    />
                  </div>
                </div>

                <div className="w-full flex">
                  <div className="text-left w-full flex flex-col justify-start">
                    <h3 className="text-neutral-700 font-bold text-xs">CARRIERS</h3>
                    {selectedValuesCarriers.length > 0 && !selectedValuesCarriers.every((value) => value === '') ? (
                      <InputMultiple
                        values={selectedValuesCarriers}
                        name="carrierFilters"
                        onChange={(e: any) => {
                          handleChange(e);
                          setFormEdited(true);
                          setButtonsDisabled(false);
                        }}
                      />
                    ) : (
                      <span className="text-xs text-core-black font-light">Open to All</span>
                    )}
                  </div>

                  <div className="w-fit flex justify-end">
                    <ModalFilter
                      title="Edit"
                      filterName="Carriers"
                      filterReset={isFilterReset}
                      refreshDropdownComponent={refreshDropdownComponent}
                      filterType={'carrierFilters'}
                      options={customSelectFilterOptions.carrier}
                      onRefreshComplete={() => {
                        setRefreshDropdownComponent(false);
                        setIsFilterReset(false);
                      }}
                      handleAddFiltersLocalStorage={() => handleAddFilters(selectedValuesCarriers, 'carrierFilters')}
                      variant="Button"
                      onSaveBuyerProfile={handleSaveBuyerProfile}
                      buyerPillsSelected={selectedValuesCarriers}
                    />
                  </div>
                </div>

                <div className="w-full flex">
                  <div className="text-left w-full flex flex-col justify-start">
                    <h3 className="text-neutral-700 font-bold text-xs">LINES OF BUSINESS</h3>
                    {selectedValuesLob.length > 0 && !selectedValuesLob.every((value) => value === '') ? (
                      <InputMultiple
                        values={selectedValuesLob}
                        name="lobFilters"
                        onChange={(e: any) => {
                          handleChange(e);
                          setFormEdited(true);
                          setButtonsDisabled(false);
                        }}
                      />
                    ) : (
                      <span className="text-xs text-core-black font-light">Open to All</span>
                    )}
                  </div>

                  <div className="w-fit flex justify-end">
                    <ModalFilter
                      title="Edit"
                      filterName="Lines of business"
                      filterReset={isFilterReset}
                      refreshDropdownComponent={refreshDropdownComponent}
                      filterType={'lineOfBusinessFilters'}
                      options={customSelectFilterOptions.type}
                      onRefreshComplete={() => {
                        setRefreshDropdownComponent(false);
                        setIsFilterReset(false);
                      }}
                      handleAddFiltersLocalStorage={() => handleAddFilters(selectedValuesLob, 'lineOfBusinessFilters')}
                      variant="Button"
                      onSaveBuyerProfile={handleSaveBuyerProfile}
                      buyerPillsSelected={selectedValuesLob}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-col mt-12 space-y-10">
                <div className="w-full">
                  <h2 className="text-lg font-medium text-core-black mb-8 text-left">More Attributes</h2>
                  <div className="w-full flex md:flex-row flex-col space-y-3 md:space-y-0 gap-3 isolation-auto">
                    <Select
                      options={optionsReadliness}
                      labelInput="READINESS"
                      isRequiredInput={true}
                      name="buyingTimeline"
                      value={values.buyingTimeline}
                      onChange={(e) => {
                        handleChange(e);
                        setFormEdited(true);
                        setButtonsDisabled(false);
                      }}
                    />
                    <Select
                      options={optionsOpenToSlices}
                      labelInput="OPEN TO SLICES"
                      name="buyingSlices"
                      value={values.buyingSlices}
                      isRequiredInput={true}
                      onChange={(e) => {
                        handleChange(e);
                        setFormEdited(true);
                        setButtonsDisabled(false);
                      }}
                    />
                  </div>
                </div>

                <div className="w-full">
                  <h2 className="text-lg font-medium text-core-black mb-8 text-left">Premium Range</h2>
                  <div className="w-full md:flex-row flex-col space-y-4 md:space-y-0 md:w-1/2 flex gap-4">
                    <Input
                      type="number"
                      labelInput="MINIMUM PREMIUM"
                      placeholder="$"
                      name="totalPremiumMin"
                      isRequiredInput={true}
                      value={values.totalPremiumMin.toString()}
                      onChange={(e) => {
                        handleChange(e);
                        setFormEdited(true);
                        setButtonsDisabled(false);
                      }}
                    />
                    <Input
                      type="number"
                      labelInput="MAXIMUM PREMIUM"
                      placeholder="$"
                      isRequiredInput={true}
                      name="totalPremiumMax"
                      value={values.totalPremiumMax.toString()}
                      onChange={(e) => {
                        handleChange(e);
                        setFormEdited(true);
                        setButtonsDisabled(false);
                      }}
                    />
                  </div>
                </div>

                <div className="w-full">
                  <h2 className="text-lg font-medium text-core-black mb-8 text-left gap-3 flex">
                    Agency Description{' '}
                    {buyerProfileResponse?.buyerProfile.profileDescriptionApproved ? (
                      <span className="rounded border border-neutral-200 px-2 py-1 font-bold text-sm text-core-toastSuccess uppercase">
                        Verified
                      </span>
                    ) : (
                      <span className="rounded border border-neutral-200 px-2 py-1 font-bold text-sm text-core-toastErro uppercases">
                        Not verified
                      </span>
                    )}
                  </h2>
                  <div className="md:w-1/2 w-full">
                    <TextareaComponent
                      height="h-36"
                      labelInput="DESCRIPTION"
                      placeholderText="Enter a description here"
                      showCharacterCount={false}
                      isRequiredInput={true}
                      name="profileDescription"
                      value={values.profileDescription}
                      onChange={(e) => {
                        handleChange(e);
                        setFormEdited(true);
                        setButtonsDisabled(false);
                      }}
                    />
                  </div>
                </div>
              </div>
              {alerts}
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default BuyerProfile;
