import React, { useEffect, useState } from 'react';
import { Onboarding } from '../components/Onboarding';
import ModalFilter from 'src/app/shared/components/Filters/ModalFilter';
import { UsStatesOptions } from 'src/app/shared/ListingEnums';
import { FilterOptions } from 'src/types/domain/Filters';
import InputMultiple from 'src/app/shared/components/Inputs/InputMultiple';
import { useFormik } from 'formik';
import { useGetBuyerProfile, usePatchBuyerProfile } from 'src/hooks/useBuyersProfile';
import { useHistory } from 'react-router';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';

type EnumType = { [key: string]: string };

const StatesBuyerProfile = () => {
  const history = useHistory();
  const { data: buyerProfileResponse, isPending } = useGetBuyerProfile();
  const [, setBuyerProfile] = useState(buyerProfileResponse);
  const { mutate, isSuccess } = usePatchBuyerProfile();
  const [selectedValuesStates, setSelectedValuesStates] = useState<string[]>([]);

  useEffect(() => {
    if (buyerProfileResponse) {
      setBuyerProfile(buyerProfileResponse);
      setSelectedValuesStates(buyerProfileResponse.buyerProfile.stateFilters || []);
    }
  }, [buyerProfileResponse]);

  const renderCustomSelectOptions = (listingsEnumObj: EnumType): FilterOptions[] => {
    const entries = Object.entries(listingsEnumObj);

    return entries.map(([value, label]) => {
      return {
        value,
        label: `${label}`,
      };
    });
  };

  const customSelectFilterOptions = {
    usState: renderCustomSelectOptions(UsStatesOptions),
  };

  const handleAddFilters = (selectedFilters: string[], filterType: string) => {
    if (filterType === 'usStateFilters') {
      setSelectedValuesStates(selectedFilters);
    }
  };

  const handleSaveBuyerProfile = (selectedItems: any, filterType: string) => {
    if (filterType === 'usStateFilters') {
      setSelectedValuesStates(selectedItems.map((item: any) => item.value));
    }
    if (selectedItems && selectedItems.length > 0) {
      if (selectedItems[0].type === 'usStateFilters') {
        setSelectedValuesStates(selectedItems.map((item: any) => item.value));
      }
    }
  };

  const renderFullStateName = (shortState: string) => {
    if (shortState in UsStatesOptions) {
      return UsStatesOptions[shortState as keyof typeof UsStatesOptions];
    } else {
      return shortState;
    }
  };

  useEffect(() => {
    setValues({
      stateFilters: selectedValuesStates as never[],
    });
  }, [selectedValuesStates]);

  const { handleChange, handleSubmit, setValues } = useFormik({
    initialValues: {
      stateFilters: selectedValuesStates,
    },
    onSubmit: () => {
      const shortStates = selectedValuesStates.map((shortState) => {
        for (const [shortCode, shortName] of Object.entries(UsStatesOptions)) {
          if (shortName === shortState) {
            return shortCode;
          }
        }
        return '';
      });
      mutate({ stateFilters: shortStates });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      history.push('/onboarding/buyer-profile/carriers');
    }
  }, [isSuccess, history]);

  if (isPending) {
    return (
      <div className="w-full mb-12 z-0">
        <Skeleton type="step-onboarding" />
      </div>
    );
  }

  return (
    <Onboarding
      handleSubmit={handleSubmit}
      isContinue={true}
      isBack={true}
      isStep={true}
      numOfSteps={7}
      stepPosition={1}
    >
      <div className="w-full md:w-3/5 items-center justify-center h-screen mx-auto block py-20 px-8 md:px-32 text-left gap-8 mt-10">
        <div className="w-full">
          <p className="text-center text-sm text-gray-600 font-bold mx-0 p-0 mb-8">BUILD YOUR BUYER PROFILE</p>
        </div>

        <div className="w-full mb-12 mt-8">
          <h1 className="text-core-black text-3xl md:text-5xl font-medium text-center">
            What states are you interested in?
          </h1>
        </div>

        <div className="w-full">
          <ModalFilter
            title="Add States"
            filterName="States"
            filterType={'usStateFilters'}
            options={customSelectFilterOptions.usState}
            handleAddFiltersLocalStorage={() => handleAddFilters(selectedValuesStates, 'usStateFilters')}
            variant="Card"
            CardSubtitle="States"
            buyerPillsSelected={selectedValuesStates.map(renderFullStateName)}
            onSaveBuyerProfile={handleSaveBuyerProfile}
          >
            <div className="w-full">
              {selectedValuesStates.length > 0 && !selectedValuesStates.every((value) => value === '') ? (
                <InputMultiple
                  values={selectedValuesStates.map(renderFullStateName)}
                  name="stateFilters"
                  onChange={handleChange}
                  variant="basic"
                />
              ) : null}
            </div>
          </ModalFilter>
        </div>

        <div className="w-full mt-6">
          <p className="text-gray-700 text-base font-medium p-0 m-0 text-center">
            Open to all or have no preference? Click <strong className="text-core-black">‘Continue’</strong>.
          </p>
        </div>
      </div>
    </Onboarding>
  );
};

export default StatesBuyerProfile;
