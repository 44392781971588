import React from 'react';
import checkIcon from '../../../../../styles/assets/icons/mini/CheckIcon.svg';
import { Button } from 'src/app/shared/components/Buttons/Button';
import { useHistory } from 'react-router';

const PayWall = () => {
  const history = useHistory();

  const handleGotoPricingPage = () => {
    history.push(`/account/pricing`);
  };

  const handleChatWithUs = () => {
    window.location.href = 'mailto:support@haveyourcake.com';
  };

  return (
    <div className="w-full flex flex-wrap gap-3">
      <div className="rounded-lg border-[1px] border-neutral-200 p-8 w-full bg-core-white">
        <div className="w-full md:w-[440px] mx-auto">
          <h1 className="font-medium text-core-black text-lg md:text-2xl mb-1 text-center">
            Upgrade to unlock listing analytics and communicate with sellers
          </h1>
          <p className="font-medium text-sm text-center text-gray-900">Your get everything in Starter, plus</p>

          <div className="w-full md:flex gap-3 mt-6">
            <ul className="w-1/2 space-y-4 p-0 m-0">
              <li className="w-full flex text-gray-900 font-medium text-base gap-1 whitespace-nowrap">
                <img src={checkIcon} className="h-3 w-3 my-auto" />
                Private and secure chat
              </li>
              <li className="w-full flex text-gray-900 font-medium text-base gap-1 whitespace-nowrap">
                <img src={checkIcon} className="h-3 w-3 my-auto" />
                Personal deal room
              </li>
            </ul>

            <ul className="w-1/2 space-y-4 p-0 md:m-0">
              <li className="w-full flex text-gray-900 font-medium text-base gap-1 whitespace-nowrap mt-3 sm:!mt-0">
                <img src={checkIcon} className="h-3 w-3 my-auto" />
                Buyer Profile
              </li>
              <li className="w-full flex text-gray-900 font-medium text-base gap-1 whitespace-nowrap">
                <img src={checkIcon} className="h-3 w-3 my-auto" />
                More coming soon!
              </li>
            </ul>
          </div>

          <div className="w-full md:flex gap-3 mt-7">
            <div className="w-full md:w-1/2">
              <Button type="button" variant="fill" color="primary" onClick={handleGotoPricingPage}>
                Upgrade to Pro
              </Button>
            </div>

            <div className="w-full md:w-1/2 mt-2 md:!mt-0">
              <Button type="button" variant="outline" color="primary" onClick={handleChatWithUs}>
                Chat with us
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full space-y-3 md:space-y-0 md:flex gap-6 mt-4">
        {[...Array(2)].map((_, indexCard) => (
          <div key={indexCard} className="w-full md:w-1/2 bg-white rounded-lg p-6">
            <div className="bg-neutral-100 w-[140px] p-[10px]"></div>
            <div className="w-[240px] h-[240px] mx-auto my-6 rounded-full border-[40px] border-neutral-100">
              <div className="bg-core-white h-[45px] w-[1px] p-1 -mt-10 ml-[70px]"></div>
              <div className="bg-core-white h-[45px] w-[1px] p-1 -mt-3 ml-[150px] rotate-[50deg]"></div>
              <div className="bg-core-white h-[45px] w-[1px] p-1 -mt-[80px] ml-[100px] rotate-[12deg]"></div>
              <div className="bg-core-white h-[45px] w-[1px] p-1 mt-[155px] ml-[80px]"></div>
              <div className="bg-core-white h-[45px] w-[1px] p-1 -mt-[225px] ml-[20px] rotate-[140deg]"></div>
              <div className="bg-core-white h-[45px] w-[1px] p-1 mt-[105px] ml-[5px] rotate-[220deg]"></div>
            </div>
            {[...Array(3)].map((_, index) => (
              <div
                key={index}
                className="grid grid-cols-3 py-2 gap-3 my-2 border-b-[1px] border-gray-200 last:border-b-[0px]"
              >
                {[...Array(3)].map((_, innerIndex) => (
                  <div key={innerIndex} className="w-full p-[10px] bg-neutral-100"></div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="w-full space-y-4">
        <div className="w-full rounded-lg bg-white p-6">
          <div className="bg-neutral-100 w-[140px] p-[10px]"></div>
          {[...Array(3)].map((_, index) => (
            <div
              key={index}
              className="grid grid-cols-3 py-2 gap-3 my-2 border-b-[1px] border-gray-200 last:border-b-[0px]"
            >
              <div className="w-full md:w-80 p-[10px] bg-neutral-100"></div>
              {[...Array(2)].map((_, innerIndex) => (
                <div key={innerIndex} className="w-full md:w-40 p-[10px] bg-neutral-100"></div>
              ))}
            </div>
          ))}
        </div>

        <div className="w-full rounded-lg bg-white p-6">
          <div className="bg-neutral-100 w-[140px] p-[10px]"></div>
          <div className="w-full mt-10 md:flex">
            <div className="w-full md:w-[30%]">
              <div className="h-[250px] w-[250px] rounded-full border-[40px] border-neutral-100 bg-core-lightGrey">
                <div className="bg-core-white h-[45px] w-[1px] p-1 -mt-[24px] ml-[20px] rotate-[140deg]"></div>
                <div className="bg-core-white h-[45px] w-[1px] p-1 mt-[130px] ml-[20px] rotate-[2200deg]"></div>
              </div>
            </div>
            <div className="w-full md:w-[70%] my-auto">
              {[...Array(3)].map((_, index) => (
                <div
                  key={index}
                  className="grid grid-cols-3 py-2 gap-3 my-2 border-b-[1px] border-gray-200 last:border-b-[0px]"
                >
                  <div className="w-full md:w-52 p-[10px] bg-neutral-100"></div>
                  {[...Array(2)].map((_, innerIndex) => (
                    <div key={innerIndex} className="w-full md:w-32 p-[10px] bg-neutral-100"></div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayWall;
