import React, { FC, useState } from 'react';
import { Form, Formik } from 'formik';
import Input from 'src/app/shared/components/Inputs/Input';
import { Button } from 'src/app/shared/components/Buttons/Button';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import { EditNameSlices } from 'src/api/policies';

interface EditSliceFormProps {
  sliceData: any | undefined;
  onSuccess: (success: boolean) => void;
}

const EditSliceForm: FC<EditSliceFormProps> = ({ sliceData, onSuccess }) => {
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const { alerts, createToast } = useAlert();

  const initialValues = {
    sliceNameValue: sliceData.name || '',
  };

  const onSubmit = async (values: any) => {
    setButtonsDisabled(false);
    try {
      await EditNameSlices(sliceData.uid, values.sliceNameValue, sliceData.groupType);
      createToast({ text: 'Slice updated', variant: 'success' });
      setButtonsDisabled(false);
      setTimeout(() => {
        onSuccess(true);
      }, 5000);
    } catch (error) {
      console.log(error);
      onSuccess(false);
      createToast({
        text: 'Invalid action please try again.',
        variant: 'danger',
      });
    }
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize>
      {({ values, handleChange }) => (
        <>
          <Form className="w-full h-fit bg-white">
            <Input
              type="text"
              labelInput="SLICE NAME"
              placeholder="Enter the slice name"
              isRequiredInput={true}
              name="sliceNameValue"
              onChange={(e) => {
                handleChange(e);
                setButtonsDisabled(false);
              }}
              value={values.sliceNameValue || ''}
            />

            <div className="w-full absolute bottom-1 border-t p-6 right-0">
              <Button variant="fill" color="secondary" type="submit" disabled={buttonsDisabled}>
                Save
              </Button>
            </div>
            {alerts}
          </Form>
        </>
      )}
    </Formik>
  );
};

export default EditSliceForm;
