import React from 'react';

type Color = 'primary' | 'secondary' | 'social' | 'terceary' | 'border';
type Variant = 'fill' | 'outline';

interface VariantClasses {
  [key: string]: {
    [key in Color]: string[];
  };
}

export interface ButtonProps {
  children: React.ReactNode;
  color?: Color;
  disabled?: boolean;
  href?: string;
  icon?: string;
  variant?: Variant;
  type?: 'button' | 'submit' | 'reset' | undefined;
  width?: string;
  onClick?: () => void;
}

const baseClasses = [
  'flex flex-row min-w-fit items-center justify-center rounded disabled:cursor-not-allowed cursor-auto py-2 px-4 h-fit',
];

const variantClasses: VariantClasses = {
  outline: {
    primary: [
      'border',
      'disabled:bg-gray-400',
      'disabled:border-gray-400',
      'bg-transparent',
      'border-black',
      'text-core-black',
    ],
    border: ['bg-core-yellow', 'text-core-black', 'disabled:bg-gray-400', 'disabled:text-gray-50', 'border-2'],
    secondary: [
      'bg-white',
      'text-core-black',
      'uppercase',
      'border',
      'border-white',
      'hover:border-core-black',
      'transition',
      'ease-in',
      'font-bolder',
    ],
    terceary: ['border-2', 'bg-white', 'border-gey-400', 'rounded-[2px]'],
    social: ['border', 'text-white', 'disabled:bg-gray-400', 'disabled:text-gray-50', 'whitespace-nowrap'],
  },
  fill: {
    primary: ['bg-core-yellow', 'text-core-black', 'disabled:bg-gray-400', 'disabled:text-gray-50'],
    border: [
      'bg-core-yellow',
      'text-core-black',
      'disabled:bg-gray-400',
      'disabled:text-gray-50',
      'border-2',
      'border-core-yellow',
    ],
    secondary: [
      'border-2',
      'border-core-black',
      'bg-core-black',
      'text-white',
      'disabled:bg-gray-400',
      'disabled:border-gray-400',
      'disabled:text-gray-50',
      'whitespace-nowrap',
    ],
    terceary: ['border-1', 'bg-white', 'border-gey-400', 'rounded-[4px]'],
    social: [
      'border-1',
      'border-core-pointer',
      'bg-core-pointer',
      'text-white',
      'disabled:bg-gray-400',
      'disabled:text-gray-50',
      'whitespace-nowrap',
      'flex',
      'gap-3',
      'items-center',
      'text-sm',
    ],
  },
};

export const Button: React.FC<ButtonProps> = ({ children, color, variant, width = 'w-full', ...providedProps }) => {
  const buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement> = providedProps;
  if (providedProps.type === undefined) {
    buttonProps.type = 'button';
  }

  if (providedProps.disabled === undefined) {
    buttonProps.disabled = false;
  }

  const classList = baseClasses.concat(variantClasses[variant || 'fill'][color || 'primary'] || []);

  return (
    <button className={classList.join(' ') + ` ${width}`} {...buttonProps} role="button">
      {children}
    </button>
  );
};

export default Button;
