import React, { FC, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Input from 'src/app/shared/components/Inputs/Input';
import { Button } from 'src/app/shared/components/Buttons/Button';
import { createSliceGroup } from 'src/api/policies';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import { Form, Formik } from 'formik';

interface ModalAddSliceProps {
  isOpen: boolean;
  onClose: () => void;
  listingSlices: any;
}

const ModalAddSlice: FC<ModalAddSliceProps> = ({ isOpen, onClose, listingSlices }) => {
  if (!isOpen) {
    return null;
  }

  const { alerts, createToast } = useAlert();
  const [buttonsDisabled, setButtonsDisabled] = useState(true);

  // const colors = [
  //   { name: 'Gray', code: 'bg-gray-500', border: 'border-gray-500' },
  //   { name: 'Core Yellow', code: 'bg-core-yellow', border: 'border-core-yellow' },
  //   { name: 'Blue', code: 'bg-blue-700', border: 'border-blue-700' },
  //   { name: 'Core Aloe', code: 'bg-core-aloe', border: 'border-core-aloe' },
  //   { name: 'Orange', code: 'bg-orange-600', border: 'border-orange-600' },
  //   { name: 'Dark Gray', code: 'bg-gray-700', border: 'border-gray-700' },
  //   { name: 'Red', code: 'bg-red-800', border: 'border-red-800' },
  // ];

  // const [selectedColor, setSelectedColor] = useState(-1);

  // const handleColorSelect = (index: number) => {
  //   setSelectedColor(index);
  // };

  const onSubmit = async (values: any) => {
    setButtonsDisabled(true);
    try {
      const groupType = values.groupType;
      const policy_uid = listingSlices;
      await createSliceGroup(values.name, groupType, policy_uid);
      createToast({ text: 'Slice created!', variant: 'success' });
      setTimeout(() => {
        onClose();
        window.location.href = '/book-management#slices';
      }, 5000);
    } catch (error: any) {
      console.log(error);
      createToast({ text: error.message, variant: 'danger' });
      setButtonsDisabled(false);
    }
  };

  const initialValues = {
    name: '',
    groupType: 'group',
  };

  return (
    <div
      onClick={onClose}
      className="fixed top-0 z-50 left-0 w-full h-full flex justify-center items-center backdrop-blur-md bg-black/50 overflow-hidden"
    >
      <Formik onSubmit={onSubmit} enableReinitialize initialValues={initialValues} className="w-full">
        {({ values, handleChange }) => (
          <>
            <Form className="w-full mx-auto">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="bg-white w-full rounded shadow-lg md:w-[536px] h-fit p-6 flex flex-col mx-auto"
              >
                <div className="w-full flex">
                  <div className="w-11/12 gap-3">
                    <h1 className="text-core-black font-medium text-xl p-0 m-0 whitespace-nowrap text-left">
                      Finish your slice
                    </h1>
                  </div>
                  <div className="w-1/12 flex justify-end">
                    <button
                      type="button"
                      onClick={onClose}
                      className="rounded-full p-2 h-8 w-8 bg-neutral-50 hover:bg-neutral-100 text-core-black transition ease-in"
                    >
                      <XMarkIcon className="h-4 w-4" />
                    </button>
                  </div>
                </div>

                <div className="py-6 text-left w-full">
                  <p className="p-0 m-p text-lg font-medium">Give your slice a name</p>
                  <Input
                    type="text"
                    labelInput="Slice name"
                    isRequiredInput={true}
                    placeholder="Enter slice name"
                    name="name"
                    value={values.name || ''}
                    onChange={(e) => {
                      handleChange(e);
                      setButtonsDisabled(false);
                    }}
                  />
                  <p className="p-0 m-0 text-gray-700 text-xs font-medium">Buyers will not see this information</p>
                </div>

                {/* To implement in a future version 
                <div className="py-2 text-left w-full">
                  <p className="p-0 m-p text-lg font-medium">Select a color code</p>
                  <div className="w-full flex space-x-5">
                    {colors.map((color, index) => (
                      <button
                        key={index}
                        className={`p-2 border-1 hover:border-blue-500 transition ease-in rounded-[4px] ${
                          selectedColor === index ? 'border-blue-500' : 'border-transparent'
                        }`}
                        onClick={() => handleColorSelect(index)}
                      >
                        <div
                          className={`h-2.5 w-2.5 ${color.code} -mb-2.5 ${color.border} border-2 z-10 isolate rounded-tl-full`}
                        ></div>
                        <div className="h-5 w-5 rounded-full border-2 border-gray-400 border-dashed"></div>
                      </button>
                    ))}
                  </div>
                </div> */}

                <div className="pt-4 w-full flex justify-end gap-3">
                  <div className="w-fit">
                    <Button variant="outline" color="primary" type="button" onClick={onClose}>
                      Cancel
                    </Button>
                  </div>
                  <div className="w-fit">
                    <Button variant="fill" color="primary" type="submit" disabled={buttonsDisabled}>
                      Create
                    </Button>
                  </div>
                </div>
                {alerts}
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ModalAddSlice;
