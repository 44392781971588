import { XMarkIcon } from '@heroicons/react/24/solid';
import { Form, Formik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { Button } from 'src/app/shared/components/Buttons/Button';
import Input from 'src/app/shared/components/Inputs/Input';
import useAlert from 'src/app/shared/components/Toast/useAlert';

interface ModalEditBookValuationProps {
  onClose: () => void;
  onSuccess: (success: boolean) => void;
}

const ModalEditBookValuation: FC<ModalEditBookValuationProps> = ({ onClose, onSuccess }) => {
  const { alerts, createToast } = useAlert();
  const [success, setSuccess] = useState(false);
  const localStorageAssumedCommission = localStorage.getItem('assumedCommission');
  const localStorageUniqueCustomers = localStorage.getItem('uniqueCustomers');

  const initialValues = {
    assumedCommission: localStorageAssumedCommission !== null ? localStorageAssumedCommission : '',
    uniqueCustomers: localStorageUniqueCustomers !== null ? localStorageUniqueCustomers : '',
  };

  const onSubmit = async (values: any) => {
    localStorage.setItem('assumedCommission', values.assumedCommission);
    localStorage.setItem('uniqueCustomers', values.uniqueCustomers);
    createToast({ text: 'Your configuration is saved successfully', variant: 'success' });
    setSuccess(true);
    setTimeout(() => {
      onClose();
    }, 3000);
  };

  useEffect(() => {
    if (success) {
      onSuccess(true);
    }
  }, [success, onSuccess]);

  return (
    <div
      onClick={onClose}
      className="fixed top-0 z-50 left-0 w-full h-full flex justify-center items-center backdrop-blur-md bg-black/50"
    >
      <div
        className="bg-white p-6 rounded shadow-lg w-[500px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="w-full flex mb-3">
          <div className="w-11/12 gap-3">
            <h1 className="text-core-black font-medium text-xl p-0 m-0 whitespace-wrap text-left">Edit Valuation</h1>
          </div>
          <div className="w-1/12 flex justify-end mb-1">
            <button
              onClick={onClose}
              className="rounded-full p-2 h-8 w-8 bg-neutral-50 hover:bg-neutral-100 text-core-black transition ease-in"
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          </div>
        </div>

        <Formik className="w-full" initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, handleChange }) => (
            <>
              <Form className="flex flex-col space-y-4">
                <Input
                  type="text"
                  labelInput="Commission Rate Override"
                  placeholder="Enter commission rate (%)"
                  isRequiredInput={false}
                  name="assumedCommission"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={values.assumedCommission || ''}
                />

                <Input
                  type="text"
                  labelInput="Unique Customers Override"
                  placeholder="Enter unique customers"
                  isRequiredInput={false}
                  name="uniqueCustomers"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={values.uniqueCustomers || ''}
                />

                <div className="w-full flex justify-end gap-3 mt-4">
                  <div>
                    <Button variant="outline" color="primary" onClick={onClose}>
                      Cancel
                    </Button>
                  </div>

                  <div>
                    <Button variant="fill" color="secondary" type="submit">
                      Save
                    </Button>
                  </div>
                </div>
                {alerts}
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ModalEditBookValuation;
