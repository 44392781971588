import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useGetPortalSubscription, useGetSubscription } from 'src/hooks/useSubscription';
import Button from 'src/app/shared/components/Buttons/Button';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';
import { useGetUser } from 'src/hooks/useOnboarding';

const Subscriptions = () => {
  const history = useHistory();
  const { data: userResponse } = useGetUser();
  const { data: subscriptionResponse, isPending: subscriptionPending, error: subscriptionError } = useGetSubscription();
  const stackEnvFromLocalStorage = localStorage.getItem('stackEnv');
  const isProduction = stackEnvFromLocalStorage === 'prod';

  const [subscriptionName, setSubscriptionName] = useState<string>('Starter');
  const [subscriptionPortal, setSubscriptionPortal] = useState<any | null>(null);
  const { data: subscriptionPortalData } = useGetPortalSubscription(
    isProduction
      ? 'https://app.haveyourcake.com/account#subscriptions'
      : 'https://app.demo.haveyourcake.ai/account#subscriptions'
  );

  const handleGotoPricingPage = () => {
    history.push(`/account/pricing`);
  };

  const handleGotoManagePage = () => {
    if (subscriptionPortal) {
      window.open(subscriptionPortal.redirect, '_blank');
    }
  };

  useEffect(() => {
    if (!subscriptionPending && !subscriptionError && subscriptionResponse) {
      setSubscriptionName(subscriptionResponse.userSubscription);
    }
  }, [subscriptionPending, subscriptionError, subscriptionResponse]);

  useEffect(() => {
    const fetchData = async () => {
      if (subscriptionName === 'Pro') {
        try {
          setSubscriptionPortal(subscriptionPortalData);
        } catch (error) {
          console.error('Error fetching portal subscription:', error);
        }
      }
    };

    fetchData();
  }, [subscriptionName, subscriptionPortalData]);
  return (
    <>
      {subscriptionPending ? (
        <Skeleton type="subscriptionTab" />
      ) : (
        <>
          <div className="w-full">
            <h1 className="text-core-black text-2xl text-left">Subscriptions</h1>
          </div>

          <div className="w-full flex flex-col mt-12">
            <h2 className="text-lg font-medium text-core-black mb-6 text-left">Current subscription</h2>

            <div className="w-full border rounded border-neutral-200 px-10 py-8 text-left flex flex-col md:flex-row">
              {userResponse?.userProfile.hasActiveListing ? (
                <div className="md:w-1/2 flex flex-col w-full">
                  <h3 className="text-2xl font-medium">Seller</h3>
                </div>
              ) : (
                <>
                  <div className="md:w-1/2 flex flex-col w-full">
                    <h3 className="text-2xl font-medium">
                      {subscriptionName === 'Pro'
                        ? 'Pro'
                        : subscriptionName === 'Enterprise'
                        ? 'Enterprise'
                        : 'Starter'}
                    </h3>
                    {subscriptionName !== 'Pro' && subscriptionName !== 'Enterprise' ? (
                      <span className="text-lg font-medium">Free</span>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="md:w-1/2 w-full flex md:justify-end md:!mt-0 mt-3">
                    <>
                      {subscriptionName !== 'Pro' ? (
                        <Button
                          variant="fill"
                          color="primary"
                          width="md:w-fit w-full"
                          type="button"
                          onClick={handleGotoPricingPage}
                        >
                          Upgrade to Pro
                        </Button>
                      ) : (
                        <Button
                          variant="outline"
                          color="primary"
                          width="md:w-fit w-full"
                          type="button"
                          onClick={handleGotoManagePage}
                        >
                          Manage with Stripe
                        </Button>
                      )}
                    </>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Subscriptions;
