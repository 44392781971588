import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Onboarding } from '../components/Onboarding';
import { BASIC_PROFILE_COMTINUE_SECOND_TITLE, BASIC_PROFILE_CONTINUE_TITLE2 } from 'src/constants/labels';
import { useOnboardingStore } from 'src/app/store/store';
import { useFormik } from 'formik';
import { usePatchUser } from 'src/hooks';
import Input from 'src/app/shared/components/Inputs/Input';
import { amsValues } from 'src/constants/amsValues';
import { networkValues } from 'src/constants/networkValues';
import Select from 'src/app/shared/components/Inputs/Select';
import posthog from 'posthog-js';

export const BasicInfoContinue: FC = () => {
  const history = useHistory();
  const { userInfo } = useOnboardingStore();

  const { mutate, isSuccess, error, isPending } = usePatchUser();
  const [errorMessage, setErrorMessage] = useState<string>();

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      businessAgeYrs: userInfo?.agencyInfo.business_age_yrs || 0,
      npn: userInfo?.agencyInfo.npn || 0,
      ams: userInfo?.agencyInfo.ams || '',
      privateNetworkEntry: userInfo?.agencyInfo.private_network_entry || '',
    },
    onSubmit: (values) => {
      mutate({ ...values, businessAgeYrs: values.businessAgeYrs || 0, npn: values.npn || 0 });
    },
  });

  const transformedAmsValues = amsValues.map((value) => ({ value, label: value }));
  const transformedNetworkValues = networkValues.map((value) => ({ value, label: value }));

  useEffect(() => {
    if (isSuccess) {
      if (posthog.isFeatureEnabled('book-upload-onboard-test')) {
        history.push('/onboarding/book-upload/');
      } else {
        history.push('/onboarding/interests');
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (userInfo?.userProfile.onboardingComplete) {
      history.push('/onboarding/completed');
    }
  }, [userInfo]);
  return (
    <Onboarding handleSubmit={handleSubmit} isBack={true} isLoading={isPending} isContinue={true}>
      <div className="flex flex-col lg:w-11/12  sm:w-12/12 m-auto h-full">
        <div className="flex flex-col lg:w-6/12 mx-auto mt-32 md:mt-40">
          <div className="flex flex-col lg:w-10/12 sm:w-full px-4 m-auto h-full text-center">
            <h3 className="m-auto lg:text-5xl sm:text-4xl">{`${BASIC_PROFILE_CONTINUE_TITLE2}`}</h3>
            <p className="text-base text-core-grey mt-3 lg:px-20 sm:px-8 xs:px-0">
              {BASIC_PROFILE_COMTINUE_SECOND_TITLE}
            </p>
          </div>
          <form className="w-full mt-2 pb-5">
            <div className="w-8/12 m-auto flex flex-row justify-center mt-3">
              <Input
                type="text"
                placeholder={`${values.businessAgeYrs || ' Years in the Business (optional)'}`}
                name="businessAgeYrs"
                onChange={handleChange}
                value={values.businessAgeYrs || ''}
                labelInput="Years experience"
              />
            </div>
            <div className="w-8/12 m-auto flex flex-row  mt-3 justify-center">
              <Input
                type="text"
                placeholder={`${values.npn || 'National Producer Number (optional)'}`}
                name="npn"
                onChange={handleChange}
                value={values.npn || ''}
                labelInput="National Producer Number"
              />
            </div>
            <div className="w-8/12 m-auto flex flex-row  mt-2 justify-center">
              <Select
                options={transformedAmsValues}
                name="ams"
                value={values.ams}
                onChange={handleChange}
                positionLast={true}
                labelInput="Agency Managment System"
                placeholderOption="Agency Managment System (optional)"
              />
            </div>
            <div className="w-8/12 m-auto flex flex-row  mt-3 justify-center">
              <Select
                options={transformedNetworkValues}
                name="privateNetworkEntry"
                value={values.privateNetworkEntry}
                onChange={handleChange}
                positionLast={true}
                labelInput="Association / Network Membership"
                placeholderOption="Association / Network Membership (optional)"
              />
            </div>
          </form>
        </div>
        <div className="flex w-full">
          <span className="text-system-negative text-sm ml-auto mr-auto mt-2">{errorMessage}</span>
        </div>
      </div>
    </Onboarding>
  );
};
