import React, { FC } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface ConfirmDisconnectProps {
  onClose: () => void;
  onDisconnect: (values: any) => void;
  values: any;
}

const ModalConfirmDisconnect: FC<ConfirmDisconnectProps> = ({ onClose, onDisconnect, values }) => {
  return (
    <div
      onClick={onClose}
      className="fixed top-0 z-50 left-0 w-full h-full flex justify-center items-center backdrop-blur-md bg-black/50"
    >
      <div
        className="bg-white p-6 rounded shadow-lg w-[600px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="w-full flex">
          <div className="w-11/12 gap-3">
            <h1 className="text-core-black font-medium text-xl p-0 m-0">
              Are you sure you want to {values.status === 'Enabled' ? 'disconnect' : 'connect'} this integration?
            </h1>
          </div>
          <div className="w-1/12 flex justify-end mb-3">
            <button
              onClick={onClose}
              className="rounded-full p-2 h-8 w-8 bg-neutral-50 hover:bg-neutral-100 text-core-black transition ease-in"
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          </div>
        </div>

        <div className="flex justify-center gap-2">
          <button
            type="button"
            className="px-4 py-2 bg-white text-gray-800 rounded hover:bg-gray-300"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="px-4 py-2 bg-core-black text-white rounded"
            onClick={() => onDisconnect(values)}
          >
            {values.status === 'Enabled' ? 'Disconnect' : 'Connect'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmDisconnect;
