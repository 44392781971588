import React, { FC, ReactNode, useState } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as XMarkIcon } from '../../../../styles/assets/icons/mini/xMark.svg';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
  childrenList?: ReactNode;
  childrenInput?: ReactNode;
  ModalType?: string;
  countItemsPerFilter?: number;
  onSave: (value: string) => void;
}

const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  ModalType,
  childrenInput,
  countItemsPerFilter,
  onSave,
}) => {
  const [selectedType] = useState<string>('');

  document.body.style.overflow = isOpen ? 'hidden' : 'auto';

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="fixed top-0 px-3 sm:px-0 left-0 w-full h-full backdrop-blur-md bg-black/50 flex justify-center justify-items-center z-[100]">
      <div className="bg-white rounded-lg w-full sm:w-1/2 h-fit my-auto">
        <div className="w-full flex flex-col border-b border-neutral-300 p-3">
          <div className="flex w-full">
            <div className="w-11/12">
              <p className="text-core-black font-normal text-[24px] capitalize my-0">{ModalType}</p>
            </div>
            <div className="w-1/12 flex justify-end -mt-5">
              <button className="my-auto" onClick={onClose}>
                <XMarkIcon className={`my-auto w-5 h-5 text-core-black group-hover:text-white`} />
              </button>
            </div>
          </div>

          {childrenInput ? <div className="w-full">{childrenInput}</div> : null}
        </div>
        <div className="w-full px-8 py-2 h-[400px] overflow-y-auto">{children}</div>

        <div className="w-full border-t flex border-neutral-300 p-3 justify-end gap-3">
          {countItemsPerFilter !== 0 && (
            <div className="flex w-full my-auto justify-start">
              <p className="m-0 p-0 text-xs">{countItemsPerFilter} items selected</p>
            </div>
          )}

          <button
            className="uppercase border border-core-black text-core-black bg-white px-4 py-2.5 text-sm rounded"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="uppercase text-white bg-core-black text-sm text-center px-4 py-2.5 rounded"
            onClick={() => {
              onClose();
              onSave(selectedType);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
