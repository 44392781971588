import React, { FC, ReactElement, useEffect, useState } from 'react';
import Breadcrumb from 'src/app/shared/components/Breadcrumb/Breadcrumb';
import Tabs from 'src/app/shared/components/Tabs/Tabs';
import BuyerProfile from './components/BuyerProfile';
import BasicDetails from './components/BasicDetail';
import Subscriptions from './components/Subscriptions';
import Security from './components/Login';
import { useHistory, useLocation } from 'react-router';
import Integrations from './components/Integrations';
import { resetAppStoreState } from 'src/app/shared/utils/resetAppStoreState';
import { userSignOut } from 'src/api/user';
import { useAppDispatch } from 'src/app/hooks';
import { ArrowRightStartOnRectangleIcon } from '@heroicons/react/24/outline';

interface Tab {
  label: string;
  content: ReactElement;
  labelUrl: string;
}

export const UserSettings: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number) => {
    const labelUrl = tabs[index]?.labelUrl || '';
    history.push(`#${labelUrl}`);
    setActiveTab(index);
  };

  const handleSignOut = async (): Promise<void> => {
    await dispatch(userSignOut);
    resetAppStoreState(dispatch);
  };

  const tabs: Tab[] = [
    {
      label: 'Basic Details',
      content: <BasicDetails />,
      labelUrl: 'basic-details',
    },
    {
      label: 'Buyer Profile',
      content: <BuyerProfile />,
      labelUrl: 'buyer-profile',
    },
    {
      label: 'Subscriptions',
      content: <Subscriptions />,
      labelUrl: 'subscriptions',
    },
    {
      label: 'Integrations',
      content: <Integrations />,
      labelUrl: 'integrations',
    },
    {
      label: 'Login',
      content: <Security />,
      labelUrl: 'Login',
    },
  ].filter((tab) => !!tab) as Tab[];

  useEffect(() => {
    const currentLabelUrl = location.hash.replace('#', '');
    const foundTabIndex = tabs.findIndex((tab) => tab?.labelUrl === currentLabelUrl);

    if (foundTabIndex !== -1) {
      setActiveTab(foundTabIndex);
    }
  }, [location.hash, tabs]);

  return (
    <div className="p-3 w-full mb-20 md:mb-0 h-fit">
      <div className="w-full flex">
        <Breadcrumb title="Account" />
        <div className="w-1/2 flex justify-end">
          <div>
            <button
              className="uppercase items-center text-sm flex text-core-black font-bold gap-2 hover:bg-neutral-50 rounded-[4px] transition ease-in py-2 px-3"
              onClick={handleSignOut}
            >
              Sign out
              <ArrowRightStartOnRectangleIcon className="h-5 w-5 my-auto" />
            </button>
          </div>
        </div>
      </div>

      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} variant={true} />
    </div>
  );
};

export default UserSettings;
