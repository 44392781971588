import React, { useEffect, useState } from 'react';
import { Button } from 'src/app/shared/components/Buttons/Button';
import PublishIcon from '../../../styles/assets/logos/publishIcon.svg';
import { Onboarding } from '../components/Onboarding';
import { useGetSubscription, useGetUser } from 'src/hooks';
import { useGetBuyerProfile, usePatchBuyerProfile } from 'src/hooks/useBuyersProfile';
import { BProfile } from 'src/types/BuyerProfile';
import useAlert from 'src/app/shared/components/Toast/useAlert';

const PublishProfile = () => {
  const { data: userResponse } = useGetUser();
  const { data: subscriptionResponse, isPending: subscriptionPending, error: subscriptionError } = useGetSubscription();
  const { mutate: updateUser, isSuccess } = usePatchBuyerProfile();
  const [subscriptionName, setSubscriptionName] = useState<string>('Starter');
  const { data: buyerProfileResponse } = useGetBuyerProfile();
  const { alerts, createToast } = useAlert();

  useEffect(() => {
    if (!subscriptionPending && !subscriptionError && subscriptionResponse) {
      setSubscriptionName(subscriptionResponse.userSubscription);
    }
  }, [subscriptionPending, subscriptionError, subscriptionResponse]);

  const showSuccessMessage = () => {
    createToast({ text: 'Account has been updated successfully', variant: 'success' });
  };

  const handlePublicMyProfile = async (publicValue: boolean) => {
    const updatedProfilePublished = publicValue;
    try {
      const transformedData: BProfile = {
        uid: buyerProfileResponse?.buyerProfile.uid || '',
        buyingTimeline: buyerProfileResponse?.buyerProfile.buyingTimeline || '',
        buyingSlices: buyerProfileResponse?.buyerProfile.buyingSlices || '',
        profileDescription: buyerProfileResponse?.buyerProfile.profileDescription || '',
        profileDescriptionApproved: buyerProfileResponse?.buyerProfile.profileDescriptionApproved || false,
        totalPremiumMin: buyerProfileResponse?.buyerProfile.totalPremiumMin || 0,
        totalPremiumMax: buyerProfileResponse?.buyerProfile.totalPremiumMax || 0,
        profilePublished: updatedProfilePublished,
        stateFilters: buyerProfileResponse?.buyerProfile.stateFilters || [],
        carrierFilters: buyerProfileResponse?.buyerProfile.carrierFilters || [],
        lobFilters: buyerProfileResponse?.buyerProfile.lobFilters || [],
      };

      updateUser(transformedData);
      showSuccessMessage();
    } catch (error) {
      createToast({ text: 'We have not been able to update the data', variant: 'danger' });
      console.error('Error data:', error);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      window.location.href = '/slices';
    }
  }, [isSuccess]);

  const handleGotoPricingPage = () => {
    window.location.href = '/account/pricing';
  };

  const handleSkipPublish = () => {
    window.location.href = '/slices';
  };
  return (
    <Onboarding>
      <div className="w-full px-10 md:px-0 md:w-2/5 mx-auto flex justify-center items-center flex-col h-screen">
        <p className="text-gray-600 text-center text-sm font-bold uppercase">One last thing</p>
        <img src={PublishIcon} className="h-44 w-44 mx-auto block my-6" />
        <h1 className="text-2xl md:text-5xl font-medium text-center">Publish your profile to connect with sellers!</h1>
        <p className="p-0 my-3 mx-0 text-center text-gray-700 text-md md:text-base font-medium">
          Publish your buyer profile and unlock full access to our features and tools, including private and secure
          chat.
        </p>

        <div className="mx-auto flex flex-col gap-2 mt-3 dix-fit">
          {subscriptionName === 'Pro' || userResponse?.userProfile.hasActiveListing ? (
            <Button variant="fill" color="primary" onClick={() => handlePublicMyProfile(true)}>
              Publish my profile
            </Button>
          ) : (
            <Button variant="fill" color="primary" onClick={handleGotoPricingPage}>
              Upgrade to Pro
            </Button>
          )}
          <Button variant="outline" color="primary" onClick={handleSkipPublish}>
            Skip for now
          </Button>
        </div>
      </div>
      {alerts}
    </Onboarding>
  );
};

export default PublishProfile;
