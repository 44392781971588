import { ArrowLeftIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useState } from 'react';
import Button from 'src/app/shared/components/Buttons/Button';
import { useGetListingById } from 'src/hooks/useGetListings';
import renderSlices from '../../../../styles/assets/logos/Render.svg';
import { useGetSlicesGroup } from 'src/hooks/usePolicies';
import { formatCurrency } from 'src/app/shared/utils/formatters';
import { PostAddGroups, PostRemoveGroups } from 'src/api/policies';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

interface LayoutEditSliceProps {
  uid: string;
  onClose: () => void;
  onSuccess: () => void;
}

const LayoutEditSlices: React.FC<LayoutEditSliceProps> = ({ onClose, onSuccess, uid }) => {
  const { data: listingDetail } = useGetListingById(uid);
  const [openDataInListing, setOpenDataInListing] = useState(true);
  const [openDataUnselected, setOpenDataUnselected] = useState(true);
  const { data: slicesData } = useGetSlicesGroup();
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [initialSelectedGroups, setInitialSelectedGroups] = useState<string[]>([]);
  const { alerts, createToast } = useAlert();

  useEffect(() => {
    if (listingDetail?.listing?.groups) {
      setSelectedGroups(listingDetail.listing.groups.map((group) => group.uid));
      setInitialSelectedGroups(listingDetail.listing.groups.map((group) => group.uid));
    }
  }, [listingDetail]);

  const handleOpenDataInListing = () => {
    setOpenDataInListing(!openDataInListing);
  };

  const handleOpenDataUnselected = () => {
    setOpenDataUnselected(!openDataUnselected);
  };

  const handleCheckboxChange = (groupUid: string) => {
    setSelectedGroups((prevSelected) =>
      prevSelected.includes(groupUid) ? prevSelected.filter((uid) => uid !== groupUid) : [...prevSelected, groupUid]
    );
  };

  const isSaveDisabled = JSON.stringify(selectedGroups) === JSON.stringify(initialSelectedGroups);

  const handleSave = async () => {
    const uidsToRemove = initialSelectedGroups.filter((uid) => !selectedGroups.includes(uid));
    const uidsToAdd = selectedGroups.filter((uid) => !initialSelectedGroups.includes(uid));
    try {
      if (uidsToAdd.length > 0) {
        await PostAddGroups(uid, uidsToAdd);
      }

      if (uidsToRemove.length > 0) {
        await PostRemoveGroups(uid, uidsToRemove);
      }

      onSuccess();
      onClose();
    } catch (error) {
      createToast({
        text: 'Error during save operation',
        variant: 'warning',
      });
      console.error('Error during save operation:', error);
    }
  };

  return (
    <div
      onClick={onClose}
      className="fixed top-0 z-50 left-0 w-full h-full flex justify-center items-center backdrop-blur-md bg-black/50"
    >
      <div
        className="bg-white rounded md:h-[96vh] w-full md:w-[536px] h-[99vh] fixed md:right-3 py-6"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="w-full flex gap-3 px-6 pb-4">
          <div className="w-1/12 flex justify-start my-auto items-center">
            <button
              onClick={onClose}
              className="my-auto p-3 rounded-full bg-neutral-50 cursor-pointer hover:bg-neutral-100 transition ease-in"
            >
              <ArrowLeftIcon className="my-auto w-3 h-3 text-core-black group-hover:text-white" />
            </button>
          </div>

          <div className="w-11/12 gap-3 items-center text-left">
            <h1 className="text-core-black font-medium text-xl p-0 mx-0 whitespace-nowrap text-left my-auto">
              Edit Listing Slices
            </h1>

            <span className="text-gray-600 text-sm font-bold capitalize">{listingDetail?.listing.name}</span>
          </div>
        </div>

        <div className="max-h-[71vh] overflow-y-auto px-6 pb-10">
          <div className="w-full flex flex-col">
            <div className="w-full flex py-0 mt-8 item-center cursor-pointer" onClick={handleOpenDataInListing}>
              <div className="w-1/2">
                <p className="text-core-black text-lg font-medium text-left mx-0">
                  In Listing ({listingDetail?.listing?.groups?.length ?? 0})
                </p>
              </div>
              <div className="w-1/2 flex justify-end">
                {openDataInListing ? (
                  <ChevronUpIcon className="h-4 w-4 my-auto" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4 my-auto" />
                )}
              </div>
            </div>

            <div className={` ${openDataInListing ? '' : 'border-b border-gray-200'} w-full `}>
              {openDataInListing && (
                <div className="w-full flex flex-col space-y-2">
                  {slicesData?.groups?.filter((group) =>
                    listingDetail?.listing?.groups.some((slice) => slice.uid === group.uid)
                  ).length === 0 && (
                    <div className="w-full p-4 h-32 rounded border bg-neutral-50 items-center flex flex-col">
                      <InformationCircleIcon className="h-18 w-18 my-auto text-core-black" />
                      <h1 className="text-base font-medium text-gray-400 text-center my-auto">No slices listed</h1>
                    </div>
                  )}

                  {slicesData?.groups
                    ?.filter((group) => listingDetail?.listing?.groups.some((slice) => slice.uid === group.uid))
                    .map((group) => (
                      <div key={group.uid} className="bg-white rounded-md border shadow-sm p-4">
                        <div className="w-full flex gap-3">
                          <img src={renderSlices} alt="render img slices" className="w-10 h-10 flex justify-start" />

                          <div className="flex items-center w-3/4 justify-between">
                            <div className="text-lg font-medium text-left text-core-black max-w-[300x] truncate">
                              {group.name}
                            </div>
                          </div>

                          <div className="flex justify-end w-3/12 pr-4">
                            <div className="md:w-1/2 w-full my-auto flex justify-end">
                              <input
                                type="checkbox"
                                className="hidden"
                                id={`checkbox-${group.uid}`}
                                checked={selectedGroups.includes(group.uid)}
                                onChange={() => handleCheckboxChange(group.uid)}
                              />
                              <label
                                htmlFor={`checkbox-${group.uid}`}
                                className={`h-5 w-5 my-auto group flex items-center justify-center cursor-pointer border-2 border-gray-600 rounded-[4px] ${
                                  selectedGroups.includes(group.uid) ? 'bg-core-black border-black' : 'bg-white'
                                }`}
                              >
                                {selectedGroups.includes(group.uid) && (
                                  <CheckIcon className="w-3 h-3 text-white stroke-2" />
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="w-full grid grid-cols-3 text-left mt-4">
                          <div>
                            <p className="p-0 m-0 text-gray-700 text-2xs uppercase">Premium</p>
                            <p className="p-0 m-0 text-core-black text-sm uppercase">
                              {formatCurrency(group.totalPremium)}
                            </p>
                          </div>
                          <div>
                            <p className="p-0 m-0 text-gray-700 text-2xs uppercase">Policies</p>
                            <p className="p-0 m-0 text-core-black text-sm uppercase">{group.totalPolicies}</p>
                          </div>
                          <div>
                            <p className="p-0 m-0 text-gray-700 text-2xs uppercase">Customers</p>
                            <p className="p-0 m-0 text-core-black text-sm uppercase">{group.totalCustomers}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>

          <div className="w-full flex flex-col">
            <div className="w-full flex py-0 mt-8 item-center cursor-pointer" onClick={handleOpenDataUnselected}>
              <div className="w-1/2">
                <p className="text-core-black text-lg font-medium text-left mx-0">Unselected</p>
              </div>
              <div className="w-1/2 flex justify-end">
                {openDataUnselected ? (
                  <ChevronUpIcon className="h-4 w-4 my-auto" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4 my-auto" />
                )}
              </div>
            </div>

            <div className={` ${openDataUnselected ? '' : 'border-b border-gray-200'} w-full `}>
              {openDataUnselected && (
                <div className="w-full flex flex-col space-y-2">
                  {slicesData?.groups?.filter(
                    (group) => !listingDetail?.listing?.groups.some((slice) => slice.uid === group.uid)
                  ).length === 0 && (
                    <div className="w-full p-4 h-32 rounded border bg-neutral-50 items-center flex flex-col">
                      <InformationCircleIcon className="h-18 w-18 my-auto text-core-black" />
                      <h1 className="text-base font-medium text-gray-400 text-center my-auto">
                        No slices available to list
                      </h1>
                    </div>
                  )}

                  {slicesData?.groups
                    ?.filter((group) => !listingDetail?.listing?.groups?.some((slice) => slice.uid === group.uid))
                    .map((group) => (
                      <div key={group.uid} className="bg-white rounded-md border shadow-sm p-4">
                        <div className="w-full flex gap-3">
                          <img src={renderSlices} alt="render img slices" className="w-10 h-10 flex justify-start" />

                          <div className="flex items-center w-3/4 justify-between">
                            <div className="text-lg font-medium text-left text-core-black max-w-[300x] truncate">
                              {group.name}
                            </div>
                          </div>

                          <div className="flex justify-end w-3/12 pr-4">
                            <div className="md:w-1/2 w-full my-auto flex justify-end">
                              <input
                                type="checkbox"
                                className="hidden"
                                id={`checkbox-${group.uid}`}
                                checked={selectedGroups.includes(group.uid)}
                                onChange={() => handleCheckboxChange(group.uid)}
                              />
                              <label
                                htmlFor={`checkbox-${group.uid}`}
                                className={`h-5 w-5 my-auto group flex items-center justify-center cursor-pointer border-2 border-gray-600 rounded-[4px] ${
                                  selectedGroups.includes(group.uid) ? 'bg-core-black border-black' : 'bg-white'
                                }`}
                              >
                                {selectedGroups.includes(group.uid) && (
                                  <CheckIcon className="w-3 h-3 text-white stroke-2" />
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="w-full grid grid-cols-3 text-left mt-4">
                          <div>
                            <p className="p-0 m-0 text-gray-700 text-2xs uppercase">Premium</p>
                            <p className="p-0 m-0 text-core-black text-sm uppercase">
                              {formatCurrency(group.totalPremium)}
                            </p>
                          </div>
                          <div>
                            <p className="p-0 m-0 text-gray-700 text-2xs uppercase">Policies</p>
                            <p className="p-0 m-0 text-core-black text-sm uppercase">{group.totalPolicies}</p>
                          </div>
                          <div>
                            <p className="p-0 m-0 text-gray-700 text-2xs uppercase">Customers</p>
                            <p className="p-0 m-0 text-core-black text-sm uppercase">{group.totalCustomers}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="w-full p-4 border-t border-gray-200 rounded-b-lg absolute bottom-0 bg-white flex gap-3 right-0">
          <div className="w-1/2">
            <Button color="primary" variant="outline" onClick={onClose}>
              Cancel
            </Button>
          </div>

          <div className="w-1/2">
            <Button color="secondary" variant="fill" onClick={handleSave} disabled={isSaveDisabled}>
              Save
            </Button>
          </div>
        </div>
      </div>
      {alerts}
    </div>
  );
};

export default LayoutEditSlices;
