import { BASEREQUESTOPTIONS } from 'src/api/_constants';
import { Checkout, Portal, Subscription, User, UserInfo, postPasswordResponse } from 'src/types/User';
import { routes } from './routes';

export const getUser = async (): Promise<UserInfo> => {
  const response = await fetch('/trantor/user-profile', BASEREQUESTOPTIONS);
  return response.json();
};

export const getSubscription = async (): Promise<Subscription> => {
  const response = await fetch('/trantor/subscriptions', BASEREQUESTOPTIONS);
  return response.json();
};

export const getStripeCustomerPortal = async (successUrl: string): Promise<Portal> => {
  const url = `/trantor/subscriptions/portal`;
  const queryParams = `successUrl=${encodeURIComponent(successUrl)}`;
  const fullUrl = `${url}?${queryParams}`;

  const options = {
    ...BASEREQUESTOPTIONS,
    method: 'GET',
  };

  const response = await fetch(fullUrl, options);
  return response.json();
};

export const getStripeCheckout = async (successUrl: string, productId: string): Promise<Checkout> => {
  const url = `/trantor/checkout`;
  const queryParams = `successUrl=${encodeURIComponent(successUrl)}&productId=${encodeURIComponent(productId)}`;
  const fullUrl = `${url}?${queryParams}`;

  const options = {
    ...BASEREQUESTOPTIONS,
    method: 'GET',
  };

  const response = await fetch(fullUrl, options);
  return response.json();
};

export const patchUser = async (user: Partial<User>) => {
  const response = await fetch(routes.pathUserProfile, {
    ...BASEREQUESTOPTIONS,
    method: 'PATCH',
    body: JSON.stringify(user),
  });
  return await response.json();
};

export const changePasswordUser = async (oldPassword: string, newPassword: string): Promise<postPasswordResponse> => {
  const body = new URLSearchParams();
  body.append('oldPassword', oldPassword);
  body.append('newPassword', newPassword);

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: body.toString(),
  };

  const response = await fetch(`/trantor/auth/change-password`, requestOptions);

  if (!response.ok) {
    throw new Error('Error:' + response.statusText);
  }

  return response.json();
};

export const userSignOut = async () => {
  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  const response = await fetch(`/trantor/auth/sign-out`, requestOptions);

  if (!response.ok) {
    throw new Error('Error:' + response.statusText);
  }

  return response.json();
};

export const userPostLogin = async (username: string, password: string) => {
  const body = new URLSearchParams();
  body.append('username', username);
  body.append('password', password);

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: body.toString(),
  };

  const response = await fetch(`/trantor/auth/sign-in`, requestOptions);

  if (!response.ok) {
    throw new Error('Error:' + response.statusText);
  }

  return response.json();
};

export const confirmSignUp = async (username: string, code: string) => {
  const body = new URLSearchParams();
  body.append('username', username);
  body.append('code', code);

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: body.toString(),
  };

  const response = await fetch(`/trantor/auth/confirm-email`, requestOptions);

  if (!response.ok) {
    throw new Error('Error:' + response);
  }

  return response.json();
};

export const forgotPassword = async (username: string) => {
  const body = new URLSearchParams();
  body.append('username', username);

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: body.toString(),
  };

  const response = await fetch(`/trantor/auth/forgot-password`, requestOptions);

  if (!response.ok) {
    throw new Error('Error:' + response);
  }

  return response.json();
};

export const ConfirmforgotPassword = async (username: string, code: string, password: string) => {
  const body = new URLSearchParams();
  body.append('username', username);
  body.append('code', code);
  body.append('password', password);

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: body.toString(),
  };

  const response = await fetch(`/trantor/auth/confirm-forgot-password`, requestOptions);

  if (!response.ok) {
    throw new Error('Error:' + response);
  }

  return response.json();
};

export const resendConfirmEmail = async (username: string) => {
  const body = new URLSearchParams();
  body.append('username', username);

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: body.toString(),
  };

  const response = await fetch(`/trantor/auth/resend-confirm-email`, requestOptions);

  if (!response.ok) {
    throw new Error('Error:' + response);
  }

  return response.json();
};

export const userRegistration = async (
  email: string,
  password: string,
  username: string,
  receivePromotions: string
) => {
  const body = new URLSearchParams();
  body.append('email', email);
  body.append('password', password);
  body.append('username', username);
  body.append('receivePromotions', receivePromotions);

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: body.toString(),
  };

  const response = await fetch(`/trantor/auth/register`, requestOptions);

  if (!response.ok) {
    throw new Error('Error:' + response.statusText);
  }

  return response.json();
};
