import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../../../styles/assets/icons/mini/downArrowChevron.svg';
import { FilterOptions } from 'src/types/domain/Filters';
import Modal from '../Modals/Modals';
import { ReactComponent as SearchIcon } from '../../../../styles/assets/icons/outline/searchOutline.svg';
import { ReactComponent as TrashIcon } from '../../../../styles/assets/icons/outline/deleteOutline.svg';
import { ReactComponent as FilterIcon } from '../../../../styles/assets/icons/outline/filterOutline.svg';
import States from './usStatesData';
import Pills from '../Pills/Pills';
import EmptyState from '../emptyState/EmptyState';
import { Button } from '../Buttons/Button';
import { PlussIcon, CheckIconMini } from '../../../../styles/assets/icons/Icons';

interface ModalFilterProps {
  title: string;
  options?: FilterOptions[] | undefined;
  filterType: string;
  filterReset?: boolean;
  refreshDropdownComponent?: boolean;
  onRefreshComplete?: () => void;
  handleAddFiltersLocalStorage: () => void;
  variant?: string;
  filterName?: string;
  onSaveBuyerProfile?: (selectedItems: { type: string; value: string }[], filterType: string) => void;
  buyerPillsSelected?: string[];
  children?: React.ReactNode;
  CardSubtitle?: string;
}

const ModalFilter: React.FC<ModalFilterProps> = ({
  title,
  options,
  filterType,
  filterReset,
  refreshDropdownComponent,
  onRefreshComplete,
  handleAddFiltersLocalStorage,
  variant,
  filterName,
  children,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSaveBuyerProfile = () => {},
  buyerPillsSelected,
  CardSubtitle,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const listRef = useRef<HTMLUListElement>(null);
  const [filteredOptions, setFilteredOptions] = useState<FilterOptions[] | undefined>(options);
  const [selectedPills, setSelectedPills] = useState<{ type: string; value: string }[]>([]);
  const countPillsByFilterType = selectedPills.filter((pill) => pill.type === filterType).length;
  const [localStorageItemCount, setLocalStorageItemCount] = useState<number>(0);
  const [premiumRange, setPremiumRange] = useState<{ min: string; max: string }>({ min: '', max: '' });
  const [selectedInsuranceType, setSelectedInsuranceType] = useState<string | null>(null);
  const [isMinInputFocused, setIsMinInputFocused] = useState(false);

  const handleMinInputFocus = useCallback(() => {
    setIsMinInputFocused(true);
  }, []);

  const handleMaxInputFocus = useCallback(() => {
    setIsMinInputFocused(false);
  }, []);

  const addPill = (type: string, value: string) => {
    setSelectedPills((prevPills) => [...prevPills, { type, value }]);
  };

  const removePill = (type: string, value: string) => {
    setSelectedPills((prevPills) => prevPills.filter((item) => item.type !== type || item.value !== value));
  };

  const clearPills = (type: string) => {
    setSelectedPills((prevPills) => prevPills.filter((item) => item.type !== type));
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearchValue(inputValue);

    if (options) {
      const filtered = options.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()));
      setFilteredOptions(filtered);
    }
  };

  const handleTrashClick = () => {
    setSearchValue('');
    setFilteredOptions(options);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    handleTrashClick();
  };

  const toggleCheckbox = (label: string) => {
    if (isPillSelected(label)) {
      removePill(filterType, label);
    } else {
      addPill(filterType, label);
    }
  };

  const handleButtonSave = () => {
    const nonNullSelectedPills = selectedPills.filter((pill) => pill.value !== null);

    if (nonNullSelectedPills.length > 0) {
      localStorage.setItem(filterType, JSON.stringify(nonNullSelectedPills.map((pill) => pill.value)));
    } else {
      localStorage.removeItem(filterType);
    }
    if (filterType === 'moreFilters') {
      const totalPremiumFilters = JSON.stringify(premiumRange);
      localStorage.setItem('premiumRangeFilters', totalPremiumFilters);

      if (selectedInsuranceType === 'Personal') {
        localStorage.setItem('insuranceTypeFilters', 'Personal');
      } else if (selectedInsuranceType === 'Commercial') {
        localStorage.setItem('insuranceTypeFilters', 'Commercial');
      } else {
        localStorage.setItem('insuranceTypeFilters', '');
      }
    }

    handleAddFiltersLocalStorage();
    handleCountFilters();
    closeModal();
  };

  const handleButtonSaveBuyerProfile = () => {
    onSaveBuyerProfile(selectedPills, filterType);
    closeModal();
  };

  const isPillSelected = (label: string) =>
    selectedPills.some((pill) => pill.type === filterType && pill.value === label);

  const handleCountFilters = () => {
    if (filterType === 'moreFilters') {
      const totalPremiumFilters = localStorage.getItem('premiumRangeFilters');
      const insuranceType = localStorage.getItem('insuranceTypeFilters');

      const hasTotalPremiumFilters = !!totalPremiumFilters && totalPremiumFilters !== '{"min":"","max":""}';
      const hasInsuranceType = !!insuranceType && insuranceType !== 'null' && insuranceType !== '';

      setLocalStorageItemCount((hasTotalPremiumFilters ? 1 : 0) + (hasInsuranceType ? 1 : 0));

      if (hasTotalPremiumFilters) {
        const premiumRangeFilters = JSON.parse(totalPremiumFilters);
        setPremiumRange({
          min: premiumRangeFilters.min,
          max: premiumRangeFilters.max,
        });
      } else {
        setPremiumRange({ min: '', max: '' });
      }
    } else {
      let storedSelections: any;
      if (variant === 'Button') {
        storedSelections = selectedPills;
      } else {
        storedSelections = JSON.parse(localStorage.getItem(filterType) || '[]');
      }
      setLocalStorageItemCount(storedSelections.length);

      setSelectedPills(
        storedSelections.map((value: any) => ({
          type: filterType,
          value,
        }))
      );
    }
  };

  useEffect(() => {
    if (buyerPillsSelected) {
      setSelectedPills(buyerPillsSelected.map((value) => ({ type: filterType, value })));
    }
  }, [buyerPillsSelected]);

  useEffect(() => {
    const storedInsuranceType = localStorage.getItem('insuranceTypeFilters');
    setSelectedInsuranceType(storedInsuranceType || null);
  }, [filterType]);

  useEffect(() => {
    handleCountFilters();
  }, [filterType]);

  useEffect(() => {
    if (refreshDropdownComponent || filterReset) {
      handleCountFilters();
      if (onRefreshComplete) {
        onRefreshComplete();
      }
    }
  }, [refreshDropdownComponent, filterReset]);

  const handlePremiumRangeChange = (key: 'min' | 'max', value: string) => {
    setPremiumRange((prev) => ({ ...prev, [key]: value }));
  };

  const handleInsuranceTypeChange = (value: string | null) => {
    selectedInsuranceType === value ? setSelectedInsuranceType(null) : setSelectedInsuranceType(value);
  };

  const placeholderForSearch = `Search ${filterName || title}`;

  const ModalInputSearch = React.memo(() => {
    return filterType !== 'moreFilters' ? (
      <>
        <div className="relative mt-2">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <SearchIcon className="w-5 h-5 text-gray-500" />
          </div>
          <input
            type="text"
            name="search"
            className="block w-full py-2 pl-10 pr-20 rounded border text-black placeholder:text-gray-400"
            placeholder={placeholderForSearch}
            value={searchValue}
            onChange={handleSearchChange}
            autoFocus
          />

          {searchValue && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-2">
              <TrashIcon
                className="w-5 h-6 text-gray-500 hover:text-black transition ease-in cursor-pointer"
                onClick={handleTrashClick}
              />
            </div>
          )}
        </div>

        <div className="w-full mt-2 flex">
          <div className="w-full">
            <Pills allPills={selectedPills} removePill={removePill} clearButton={true} clearPills={clearPills} />
          </div>
        </div>
      </>
    ) : null;
  });

  const ModalFilterListType = React.memo(() => {
    let filterContent;

    switch (filterType) {
      case 'carrierFilters':
      case 'usStateFilters':
      case 'buyerLocationFilters':
      case 'policyNumberFilters':
      case 'lineOfBusinessFilters':
        filterContent = (
          <>
            {filteredOptions?.length ? (
              <>
                <div className="w-full">
                  {filterType === 'lineOfBusinessFilters' ? (
                    <ul ref={listRef} className="list-inside w-full pr-4 pl-0">
                      {filteredOptions &&
                        filteredOptions.map((option, index) => {
                          const [code, label] = option.label.split('|').map((item) => item.trim());

                          return (
                            <li
                              key={index}
                              className="text-base text-core-black border-b last:border-b-0 py-2.5 gap-4 flex cursor-pointer"
                              onClick={() => option.value && toggleCheckbox(option.value)}
                            >
                              <input
                                type="checkbox"
                                className="accent-core-black"
                                checked={isPillSelected(option.value || '')}
                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                                onChange={() => {}}
                              />
                              <div className="flex items-center my-auto gap-2.5">
                                <span className="text-core-tableHeader text-xs font-normal">{code}</span>
                                <span className="text-core-black text-base">{label}</span>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  ) : (
                    <ul ref={listRef} className="list-inside w-full pr-4 pl-0">
                      {filteredOptions &&
                        filteredOptions.map((option, index) => (
                          <li
                            key={index}
                            className="text-base text-core-black border-b last:border-b-0 py-2.5 gap-2 flex cursor-pointer"
                            onClick={() => toggleCheckbox(option.label)}
                          >
                            <input
                              type="checkbox"
                              className="accent-core-black"
                              checked={isPillSelected(option.label)}
                              // eslint-disable-next-line @typescript-eslint/no-empty-function
                              onChange={() => {}}
                            />
                            <div className="flex items-center my-auto gap-2.5">
                              {filterType === 'usStateFilters' || filterType === 'buyerLocationFilters' ? (
                                <div className="w-8 h-8 my-auto">
                                  <States label={option.label} />
                                </div>
                              ) : null}
                              <span className="my-auto">{option.label}</span>
                            </div>
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
                {/* <div className="my-auto h-full flex py-auto justify-center">
                  <AlphabetFilter />
                </div> */}
              </>
            ) : (
              <>
                <div className="w-full h-[400px]">
                  <EmptyState text="no results were found for your search" help={true} />
                </div>
              </>
            )}
          </>
        );
        break;

      case 'moreFilters':
        filterContent = (
          <div className="flex flex-col w-full">
            <div className="w-full my-10 mx-0">
              <p className="text-sm text-core-grey uppercase font-bold">Premium range</p>
              <div className="w-full sm:!px-14 px-0 flex">
                <div className="relative block w-[45%]">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2 -mt-1 text-base">$</span>
                  <input
                    type="number"
                    placeholder="Minimum"
                    className="border rounded border-core-grey px-4 py-2 text-base w-full"
                    value={premiumRange.min}
                    onChange={(e) => handlePremiumRangeChange('min', e.target.value)}
                    onFocus={handleMinInputFocus}
                    autoFocus={isMinInputFocused}
                  />
                </div>
                <div className="relative text-center text-3xl font-extrabold w-[10%] flex justify-center">
                  <p className="m-0 p-0 text-center mx-auto block justify-center justify-items-center">-</p>
                </div>
                <div className="relative block w-[45%]">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2 -mt-1 text-base">$</span>
                  <input
                    type="number"
                    placeholder="Maximum"
                    className="border rounded border-core-grey px-4 py-2 text-base w-full"
                    value={premiumRange.max}
                    onChange={(e) => handlePremiumRangeChange('max', e.target.value)}
                    onFocus={handleMaxInputFocus}
                    autoFocus={!isMinInputFocused}
                  />
                </div>
              </div>
            </div>

            <div className="w-full my-10">
              <p className="text-sm text-core-grey uppercase font-bold">Insurance type</p>
              <ul className="m-0 p-0 space-y-2">
                <li key="Personal" className="flex items-center hover:cursor-pointer">
                  <input
                    type="checkbox"
                    id="Personal"
                    value="Personal"
                    checked={'Personal' === selectedInsuranceType}
                    onChange={() => handleInsuranceTypeChange('Personal')}
                    className="mr-2 accent-black"
                  />
                  <label htmlFor="Personal">Personal</label>
                </li>
                <li key="Commercial" className="flex items-center hover:cursor-pointer">
                  <input
                    type="checkbox"
                    id="Commercial"
                    value="Commercial"
                    checked={'Commercial' === selectedInsuranceType}
                    onChange={() => handleInsuranceTypeChange('Commercial')}
                    className="mr-2 accent-black"
                  />
                  <label htmlFor="Commercial">Commercial</label>
                </li>
              </ul>
            </div>
          </div>
        );
        break;
    }

    return <div className="w-full flex">{filterContent}</div>;
  });

  const titleForModal = filterType !== 'moreFilters' ? 'Filter ' + (filterName || title) : title;

  const renderVariantContent = () => {
    switch (variant) {
      case 'Button':
        return (
          <Button variant="outline" color="primary" onClick={openModal}>
            Edit
          </Button>
        );
      case 'Card':
        return (
          <div
            className={`border-1 rounded p-10 flex group cursor-pointer ${
              countPillsByFilterType > 0 ? 'border-core-black' : ''
            }`}
            onClick={openModal}
          >
            <div className="flex flex-col justify-start w-full my-auto gap-2">
              {countPillsByFilterType > 0 ? (
                <>
                  <div className="flex gap-2">
                    <span className="bg-core-yellow rounded-full p-1 mt-2 h-fit w-fit">
                      <CheckIconMini className="h-3 w-3" />
                    </span>
                    <h3 className="text-core-black text-2xl font-medium">
                      {CardSubtitle}
                      <strong className="text-gray-500 font-normal text-lg">({countPillsByFilterType})</strong>
                    </h3>
                  </div>

                  <div>{children}</div>
                </>
              ) : (
                <>
                  <h3 className="text-core-black text-2xl font-medium">{title}</h3>
                </>
              )}
            </div>
            <div className="my-auto flex justify-end w-fit">
              {countPillsByFilterType > 0 ? (
                <>
                  <button className="text-core-black font-bold text-sm">EDIT</button>
                </>
              ) : (
                <>
                  <button className="bg-core-yellow rounded-full p-2">
                    <PlussIcon className="h-4 w-4" />
                  </button>
                </>
              )}
            </div>
          </div>
        );
      default:
        return (
          <button
            onClick={openModal}
            className="flex border w-full rounded border-black p-2 text-black font-medium text-sm text-left"
          >
            {filterType === 'moreFilters' && <FilterIcon className="w-4 h-4 mr-1 my-auto" />}
            <span className="flex justify-start w-full whitespace-nowrap gap-0.5">
              {title} {localStorageItemCount > 0 && <span>({localStorageItemCount})</span>}
            </span>
            <ArrowDownIcon className="w-4 h-4 my-auto flex justify-end" />
          </button>
        );
    }
  };

  return (
    <>
      {renderVariantContent()}

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        ModalType={titleForModal}
        countItemsPerFilter={countPillsByFilterType}
        childrenInput={<ModalInputSearch />}
        onSave={variant ? handleButtonSaveBuyerProfile : handleButtonSave}
      >
        <ModalFilterListType />
      </Modal>
    </>
  );
};

export default ModalFilter;
