import { BASEREQUESTOPTIONS } from 'src/api/_constants';
import { ValuationReq, ValuationResponse } from 'src/types/bookValuations';

export const postBookValuation = async (valuationReq: ValuationReq): Promise<ValuationResponse> => {
  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
    body: JSON.stringify(valuationReq),
  };
  const response = await fetch('/analysis-api/slice-valuation', requestOptions);
  const data = await response.json();
  return data;
};
