import React, { useEffect, useState } from 'react';
import { ReactComponent as XMarkIcon } from '../../../../styles/assets/icons/mini/xMark.svg';
import { ReactComponent as ChevronDoubleUp } from '../../../../styles/assets/icons/mini/chevronDoubleUp.svg';
import { ReactComponent as ChevronDoubleDown } from '../../../../styles/assets/icons/mini/chevronDoubleDown.svg';

type FilterType = 'carrierFilters' | 'usStateFilters' | 'lineOfBusinessFilters';
interface PillsProps {
  allPills: { type: string; value: string }[];
  removePill: (filterType: FilterType, value: string) => void;
  clearButton?: boolean;
  clearPills?: (filterType: string) => void;
  filterReset?: boolean;
  isBuyerLocation?: boolean;
}

const Pills: React.FC<PillsProps> = ({ allPills, removePill, clearButton, clearPills, isBuyerLocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [containerHeight, setContainerHeight] = useState<number | null>(null);

  useEffect(() => {
    const container = document.getElementById(`pillsContainer_allFilters`);
    if (container) {
      setContainerHeight(container.clientHeight);
    }
  }, [allPills]);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const getArrowIcon = () => {
    return isExpanded ? <ChevronDoubleUp className="w-4 h-4" /> : <ChevronDoubleDown className="w-4 h-4" />;
  };

  // icon
  const xMarkIcon = (type: FilterType, value: string) => (
    <button onClick={() => removePill(type, value)}>
      <XMarkIcon className={`my-auto w-4 h-4 text-core-black group-hover:text-white`} />
    </button>
  );

  const filteredPills = allPills.filter((item) => !(item.value.includes('BL') && !isBuyerLocation));

  return (
    <>
      {filteredPills.length > 0 && (
        <div
          id="pillsContainer_allFilters"
          className={`w-full flex flex-wrap gap-2 overflow-hidden ${
            isExpanded ? 'max-h-full transition-max-height duration-100 ease-out' : 'max-h-20'
          }`}
        >
          {filteredPills.map((item, index) => (
            <div
              key={index}
              className={`text-xs flex border text-core-black border-core-black rounded-full bg-neutral-50 uppercase w-fit p-2 gap-1`}
            >
              <div className="flex items-center space-x-1 flex-nowrap uppercase">
                <div className="whitespace-nowrap cursor-pointer">{item.value}</div>
                {xMarkIcon(item.type as FilterType, item.value)}
              </div>
            </div>
          ))}

          {clearButton && clearPills && clearButton === true ? (
            <button
              className="text-core-black text-sm hover:text-gray-700 transition ease-in"
              onClick={() => clearPills(allPills[0]?.type || 'carrierFilters')}
            >
              Clear
            </button>
          ) : null}
        </div>
      )}

      <div className="lg:block hidden">
        {containerHeight && containerHeight > 76 ? (
          <div className="w-full border-b border-neutral-100 my-2 md:block">
            <button className="bg-white mx-auto block -mb-2.5 px-1.5" onClick={handleToggleExpand}>
              {getArrowIcon()}
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Pills;
