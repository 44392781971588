import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as XMarkIcon } from '../../../../styles/assets/icons/mini/xMark.svg';
import Avatar from '../Avatar/Avatar';
import { UsStatesOptions } from '../../ListingEnums';
import { ReactComponent as Search } from '../../../../styles/assets/icons/outline/documentMagnify.svg';
import { ReactComponent as PieChart } from '../../../../styles/assets/icons/outline/pieChart.svg';
import { ReactComponent as BackNotes } from '../../../../styles/assets/icons/outline/bancknotes.svg';
import { ReactComponent as BookOpen } from '../../../../styles/assets/icons/outline/bookOpen.svg';
import { ReactComponent as Clock } from '../../../../styles/assets/icons/outline/clock.svg';
interface ModalRightSideProps {
  isOpen: boolean;
  onClose: () => void;
  buyerProfile: any | undefined;
}

const ModalRightSide: FC<ModalRightSideProps> = ({ isOpen, onClose, buyerProfile }) => {
  document.body.style.overflow = isOpen ? 'hidden' : 'auto';

  if (!isOpen) {
    return null;
  }

  const getInitials = (firstName: string, lastName: string): string => {
    const firstInitial = firstName.charAt(0);
    const lastInitial = lastName.charAt(0);
    return `${firstInitial}${lastInitial}`;
  };

  const getStateFullName = (stateCode: string): string | undefined => {
    return UsStatesOptions[stateCode as keyof typeof UsStatesOptions];
  };

  const getBuyingOption = (buyingSlices: string) => {
    if (buyingSlices === 'Yes') {
      return 'Slices & Full Books';
    } else {
      return 'Full Books Only';
    }
  };

  const getIconBuyingOption = (timeline: string) => {
    if (timeline === 'Exploring options') {
      return <Search className="w-6 h-6" />;
    } else if (timeline === 'No timeline') {
      return <Clock className="w-6 h-6" />;
    } else {
      return <BackNotes className="w-6 h-6" />;
    }
  };

  const getIconSlices = (buyingSlices: string) => {
    if (buyingSlices === 'Yes') {
      return <PieChart className="w-6 h-6" />;
    } else {
      return <BookOpen className="w-6 h-6" />;
    }
  };

  const getPremiumRange = (min: number, max: number) => {
    const formatValue = (value: number) => {
      if (value < 1000000) {
        return `$${(value / 1000).toFixed(1)}k`;
      } else {
        return `$${(value / 1000000).toFixed(1)}m`;
      }
    };

    const formattedMin = formatValue(min);
    const formattedMax = formatValue(max);

    return `${formattedMin} - ${formattedMax}`;
  };

  return ReactDOM.createPortal(
    <div
      className="fixed top-0 p-3 sm:px-0 left-0 w-full h-full backdrop-blur-md bg-black/50 flex justify-center justify-items-center z-[100]"
      onClick={onClose}
    >
      <div
        className="bg-white rounded md:h-[96vh] md:w-[536px] h-[99vh] fixed md:right-3"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="w-full flex p-6">
          <div className="w-full flex justify-start">
            <h1 className="font-medium text-2xl text-core-black my-auto">Buyer Profile</h1>
          </div>
          <div className="w-fit flex justify-end">
            <button
              className="my-auto p-2 rounded-full bg-neutral-50 cursor-pointer hover:bg-neutral-100 transition ease-in"
              onClick={onClose}
            >
              <XMarkIcon className={`my-auto w-4 h-4 text-core-black group-hover:text-white`} />
            </button>
          </div>
        </div>

        {/* scrollable section */}
        <div className="w-full max-h-[80vh] relative px-6 overflow-y-auto">
          <div className="w-full flex mb-3 gap-4">
            <div className="w-fit flex justify-start my-auto">
              <Avatar size="lg" userName={getInitials(buyerProfile.firstName, buyerProfile.lastName)} />
            </div>
            <div className="w-full flex flex-col justify-between text-left">
              <h3 className="text-core-black text-lg font-medium m-0 p-0">
                {buyerProfile.firstName} {buyerProfile.lastName}
              </h3>
              <p className="text-core-black text-xs font-medium m-0 p-0">{buyerProfile.agencyName}</p>
              <p className="text-core-accentText uppercase m-0 p-0 text-2xs">
                {getStateFullName(buyerProfile.state)} • {buyerProfile.yearsExperience} YEARS EXP.
              </p>
            </div>
          </div>
          <div className="w-full flex gap-4 border-b border-gray-100 pb-6 mb-6">
            <div className="w-1/2 p-4 bg-blue-50 uppercase text-core-textNavy items-center rounded">
              <div className="w-full mx-auto mb-2 items-center flex justify-center">
                {getIconBuyingOption(buyerProfile.buyingTimeline)}
              </div>
              <p className="m-0 p-0 text-sm font-bold text-center">{buyerProfile.buyingTimeline}</p>
            </div>
            <div className="w-1/2 p-4 bg-blue-50 uppercase text-core-textNavy items-center rounded">
              <div className="w-full mx-auto mb-2 items-center flex justify-center">
                {getIconSlices(buyerProfile.buyingSlices)}
              </div>
              <p className="m-0 p-0 text-sm font-bold text-center">{getBuyingOption(buyerProfile.buyingSlices)}</p>
            </div>
          </div>

          <div className="w-full flex flex-col">
            <h3 className="text-core-black text-lg font-medium mb-8">Slice Preferences</h3>

            <div className="w-full space-y-8">
              <div className="w-full flex flex-col space-y-2">
                <h4 className="uppercase text-sm text-core-accentText">Premium Range</h4>
                <div className="rounded-full px-4 py-2 text-core-black w-fit text-center border border-gray-200 bg-gray-50 text-base font-bold">
                  {getPremiumRange(buyerProfile.totalPremiumMin, buyerProfile.totalPremiumMax)}
                </div>
              </div>

              <div className="w-full flex flex-col space-y-2">
                <h4 className="uppercase text-sm text-core-accentText">States</h4>
                <div className="flex gap-2 w-full flex-wrap">
                  {buyerProfile.stateFilters.length === 0 ||
                  (buyerProfile.stateFilters.length === 1 && buyerProfile.stateFilters[0] === '') ? (
                    <div className="text-core-accentText text-base font-bold">Open to all</div>
                  ) : (
                    buyerProfile.stateFilters
                      .sort((a: any, b: any) => a.localeCompare(b))
                      .map((stateCode: any, index: any) => {
                        const stateName = getStateFullName(stateCode);
                        return (
                          <div
                            key={index}
                            className="rounded-full px-4 py-2 text-core-black border border-gray-200 bg-gray-50 text-sm font-bold uppercase"
                          >
                            {stateName || stateCode}
                          </div>
                        );
                      })
                  )}
                </div>
              </div>

              <div className="w-full flex flex-col space-y-2">
                <h4 className="uppercase text-sm text-core-accentText">Carriers</h4>
                <div className="flex flex-wrap gap-2 w-full">
                  {buyerProfile.carrierFilters.length === 0 ||
                  (buyerProfile.carrierFilters.length === 1 && buyerProfile.carrierFilters[0] === '') ? (
                    <div className="text-core-accentText text-base font-bold">Open to all</div>
                  ) : (
                    buyerProfile.carrierFilters
                      .sort((a: any, b: any) => a.localeCompare(b))
                      .map((carrier: string, index: number) => (
                        <div
                          key={index}
                          className="rounded-full px-4 py-2 text-core-black border border-gray-200 bg-gray-50 text-base font-bold"
                        >
                          {carrier}
                        </div>
                      ))
                  )}
                </div>
              </div>

              <div className="w-full flex flex-col space-y-2">
                <h4 className="uppercase text-sm text-core-accentText">Lines</h4>
                <div className="flex flex-wrap gap-2 w-full">
                  {buyerProfile.lobFilters.length === 0 ||
                  (buyerProfile.lobFilters.length === 1 && buyerProfile.lobFilters[0] === '') ? (
                    <div className="text-core-accentText text-base font-bold">Open to all</div>
                  ) : (
                    buyerProfile.lobFilters
                      .sort((a: any, b: any) => a.localeCompare(b))
                      .map((lobName: string, index: number) => (
                        <div
                          key={index}
                          className="rounded-full px-4 py-2 text-core-black border border-gray-200 bg-gray-50 text-base font-bold"
                        >
                          {lobName}
                        </div>
                      ))
                  )}
                </div>
              </div>

              <div className="w-full flex flex-col space-y-2">
                {buyerProfile.profileDescriptionApproved ? (
                  <>
                    <h4 className="uppercase text-sm text-core-accentText">Buyer Bio</h4>
                    <p className="p-0 m-0 text-gray-900 text-base font-medium" style={{ whiteSpace: 'pre-line' }}>
                      "{buyerProfile.profileDescription.replace(/<br\s*\/?>/g, '\n').replace(/<\/?[^>]+(>|$)/g, '')}"
                    </p>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full bottom-6 rounded-b bg-white py-6 px-8 border-t">
          {/* <Button variant="fill" color="primary">
            <div className="flex w-full justify-center gap-2">
              <ChatBubble className="w-4 h-4 my-auto" />
              Contact Buyer
            </div>
          </Button> */}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ModalRightSide;
