import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { ReactComponent as ExpandIcon } from '../../../../styles/assets/icons/solid/expandSolid.svg';

ChartJS.register(ArcElement, Tooltip, Legend);

interface PieChartProps {
  sliceTotalPoliciesData: any;
  onCloseModal: () => void;
  handleOpenPivotTable: (open: boolean) => void;
}

const PieChart: React.FC<PieChartProps> = ({ sliceTotalPoliciesData, onCloseModal, handleOpenPivotTable }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  let carrierSummaries =
    sliceTotalPoliciesData?.group?.carrier_summaries || sliceTotalPoliciesData?.carrierSummaries || [];
  let lobSummaries = sliceTotalPoliciesData?.group?.lob_summaries || sliceTotalPoliciesData?.lobSummaries || [];
  let stateSummaries = sliceTotalPoliciesData?.group?.state_summaries || sliceTotalPoliciesData?.stateSummaries || [];

  const moveRemainingToEnd = (summaries: any[]) => {
    const remainingIndex = summaries.findIndex(
      (item) => item.carrier === 'Remaining' || item.lob === 'Remaining' || item.state === 'Remaining'
    );
    if (remainingIndex > -1) {
      const [remainingItem] = summaries.splice(remainingIndex, 1);
      summaries.push(remainingItem);
    }
    return summaries;
  };

  carrierSummaries = moveRemainingToEnd(carrierSummaries.sort((a: any, b: any) => b.percentage - a.percentage));
  lobSummaries = moveRemainingToEnd(lobSummaries.sort((a: any, b: any) => b.percentage - a.percentage));
  stateSummaries = moveRemainingToEnd(stateSummaries.sort((a: any, b: any) => b.percentage - a.percentage));

  const colors = ['#00143A', '#0046C8', '#4A8AFF', '#A44300', '#FA6E0E', '#BBBBBB'];

  const tabs = [
    {
      label: 'Carrier',
      content: (
        <div className="w-full flex gap-4">
          <div className="w-2/5">
            {carrierSummaries.length > 0 && (
              <Doughnut
                data={{
                  labels: carrierSummaries.map((item: any) => item.carrier),
                  datasets: [
                    {
                      data: carrierSummaries.map((item: any) => item.percentage),
                      backgroundColor: colors,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          let label = context.label || '';
                          if (label) {
                            label += ': ';
                          }
                          if (context.raw !== null) {
                            label += context.raw + '%';
                          }
                          return label;
                        },
                      },
                    },
                  },
                }}
              />
            )}
          </div>

          <div className="w-3/5">
            {carrierSummaries.map((item: any, index: number) => (
              <div className="w-full flex mb-2 gap-1" key={index}>
                <div className="w-8/12 flex text-gray-900 text-sm font-medium text-left whitespace-normal gap-2">
                  <div className="w-2 h-2 rounded-full my-auto" style={{ backgroundColor: colors[index] }}></div>
                  <div className="w-full truncate">{item.carrier}</div>
                </div>
                <div className="w-3/12 flex justify-end text-gray-700 font-medium text-sm">{item.percentage + '%'}</div>
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      label: 'Line',
      content: (
        <div className="w-full flex gap-4">
          <div className="w-2/5">
            {lobSummaries.length > 0 && (
              <Doughnut
                data={{
                  labels: lobSummaries.map((item: any) => item.lob),
                  datasets: [
                    {
                      data: lobSummaries.map((item: any) => item.percentage),
                      backgroundColor: colors,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          let label = context.label || '';
                          if (label) {
                            label += ': ';
                          }
                          if (context.raw !== null) {
                            label += context.raw + '%';
                          }
                          return label;
                        },
                      },
                    },
                  },
                }}
              />
            )}
          </div>

          <div className="w-3/5">
            {lobSummaries.map((item: any, index: number) => (
              <div className="w-full flex mb-2 gap-1" key={index}>
                <div className="w-9/12 flex text-gray-900 text-sm font-medium text-left whitespace-normal gap-2">
                  <div className="w-2 h-2 rounded-full my-auto" style={{ backgroundColor: colors[index] }}></div>
                  <div className="w-full truncate">{item.lob}</div>
                </div>
                <div className="w-3/12 flex justify-end text-gray-700 font-medium text-sm">{item.percentage + '%'}</div>
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      label: 'State',
      content: (
        <div className="w-full flex gap-4">
          <div className="w-2/5">
            {stateSummaries.length > 0 && (
              <Doughnut
                data={{
                  labels: stateSummaries.map((item: any) => item.state),
                  datasets: [
                    {
                      data: stateSummaries.map((item: any) => item.percentage),
                      backgroundColor: colors,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          let label = context.label || '';
                          if (label) {
                            label += ': ';
                          }
                          if (context.raw !== null) {
                            label += context.raw + '%';
                          }
                          return label;
                        },
                      },
                    },
                  },
                }}
              />
            )}
          </div>

          <div className="w-3/5">
            {stateSummaries.map((item: any, index: number) => (
              <div className="w-full flex mb-2 gap-1" key={index}>
                <div className="w-9/12 flex text-gray-900 text-sm font-medium text-left whitespace-normal gap-2">
                  <div className="w-2 h-2 rounded-full my-auto" style={{ backgroundColor: colors[index] }}></div>
                  <div className="w-full truncate">{item.state}</div>
                </div>
                <div className="w-3/12 flex justify-end text-gray-700 font-medium text-sm">{item.percentage + '%'}</div>
              </div>
            ))}
          </div>
        </div>
      ),
    },
  ];

  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
  };

  const handleOpenModalTableLib = () => {
    onCloseModal();
    handleOpenPivotTable(true);
  };

  const [shouldShowButton, setShouldShowButton] = useState(true);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.startsWith('https://app.haveyourcake.com/')) {
      setShouldShowButton(false);
    }
  }, []);

  return (
    <div className="w-full bg-core-lightGrey rounded-lg p-6">
      <div className="w-full flex">
        <div className="text-left w-1/2 flex flex-col">
          <h1 className="p-0 m-0 font-medium text-core-black text-lg">Drilldowns</h1>
          <p className="p-0 mt-0 text-core-grey font-medium text-sm">by Total Premium</p>
        </div>

        <div className="w-1/2 flex flex-wrap justify-end">
          {shouldShowButton && (
            <button
              className="w-fit h-fit py-2 px-3 border rounded-full bg-white text-core-black font-medium text-base flex gap-2"
              type="button"
              onClick={() => handleOpenModalTableLib()}
            >
              <ExpandIcon className="h-4 w-4 my-auto" />
              Pivot Table
            </button>
          )}
        </div>
      </div>

      <div className="w-full flex">
        <div className="w-full flex gap-4 justify-start mt-4 text-neutral-700">
          {tabs.map((tab, index) => (
            <button
              key={tab.label}
              onClick={() => handleTabClick(index)}
              className={`font-bold text-sm uppercase border-b-2 pb-1 transition ease-in ${
                index === activeTabIndex
                  ? 'text-core-black border-core-black'
                  : 'border-b-transparent hover:text-core-black hover:border-core-black'
              }`}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </div>

      <div className="mt-4">{tabs[activeTabIndex].content}</div>
    </div>
  );
};

export default PieChart;
