import { BASEREQUESTOPTIONS } from 'src/api/_constants';
import { FormikValues } from 'formik';

export const signInUser = async (formValues: URLSearchParams): Promise<Response> => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: formValues,
  };

  return await fetch('/cake-node-api/v2/auth/signin', requestOptions);
};

export const signOutUser = async (): Promise<Response> => {
  return await fetch('/cake-node-api/v2/auth/signout', { ...BASEREQUESTOPTIONS, method: 'POST' });
};

export const changePasswordSubmit = async (values: FormikValues): Promise<Response> => {
  const myHeaders = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  const urlencoded = new URLSearchParams({
    oldpassword: values.oldpassword,
    newpassword: values.newpassword,
  });
  return await fetch('/cake-node-api/v2/auth/changepassword', {
    ...BASEREQUESTOPTIONS,
    headers: myHeaders,
    method: 'POST',
    body: urlencoded,
  });
};

export const forgotPasswordSubmit = async (values: FormikValues): Promise<Response> => {
  const myHeaders = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  const urlencoded = new URLSearchParams({
    username: values.email,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
  };

  return await fetch('/cake-node-api/v2/auth/forgotpassword', requestOptions);
};

export const confirmForgotPasswordSubmit = async (values: FormikValues): Promise<Response> => {
  const urlencoded = new URLSearchParams({
    username: values.email,
    password: values.password,
    code: values.mfa,
  });

  const requestOptions = {
    method: 'POST',
    body: urlencoded,
  };

  return await fetch('/cake-node-api/v2/auth/confirmforgotpassword', requestOptions);
};

export const resendConfirmSignup = async (values: string): Promise<Response> => {
  const myHeaders = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  const urlencoded = new URLSearchParams({
    username: values,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
  };

  return await fetch('/cake-node-api/v2/auth/resendconfirmsignup', requestOptions);
};
