import React, { useEffect, useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import ModalRightSlices from 'src/app/shared/components/ModalRightSide/ModalRightSlices';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';
import EmptyState from 'src/app/shared/components/emptyState/EmptyState';
import { formatCurrency } from 'src/app/shared/utils/formatters';
import { useGetPolicies, useGetSlicesGroup } from 'src/hooks/usePolicies';
import cakeUpload from 'src/styles/assets/logos/cakeUpload.svg';
import UploadModal from './components/uploadModal';
import { useHistory } from 'react-router';
import ModalPivotTable from 'src/app/shared/components/Graphs/PivotTables/ModalPivotTable';
import SelectionCounter from './components/filter/selectionCounter';
interface SliceProps {
  onSelectSlices: (selectedSlices: string[]) => void;
  isDelete: boolean | false;
  isListingCreateAction: boolean | false;
  showLayout: boolean | false;
  setShowLayout: (value: boolean) => void;
  continueButton: boolean | false;
  setContinueButton: (value: boolean) => void;
}

const Slices: React.FC<SliceProps> = ({
  onSelectSlices,
  isDelete,
  showLayout,
  setShowLayout,
  continueButton,
  setContinueButton,
}) => {
  const { data: slicesData, isPending, refetch } = useGetSlicesGroup();
  const { data: policiesData } = useGetPolicies();
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
  const [visibleListings, setVisibleListings] = useState<{ groups: any[] }>({ groups: [] });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalUploadOpen, setisModalUploadOpen] = useState(false);
  const [selectedGroupUid, setSelectedGroupUid] = useState<string>('');
  const [allLoaded, setAllLoaded] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const history = useHistory();
  const [page, setPage] = useState(1);

  useEffect(() => {
    const startIndex = 0;
    const endIndex = startIndex + 12;
    if (slicesData && slicesData.groups) {
      setVisibleListings({ groups: slicesData.groups.slice(startIndex, page * endIndex) });
    }
  }, [slicesData, page]);

  useEffect(() => {
    if (isDelete) {
      refetch();
    }
  });

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      !loadingMore &&
      !allLoaded &&
      slicesData &&
      slicesData?.groups?.length > 0
    ) {
      setLoadingMore(true);
      setTimeout(() => {
        const newPage = page + 1;
        setPage(newPage);

        const startIndex = (newPage - 1) * 6;
        const endIndex = startIndex + 3;
        const newVisibleSlices = slicesData?.groups.slice(startIndex, endIndex);

        if (newVisibleSlices && newVisibleSlices.length > 0) {
          setVisibleListings({
            groups: [...visibleListings.groups, ...newVisibleSlices],
          });
        } else {
          setAllLoaded(true);
        }

        setLoadingMore(false);
      }, 1000);
    }
  };

  const setShowLayoutState = (value: boolean) => {
    setShowLayout(value);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const closeModal = () => {
    setIsModalOpen(false);
    setisModalUploadOpen(false);
    refetch();
  };

  const [openModalPivotTable, setOpenModalPivotTable] = useState(false);

  const handleOpenModalPivotTable = () => {
    setOpenModalPivotTable(!openModalPivotTable);
  };

  const onCloseModalPivotTable = () => {
    setIsModalOpen(true);
    setOpenModalPivotTable(!openModalPivotTable);
  };

  const handleUpload = () => {
    setisModalUploadOpen(true);
  };

  const handleCheckboxChange = (sliceId: number) => {
    const newSelectedIndexes = new Set(selectedIndexes);
    if (newSelectedIndexes.has(sliceId)) {
      newSelectedIndexes.delete(sliceId);
    } else {
      newSelectedIndexes.add(sliceId);
    }
    const newSelectedIndexesArray = Array.from(newSelectedIndexes);
    onSelectSlices(newSelectedIndexesArray.map(String));
    setSelectedIndexes(newSelectedIndexesArray);
  };

  const clearSelection = () => {
    setSelectedIndexes([]);
  };

  const getTotalSlices = () => {
    return visibleListings.groups.length;
  };

  const openModalSlices = (group: any) => {
    setSelectedGroupUid(group.uid);
    setIsModalOpen(true);
  };

  const handleCreateSlice = () => {
    const currentUrl = location.pathname + location.hash;
    if (currentUrl !== '/book-management#book') {
      history.push('/book-management#book');
    }
  };

  if (isPending) {
    return (
      <div className="w-full mb-12 z-0">
        <Skeleton type="buyerTab" />
      </div>
    );
  }

  const showEmptyState =
    policiesData && policiesData.myPolicies && policiesData.myPolicies.length > 0
      ? { text: 'To create a Slice, group Policies together on the My Book Tab', createSlice: true }
      : { text: 'Import your book of business to get started!', upload: true };

  return (
    <div className="w-full">
      <div className="w-full flex mb-4">
        <div className="w-1/2 flex justify-start">
          <span className="text-core-accentText text-sm font-normal">
            Showing <strong className="text-core-black">{getTotalSlices()}</strong> slices
          </span>
        </div>
      </div>
      {slicesData?.groups && slicesData?.groups.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {visibleListings.groups.map((item, index) => (
            <div key={item.uid} onClick={() => openModalSlices(item)}>
              <div className="bg-white rounded border pb-4 pt-3 cursor-pointer hover:shadow-lg transition ease-in">
                <div className="flex items-center gap-1">
                  <div className="w-2/12 border-l-4 border-[#000] p-3">
                    <div
                      className={`h-2.5 w-2.5 bg-[#000] border-[#000] -mb-2.5 border-2 z-10 isolate rounded-tl-full`}
                    ></div>
                    <div className="h-5 w-5 rounded-full border-2 border-gray-400 border-dashed"></div>
                  </div>
                  <div className="w-7/12 text-left">
                    <div className="text-core-black font-medium text-lg max-w-[300x] truncate">{item.name}</div>
                  </div>
                  <div className="flex justify-end w-3/12 pr-4">
                    <div
                      className="md:w-1/2 w-full my-auto flex justify-end"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <input
                        type="checkbox"
                        className="hidden"
                        id={`checkbox-${index}`}
                        checked={selectedIndexes.includes(item.uid)}
                        onClick={() => {
                          handleCheckboxChange(item.uid);
                        }}
                      />
                      <label
                        htmlFor={`checkbox-${index}`}
                        className={`h-5 w-5 my-auto group flex items-center justify-center cursor-pointer border-2 border-gray-600 rounded-[4px] ${
                          selectedIndexes.includes(item.uid) ? 'bg-core-black border-black' : 'bg-white'
                        }`}
                      >
                        {selectedIndexes.includes(item.uid) && <CheckIcon className="w-3 h-3 text-white stroke-2" />}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-3 text-left mt-4 px-4">
                  <div>
                    <p className="p-0 m-0 text-gray-700 text-2xs uppercase">Premium</p>
                    <p className="p-0 m-0 text-core-black text-sm uppercase">{formatCurrency(item.totalPremium)}</p>
                  </div>
                  <div>
                    <p className="p-0 m-0 text-gray-700 text-2xs uppercase">Policies</p>
                    <p className="p-0 m-0 text-core-black text-sm uppercase">{item.totalPolicies}</p>
                  </div>
                  <div>
                    <p className="p-0 m-0 text-gray-700 text-2xs uppercase">Customers</p>
                    <p className="p-0 m-0 text-core-black text-sm uppercase">{item.totalCustomers}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="w-full mx-auto border rounded">
          <EmptyState
            text={showEmptyState.text}
            help={false}
            image={cakeUpload}
            onClick={showEmptyState.createSlice ? handleCreateSlice : handleUpload}
            createSlice={showEmptyState.createSlice}
            upload={!showEmptyState.createSlice}
          />
        </div>
      )}

      {loadingMore && (
        <div className="w-full flex justify-center mt-4">
          <Skeleton type="loading-more" />
        </div>
      )}

      {!loadingMore && allLoaded && (
        <div className="w-full flex justify-center mt-5">
          <p className="text-core-accentText mt-3 text-md font-medium">No more results available</p>
        </div>
      )}

      {isModalUploadOpen && <UploadModal isOpen={isModalUploadOpen} onClose={closeModal} />}

      <SelectionCounter
        count={selectedIndexes.length}
        selectedItems={selectedIndexes}
        clearSelection={clearSelection}
        setShowLayout={setShowLayoutState}
        showLayout={showLayout}
        continueButton={continueButton}
        setContinueButton={setContinueButton}
        isListingAction={true}
      />

      <ModalRightSlices
        isOpen={isModalOpen}
        onClose={closeModal}
        groupDetail={
          slicesData && slicesData.groups && slicesData.groups.find((group) => group.uid === selectedGroupUid)
        }
        openPivotTable={handleOpenModalPivotTable}
      />

      <ModalPivotTable
        sliceData={slicesData && slicesData.groups && slicesData.groups.find((group) => group.uid === selectedGroupUid)}
        onCloseModalPivotTable={onCloseModalPivotTable}
        isOpen={openModalPivotTable}
      />
    </div>
  );
};

export default Slices;
