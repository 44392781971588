import React, { FC } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useGetSliceDetail } from 'src/hooks/usePolicies';
import { DeletePolicies, EditNameGroup } from 'src/api/policies';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import { DeleteSlices } from 'src/api/slices';

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  groupUid: string;
  selectedPolicies?: string[];
  onSuccess: (success: boolean) => void;
  action: string;
  typeData: string;
  selectedSlices?: string[];
}

const ModalDeleteConfirmation: FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  groupUid,
  selectedPolicies,
  selectedSlices,
  onSuccess,
  action,
  typeData,
}) => {
  const { data: sliceTotalPoliciesData } = useGetSliceDetail(groupUid);
  const { alerts, createToast } = useAlert();

  if (!isOpen) {
    return null;
  }

  const handleRemovePolicy = async () => {
    if (!groupUid || !sliceTotalPoliciesData?.group.name || !selectedPolicies || !selectedSlices) {
      return;
    }

    try {
      const policiesToRemove = sliceTotalPoliciesData?.group.policies
        .map((policy) => policy.uid)
        .filter((policyUid) => !selectedPolicies.includes(policyUid));
      const response = await EditNameGroup(groupUid, sliceTotalPoliciesData.group.name, 'group', policiesToRemove);
      if (response.message) {
        createToast({ text: response.message, variant: 'success' });
        onSuccess(true);
      } else {
        createToast({ text: response.message, variant: 'danger' });
        onSuccess(false);
      }
    } catch (error) {
      console.log(error);
      createToast({
        text: 'Invalid action please try again.',
        variant: 'danger',
      });
      onSuccess(false);
    }
  };

  const handleDeletePolicy = async () => {
    if (!selectedPolicies) {
      return;
    }

    try {
      const response = await DeletePolicies(selectedPolicies);
      if (response) {
        createToast({ text: 'Policy deleted successfully', variant: 'success' });
        onSuccess(true);
      } else {
        createToast({ text: 'Error deleting', variant: 'danger' });
        onSuccess(false);
      }
    } catch (error) {
      console.log(error);
      createToast({
        text: 'Invalid action, please try again.',
        variant: 'danger',
      });
      onSuccess(false);
    }
  };

  const handleDeleteSlices = async () => {
    if (!selectedSlices) {
      return;
    }

    try {
      await DeleteSlices(selectedSlices);
      createToast({ text: 'Slices deleted successfully', variant: 'success' });
      onSuccess(true);
    } catch (error) {
      console.log(error);
      createToast({
        text: 'Invalid action, please try again.',
        variant: 'danger',
      });
      onSuccess(false);
    }
  };

  return (
    <div
      onClick={onClose}
      className="fixed top-0 z-50 left-0 w-full h-full flex justify-center items-center backdrop-blur-md bg-black/50"
    >
      <div
        className="bg-white p-6 rounded shadow-lg w-[500px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="w-full flex mb-10">
          <div className="w-11/12 gap-3">
            <h1 className="text-core-black font-medium text-xl p-0 m-0 whitespace-wrap">
              Are you sure you want to {action} the selected {typeData}?
            </h1>
          </div>
          <div className="w-1/12 flex justify-end mb-3">
            <button
              onClick={onClose}
              className="rounded-full p-2 h-8 w-8 bg-neutral-50 hover:bg-neutral-100 text-core-black transition ease-in"
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          </div>
        </div>

        <div className="flex justify-center gap-2">
          <button
            className="px-4 py-2 text-red-500 rounded hover:bg-neutral-50 transition ease-in capitalize"
            onClick={() => {
              if (typeData == 'policy') {
                if (action === 'delete') {
                  handleDeletePolicy();
                } else {
                  handleRemovePolicy();
                }
              } else {
                handleDeleteSlices();
              }
              setTimeout(() => {
                onClose();
              }, 5000);
            }}
          >
            {action}
          </button>

          <button className="px-4 py-2 bg-core-black text-white rounded" onClick={onClose}>
            Go back
          </button>
        </div>

        {alerts}
      </div>
    </div>
  );
};

export default ModalDeleteConfirmation;
