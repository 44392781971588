import React, { useState } from 'react';
import cakeLogo from '../../../styles/assets/logos/cake-logo-black.png';
import Button from 'src/app/shared/components/Buttons/Button';
import Input from 'src/app/shared/components/Inputs/Input';
import { useHistory } from 'react-router';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import { Form, Formik } from 'formik';
import { forgotPassword } from 'src/api/user';

const ForgotV2 = (): React.ReactElement => {
  const history = useHistory();
  const { alerts, createToast } = useAlert();
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const [, setIsPendingButton] = useState(false);

  const initialValues = {
    email: '',
  };

  const onSubmit = async (values: any) => {
    setIsPendingButton(true);
    try {
      setIsPendingButton(true);
      const response = await forgotPassword(values.email);
      if (response.message) {
        localStorage.setItem('confirmUserEmail', values.email);
        window.location.href = '/change-password';
      } else {
        createToast({ text: response.error, variant: 'danger' });
      }
    } catch (error) {
      console.log(error);
      createToast({
        text: 'Invalid verification code provided, please try again.',
        variant: 'danger',
      });
    }
    setIsPendingButton(false);
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize>
      {({ values, handleChange }) => (
        <>
          <Form className="h-fit w-full min-h-screen bg-white">
            <div className="w-full hfull absolute top-0">
              <div className="w-full top-0">
                <div className="float-left py-4 px-10">
                  <img src={cakeLogo} alt="Cake logo" className="w-[60px] h-[24]" />
                </div>
              </div>

              <div className="w-full md:w-1/2 mx-auto my-40 space-y-3">
                <h1 className="text-xl md:text-5xl font-medium text-center">Recover Password</h1>

                <p className="font-medium text-sm md:text-base text-core-grey">
                  No worries, it happens all the time. Enter the email address associated with your account and we’ll
                  send you a code to reset your password.
                </p>

                <div className="w-1/2 mx-auto flex pt-8 space-y-2 flex-col">
                  <Input
                    type="email"
                    labelInput="Email"
                    placeholder="Enter your email"
                    isRequiredInput={true}
                    name="email"
                    onChange={(e) => {
                      handleChange(e);
                      setButtonsDisabled(false);
                    }}
                    value={values.email || ''}
                  />
                </div>

                <div className="w-56 mx-auto my-4">
                  <Button variant="fill" color="primary" type="submit" disabled={buttonsDisabled}>
                    Continue
                  </Button>
                </div>

                <div className="w-fit mx-auto mt-3">
                  <Button variant="outline" color="secondary" onClick={() => history.goBack()}>
                    Back to login
                  </Button>
                </div>
              </div>
            </div>
            {alerts}
          </Form>
        </>
      )}
    </Formik>
  );
};

export default ForgotV2;
