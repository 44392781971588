import { createSlice } from '@reduxjs/toolkit';
import { initialUserState } from 'src/types/domain/User';
import { ActionWithPayload } from 'src/types/common/ReduxTypes';
import { USER_STORE_NAME } from 'src/app/user/UserStoreActions';
import { UserStoreReducer } from 'src/app/user/UserStoreReducers';

export const userSlice = createSlice({
  name: USER_STORE_NAME,
  initialState: initialUserState,
  reducers: {
    setUserLoading(state, action: ActionWithPayload<boolean>): void {
      state.isLoading = action.payload;
    },
    setUserLogin(state, action: ActionWithPayload<boolean>): void {
      state.isUserLoggedIn = action.payload;
      if (!state.isUserLoggedIn) Object.assign(state, initialUserState);
    },
    resetUsersSlice() {
      return initialUserState;
    },
  },
  extraReducers: UserStoreReducer,
});

export const { setUserLoading, setUserLogin, resetUsersSlice } = userSlice.actions;
export const userStore = userSlice.reducer;
