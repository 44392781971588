/* eslint-disable no-use-before-define */

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from 'src/app/appStore';

// Use throughout your app instead of plain `useDispatch` and `useSelector`

// any type just to get past this because we may not use this nor really need this once we get our appStore set up
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
