import React, { useState } from 'react';
import Alert from './Alert';

type Toast = {
  text: string;
  variant: 'success' | 'warning' | 'danger' | 'confirm';
  onClose?: () => void;
  onRestore?: (id?: string) => void;
};

export default function useAlert() {
  const [alerts, setAlerts] = useState<Toast[]>([]);

  const createToast = (options: Toast) => {
    setAlerts([...alerts, options]);
    setTimeout(() => {
      setAlerts((a) => a.slice(1));
    }, 10000);
  };

  const removeToast = (index: number) => {
    setAlerts((a) => {
      const newAlerts = [...a];
      newAlerts.splice(index, 1);
      return newAlerts;
    });
  };

  const alertComponents = alerts.map((t, index) => (
    <Alert key={index} variant={t.variant} onClose={() => removeToast(index)} onRestore={t.onRestore}>
      {t.text}
    </Alert>
  ));

  return {
    alerts: <div className="fixed top-5 right-5 z-50 space-y-2">{alertComponents}</div>,
    createToast,
  };
}
