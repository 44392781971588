import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as ChevronRightIcon } from '../../../../styles/assets/icons/solid/chevronRightSolid.svg';
import { ReactComponent as ChevronRightDoubleIcon } from '../../../../styles/assets/icons/solid/chevronDoubleRightSolid.svg';
import { ReactComponent as ChevronLeftIcon } from '../../../../styles/assets/icons/solid/chevronLeftSolid.svg';
import { ReactComponent as ChevronLeftDoubleIcon } from '../../../../styles/assets/icons/solid/chevronLeftDoubleSolid.svg';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  onItemsPerPageChange: (itemsPerPage: number) => void;
  nameListing?: string;
}

const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  onItemsPerPageChange,
  nameListing,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const controlerClasses = 'h-4 w-4 my-auto cursor-pointer text-core-black hover:text-gray-500 tranistion ease-in';

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
  const [showOptionsNumbers, setShowOptionNumbers] = useState(false);
  const selectorRef = useRef<HTMLDivElement>(null);

  const goToFirstPage = () => {
    if (currentPage !== 1) {
      onPageChange(1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const goToLastPage = () => {
    if (currentPage !== totalPages) {
      onPageChange(totalPages);
    }
  };

  const handleOpenSelector = () => {
    setShowOptionNumbers(!showOptionsNumbers);
  };

  const handleItemsPerPageChange = (itemsPerPage: number) => {
    onItemsPerPageChange(itemsPerPage);
    setShowOptionNumbers(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target as Node)) {
        setShowOptionNumbers(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="w-full flex  my-2 bg-neutral-50 rounded-[5px] p-1">
        <div className="w-1/2 flex justify-start">
          <p className="text-gray-500 text-sm font-normal flex mt-3 mx-2">
            Showing{' '}
            <strong className="text-core-black !font-medium hidden md:flex mx-1.5">
              {startIndex} - {endIndex}
            </strong>{' '}
            <button onClick={handleOpenSelector} className="hidden md:block">
              {showOptionsNumbers ? (
                <ChevronUpIcon className="h-3 w-3 my-auto mr-2 font-semibold" />
              ) : (
                <ChevronDownIcon className="h-3 w-3 my-auto mr-2 font-semibold" />
              )}

              {showOptionsNumbers && (
                <div ref={selectorRef} className="absolute bg-white p-2 rounded shadow -mt-40 border">
                  <ul className="p-0 m-0">
                    <li
                      onClick={() => handleItemsPerPageChange(10)}
                      className="hover:text-core-black transition ease-in rounded hover:bg-neutral-50 px-3 py-1 mb-1"
                    >
                      10
                    </li>
                    {totalItems > 50 && (
                      <li
                        onClick={() => handleItemsPerPageChange(50)}
                        className="hover:text-core-black transition ease-in rounded hover:bg-neutral-50 px-3 py-1 mb-1"
                      >
                        50
                      </li>
                    )}
                    {totalItems > 100 && (
                      <li
                        onClick={() => handleItemsPerPageChange(100)}
                        className="hover:text-core-black transition ease-in rounded hover:bg-neutral-50 px-3 py-1 mb-1"
                      >
                        100
                      </li>
                    )}
                    <li
                      onClick={() => handleItemsPerPageChange(totalItems)}
                      className="hover:text-core-black transition ease-in rounded hover:bg-neutral-50 px-3 py-1"
                    >
                      All
                    </li>
                  </ul>
                </div>
              )}
            </button>
            <span className="hidden md:block">of</span>
            <strong className="text-core-black !font-medium mx-1.5">{totalItems}</strong> {nameListing}
          </p>
        </div>
        <div className="w-1/2 flex justify-end gap-2 mr-3 my-0 md:my-2">
          <ChevronLeftDoubleIcon
            className={`${controlerClasses} ${currentPage === 1 ? 'text-gray-300' : ''}`}
            onClick={goToFirstPage}
          />
          <ChevronLeftIcon
            className={`${controlerClasses} ${currentPage === 1 ? 'text-gray-300' : ''}`}
            onClick={goToPreviousPage}
          />
          <p className="text-gray-500 text-sm font-normal flex justify-center my-auto">
            <span className="hidden md:flex mr-1">Page</span> {currentPage} of {totalPages}
          </p>
          <ChevronRightIcon
            className={`${controlerClasses} ${currentPage === totalPages ? 'text-gray-300' : ''}`}
            onClick={goToNextPage}
          />
          <ChevronRightDoubleIcon
            className={`${controlerClasses} ${currentPage === totalPages ? 'text-gray-300' : ''}`}
            onClick={goToLastPage}
          />
        </div>
      </div>
    </>
  );
};

export default Pagination;
