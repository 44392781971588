import { useMutation, useQuery } from '@tanstack/react-query';
import { getAllBuyerProfiles, getBuyerProfile, patchBuyerProfile } from 'src/api/buyers';

export const useGetAllBuyerProfiles = () => {
  return useQuery({ queryKey: ['getAllBuyerProfiles'], queryFn: () => getAllBuyerProfiles() });
};

export const useGetBuyerProfile = () => {
  return useQuery({
    queryKey: ['getBuyerProfile'],
    queryFn: () => getBuyerProfile(),
  });
};

export const usePatchBuyerProfile = () => {
  return useMutation({ mutationKey: ['patchBuyerProfile'], mutationFn: patchBuyerProfile });
};
