import { XMarkIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { deleteListingById } from 'src/api/policies';

interface ModalDeleteListingProps {
  uid: string;
  onClose: () => void;
  onDelete: (uid: string) => void;
}

const ModalDeleteListing: React.FC<ModalDeleteListingProps> = ({ uid, onClose, onDelete }) => {
  const handleDeleteListingAction = async (): Promise<void> => {
    await deleteListingById(uid);
    onDelete(uid);
    onClose();
  };

  return (
    <div
      onClick={onClose}
      className="fixed top-0 z-50 left-0 w-full h-full flex justify-center items-center backdrop-blur-md bg-black/50"
    >
      <div
        className="bg-white p-6 rounded shadow-lg w-[500px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="w-full flex mb-10">
          <div className="w-11/12 gap-3">
            <h1 className="text-core-black font-medium text-xl p-0 m-0 whitespace-wrap">
              Are you sure you want to delete the selected listing?
            </h1>
          </div>
          <div className="w-1/12 flex justify-end mb-3">
            <button
              onClick={onClose}
              className="rounded-full p-2 h-8 w-8 bg-neutral-50 hover:bg-neutral-100 text-core-black transition ease-in"
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          </div>
        </div>
        <div className="mt-6 flex justify-center gap-2">
          <button
            className="px-4 py-2 text-red-500 rounded hover:bg-neutral-50 transition ease-in capitalize"
            onClick={() => handleDeleteListingAction()}
          >
            Delete
          </button>

          <button onClick={onClose} className="px-4 py-2 bg-core-black text-white rounded">
            Go back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalDeleteListing;
