import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { userStore } from 'src/app/user/UserStore';

export const appStore = configureStore({
  reducer: {
    user: userStore,
  },
});

export type AppDispatch = typeof appStore.dispatch;
export type RootState = ReturnType<typeof appStore.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
