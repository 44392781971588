import React, { useEffect } from 'react';
import { ReactComponent as LeftArrowMini } from '../../../../styles/assets/icons/solid/leftArrow.svg';
import { ReactComponent as ChevronRightIcon } from '../../../../styles/assets/icons/solid/chevronRightSolid.svg';
import { useGetEnvironmet } from 'src/hooks/useGetEnvironment';
interface BreadcrumbProps {
  title: string;
  backButton?: boolean | false;
  backUrl?: string;
  subtitle?: string;
}

const handleBackButtonClick = (backUrl?: string) => {
  if (backUrl) {
    window.location.href = backUrl;
  } else {
    window.history.back();
  }
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ title, backButton, backUrl, subtitle }) => {
  const { data: responseEnv } = useGetEnvironmet();

  useEffect(() => {
    if (responseEnv && responseEnv.stackEnv) {
      localStorage.setItem('stackEnv', responseEnv.stackEnv);
    }
  }, [responseEnv]);

  return (
    <div className="mb-3 flex w-full">
      <div className="w-full flex justify-start">
        {!subtitle ? (
          <h1 className={`text-core-black font-medium text-left flex gap-2 ${backButton ? 'text-lg' : 'text-[32px]'}`}>
            {backButton ? (
              <LeftArrowMini
                className="w-6 h-6 rounded-full p-1.5 my-auto font-bold cursor-pointer bg-white hover:bg-neutral-500 transition ease-in"
                onClick={() => handleBackButtonClick(backUrl)}
              />
            ) : null}
            <div className="w-full flex">
              <span className="w-fit flex">{title}</span>
            </div>
          </h1>
        ) : (
          <h1 className="text-core-black font-medium text-left flex gap-2 text-[32px]">
            {backButton ? (
              <LeftArrowMini
                className="w-6 h-6 rounded-full p-1.5 my-auto font-bold cursor-pointer bg-white hover:bg-neutral-500 transition ease-in"
                onClick={() => handleBackButtonClick(backUrl)}
              />
            ) : null}
            <div className="w-full flex">
              <span className="text-core-accentText w-fit flex">
                {title}
                <ChevronRightIcon className="h-5 w-5 my-auto mx-2" />
              </span>
              <span>{subtitle}</span>
            </div>
          </h1>
        )}
      </div>
    </div>
  );
};

export default Breadcrumb;
