import React, { FC, useEffect, useState } from 'react';
import { Onboarding } from '../components/Onboarding';
import { useHistory } from 'react-router-dom';
import { useOnboardingStore } from 'src/app/store/store';
import { UsStatesOptions } from 'src/app/shared/ListingEnums';
import { usePatchUser } from 'src/hooks';
import Select from 'src/app/shared/components/Inputs/Select';
import { useFormik } from 'formik';
import { addressInfoSchema } from '../schemas/addressInfo.schema';
import Input from 'src/app/shared/components/Inputs/Input';
import {
  BASIC_PROFILE_ADDRESS_TEXT,
  BASIC_PROFILE_CONTINUE_ADDRESS_TITLE,
  BASIC_PROFILE_CONTINUE_TITLE1,
} from 'src/constants/labels';

export const AddressInfo: FC = () => {
  const history = useHistory();
  const { userInfo } = useOnboardingStore();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const { mutate, isSuccess, error, isPending } = usePatchUser();

  const optionsStates = Object.entries(UsStatesOptions)
    .sort((a, b) => a[1].localeCompare(b[1]))
    .map(([value, label]) => ({ value, label }));

  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      addressLine1: userInfo?.agencyInfo.address_line1 || '',
      addressLine2: userInfo?.agencyInfo.address_line2 || '',
      addressCity: userInfo?.agencyInfo.address_city || '',
      addressState: userInfo?.agencyInfo.address_state || '',
      addressZip: userInfo?.agencyInfo.address_zip || '',
    },
    validationSchema: addressInfoSchema,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  useEffect(() => {
    const isValid = Object.keys(errors).length === 0;

    const requiredFieldsFilled =
      values.addressLine1.trim() !== '' &&
      values.addressCity.trim() !== '' &&
      values.addressState.trim() !== '' &&
      values.addressZip.trim() !== '';
    setIsDisabled(!(isValid && requiredFieldsFilled));
  }, [errors, values.addressLine1, values.addressCity, values.addressState, values.addressZip]);

  useEffect(() => {
    if (isSuccess) {
      history.push('/onboarding/basic-info-continue');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (userInfo?.userProfile.onboardingComplete) {
      history.push('/onboarding/completed');
    }
  }, [userInfo]);

  return (
    <Onboarding
      handleSubmit={handleSubmit}
      isBack={true}
      isLoading={isPending}
      isContinue={true}
      isDisabled={isDisabled}
    >
      <div className="flex flex-col lg:w-11/12 sm:w-12/12 m-auto h-fit">
        <div className="flex flex-col lg:w-8/12 mt-32 md:mt-40 px-3 pb-36 md:px-0 mx-auto">
          <div className="flex flex-col lg:w-10/12 sm:w-full m-auto h-full text-center">
            <h3 className="m-auto lg:text-5xl sm:text-4xl capitalize">{`${BASIC_PROFILE_CONTINUE_TITLE1} ${
              userInfo?.userProfile.firstName || ''
            }! ${BASIC_PROFILE_CONTINUE_ADDRESS_TITLE}`}</h3>
            <p className="text-base text-core-grey mt-3">{BASIC_PROFILE_ADDRESS_TEXT}</p>
          </div>
          <form className="w-full mt-4">
            <div className="w-8/12 m-auto flex flex-row justify-center mt-3">
              <Input
                type="text"
                placeholder="Address Line 1"
                name="addressLine1"
                onChange={handleChange}
                value={values.addressLine1}
                errorText={errors.addressLine1}
                labelInput="Address Line 1"
                isRequiredInput={true}
              />
            </div>
            <div className="w-8/12 m-auto flex flex-row  mt-3 justify-center">
              <Input
                type="text"
                placeholder="Address Line 2"
                name="addressLine2"
                onChange={handleChange}
                value={values.addressLine2}
                labelInput="Address Line 2"
              />
            </div>
            <div className="w-8/12 m-auto flex flex-row  mt-3 justify-center">
              <Input
                type="text"
                placeholder="City"
                name="addressCity"
                onChange={handleChange}
                value={values.addressCity}
                labelInput="City"
                isRequiredInput={true}
                errorText={errors.addressCity}
              />
            </div>
            <div className="w-8/12 m-auto flex gap-3 flex-col md:flex-row md:space-x-3 mt-3">
              <div className="w-full md:w-1/2">
                <Select
                  options={optionsStates}
                  name="addressState"
                  value={values.addressState}
                  onChange={handleChange}
                  errorText={errors.addressState}
                  positionLast={true}
                  labelInput="STATE"
                  isRequiredInput={true}
                  placeholderOption="Select a State"
                />
              </div>
              <div className="w-full md:w-1/2">
                <Input
                  type="string"
                  placeholder="Zip Code"
                  name="addressZip"
                  value={values.addressZip}
                  onChange={handleChange}
                  labelInput="ZIP"
                  isRequiredInput={true}
                  errorText={errors.addressZip}
                />
              </div>
            </div>
            <div className="w-8/12 m-auto">
              <p className="text-core-black text-xs text-left my-3">
                Required fields designated with an asterisk (<span className="text-red-500">*</span>)
              </p>
            </div>
          </form>
        </div>
        <div className="flex w-full">
          <span className="text-system-negative text-sm ml-auto mr-auto mt-2">{errorMessage}</span>
        </div>
      </div>
    </Onboarding>
  );
};
