import React, { FC } from 'react';
import checkIcon from '../../../../../styles/assets/icons/mini/CheckIcon.svg';
import { Button } from 'src/app/shared/components/Buttons/Button';
import { useHistory } from 'react-router';

interface UpgradeModalProps {
  onBack: () => void;
}

const UpgradeModal: FC<UpgradeModalProps> = ({ onBack }) => {
  const history = useHistory();

  const handleGotoPricingPage = () => {
    history.push(`/account/pricing`);
  };

  const handleChatWithUs = () => {
    window.location.href = 'mailto:support@haveyourcake.com';
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white rounded-lg w-11/12 md:w-[680px] h-fit p-4 shadow-lg">
        <h1 className="text-center text-core-black font-medium text-3xl">
          Upgrade to unlock listing analytics and communicate with sellers
        </h1>

        <p className="text-gray-900 text-sm font-medium text-center">Your get everything in Starter, plus</p>

        <div className="w-full md:w-4/6 mx-auto md:flex gap-3 mt-6">
          <ul className="w-full md:w-1/2 space-y-4 p-0 m-0">
            <li className="w-full flex text-gray-900 font-medium text-base gap-1 whitespace-nowrap">
              <img src={checkIcon} className="h-3 w-3 my-auto" />
              Private and secure chat
            </li>
            <li className="w-full flex text-gray-900 font-medium text-base gap-1 whitespace-nowrap">
              <img src={checkIcon} className="h-3 w-3 my-auto" />
              Personal deal room
            </li>
          </ul>

          <ul className="w-full mt-3 sm:!mt-0 md:w-1/2 space-y-4 p-0 m-0">
            <li className="w-full flex text-gray-900 font-medium text-base gap-1 whitespace-nowrap">
              <img src={checkIcon} className="h-3 w-3 my-auto" />
              Buyer Profile
            </li>
            <li className="w-full flex text-gray-900 font-medium text-base gap-1 whitespace-nowrap">
              <img src={checkIcon} className="h-3 w-3 my-auto" />
              More coming soon!
            </li>
          </ul>
        </div>

        <div className="mt-8 w-full flex flex-wrap space-y-4">
          <Button variant="fill" color="primary" onClick={handleGotoPricingPage}>
            Upgrade to Pro
          </Button>

          <Button variant="outline" color="primary" onClick={handleChatWithUs}>
            Chat with us
          </Button>

          <div className="pt-7 flex w-full justify-center items-center">
            <button
              onClick={onBack}
              className="w-full bg-white border-0 rounded-lg text-core-black text-sm font-bold px-3 mx-auto"
            >
              NO THANKS
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeModal;
