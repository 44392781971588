import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, LinearScale, CategoryScale } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, LinearScale, CategoryScale);

interface BarChartProps {
  policies: any;
}

const BarChart: React.FC<BarChartProps> = ({ policies }) => {
  const [topOpenLine, setTopOpenLine] = useState(false);

  const groupPoliciesByLOB = (policies: any) => {
    const groupedPolicies: { line_of_business: string; num_of_policies: number; premium: number }[] = [];

    for (const policy of policies) {
      const existingPolicy = groupedPolicies.find((p) => p.line_of_business === policy.line_of_business);

      if (existingPolicy) {
        existingPolicy.num_of_policies += 1;
        existingPolicy.premium += policy.premium;
      } else {
        groupedPolicies.push({
          line_of_business: policy.line_of_business,
          num_of_policies: 1,
          premium: policy.premium,
        });
      }
    }

    return groupedPolicies;
  };

  const groupedPolicies = groupPoliciesByLOB(policies);

  const formatLabel = (policy: any) => {
    return policy.line_of_business.toUpperCase();
  };

  // Sort grouped policies by premium for personal and commercial groups
  groupedPolicies.sort((a, b) => b.num_of_policies - a.num_of_policies);

  // TODO separate personal and commercial but will need padding to match data to labels
  const barChartData = {
    labels: groupedPolicies.map(formatLabel),
    datasets: [
      {
        label: 'Line of Business',
        data: groupedPolicies.map((policy) => policy.num_of_policies),
        backgroundColor: '#276EF1',
        borderRadius: 4,
        minBarLength: 6,
      },
    ],
  };

  const handleOpenTopLine = () => {
    setTopOpenLine(!topOpenLine);
  };

  return (
    <div className="w-full bg-core-lightGrey rounded-lg p-6">
      <div className="w-full flex">
        <div className="text-left w-4/5 flex flex-col">
          <h1 className="p-0 m-0 font-medium text-core-black text-lg">Number of Policies by LOB</h1>
        </div>

        <div className="w-1/5 flex justify-end">
          <button className="bg-white rounded-md py-1 px-1.5 hover:shadow" onClick={handleOpenTopLine}>
            {topOpenLine ? (
              <ChevronUpIcon className="w-4 h-4 my-auto" />
            ) : (
              <ChevronDownIcon className="w-4 h-4 my-auto" />
            )}
          </button>
        </div>
      </div>

      {topOpenLine && (
        <div className="w-full">
          <div className="w-full flex my-4 gap-3"></div>
          <div className="w-full">
            <div className="mt-4">
              <Bar
                data={barChartData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  indexAxis: 'y',
                  scales: {
                    y: {
                      position: 'left',
                      ticks: {
                        display: true,
                        color: '#5E5E5E',
                        padding: 4,
                      },
                      grid: {
                        display: false,
                      },
                    },
                    x: {
                      display: true,
                      position: 'top',
                      grid: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BarChart;
