import { ActionWithPayload } from 'src/types/common/ReduxTypes';
import { fetchUserThunk, signOutThunk } from 'src/app/user/UserStoreActions';
import { UserState, UserProfile, UserProfileResponse } from 'src/types/domain/User';

export const UserStoreReducer = {
  [fetchUserThunk.fulfilled.type]: (state: UserState, action: ActionWithPayload<UserProfileResponse>): void => {
    state.userProfile = action.payload?.userProfile;
    state.isUserLoggedIn = !!state.userProfile;
    state.isLoading = false;
  },
  [fetchUserThunk.pending.type]: (state: UserState): void => {
    state.isLoading = true;
  },
  [fetchUserThunk.rejected.type]: (state: UserState): void => {
    state.isLoading = false;
    state.isUserLoggedIn = false;
  },
  [signOutThunk.fulfilled.type]: (state: UserState): void => {
    state.isLoading = false;
    state.isUserLoggedIn = false;
    state.userProfile = {} as UserProfile;
  },
};
