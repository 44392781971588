import React, { ReactNode } from 'react';
import { useHistory } from 'react-router';

interface Tab {
  label: string;
  content: ReactNode;
  labelUrl: string;
}

interface TabsProps {
  tabs: Tab[];
  onTabClick?: (index: number) => void;
  variant?: boolean | true;
  activeTab: number;
  type?: string;
}

const Tabs: React.FC<TabsProps> = ({ tabs, activeTab, variant, onTabClick, type }) => {
  const history = useHistory();

  const handleTabClick = (index: number) => {
    const labelUrl = tabs[index].labelUrl;
    history.push(`#${labelUrl}`);

    if (onTabClick) {
      onTabClick(index);
    }
  };

  return (
    <div className="w-full">
      <div
        className={`${
          type === 'pills' ? 'pb-0' : 'border-b border-gray-200 mb-3'
        } flex w-full overflow-auto md:pb-0 md:overflow-hidden`}
      >
        {tabs.map((tab, index) => (
          <>
            {type === 'pills' ? (
              <button
                key={tab.label}
                onClick={() => handleTabClick(index)}
                className={`border-1 rounded-full border-gray-200 uppercase  ${
                  index === activeTab ? 'bg-core-black text-white' : 'bg-white text-gray-700'
                } p-4 py-2 font-bold text-sm mr-2 hover:!bg-core-black hover:text-white transition ease-in`}
              >
                {tab.label}
              </button>
            ) : (
              <button
                key={index}
                onClick={() => handleTabClick(index)}
                className={`flex pb-3 pt-2 gap-3 mr-6 whitespace-nowrap text-md transition ease-in ${
                  index === activeTab ? 'border-b-2 border-core-black ' : 'border-b-0'
                } hover:border-core-black hover:border-b-2 focus:outline-none focus:border-core-black`}
              >
                {tab.label}
              </button>
            )}
          </>
        ))}
      </div>
      <div className={`${variant === true ? 'bg-white rounded px-10 py-8' : 'my-4'}`}>{tabs[activeTab].content}</div>
    </div>
  );
};

export default Tabs;
