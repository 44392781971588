import { getUser } from 'src/api/user';
import { UserInfo } from '../../types/User';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface OnboardingStore {
  userInfo: UserInfo | null;
  setUserInfo: () => void;
}

export const useOnboardingStore = create<OnboardingStore>()(
  persist(
    (set) => ({
      userInfo: null,
      setUserInfo: async () => {
        const user = await getUser();
        set({ userInfo: user as UserInfo });
      },
    }),
    {
      name: 'eat-cake-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
