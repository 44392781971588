import { ArrowLeftIcon, ArrowPathIcon, PlusIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src/app/hooks';
import { Button } from 'src/app/shared/components/Buttons/Button';
import { userStateSelector } from 'src/app/user/UserStoreSelectors';
import cakeFinalIcon from 'src/styles/assets/logos/cakeFinalIcon.svg';

interface uploadProps {
  jotForm?: boolean;
  onClose: () => void;
  onBack: () => void;
}

interface Step {
  id: number;
  renderContent: () => React.ReactNode;
}

const UploadModal = (props: uploadProps): React.ReactElement => {
  const { jotForm, onClose, onBack } = props;
  const [currentStep, setCurrentStep] = useState(1);
  const [fileName, setFileName] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const { userProfile } = useAppSelector(userStateSelector);

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = () => {
    onBack();
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    if (!fileName) {
      e.preventDefault();
      setShowSpinner(true);
      setTimeout(() => {
        setShowSpinner(false);
      }, 15000);
    }
  };

  const handleDropzoneClick = () => {
    if (fileName === '') {
      const inputElement = document.getElementById('dropzone-file') as HTMLInputElement;
      inputElement.click();
      setShowSpinner(true);
      setTimeout(() => {
        setShowSpinner(false);
        setButtonsDisabled(false);
      }, 15000);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    if (!fileName) {
      e.preventDefault();
      setFileLoaded(true);
      const file = e.dataTransfer.files[0];
      setFileName(file.name);
      setShowSpinner(true);
      setTimeout(() => {
        setShowSpinner(false);
        setButtonsDisabled(false);
      }, 15000);
    }
  };

  const handleClearFile = () => {
    setFileName('');
    setButtonsDisabled(false);
  };

  const handleReloadFile = () => {
    setShowSpinner(true);
    setTimeout(() => {
      setShowSpinner(false);
      setButtonsDisabled(false);
    }, 15000);
  };

  const stepsData: Step[] = [
    {
      id: 1,
      renderContent: () => (
        <>
          <div className="w-full flex flex-col mt-16 mb-3 px-20">
            <h1 className="text-core-black text-3xl mb-4 font-medium text-center">
              Upload files and let Cake do the rest
            </h1>
            <p className="text-gray-700 text-lg font-medium p-0 text-center mb-8">
              We use files right from your AMS to create your book of business on Cake. Upload your files and Cake will
              do the rest!
            </p>
          </div>

          <div className="w-full gap-3 px-36 mb-16">
            <div
              id="dropzone"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={handleDropzoneClick}
              className={` ${
                fileName
                  ? 'bg-white border-1 border-gray-300'
                  : 'bg-gray-100 border-2 border-gray-300 border-dashed cursor-pointer'
              } rounded p-10 flex`}
            >
              <div className="w-4/5 text-left">
                <h2 className="text-core-black font-medium text-2xl mb-2">Drag files here or browse</h2>
                {fileName ? (
                  <p className="text-color-[#4D4D4D] p-0 m-0 text-sm">{fileName}</p>
                ) : (
                  <>
                    <p className="text-color-[#4D4D4D] p-0 m-0 text-sm">Supported file types: PDF, CSV and XLSX</p>
                    <p className="text-color-[#4D4D4D] p-0 m-0 text-sm">Max file size: 20 MB</p>
                  </>
                )}
              </div>
              <div className="w-1/5 flex justify-end items-center">
                {fileName ? (
                  <div className="relative">
                    {showSpinner ? (
                      <>
                        <button
                          className="rounded-full border p-2 z-10 h-9 w-9 bg-white text-core-black transition ease-in relative"
                          onClick={handleClearFile}
                          type="button"
                        >
                          <XMarkIcon className="h-5 w-5" />
                        </button>

                        <div role="status" className="z-0 -mt-[38px]">
                          <svg
                            aria-hidden="true"
                            className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        </div>
                      </>
                    ) : (
                      <div className="w-fit flex gap-2">
                        <button
                          className="rounded-full border-1 border-core-black p-2 z-10 h-9 w-9 bg-white text-core-black transition ease-in relative"
                          onClick={handleReloadFile}
                          type="button"
                        >
                          <ArrowPathIcon className="h-4 w-4 m-auto" />
                        </button>

                        <button
                          className="rounded-full border-1 border-core-black p-2 z-10 h-9 w-9 bg-white text-core-black transition ease-in relative"
                          onClick={handleClearFile}
                          type="button"
                        >
                          <TrashIcon className="h-4 w-4 m-auto" />
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <button
                    type="button"
                    className="rounded-full p-2 h-9 w-9 bg-core-yellow text-core-black transition ease-in"
                    onClick={handleDropzoneClick}
                  >
                    <PlusIcon className="h-5 w-5" />
                  </button>
                )}
              </div>

              <input
                type="file"
                id="dropzone-file"
                className="hidden"
                name="bulkupload"
                accept=".csv, .pdf, .xlsx"
                onChange={(e): void => {
                  setFileLoaded(true);
                  const target = e.target as HTMLInputElement;
                  if (target.files && target.files.length > 0) {
                    setFileName(target.files[0].name);
                  }
                }}
                disabled={fileLoaded}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      id: 2,
      renderContent: () => (
        <>
          <div className="w-full flex flex-col my-16 px-48">
            <img src={cakeFinalIcon} alt="check icon" className="w-24 h-auto mx-auto mb-3" />
            <h1 className="text-core-black text-3xl mb-6 font-medium text-center">
              Done. We’ll reach out soon with next steps
            </h1>
            <p className="text-gray-700 text-base font-medium p-0 text-center mb-8">
              Cake will set up your book of business on the platform and a representative will reach out within 2
              business days.
            </p>
            <div className="w-fit mx-auto">
              <Button variant="fill" color="secondary" onClick={onClose}>
                Got it
              </Button>
            </div>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    const stackEnv = localStorage.getItem('stackEnv') || 'unknown';
    const script = document.createElement('script');
    script.src = `https://form.jotform.com/jsform/240675859063062?user=${encodeURIComponent(
      userProfile.email
    )}&env=${encodeURIComponent(stackEnv)}`;
    script.async = true;
    document.getElementById('jotform-container')?.appendChild(script);

    return () => {
      document.getElementById('jotform-container')?.removeChild(script);
    };
  }, []);

  const initialValues = {
    bulkupload: '',
  };

  const onSubmit = async () => {
    // add BE service here
    console.log(fileName);
  };

  return (
    <>
      {/* {To delete with the backend integration} */}
      {jotForm ? (
        <>
          <div id="jotform-container" className="w-full px-20 py-0 h-fit"></div>
        </>
      ) : (
        <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize>
          <Form className="w-full">
            <div className="w-full">
              {stepsData.map((step) => (
                <div key={step.id} style={{ display: step.id === currentStep ? 'block' : 'none' }}>
                  {step.renderContent()}

                  {step.id === 1 && (
                    <div className="w-full border-t p-6 flex">
                      <div className="w-1/2 flex justify-start">
                        <button
                          onClick={handlePrevStep}
                          className="flex py-2 px-4 w-fit text-core-black font-bold uppercase gap-2 hover:bg-neutral-50 transition ease-in rounded"
                        >
                          <ArrowLeftIcon className="h-3 w-3 my-auto stroke-black" /> Back
                        </button>
                      </div>

                      <div className="w-full flex justify-end">
                        <div className="w-fit">
                          <Button variant="fill" color="primary" onClick={handleNextStep} disabled={buttonsDisabled}>
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default UploadModal;
