import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import UserSettings from 'src/app/user/settings/UserSettings';
import { Home } from './home/Home';
import { PrivateRoute } from './shared/components/privateRoute/PrivateRoute';
import { Role, BasicInfo, AddressInfo, BasicInfoContinue, Interests, NextSteps } from './onboarding';
import UserSettingsCard from './user/settings/components/UserSettingsCard';
import NotFound from './shared/components/Page404/Page404';
import BookAnalysis from './shared/components/BookAnalysis/BookAnalysis';
import Pricing from './user/settings/components/Pricing&Success/Pricing';
import SucessPurchase from './user/settings/components/Pricing&Success/Success';
import { OnboardingComplete } from './onboarding/pages/OnBoardingComplete';
import BuyerProfileStep1 from './onboarding/pages/BuyerProfile';
import StatesBuyerProfile from './onboarding/pages/StatesBuyerProfile';
import CarrersBuyerProfile from './onboarding/pages/CarriersBuyerProfile';
import LobBuyerProfile from './onboarding/pages/LobBuyerProfile';
import PremiumBuyerProfile from './onboarding/pages/PremiumBuyerProfile';
import ReadinessBuyerProfile from './onboarding/pages/ReadinessBuyerProfile';
import BuySlicesBuyerProfile from './onboarding/pages/buySlicesBuyerProfile';
import BuyerProfileDescription from './onboarding/pages/BuyerProfileDescription';
import BuyerProfilePreview from './onboarding/pages/BuyerProfilePreview';
import BookUpload from './onboarding/pages/BookUpload';
import LoginV2 from './login/Login-V2';
import CreateAccountV2 from './accounts/CreateAccount/CreateAccountV2';
import ConfirmAccountV2 from './accounts/ConfirmAccount/ConfirmAccountV2';
import ForgotV2 from './login/forgot/ForgotV2';
import ChangePasswordFormV2 from './login/forgot/ChangePasswordFormV2';
import PublishProfile from './onboarding/pages/PublishProfile';
import BookManagement from './book-management/BookManagement';
import InboxV2 from './inbox/InboxV2';
import BuyersDatabases from './buyers-database/BuyersDatabase';

const Routes: React.FC<{
  isLoading: boolean;
  isUserLoggedIn: boolean;
  showAnalytics: (show: boolean) => void;
  onUrlChange: (url: string) => void;
}> = ({ isLoading, isUserLoggedIn, onUrlChange, showAnalytics }) => {
  const location = useLocation();

  const handleShowAnalytics = (show: boolean) => {
    showAnalytics(show);
  };

  useEffect(() => {
    onUrlChange(location.pathname + location.search);
  }, [location, onUrlChange]);

  return (
    <Switch>
      <Route path="/change-password" exact={true} component={ChangePasswordFormV2} />
      <Route path="/confirm-account-2" exact={true} component={ConfirmAccountV2} />
      <Route path="/create-account" exact={true} component={CreateAccountV2} />
      <Route
        path="/login"
        exact={true}
        component={() => <LoginV2 isLoading={isLoading} isUserLoggedIn={isUserLoggedIn} />}
      />
      <Route path="/forgot" exact={true} component={ForgotV2} />
      <Route path="/create-account" exact={true} component={CreateAccountV2} />

      <PrivateRoute path="/" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <Home />
      </PrivateRoute>
      <PrivateRoute path="/slices" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <Home />
      </PrivateRoute>
      <PrivateRoute path="/buyers-database" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <BuyersDatabases />
      </PrivateRoute>
      <PrivateRoute path="/book-management" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <BookManagement onShowAnalytics={handleShowAnalytics} />
      </PrivateRoute>
      <PrivateRoute path="/inbox" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <InboxV2 />
      </PrivateRoute>
      <PrivateRoute path="/book-analysis" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <BookAnalysis />
      </PrivateRoute>
      <PrivateRoute path="/account" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <UserSettings />
      </PrivateRoute>
      <PrivateRoute path="/settings" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <UserSettingsCard />
      </PrivateRoute>
      <PrivateRoute path="/onboarding/role" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <Role />
      </PrivateRoute>
      <PrivateRoute path="/onboarding/basic-info" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <BasicInfo />
      </PrivateRoute>
      <PrivateRoute path="/onboarding/address" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <AddressInfo />
      </PrivateRoute>
      <PrivateRoute
        path="/onboarding/basic-info-continue"
        exact={true}
        isLoading={isLoading}
        isUserLoggedIn={isUserLoggedIn}
      >
        <BasicInfoContinue />
      </PrivateRoute>
      <PrivateRoute path="/onboarding/interests" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <Interests />
      </PrivateRoute>
      <PrivateRoute path="/onboarding/next-steps" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <NextSteps />
      </PrivateRoute>
      <PrivateRoute path="/account/pricing" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <Pricing />
      </PrivateRoute>
      <PrivateRoute path="/checkout/success" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <SucessPurchase />
      </PrivateRoute>
      <PrivateRoute path="/onboarding/completed" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <OnboardingComplete />
      </PrivateRoute>
      {/* BuyerProfileRoutes */}
      <PrivateRoute path="/onboarding/buyer-profile" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <BuyerProfileStep1></BuyerProfileStep1>
      </PrivateRoute>
      <PrivateRoute
        path="/onboarding/buyer-profile/states"
        exact={true}
        isLoading={isLoading}
        isUserLoggedIn={isUserLoggedIn}
      >
        <StatesBuyerProfile></StatesBuyerProfile>
      </PrivateRoute>
      <PrivateRoute
        path="/onboarding/buyer-profile/carriers"
        exact={true}
        isLoading={isLoading}
        isUserLoggedIn={isUserLoggedIn}
      >
        <CarrersBuyerProfile></CarrersBuyerProfile>
      </PrivateRoute>
      <PrivateRoute
        path="/onboarding/buyer-profile/lob"
        exact={true}
        isLoading={isLoading}
        isUserLoggedIn={isUserLoggedIn}
      >
        <LobBuyerProfile></LobBuyerProfile>
      </PrivateRoute>
      <PrivateRoute
        path="/onboarding/buyer-profile/premium-range"
        exact={true}
        isLoading={isLoading}
        isUserLoggedIn={isUserLoggedIn}
      >
        <PremiumBuyerProfile></PremiumBuyerProfile>
      </PrivateRoute>
      <PrivateRoute
        path="/onboarding/buyer-profile/readiness"
        exact={true}
        isLoading={isLoading}
        isUserLoggedIn={isUserLoggedIn}
      >
        <ReadinessBuyerProfile></ReadinessBuyerProfile>
      </PrivateRoute>
      <PrivateRoute
        path="/onboarding/buyer-profile/buy-slices"
        exact={true}
        isLoading={isLoading}
        isUserLoggedIn={isUserLoggedIn}
      >
        <BuySlicesBuyerProfile></BuySlicesBuyerProfile>
      </PrivateRoute>
      <PrivateRoute
        path="/onboarding/buyer-profile/description"
        exact={true}
        isLoading={isLoading}
        isUserLoggedIn={isUserLoggedIn}
      >
        <BuyerProfileDescription></BuyerProfileDescription>
      </PrivateRoute>
      <PrivateRoute
        path="/onboarding/buyer-profile/preview"
        exact={true}
        isLoading={isLoading}
        isUserLoggedIn={isUserLoggedIn}
      >
        <BuyerProfilePreview></BuyerProfilePreview>
      </PrivateRoute>
      <PrivateRoute
        path="/onboarding/buyer-profile/publish"
        exact={true}
        isLoading={isLoading}
        isUserLoggedIn={isUserLoggedIn}
      >
        <PublishProfile></PublishProfile>
      </PrivateRoute>
      <PrivateRoute path="/onboarding/book-upload" exact={true} isLoading={isLoading} isUserLoggedIn={isUserLoggedIn}>
        <BookUpload></BookUpload>
      </PrivateRoute>
      <Route path="**" component={NotFound} />
    </Switch>
  );
};
export default Routes;
