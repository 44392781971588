import React, { FC } from 'react';

interface InputMultipleProps {
  values: string[];
  name: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  variant?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InputMultiple: FC<InputMultipleProps> = ({ values, name, onChange, variant }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className="flex gap-2 flex-wrap w-full">
      {variant ? (
        <>
          <div className="text-gray-700 text-sm font-medium">{values.join(', ')}</div>
        </>
      ) : (
        <>
          {values.map((value, index) => (
            <div
              key={index}
              className="rounded-full px-3 py-2 text-core-black w-fit text-center border border-gray-200 bg-gray-50 text-sm font-bold"
            >
              {value}
            </div>
          ))}
        </>
      )}

      <input type="text" name={name} onChange={handleInputChange} className="w-full hidden" />
    </div>
  );
};

export default InputMultiple;
