import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as ArrowUpIcon } from '../../../../styles/assets/icons/mini/upArrowChevron.svg';
import { ReactComponent as ArrowDownIcon } from '../../../../styles/assets/icons/mini/downArrowChevron.svg';

interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  options: Option[];
  value?: string;
  name?: string;
  labelInput?: string;
  isRequiredInput?: boolean | false;
  onChange?: (e: React.ChangeEvent<any>) => void;
  errorText?: string;
  positionLast?: boolean;
  placeholderOption?: string | '';
  disabled?: boolean;
}

const CustomSelect: React.FC<SelectProps> = ({
  options,
  value,
  name,
  labelInput,
  isRequiredInput,
  onChange,
  errorText,
  positionLast,
  placeholderOption,
  disabled,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(value);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      toggleDropdown();
    } else if (event.key === 'Escape') {
      setIsOpen(false);
    } else if (event.key === 'ArrowDown' && !isOpen) {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionClick = (optionValue: string) => {
    setSelectedOption(optionValue);
    setIsOpen(false);
    if (onChange) {
      const event = {
        target: {
          name,
          value: optionValue,
        },
      } as React.ChangeEvent<any>;
      onChange(event);
    }
  };

  const arrowIcon = isOpen ? (
    <ArrowUpIcon className="w-4 h-4 my-auto flex justify-end" />
  ) : (
    <ArrowDownIcon className="w-4 h-4 my-auto flex justify-end" />
  );

  return (
    <div className={`w-full grid grid-cols-1 ${disabled ? 'opacity-50' : ''}`}>
      <div
        ref={selectRef}
        className={`relative w-full ${disabled ? 'pointer-events-none' : ''}`}
        onKeyDown={handleKeyDown}
      >
        <label className="flex justify-start bg-white px-2 mx-2 -mb-2 w-fit relative text-neutral-700 text-xs font-semibold gap-1 uppercase">
          {labelInput}
          {isRequiredInput && <span className="text-red-500">*</span>}
        </label>
        <div
          tabIndex={0}
          className={`cursor-pointer flex w-full !border-gray-700 rounded-[4px] border-1 p-2.5 text-gray-600 text-sm text-left ${
            disabled ? 'bg-gray-200' : ''
          }`}
          onClick={toggleDropdown}
        >
          <span className="w-full">
            {selectedOption
              ? options.find((option) => option.value === selectedOption)?.label
              : placeholderOption === ''
              ? placeholderOption
              : 'Select an option'}
          </span>
          {arrowIcon}
        </div>
        {isOpen && (
          <ul
            className={`absolute z-1 bg-white rounded border p-2 w-full max-h-[220px] overflow-y-auto ${
              positionLast ? '-mt-[270px]' : 'mt-1'
            }`}
          >
            {options.map((option, index) => (
              <li
                className="cursor-pointer text-left p-3 text-sm hover:bg-neutral-50 rounded transition ease-in"
                key={index}
                onClick={() => handleOptionClick(option.value)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>

      {errorText !== '' ? (
        <div className="flex w-full">
          <span className="text-system-negative text-xs text-start ml-2 mt-2">{errorText}</span>
        </div>
      ) : null}
    </div>
  );
};

export default CustomSelect;
