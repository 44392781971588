import React, { useEffect, useState } from 'react';
import cakeLogoGold from '../../styles/assets/logos/cake-logo-gold.svg';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Input from '../shared/components/Inputs/Input';
import Button from '../shared/components/Buttons/Button';
import { ReactComponent as CheckIconMini } from '../../styles/assets/icons/mini/checkMini.svg';
import { Form, Formik } from 'formik';
import { userLoginSchema } from '../onboarding/schemas/userLogin.schema';
import { userPostLogin } from 'src/api/user';
import useAlert from '../shared/components/Toast/useAlert';
import { LoadingIcon } from 'src/styles/assets/icons/Icons';

interface loginProps {
  isLoading: boolean;
  isUserLoggedIn: boolean;
}

interface RedirectLocationState {
  referrer: Location;
}

const Loginv2 = (props: loginProps): React.ReactElement => {
  const { isLoading, isUserLoggedIn } = props;
  const history = useHistory();
  const { state: locationState } = useLocation();
  const [checked, setChecked] = useState(false);
  const [isRememberChecked, setIsRememberChecked] = useState(!!localStorage.getItem('rememberUserEmail'));
  const { alerts, createToast } = useAlert();
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const [isPendingButton, setIsPendingButton] = useState(false);

  useEffect(() => {
    if (!isLoading && isUserLoggedIn) {
      if (locationState) {
        const { referrer } = locationState as RedirectLocationState;
        window.location.href = `${referrer.pathname}${referrer.search}`;
      } else {
        window.location.href = '/slices';
      }
    }
  }, [isLoading, isUserLoggedIn, history]);

  const handleCheckboxChange = () => {
    setChecked(!checked);
    setIsRememberChecked(!isRememberChecked);
  };

  const handleRedirectAfterLogin = () => {
    if (locationState) {
      const { referrer } = locationState as RedirectLocationState;

      window.location.href = `${referrer.pathname}${referrer.search}`;
    } else {
      window.location.href = '/slices';
    }
  };

  useEffect(() => {
    setChecked(!!localStorage.getItem('rememberUserEmail'));
    setIsRememberChecked(!!localStorage.getItem('rememberUserEmail'));
  }, []);

  const initialValues = {
    email: localStorage.getItem('rememberUserEmail') || '',
    password: '',
  };

  useEffect(() => {
    if (isRememberChecked) {
      localStorage.setItem('rememberUserEmail', initialValues.email);
    } else {
      localStorage.removeItem('rememberUserEmail');
    }
  }, [isRememberChecked, initialValues.email]);

  const onSubmit = async (values: any) => {
    setIsPendingButton(true);
    try {
      const response = await userPostLogin(values.email, values.password);
      if (response.message) {
        isRememberChecked ? localStorage.setItem('rememberUserEmail', values.email) : localStorage.clear();
        handleRedirectAfterLogin();
      } else {
        createToast({ text: response.message, variant: 'danger' });
      }
    } catch (error) {
      console.log(error);
      createToast({
        text: 'Incorrect username or password.',
        variant: 'danger',
      });
    }
    setIsPendingButton(false);
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={userLoginSchema} enableReinitialize>
      {({ values, handleChange }) => (
        <>
          <Form className="w-full">
            <div className="h-full w-full sticky top-0 z-50 md:py-2 px-2 py-20">
              <div className="bg-core-black rounded sm:min-h-[98vh] w-full md:w-2/5 p-14">
                <div className="w-full mb-16">
                  <img className="h-auto flex justify-start w-14" src={cakeLogoGold} />
                </div>
                <div className="h-[60vh] flex flex-col items-center justify-center">
                  <div className="w-full">
                    <h1 className="text-white text-3xl md:text-5xl font-medium text-left">Welcome Back!</h1>
                  </div>

                  <div className="w-full flex gap-2 mb-14">
                    <p className="text-gray-400 text-base p-0 m-0 font-medium">Don’t have an account?</p>
                    <strong
                      className="text-white text-base p-0 m-0 font-medium underline cursor-pointer hover:text-gray-100 transition ease-in"
                      onClick={(): void => history.push('/create-account')}
                    >
                      Sign up
                    </strong>
                  </div>

                  <div className="w-full space-y-3">
                    <Input
                      type="email"
                      placeholder="Enter your email address"
                      name="email"
                      labelInput="Email address"
                      isRequiredInput={true}
                      variant={true}
                      onChange={(e) => {
                        handleChange(e);
                        setButtonsDisabled(false);
                      }}
                      value={values.email || ''}
                    />

                    <Input
                      type="password"
                      placeholder="Enter your password"
                      name="password"
                      labelInput="Password"
                      isRequiredInput={true}
                      variant={true}
                      onChange={(e) => {
                        handleChange(e);
                        setButtonsDisabled(false);
                      }}
                      value={values.password || ''}
                    />
                  </div>

                  <div className="w-full md:-mt-8 mt-4 mb-6 md:flex flex-col md:flex-row md:space-y-0 space-y-3">
                    <div className="flex gap-4 justify-start md:w-1/2 w-full my-auto">
                      <input
                        type="checkbox"
                        className="hidden"
                        id="checkbox"
                        checked={checked}
                        onClick={handleCheckboxChange}
                      />
                      <label
                        htmlFor="checkbox"
                        className={`h-5 w-5 my-auto group flex items-center justify-center cursor-pointer border-1 border-white rounded-[4px] ${
                          checked ? 'bg-white' : 'bg-core-black'
                        }`}
                      >
                        {checked && <CheckIconMini className="w-3 h-3 text-black stroke-2" />}
                      </label>

                      <p className="p-0 m-0 text-white cursor-pointer my-auto">Remember Me</p>
                    </div>

                    <div className="flex md:my-auto mb-10 md:w-1/2 md:justify-end w-full justify-start">
                      <p
                        className="text-white text-base p-0 m-0 font-medium underline cursor-pointer hover:text-gray-100 transition ease-in"
                        onClick={(): void => history.push('/forgot')}
                      >
                        Forgot password?
                      </p>
                    </div>
                  </div>

                  <div className="w-full">
                    <Button variant="fill" color="primary" type="submit" disabled={buttonsDisabled}>
                      {isPendingButton ? (
                        <LoadingIcon className=" m-auto w-8 h-8 text-gray-200 animate-spin fill-yellow-600" />
                      ) : (
                        'Continue'
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {alerts}
          </Form>
        </>
      )}
    </Formik>
  );
};

export default Loginv2;
