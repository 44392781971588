import React, { FC, useState } from 'react';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ArrowRightIcon, CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { patchMapFields } from 'src/api/mapFields';
import { Button } from 'src/app/shared/components/Buttons/Button';
import FilterModalSelector from 'src/app/shared/components/FilterModalSelector/filterModalSelector';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import { formatDateShort } from 'src/app/shared/utils/formatters';
import { useGetMapFields } from 'src/hooks/useGetMapFields';

interface ModalManageMappingProps {
  onClose: () => void;
  integrationData?: any;
}

const ModalManageMapping: FC<ModalManageMappingProps> = ({ onClose, integrationData }) => {
  const { data: fieldsData, isPending, refetch } = useGetMapFields();
  const { alerts, createToast } = useAlert();
  const [showSendReport, setShowReport] = useState(true);
  const [isModalManageMapping, setIsModalManageMapping] = useState(true);
  const [uidSelect, setUidSelect] = useState<string>('');
  const [indicatorModal, setIndicatorModal] = useState<string>('');

  const carrierListWithIndicator = (fieldsData?.carrier || []).map((item) => ({
    ...item,
    indicator: 'CARRIER',
    selectedMapped: '',
  }));
  const lobListWithIndicator = (fieldsData?.lob || []).map((item) => ({
    ...item,
    indicator: 'LINE',
    selectedMapped: '',
  }));

  const [combinedList, setCombinedList] = useState([...carrierListWithIndicator, ...lobListWithIndicator]);

  const mappedItems = combinedList.filter((item) => item.mapped).sort((a, b) => a.unmapped.localeCompare(b.unmapped));

  const unmappedItems = combinedList
    .filter((item) => !item.mapped)
    .sort((a, b) => a.unmapped.localeCompare(b.unmapped));

  const [isDisabled, setIsDisabled] = useState(unmappedItems.length === 0);

  const combinedListWithOther = combinedList.map((item) => ({
    ...item,
    selectedMapped: item.selectedMapped !== '' ? item.selectedMapped : 'Other',
    mapped: item.mapped !== '' ? item.mapped : 'Other',
  }));

  const handleSendReport = () => {
    createToast({ text: 'Report sent', variant: 'success' });
    setShowReport(false);
  };

  const handleSelectionMappingValue = (uid: string, indicator: string) => {
    setIsModalManageMapping(false);
    setUidSelect(uid);
    setIndicatorModal(indicator);
  };

  const handleBackSelectionMappingValues = () => {
    setIsModalManageMapping(true);
    setUidSelect('');
    setIndicatorModal('');
  };

  const handleSave = (uidOption: string, selectedOption: string) => {
    const updatedCombinedList = combinedList.map((item) => {
      if (item.uid === uidOption) {
        setIsDisabled(false);
        return { ...item, selectedMapped: selectedOption };
      }
      return item;
    });

    setCombinedList(updatedCombinedList);
  };

  const handleMappingSave = async () => {
    try {
      await patchMapFields(combinedListWithOther);
      refetch();
      createToast({ text: 'Integration update', variant: 'success' });
      setIsDisabled(true);
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Error sending message:', error);
      createToast({ text: 'Error sending request', variant: 'warning' });
    }
  };

  return (
    <div
      onClick={onClose}
      className="fixed top-0 z-50 left-0 w-full h-full flex justify-center items-center backdrop-blur-md bg-black/50"
    >
      {isModalManageMapping ? (
        <div
          className="bg-white rounded shadow-lg w-[800px] border"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="w-full flex border-b px-6 py-4">
            <div className="w-10/12 text-left">
              <h2 className="text-core-black font-medium text-2xl mb-2">Manage Mapping</h2>
              <p className="text-gray-700 text-sm font-medium p-0 m-0 items-center">
                {integrationData.integration.system_name} Sync{' '}
                <span className="text-2xs my-auto items-center mx-0.5">&#9679;</span> Run{' '}
                {formatDateShort(integrationData.integration.last_sync || integrationData.integration.last_modified)}
              </p>
            </div>
            <div className="w-2/12 flex justify-end">
              <button className="rounded-full p-2 hover:bg-neutral-50 transition ease-in h-fit" onClick={onClose}>
                <XMarkIcon className="h-5 w-5 text-core-black" />
              </button>
            </div>
          </div>

          {isPending ? (
            <div className="w-full">
              <Skeleton type="manageMapping" />
            </div>
          ) : (
            <div className="w-full max-h-[500px] overflow-y-auto pb-4">
              {unmappedItems.length !== 0 && (
                <div className="w-full p-6 text-left">
                  <h4 className="text-core-black font-medium text-lg p-0 m-0">Please review all fields</h4>
                  <p className="p-0 m-0 text-gray-700 text-sm mt-2">
                    Fields with no apparent match have been mapped as 'Other'.
                  </p>
                </div>
              )}

              {/* ///////// Unmaped list ///////// */}
              {unmappedItems.length !== 0 && (
                <div className="w-full mt-6 gap-3 flex px-6 items-center">
                  <h1 className="text-core-black font-medium text-lg my-auto">Unmapped</h1>
                  <span className="text-core-warning text-sm rounded py-1 px-2 bg-[#9F640208] gap-2 flex items-center">
                    <ExclamationTriangleIcon className="h-5 w-5" />{' '}
                    {unmappedItems.length > 1
                      ? unmappedItems.length + ' ' + 'fields'
                      : unmappedItems.length + ' ' + 'field'}{' '}
                    need review
                  </span>
                </div>
              )}

              {unmappedItems.map((item, index) => (
                <div className="w-full px-6 flex text-left mt-8" key={index}>
                  <div className="w-2/5">
                    {index === 0 && (
                      <div className="w-full">
                        <h5 className="uppercase text-gray-700 font-bold text-xs">Source</h5>
                      </div>
                    )}

                    <div className="w-full space-y-1">
                      <div className="w-full flex border-gray-300 bg-neutral-100 border-2 rounded-[4px] p-3">
                        <div className="w-9/12">
                          <p className="p-0 m-0 text-gray-700 font-medium text-base truncate max-w-[190px]">
                            {item.unmapped}
                          </p>
                        </div>

                        <div className="w-3/12 my-auto flex justify-end">
                          <div className="py-1 px-2 border rounded-[4px] bg-white text-core-black uppercase text-xs items-center">
                            {item.indicator}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-1/5">
                    {(item.mapped === null && item.mapped === '') || item.selectedMapped === '' ? (
                      <ExclamationTriangleIcon
                        className={`mx-auto ${index === 0 ? 'mt-10' : 'mt-3'} h-8 w-8 text-core-warning`}
                      />
                    ) : (
                      <CheckIcon
                        className={`mx-auto ${index === 0 ? 'mt-10' : 'mt-3'} h-7 w-7 p-2 rounded-full bg-core-yellow`}
                      />
                    )}
                  </div>

                  <div className="w-2/5">
                    {index === 0 && (
                      <div className="w-full">
                        <h5 className="uppercase text-gray-700 font-bold text-xs">CAKE</h5>
                      </div>
                    )}

                    <div className="w-full space-y-1">
                      <div
                        className="w-full flex border-gray-600 bg-white border-2 rounded-[4px] p-3 cursor-pointer transition ease-in hover:border-gray-400"
                        onClick={() => handleSelectionMappingValue(item.uid, item.indicator)}
                      >
                        <div className="w-11/12">
                          <p className="p-0 m-0 text-gray-700 font-medium text-base truncate max-w-[400px]">
                            {item.selectedMapped ? item.selectedMapped : item.mapped ? item.mapped : 'Other'}
                          </p>
                        </div>

                        <div className="w-1/12 my-auto flex justify-end">
                          <ChevronDownIcon className="h-5 w-5 my-auto" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {showSendReport && (
                <div className="w-full px-6 mt-3">
                  {unmappedItems.length !== 0 && (
                    <div className="w-full rounded-[4px] bg-core-accentBlueBg p-3 text-left flex">
                      <div className="w-8/12">
                        <p className="p-0 m-0 text-core-black font-medium">
                          <strong>Didn't find an a appropiate match?</strong> Let us know
                        </p>
                      </div>

                      <div className="w-4/12 flex justify-end">
                        <button
                          type="button"
                          className="flex gap-2 uppercase font-bold text-xs items-center transition ease-in hover:bg-blue-100 py-1 px-2 rounded"
                          onClick={handleSendReport}
                        >
                          Send Report <ArrowRightIcon className="h-3 w-3 my-auto" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* ///////// Maped list ///////// */}

              {mappedItems.length !== 0 && (
                <div className="w-full mt-12 gap-3 flex px-6 items-center">
                  <h1 className="text-core-black font-medium text-lg my-auto">Mapped</h1>
                  <span className="text-core-black text-sm rounded py-1 px-2 bg-neutral-50 gap-2 flex items-center">
                    {mappedItems.length > 1 ? mappedItems.length + ' ' + 'fields' : mappedItems.length + ' ' + 'field'}{' '}
                    mapped
                  </span>
                </div>
              )}

              {mappedItems.map((item, index) => (
                <div className="w-full px-6 flex text-left mt-8" key={index}>
                  <div className="w-2/5">
                    {index === 0 && (
                      <div className="w-full">
                        <h5 className="uppercase text-gray-700 font-bold text-xs">Source</h5>
                      </div>
                    )}

                    <div className="w-full space-y-1">
                      <div className="w-full flex border-gray-300 bg-neutral-100 border-2 rounded-[4px] p-3">
                        <div className="w-9/12">
                          <p className="p-0 m-0 text-gray-700 font-medium text-base truncate max-w-[190px]">
                            {item.unmapped}
                          </p>
                        </div>

                        <div className="w-3/12 my-auto flex justify-end">
                          <div className="py-1 px-2 border rounded-[4px] bg-white text-core-black uppercase text-xs items-center">
                            {item.indicator}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-1/5">
                    <ArrowRightIcon className={`mx-auto ${index === 0 ? 'mt-10' : 'mt-3'} h-7 w-7 text-core-black`} />
                  </div>

                  <div className="w-2/5">
                    {index === 0 && (
                      <div className="w-full">
                        <h5 className="uppercase text-gray-700 font-bold text-xs">CAKE</h5>
                      </div>
                    )}

                    <div className="w-full space-y-1">
                      <div
                        className="w-full flex border-gray-600 bg-white border-2 rounded-[4px] p-3 cursor-pointer transition ease-in hover:border-gray-400"
                        onClick={() => handleSelectionMappingValue(item.uid, item.indicator)}
                      >
                        <div className="w-11/12">
                          <p className="p-0 m-0 text-gray-700 font-medium text-base truncate max-w-[400px]">
                            {item.selectedMapped === '' ? item.mapped : item.selectedMapped}
                          </p>
                        </div>

                        <div className="w-1/12 my-auto flex justify-end">
                          <ChevronDownIcon className="h-5 w-5 my-auto" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="w-full flex border-t px-6 py-4 items-center">
            {alerts}
            <div className="w-10/12 text-left">
              {unmappedItems.length !== 0 && (
                <span className="text-core-warning text-sm rounded py-2 px-3 bg-[#9F640208] gap-2 flex items-center w-fit">
                  <ExclamationTriangleIcon className="h-5 w-5" />{' '}
                  {unmappedItems.length > 1
                    ? unmappedItems.length + ' ' + 'fields'
                    : unmappedItems.length + ' ' + 'field'}{' '}
                  need review
                </span>
              )}
            </div>
            <div className="w-2/12 flex justify-end my-auto">
              <div className="my-auto">
                <Button variant="fill" color="secondary" disabled={isDisabled} onClick={handleMappingSave}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <FilterModalSelector
            type={indicatorModal}
            uidOption={uidSelect}
            onClose={handleBackSelectionMappingValues}
            onBack={handleBackSelectionMappingValues}
            onSave={handleSave}
          />
        </div>
      )}
    </div>
  );
};

export default ModalManageMapping;
