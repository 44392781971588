import React, { useEffect, useState } from 'react';
import { Onboarding } from '../components/Onboarding';
import { ReactComponent as ClockIcon } from '../../../styles/assets/icons/mini/clock-stopwatch.svg';
import { Button } from 'src/app/shared/components/Buttons/Button';
import { useGetUser } from 'src/hooks';
import Avatar from 'src/app/shared/components/Avatar/Avatar';
import { UsStatesOptions } from 'src/app/shared/ListingEnums';
import { useGetBuyerProfile } from 'src/hooks/useBuyersProfile';
import { useHistory } from 'react-router';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';

const BuyerProfileStep1 = () => {
  const history = useHistory();
  const { data: userResponse, isError } = useGetUser();
  const [userData, setUserData] = useState(userResponse);
  const { data: buyerProfileResponse, isPending } = useGetBuyerProfile();
  const [, setBuyerProfile] = useState(buyerProfileResponse);

  useEffect(() => {
    if (buyerProfileResponse) {
      setBuyerProfile(buyerProfileResponse);
    }
  }, [buyerProfileResponse]);

  useEffect(() => {
    if (userResponse && 'userProfile' in userResponse && 'agencyInfo' in userResponse) {
      setUserData(userResponse);
    } else {
      console.log(isError);
    }
  }, [userResponse]);

  const getInitials = (firstName: any, lastName: any): string => {
    const firstInitial = firstName ? firstName.charAt(0) : '';
    const lastInitial = lastName ? lastName.charAt(0) : '';
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${firstInitial}${lastInitial}`;
  };

  const getStateFullName = (stateAbbreviation: any): string => {
    return UsStatesOptions[stateAbbreviation as keyof typeof UsStatesOptions] || '';
  };

  const getBuyingOption = (buyingSlices: boolean) => (buyingSlices ? 'Slices & Full Books' : 'Full Books Only');

  const renderFullStateName = (shortState: string) => {
    if (shortState in UsStatesOptions) {
      return UsStatesOptions[shortState as keyof typeof UsStatesOptions];
    } else {
      return shortState;
    }
  };

  const getSellingOption = (selling: boolean) => (selling ? 'Ready to buy' : 'No timeline');

  const formatPremiumRange = (min: number | any, max: number | any) => {
    const formatValue = (value: number) => {
      if (value < 1000000) {
        return `$${(value / 1000).toFixed(1)}K`;
      } else {
        return `$${(value / 1000000).toFixed(1)}M`;
      }
    };

    const formattedMin = formatValue(min);
    const formattedMax = formatValue(max);

    return `${formattedMin} - ${formattedMax}`;
  };

  if (isPending) {
    return (
      <div className="w-full mb-12 z-0">
        <Skeleton type="onboarding-preview" />
      </div>
    );
  }

  return (
    <Onboarding isContinue={false} isBack={true}>
      <div className="w-full flex flex-col md:flex-row py-20 px-8 md:px-32 text-left gap-8 mb-5 md:mb-0">
        <div className="w-full md:w-3/5 px-2 md:px-12 md:order-2">
          <div className="border-1 drop-shadow-sm shadow-xl !shadow-yellow-200 rounded p-8 !border-core-yellow">
            <div className="flex w-full gap-3">
              <div className="w-fit my-auto">
                <Avatar
                  size="lg2"
                  userName={getInitials(userData?.userProfile.firstName, userData?.userProfile.lastName)}
                />
              </div>

              <div>
                <h2 className="text-core-black text-lg sm:text-2xl font-medium mb-0 capitalize">
                  {userData?.userProfile.firstName} {userData?.userProfile.lastName}
                </h2>
                <p className="text-core-black text-sm md:text-base font-medium m-0 p-0">
                  {userData?.agencyInfo.agency_name}
                </p>
                <span className="text-gray-500 text-[10px] md:text-xs font-bold uppercase">
                  {userData?.agencyInfo.address_city}, {getStateFullName(userData?.agencyInfo.address_state)} •{' '}
                  {userData?.agencyInfo.business_age_yrs} YEARS EXP.
                </span>
              </div>
            </div>

            <div className="w-full flex mt-4 border-b">
              <div className="flex gap-2 w-full mb-4">
                <div className="rounded py-3 px-3 md:px-8 uppercase bg-blue-50 text-core-textNavy text-xs md:text-base font-bold text-left">
                  {getSellingOption(userData?.userProfile.selling ? userData?.userProfile.selling : false)}
                </div>

                <div className="rounded py-3 px-3 md:px-8 uppercase bg-blue-50 text-core-textNavy text-xs md:text-base font-bold text-left">
                  {getBuyingOption(userData?.userProfile.buying ? userData?.userProfile.buying : false)}
                </div>
              </div>
            </div>

            <div className="w-full mt-4">
              <div className="w-full mb-8">
                <h3 className="font-medium text-base">My Preferences</h3>
              </div>

              <div className="grid grid-cols-2 gap-3 md:grid-cols-1">
                <div className="w-full mb-4">
                  <h4 className="text-sm font-bold text-gray-500">STATES</h4>
                  {buyerProfileResponse && buyerProfileResponse?.buyerProfile.stateFilters.length > 0 ? (
                    <div className="flex gap-2 flex-wrap w-full my-2">
                      {buyerProfileResponse?.buyerProfile.stateFilters.slice(0, 3).map((shortState, index) => (
                        <div
                          key={index}
                          className="rounded-full p-2 my-auto text-core-black w-fit text-center border border-gray-200 bg-gray-50 text-xs font-bold"
                        >
                          {renderFullStateName(shortState)}
                        </div>
                      ))}
                      {buyerProfileResponse
                        ? buyerProfileResponse?.buyerProfile.stateFilters.length > 3 && (
                            <div className="px-1 py-2 text-gray-500 w-fit text-center text-md">
                              + {buyerProfileResponse.buyerProfile.stateFilters.length - 3}
                            </div>
                          )
                        : null}
                    </div>
                  ) : (
                    <div className="flex gap-2 flex-wrap w-full mt-2 mb-3">
                      <p className="p-0 m-0 text-gray-500 w-fit text-center text-md">Open to All</p>
                    </div>
                  )}
                </div>

                <div className="w-full">
                  <h4 className="text-sm font-bold text-gray-500">CARRIERS</h4>
                  {buyerProfileResponse && buyerProfileResponse?.buyerProfile.carrierFilters.length > 0 ? (
                    <div className="flex gap-2 flex-wrap w-full my-2">
                      {buyerProfileResponse?.buyerProfile.carrierFilters.slice(0, 3).map((value, index) => (
                        <div
                          key={index}
                          className="rounded-full p-2 my-auto text-core-black w-fit text-center border border-gray-200 bg-gray-50 text-xs font-bold"
                        >
                          {value}
                        </div>
                      ))}
                      {buyerProfileResponse
                        ? buyerProfileResponse?.buyerProfile.carrierFilters.length > 3 && (
                            <div className="px-1 py-2 text-gray-500 w-fit text-center text-md">
                              + {buyerProfileResponse.buyerProfile.carrierFilters.length - 3}
                            </div>
                          )
                        : null}
                    </div>
                  ) : (
                    <div className="flex gap-2 flex-wrap w-full mt-2 mb-3">
                      <p className="p-0 m-0 text-gray-500 w-fit text-center text-md">Open to All</p>
                    </div>
                  )}
                </div>

                <div className="w-full mb-4">
                  <h4 className="text-sm font-bold text-gray-500">LINES</h4>
                  {buyerProfileResponse && buyerProfileResponse?.buyerProfile.lobFilters.length > 0 ? (
                    <div className="flex gap-2 flex-wrap w-full my-2">
                      {buyerProfileResponse?.buyerProfile.lobFilters.slice(0, 3).map((value, index) => (
                        <div
                          key={index}
                          className="rounded-full p-2 my-auto text-core-black w-fit text-center border border-gray-200 bg-gray-50 text-xs font-bold"
                        >
                          {value}
                        </div>
                      ))}
                      {buyerProfileResponse
                        ? buyerProfileResponse?.buyerProfile.lobFilters.length > 3 && (
                            <div className="px-1 py-2 text-gray-500 w-fit text-center text-md">
                              + {buyerProfileResponse.buyerProfile.lobFilters.length - 3}
                            </div>
                          )
                        : null}
                    </div>
                  ) : (
                    <div className="flex gap-2 flex-wrap w-full mt-2 mb-3">
                      <p className="p-0 m-0 text-gray-500 w-fit text-center text-md">Open to All</p>
                    </div>
                  )}
                </div>

                <div className="w-full">
                  <h4 className="text-sm font-bold text-gray-500">PREMIUM RANGE</h4>
                  {buyerProfileResponse &&
                  buyerProfileResponse?.buyerProfile.totalPremiumMax +
                    buyerProfileResponse?.buyerProfile.totalPremiumMin >
                    0 ? (
                    <div className="flex gap-2 flex-wrap w-full">
                      <div className="rounded-full px-3 py-2 text-core-black w-fit text-center border border-gray-200 bg-gray-50 text-sm font-bold">
                        {formatPremiumRange(
                          buyerProfileResponse?.buyerProfile.totalPremiumMin,
                          buyerProfileResponse?.buyerProfile.totalPremiumMax
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex gap-2 flex-wrap w-full mt-2 mb-3">
                      <p className="p-0 m-0 text-gray-500 w-fit text-center text-md">Open to All</p>
                    </div>
                  )}
                </div>
              </div>

              {buyerProfileResponse?.buyerProfile.profileDescription !== '' && (
                <div className="w-full border-t mt-8 pt-8">
                  <h4 className="text-sm font-bold text-gray-500">BUYER BIO</h4>
                  <p className="text-gray-900 text-base font-medium">
                    {buyerProfileResponse?.buyerProfile.profileDescription}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full md:w-2/5 pt-20 md:order-1">
          <h1 className="text-core-black text-3xl md:text-5xl">Build your Buyer Profile</h1>
          <div className="flex gap-2 mt-4">
            <ClockIcon className="w-4 h-4 my-auto" />
            <p className="text-gray-500 text-sm font-bold uppercase p-0 m-0">5 - 10 minutes • 7 questions</p>
          </div>
          <div className="my-4 w-full">
            <p className="text-gray-600 font-medium text-lg">
              Answer a few questions about your preferences and interests to create your profile.
            </p>
          </div>
          <div className="mt-4 w-1/3">
            <Button
              variant="fill"
              color="secondary"
              onClick={(): void => history.push('/onboarding/buyer-profile/states')}
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </Onboarding>
  );
};

export default BuyerProfileStep1;
