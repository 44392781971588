export const formatCurrencyValue = (currencyToFormat: string | number | bigint | undefined): string => {
  if (currencyToFormat === (null || undefined)) return '';
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })?.format(Number(currencyToFormat));
};

export const formatDateValue = (dateToFormat: string | undefined): string => {
  if (dateToFormat === (null || undefined)) return '';
  const preFormatted = new Date(dateToFormat)?.toISOString()?.split(/[-T]/, 3);
  return preFormatted[1] + '/' + preFormatted[2] + '/' + preFormatted[0];
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  return phoneNumber.length === 10
    ? '+1' + phoneNumber
    : phoneNumber.length === 11 && phoneNumber.charAt(0) === '1'
    ? '+' + phoneNumber
    : phoneNumber;
};

export const formatcalculateDaysAgo = (date: string): string => {
  const createdDate = new Date(date);
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - createdDate.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

  if (daysDifference <= 0) {
    return 'Today';
  }

  return `${daysDifference}D`;
};

export const formatCurrency = (amount: number, currencyCode = 'USD') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
  });

  return formatter.format(amount);
};

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatLastSync = (lastSync: string | number | Date | undefined) => {
  if (!lastSync) return '';

  const date = new Date(lastSync);
  const optionsDate = { month: 'long', day: 'numeric' } as const;
  const optionsTime = { hour: 'numeric', minute: '2-digit', hour12: true } as const;
  const formattedDate = date.toLocaleDateString('en-US', optionsDate);
  const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

  const month = capitalizeFirstLetter(formattedDate.split(' ')[0]);
  const day = formattedDate.split(' ')[1].slice(0, 1);
  const timeParts = formattedTime.split(' ');
  const time = `${timeParts[0]}${timeParts[1].toLowerCase()}`;

  return `${month} ${day}, ${time}`;
};

export const formatDateShort = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    hourCycle: 'h12',
  };
  return date.toLocaleString('en-US', options).toLowerCase();
};

export const formatLastSyncShort = (dateString: string | undefined): string => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { month: 'numeric', day: '2-digit', year: '2-digit' };
  return date.toLocaleDateString('en-US', options);
};
