import { BASEREQUESTOPTIONS } from 'src/api/_constants';
import { IntegrationData, IntegrationResponse } from 'src/types/Integrations';

export const getIntegrationData = async (): Promise<IntegrationData> => {
  const url = `/trantor/integrations`;

  const options = {
    ...BASEREQUESTOPTIONS,
    method: 'GET',
  };

  const response = await fetch(url, options);
  return response.json();
};

export const postIntegration = async (
  system_type: string,
  system_name: string,
  reference_id: string,
  status: string
): Promise<IntegrationResponse> => {
  const body = {
    system_type,
    system_name,
    reference_id,
    status,
  };

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
    body: JSON.stringify(body),
  };
  const response = await fetch(`/trantor/integrations`, requestOptions);
  return response.json();
};

export const patchIntegration = async (
  referenceId: string,
  systemName: string,
  systemType: string,
  status: string,
  Uid: string
): Promise<IntegrationResponse> => {
  const url = `/trantor/integrations`;
  const queryParams = encodeURIComponent(Uid);
  const fullUrl = `${url}/${queryParams}`;
  const body = {
    referenceId,
    systemType,
    systemName,
    status,
  };

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'PATCH',
    body: JSON.stringify(body),
  };

  const response = await fetch(fullUrl, requestOptions);
  return response.json();
};
