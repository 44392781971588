const routes = {
  slices: '/slices',
  bookValuation: '/book-valuation',
  bulkCreateSlices: '/bulk-create-slices',
  inbox: '/inbox',
  settings: '/settings',
  account: '/account',
  sliceDetails: '/view-single-listing',
  buyerProfile: '/account#buyer-profile',
  bookManagement: '/book-management',
  buyersDatabase: '/buyers-database',
};

export default routes;
