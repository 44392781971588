import React, { FC, useState } from 'react';
import Button from 'src/app/shared/components/Buttons/Button';
import Input from 'src/app/shared/components/Inputs/Input';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Form, Formik } from 'formik';
import Select from 'src/app/shared/components/Inputs/Select';
import {
  CarrierListingOptions,
  CommercialListValues,
  PersonalListValues,
  UsStatesOptions,
} from 'src/app/shared/ListingEnums';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import { EditPolicyData, createPolicyData } from 'src/api/policies';

interface ModalDataPolicyProps {
  isOpen: boolean;
  onClose: () => void;
  policie?: any | undefined;
}

const ModalDataPolicy: FC<ModalDataPolicyProps> = ({ isOpen, onClose, policie = {} }) => {
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const { alerts, createToast } = useAlert();

  const getCarrierOptions = () => {
    return Object.entries(CarrierListingOptions).map(([, description]) => ({
      value: description,
      label: description,
    }));
  };

  const getLineOfBusinessOptions = () => {
    const combinedValues = [...CommercialListValues, ...PersonalListValues];
    return combinedValues.map(({ description }) => ({
      value: description,
      label: description,
    }));
  };

  const getUsStateOptions = () => {
    return Object.entries(UsStatesOptions).map(([key, value]) => ({
      value: key,
      label: value,
    }));
  };

  const optionsLineOfBusiness = getLineOfBusinessOptions();
  const optionsCarriers = getCarrierOptions();
  const optionsUsStates = getUsStateOptions();
  const optionsTypeOfInsurance = [
    { value: 'Personal', label: 'Personal' },
    { value: 'Commercial', label: 'Commercial' },
  ];

  if (!isOpen) {
    return null;
  }

  function findCarrierDescription(description: string): string {
    const entry = Object.entries(CarrierListingOptions).find(([, value]) => value === description);
    return entry ? entry[1] : '';
  }

  const findLineOfBusinessCode = (description: string) => {
    const combinedValues = [...CommercialListValues, ...PersonalListValues];
    const found = combinedValues.find((item) => item.description === description);
    return found ? found.description : '';
  };

  const initialFormData = {
    policyNumber: policie.policyNumber || '',
    carrier: findCarrierDescription(policie.carrier) || '',
    clientId: policie.clientId || '',
    lineOfBusiness: findLineOfBusinessCode(policie.lineOfBusiness) || '',
    totalPremium: policie.totalPremium || '',
    insuredState: policie.insuredState || '',
    typeOfInsurance: policie.typeOfInsurance || '',
    insuredZipCode: policie.insuredZipCode || '',
    policyStatus: policie.policyStatus || '',
    naicCode: policie.naicCode || 0,
    numOfClaims: policie.numOfClaims || 0,
    numOfTerms: policie.numOfTerms || 0,
    paymentPlan: policie.paymentPlan || '',
    agencyId: policie.agencyId || '',
    source: policie.source || 'Manual',
    hasConflict: policie.hasConflict || false,
  };

  const onSubmit = async (values: any) => {
    setButtonsDisabled(false);
    try {
      if (policie && policie.policyNumber) {
        await EditPolicyData({ policies: [values] });
        createToast({ text: 'Policy updated', variant: 'success' });
        setTimeout(() => {
          onClose();
        }, 1000);
      } else {
        await createPolicyData({ policies: [values] });
        createToast({ text: 'Policy created', variant: 'success' });
        setTimeout(() => {
          onClose();
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      if (typeof error === 'object' && error !== null && 'detail' in error) {
        createToast({
          text: (error as { detail: string }).detail,
          variant: 'danger',
        });
      } else {
        createToast({
          text: 'Invalid action, please try again.',
          variant: 'danger',
        });
      }
    }
  };

  const isReadOnly = Object.keys(policie).length > 0 ? policie.source === 'Hawksoft' : false;

  return (
    <div
      className="fixed top-0 p-3 sm:px-0 left-0 w-full h-full backdrop-blur-md bg-black/50 flex justify-center justify-items-center z-[100]"
      onClick={onClose}
    >
      <div
        className="bg-white rounded w-full md:h-[96vh] md:w-[536px] h-[99vh] fixed md:right-3"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="w-full flex p-6">
          <div className="w-full flex flex-col text-left justify-start">
            <h1 className="font-medium text-2xl text-core-black my-auto">
              {policie.policyNumber ? 'Edit' : 'Create'} Policy
            </h1>
            <div className="w-full flex items-center gap-2">
              <p className="text-gray-600 font-bold text-sm p-0 m-0">{policie.policyNumber}</p>
            </div>
          </div>
          <div className="w-fit flex justify-end">
            <button
              className="my-auto p-2 rounded-full bg-neutral-50 cursor-pointer hover:bg-neutral-100 transition ease-in"
              onClick={onClose}
            >
              <XMarkIcon className={`my-auto w-4 h-4 text-core-black group-hover:text-white`} />
            </button>
          </div>
        </div>

        <Formik onSubmit={onSubmit} initialValues={initialFormData} enableReinitialize>
          {({ values, handleChange }) => (
            <>
              <Form className="w-full">
                <div className="w-full px-6 py-2 max-h-[70vh] overflow-y-auto">
                  <h2 className="text-lg font-medium text-left">Attributes</h2>

                  <div className="w-full space-y-3">
                    <div className="w-full">
                      <Input
                        type="text"
                        placeholder="Enter the policy ID"
                        name="policyNumber"
                        labelInput="Policy ID"
                        isRequiredInput={true}
                        variant={false}
                        value={values.policyNumber || ''}
                        onChange={(e) => {
                          handleChange(e);
                          setButtonsDisabled(false);
                        }}
                        readOnly={isReadOnly}
                        disabled={isReadOnly}
                      />
                    </div>

                    <div className="w-full">
                      <Select
                        options={optionsCarriers}
                        name="carrier"
                        value={values.carrier}
                        onChange={(e) => {
                          handleChange(e);
                          setButtonsDisabled(false);
                        }}
                        positionLast={false}
                        labelInput="Carrier"
                        isRequiredInput={true}
                        placeholderOption="Select a Carrier"
                        disabled={isReadOnly}
                      />
                    </div>

                    <div className="w-full">
                      <Select
                        options={optionsLineOfBusiness}
                        name="lineOfBusiness"
                        value={values.lineOfBusiness}
                        onChange={(e) => {
                          handleChange(e);
                          setButtonsDisabled(false);
                        }}
                        positionLast={false}
                        labelInput="Line of Business"
                        isRequiredInput={true}
                        placeholderOption="Select a LOB"
                        disabled={isReadOnly}
                      />
                    </div>

                    <div className="w-full">
                      <Select
                        options={optionsUsStates}
                        name="insuredState"
                        value={values.insuredState}
                        onChange={(e) => {
                          handleChange(e);
                          setButtonsDisabled(false);
                        }}
                        positionLast={false}
                        labelInput="State"
                        isRequiredInput={true}
                        placeholderOption="Select state"
                        disabled={isReadOnly}
                      />
                    </div>

                    <div className="w-full">
                      <Input
                        type="number"
                        placeholder="Enter the total premium"
                        name="totalPremium"
                        labelInput="Total Premium"
                        isRequiredInput={true}
                        variant={false}
                        value={values.totalPremium || ''}
                        onChange={(e) => {
                          handleChange(e);
                          setButtonsDisabled(false);
                        }}
                        readOnly={isReadOnly}
                        disabled={isReadOnly}
                      />
                    </div>

                    <div className="w-full">
                      <Select
                        options={optionsTypeOfInsurance}
                        name="typeOfInsurance"
                        value={values.typeOfInsurance}
                        onChange={(e) => {
                          handleChange(e);
                          setButtonsDisabled(false);
                        }}
                        positionLast={false}
                        labelInput="Type"
                        isRequiredInput={true}
                        placeholderOption="Select type of insurance"
                        disabled={isReadOnly}
                      />
                    </div>

                    <div className="w-full">
                      <Input
                        type="text"
                        placeholder="Enter the ZIP code"
                        name="insuredZipCode"
                        labelInput="Zip CODE"
                        isRequiredInput={false}
                        variant={false}
                        value={values.insuredZipCode || ''}
                        onChange={(e) => {
                          handleChange(e);
                          setButtonsDisabled(false);
                        }}
                        readOnly={isReadOnly}
                        disabled={isReadOnly}
                      />
                    </div>

                    <div className="w-full">
                      <Input
                        type="text"
                        placeholder="Enter the client ID"
                        name="clientId"
                        labelInput="Client ID"
                        isRequiredInput={false}
                        variant={false}
                        value={values.clientId || ''}
                        onChange={(e) => {
                          handleChange(e);
                          setButtonsDisabled(false);
                        }}
                        readOnly={isReadOnly}
                        disabled={isReadOnly}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full absolute bottom-0 rounded-b bg-white py-6 px-8 border-t">
                  <Button
                    variant="fill"
                    color={policie.policyNumber ? 'secondary' : 'primary'}
                    type="submit"
                    disabled={buttonsDisabled}
                  >
                    {policie.policyNumber ? 'Done' : 'Add Policy'}
                  </Button>
                </div>
              </Form>
              {alerts}
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ModalDataPolicy;
