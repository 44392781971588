import React, { useState, useEffect } from 'react';
import { BASEREQUESTOPTIONS } from 'src/api/_constants';

const BookAnalysis = () => {
  const [iframeUrl, setIframeUrl] = useState('');

  useEffect(() => {
    // Fetch the URL from your server
    const fetchUrl = async () => {
      try {
        const response = await fetch(`/trantor/analytics/link?metabase_dashboard=11`, {
          ...BASEREQUESTOPTIONS,
        });
        const data = await response.json();
        setIframeUrl(data.url); // Assuming the URL is in data.url
        // setIframeUrl('https://metabase.demo.haveyourcake.ai/public/dashboard/cab703ab-17dd-478f-b399-50cecaeffa15');
      } catch (error) {
        console.error('Error fetching URL:', error);
      }
    };

    fetchUrl();
  }, []); // Empty dependency array means this effect runs once on mount

  return <iframe src={iframeUrl} max-width="100%" width="100%" height="auto" allowTransparency></iframe>;
};

export default BookAnalysis;
