import React, { ReactNode, useState } from 'react';
import { ReactComponent as checkCircle } from '../../../../styles/assets/icons/solid/checkCircle.svg';
import { ReactComponent as exclamationCircle } from '../../../../styles/assets/icons/solid/exclamationCircle.svg';
import { ReactComponent as exclamationTriangle } from '../../../../styles/assets/icons/solid/exclamationTriangle.svg';
import { ReactComponent as Xmark } from '../../../../styles/assets/icons/mini/xMark.svg';
import Button from '../Buttons/Button';

type AlertProps = {
  variant?: 'success' | 'warning' | 'danger' | 'confirm';
  children: ReactNode;
  onClose?: () => void;
  onRestore?: (id?: string) => void;
};

const iconComponents = {
  success: checkCircle,
  warning: exclamationTriangle,
  danger: exclamationCircle,
  confirm: checkCircle,
};

export default function Alert({ variant = 'success', children, onClose, onRestore }: AlertProps) {
  const [isVisible, setIsVisible] = useState(true);

  const classVariants = {
    success: 'bg-white text-core-black',
    warning: 'bg-core-toastWarning text-white',
    danger: 'bg-red-700 text-white',
    confirm: 'bg-white text-core-black',
  };

  const IconComponent = iconComponents[variant];

  const closeAlert = () => {
    setIsVisible(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div
      className={`w-fit rounded p-3 shadow-lg text-sm flex gap-2 transition-transform transform items-center ${
        classVariants[variant]
      } ${isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-95'}`}
    >
      {IconComponent && (
        <IconComponent className={`my-auto h-5 w-5 ${variant === 'danger' ? 'text-white' : 'text-core-yellow'}`} />
      )}
      {children}
      {variant === 'confirm' && (
        <div className="w-fit flex justify-end">
          <Button variant="fill" color="primary" onClick={onRestore}>
            Restore
          </Button>
        </div>
      )}
      <button
        onClick={closeAlert}
        className={`ml-5 focus:outline-none  ${
          variant === 'success' || variant === 'confirm' ? 'text-core-black' : 'text-white'
        } hover:text-gray-400 transition-opacity duration-300`}
      >
        <Xmark className="my-auto h-5 w-5" />
      </button>
    </div>
  );
}
