import React, { FC, useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cakeUpload from 'src/styles/assets/logos/cakeUpload.svg';
import cakeIntegration from 'src/styles/assets/logos/cakeIntegration.png';

import Button from 'src/app/shared/components/Buttons/Button';
import UploadBook from './path-components/uploadBook';
import AmsIntegration from './path-components/amsIntegration';

interface UploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  startStep?: string;
}

const UploadModal: FC<UploadModalProps> = ({ isOpen, onClose, startStep }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [currentStep, setCurrentStep] = useState<'Upload' | 'AMS' | ''>('');
  const buttonClass =
    'border-1 w-full h-full p-10 rounded rounded text-core-gray hover:bg-yellow-100 hover:border-black hover:text-core-black transition ease-in whitespace-nowrap';
  const activeClass = 'text-core-black bg-yellow-100 border-core-black ' + buttonClass;
  const [buttonsDisabled, setButtonsDisabled] = useState(true);

  document.body.style.overflow = isOpen ? 'hidden' : 'auto';

  useEffect(() => {
    if (!isOpen) {
      setCurrentStep('');
    } else {
      setCurrentStep(startStep === 'AMS' ? 'AMS' : 'Upload');
    }
  }, [isOpen, startStep]);

  const handleOptionClick = (value: string) => {
    setSelectedOption(value);
    setCurrentStep('');
    setButtonsDisabled(false);
  };

  const handleStepPath = (value: string) => {
    setCurrentStep(value === 'Upload' ? 'Upload' : 'AMS');
    setButtonsDisabled(true);
  };

  return (
    <>
      <div
        onClick={onClose}
        className="fixed top-0 z-50 left-0 w-full h-full flex justify-center items-center backdrop-blur-md bg-black/50"
      >
        <div
          className="bg-white w-full rounded shadow-lg md:w-[60%]"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="w-full overflow-y-auto max-h-[700px]">
            <div className="w-full flex flex-col">
              <div className="w-full flex border-b p-6">
                <div className="w-11/12 gap-3">
                  <h1 className="text-core-black font-medium text-xl p-0 m-0 whitespace-nowrap text-left">
                    Import your Book
                  </h1>
                </div>
                <div className="w-1/12 flex justify-end">
                  <button
                    onClick={onClose}
                    className="rounded-full p-2 h-8 w-8 bg-neutral-50 hover:bg-neutral-100 text-core-black transition ease-in"
                  >
                    <XMarkIcon className="h-4 w-4" />
                  </button>
                </div>
              </div>

              <div className="flex flex-col">
                {currentStep === '' && (
                  <div className="my-16">
                    <div className="w-full px-2 md:px-20">
                      <h1 className="text-core-black text-lg md:text-3xl mb-6 font-medium text-center">
                        How would you like to import your book of business?
                      </h1>
                    </div>
                    <div className="w-full grid grid-cols-2 gap-3 px-10 lg:px-36">
                      <div>
                        <button
                          className={selectedOption === 'Upload' ? activeClass : buttonClass}
                          onClick={() => handleOptionClick('Upload')}
                        >
                          <img src={cakeUpload} alt="Upload" className="mb-3 h-auto w-20 mx-auto" />
                          Upload
                        </button>
                      </div>
                      <div>
                        <button
                          className={selectedOption === 'AMS' ? activeClass : buttonClass}
                          onClick={() => handleOptionClick('AMS')}
                        >
                          <img src={cakeIntegration} alt="Upload" className="mb-3 h-auto w-20 mx-auto" />
                          AMS Integration
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {currentStep === 'Upload' && (
                  <>
                    <UploadBook jotForm={true} onClose={onClose} onBack={() => handleOptionClick('')} />
                  </>
                )}

                {currentStep === 'AMS' && (
                  <>
                    <AmsIntegration onClose={onClose} onBack={() => handleOptionClick('')} />
                  </>
                )}
              </div>

              <>
                {currentStep === '' && (
                  <div className="w-full border-t p-6 flex">
                    <div className="w-full flex justify-end">
                      <div className="w-fit">
                        <Button
                          variant="fill"
                          color="primary"
                          disabled={buttonsDisabled}
                          onClick={() => {
                            handleStepPath(selectedOption);
                          }}
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadModal;
