import React, { useEffect, useState } from 'react';
import Select from 'src/app/shared/components/Inputs/Select';
import Button from 'src/app/shared/components/Buttons/Button';
import Input from 'src/app/shared/components/Inputs/Input';
import { UsStatesOptions } from 'src/app/shared/ListingEnums';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';
import { Form, Formik } from 'formik';
import { User } from 'src/types/User';
import { useGetUser, usePatchUser } from 'src/hooks/useOnboarding';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import { amsValues } from 'src/constants/amsValues';
import { networkValues } from 'src/constants/networkValues';
interface FormValues {
  firstName: string;
  lastName: string;
  roleAtAgency: string;
  agencyName: string;
  agencyWebsiteUrl: string;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: string;
  addressZip: string;
  businessAgeYrs: number;
  npn: number;
  ams: string;
  phoneNumber: string;
  privateNetworkEntry: string;
}

const optionsStates = Object.entries(UsStatesOptions)
  .sort((a, b) => a[1].localeCompare(b[1]))
  .map(([value, label]) => ({ value, label }));

const roleAgency = [
  { value: 'Agent', label: 'Agent' },
  { value: 'Agency Owner', label: 'Agency Owner' },
  { value: 'Other', label: 'Other' },
];

const transformedAmsValues = amsValues.map((value) => ({ value, label: value }));

const BasicDetails = () => {
  const { mutate: updateUser } = usePatchUser();
  const { data: userResponse, isPending, isSuccess, isFetching, refetch } = useGetUser();
  const [errorMessage] = useState<string>();
  const [formEdited, setFormEdited] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const { alerts, createToast } = useAlert();
  const transformedNetworkValues = networkValues.map((value) => ({ value, label: value }));

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  const showSuccessMessage = () => {
    setButtonsDisabled(true);
    createToast({ text: 'Data has been updated successfully', variant: 'success' });
  };

  if (isPending || isFetching) {
    return (
      <div className="w-full mb-12 z-0">
        <Skeleton type="form-details" />
      </div>
    );
  }

  const initialValues = {
    firstName: userResponse?.userProfile.firstName || '',
    lastName: userResponse?.userProfile.lastName || '',
    roleAtAgency: userResponse?.userProfile.roleAtAgency || '',
    agencyName: userResponse?.agencyInfo.agency_name || '',
    agencyWebsiteUrl: userResponse?.agencyInfo.agency_website_url || '',
    addressLine1: userResponse?.agencyInfo.address_line1 || '',
    addressLine2: userResponse?.agencyInfo.address_line2 || '',
    addressCity: userResponse?.agencyInfo.address_city || '',
    addressState: userResponse?.agencyInfo.address_state || '',
    addressZip: userResponse?.agencyInfo.address_zip || '',
    businessAgeYrs: userResponse?.agencyInfo.business_age_yrs || 0,
    npn: userResponse?.agencyInfo.npn || 0,
    ams: userResponse?.agencyInfo.ams || '',
    phoneNumber: userResponse?.userProfile.phoneNumber || '',
    privateNetworkEntry: userResponse?.agencyInfo.private_network_entry || '',
  };

  const onSubmit = async (values: FormValues) => {
    try {
      const transformedData: User = {
        email: userResponse?.userProfile.email || '',
        firstName: values.firstName,
        lastName: values.lastName,
        username: userResponse?.userProfile.username || '',
        receivePromotions: userResponse?.userProfile.receivePromotions || false,
        onboardingComplete: userResponse?.userProfile.onboardingComplete || false,
        onboardingSkip: userResponse?.userProfile.onboardingSkip || false,
        roleAtAgency: values.roleAtAgency,
        agencyName: values.agencyName,
        agencyWebsiteUrl: values.agencyWebsiteUrl,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        addressCity: values.addressCity,
        addressState: values.addressState,
        addressZip: values.addressZip,
        businessAgeYrs: values.businessAgeYrs,
        npn: values.npn,
        ams: values.ams,
        phoneNumber: values.phoneNumber || '',
        privateNetworkEntry: values.privateNetworkEntry,
      };

      await updateUser(transformedData);
      setFormEdited(false);
      showSuccessMessage();
    } catch (error) {
      createToast({ text: 'We have not been able to update the data', variant: 'danger' });
      console.error('Error data:', error);
    }
  };

  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {({ values, handleChange, errors }) => (
          <>
            <Form>
              <input type="hidden" value={formEdited ? '1' : '0'} />
              <div className="w-full flex mb-12 z-0">
                <div className="w-1/2 flex justify-start">
                  <h1 className="text-core-black text-2xl text-left">Basic Details</h1>
                </div>

                <div className="flex justify-end w-1/2 gap-2">
                  <div>
                    <Button variant="fill" color="secondary" type="submit" disabled={buttonsDisabled}>
                      Save
                    </Button>
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-col mb-12">
                <h2 className="text-lg font-medium text-core-black mb-8 text-left">Personal Details</h2>
                <div className="w-full md:grid-cols-2 space-y-2 md:space-y-0 grid grid-cols-1 gap-4">
                  <Input
                    type="text"
                    labelInput="FIRST NAME"
                    placeholder="Fist name"
                    name="firstName"
                    isRequiredInput={true}
                    value={values.firstName}
                    onChange={(e) => {
                      handleChange(e);
                      setFormEdited(true);
                      setButtonsDisabled(false);
                    }}
                    errorText={errors.firstName}
                  />
                  <Input
                    type="text"
                    labelInput="LAST NAME"
                    placeholder="Last Name"
                    name="lastName"
                    isRequiredInput={true}
                    value={values.lastName}
                    onChange={(e) => {
                      handleChange(e);
                      setFormEdited(true);
                      setButtonsDisabled(false);
                    }}
                    errorText={errors.lastName}
                  />
                  <Input
                    type="string"
                    labelInput="Phone number"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    isRequiredInput={true}
                    value={values.phoneNumber}
                    onChange={(e) => {
                      handleChange(e);
                      setFormEdited(true);
                      setButtonsDisabled(false);
                    }}
                    errorText={errors.phoneNumber}
                  />
                </div>
              </div>

              <div className="w-full flex flex-col mb-12">
                <h2 className="text-lg font-medium text-core-black mb-8 text-left">Address</h2>
                <div className="w-full md:grid-cols-2 space-y-2 md:space-y-0 grid grid-cols-1 gap-4">
                  <Input
                    type="text"
                    labelInput="ADDRESS LINE 1"
                    placeholder="Address line 1"
                    isRequiredInput={true}
                    name="addressLine1"
                    value={values.addressLine1}
                    onChange={(e) => {
                      handleChange(e);
                      setFormEdited(true);
                      setButtonsDisabled(false);
                    }}
                    errorText={errors.addressLine1}
                  />
                  <Input
                    type="text"
                    labelInput="ADDRESS LINE 2"
                    placeholder="Address line 2"
                    name="addressLine2"
                    value={values.addressLine2}
                    onChange={(e) => {
                      handleChange(e);
                      setFormEdited(true);
                      setButtonsDisabled(false);
                    }}
                    errorText={errors.addressLine2}
                  />
                  <Input
                    type="text"
                    labelInput="CITY"
                    name="addressCity"
                    placeholder="City"
                    isRequiredInput={true}
                    value={values.addressCity}
                    onChange={(e) => {
                      handleChange(e);
                      setFormEdited(true);
                      setButtonsDisabled(false);
                    }}
                    errorText={errors.addressCity}
                  />
                  <Select
                    options={optionsStates}
                    name="addressState"
                    value={values.addressState}
                    labelInput="STATE"
                    isRequiredInput={true}
                    onChange={(e) => {
                      handleChange(e);
                      setFormEdited(true);
                      setButtonsDisabled(false);
                    }}
                  />
                  <Input
                    type="string"
                    labelInput="ZIP CODE"
                    placeholder="Zip Code"
                    name="addressZip"
                    isRequiredInput={false}
                    value={values.addressZip}
                    onChange={(e) => {
                      handleChange(e);
                      setFormEdited(true);
                      setButtonsDisabled(false);
                    }}
                    errorText={errors.addressZip}
                  />
                </div>
              </div>

              <div className="w-full flex flex-col mb-12">
                <h2 className="text-lg font-medium text-core-black mb-8 text-left">Producer Details</h2>
                <div className="w-full md:grid-cols-2 space-y-2 md:space-y-0 grid grid-cols-1 gap-4">
                  <Input
                    type="text"
                    labelInput="AGENCY URL"
                    placeholder="Agency url"
                    isRequiredInput={false}
                    name="agencyWebsiteUrl"
                    value={values.agencyWebsiteUrl}
                    onChange={(e) => {
                      handleChange(e);
                      setFormEdited(true);
                      setButtonsDisabled(false);
                    }}
                    errorText={errors.agencyWebsiteUrl}
                  />
                  <Input
                    type="text"
                    labelInput="AGENCY NAME"
                    placeholder="Agency name"
                    isRequiredInput={true}
                    name="agencyName"
                    value={values.agencyName}
                    onChange={(e) => {
                      handleChange(e);
                      setFormEdited(true);
                      setButtonsDisabled(false);
                    }}
                    errorText={errors.agencyName}
                  />
                  <Input
                    type="number"
                    labelInput="NATIONAL PRODUCER NUMBER"
                    placeholder="National Producer Number"
                    name="npn"
                    isRequiredInput={false}
                    value={values.npn.toString()}
                    onChange={(e) => {
                      handleChange(e);
                      setFormEdited(true);
                      setButtonsDisabled(false);
                    }}
                    errorText={errors.npn}
                  />
                  <Input
                    type="number"
                    labelInput="YEARS IN THE BUSINESS"
                    placeholder="Years in the business"
                    isRequiredInput={true}
                    name="businessAgeYrs"
                    value={values.businessAgeYrs.toString()}
                    onChange={(e) => {
                      handleChange(e);
                      setFormEdited(true);
                      setButtonsDisabled(false);
                    }}
                    errorText={errors.businessAgeYrs}
                  />

                  <Select
                    options={transformedAmsValues}
                    name="ams"
                    isRequiredInput={false}
                    positionLast={true}
                    labelInput="AGENCY MGMT SYSTEM"
                    placeholderOption="Select an Agency MGMT System"
                    value={values.ams}
                    onChange={(e) => {
                      handleChange(e);
                      setFormEdited(true);
                      setButtonsDisabled(false);
                    }}
                  />
                  {/* <Input type="text" labelInput="ASOCIATION MEMBERSHIP" placeholder="-" isRequiredInput={true} /> */}
                  <Select
                    options={roleAgency}
                    name="roleAtAgency"
                    value={values.roleAtAgency}
                    labelInput="ROLE"
                    isRequiredInput={false}
                    positionLast={true}
                    onChange={(e) => {
                      handleChange(e);
                      setFormEdited(true);
                      setButtonsDisabled(false);
                    }}
                  />
                  {/* <Select options={AsociationMem} labelInput="USAGE" isRequiredInput={true} /> */}
                </div>
              </div>

              <div className="w-full flex flex-col">
                <h2 className="text-lg font-medium text-core-black mb-8 text-left">Private Network</h2>
                <div className="w-full md:grid-cols-2 space-y-2 md:space-y-0 grid grid-cols-1 gap-4">
                  <Select
                    options={transformedNetworkValues}
                    name="privateNetworkEntry"
                    value={values.privateNetworkEntry}
                    onChange={(e) => {
                      handleChange(e);
                      setFormEdited(true);
                      setButtonsDisabled(false);
                    }}
                    positionLast={true}
                    labelInput="Association / Network Membership"
                    placeholderOption="Association / Network Membership (optional)"
                  />
                </div>
              </div>

              {alerts}
            </Form>
            <div className="flex w-full">
              <span className="text-system-negative text-sm ml-auto mr-auto mt-2">{errorMessage}</span>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default BasicDetails;
