export const ROLE_QUESTION = 'Which of these best describes you?';
export const BASIC_PROFILE_TITLE = 'Let’s get the basics down';
export const BASIC_PROFILE_SECOND_TITLE =
  'Cake uses this information to fill out a general profile for yourself on the platform. This information will be kept private.';

export const BASIC_PROFILE_CONTINUE_TITLE1 = 'Hey';
export const BASIC_PROFILE_CONTINUE_ADDRESS_TITLE = 'Where are you located?';
export const BASIC_PROFILE_ADDRESS_TEXT =
  'Cake uses this information to fill out a general profile for yourself on the platform. This information will be kept private';
export const BASIC_PROFILE_CONTINUE_TITLE2 = 'Just a few more details';
export const BASIC_PROFILE_COMTINUE_SECOND_TITLE =
  'Cake uses this information to fill out a general profile for yourself on the platform. This information will be kept private.';
export const INTERESTS_TITLE = 'What will you use Cake for?';
export const INTERESTS_TITLE2 = 'Choose all that apply';
