import React, { useEffect, useState } from 'react';
import { Onboarding } from '../components/Onboarding';
import { useFormik } from 'formik';
import { useGetBuyerProfile, usePatchBuyerProfile } from 'src/hooks/useBuyersProfile';
import { useHistory } from 'react-router';
import TextareaComponent from 'src/app/shared/components/Textarea/Textarea';

const BuySlicesBuyerProfile = () => {
  const history = useHistory();
  const { data: buyerProfileResponse } = useGetBuyerProfile();
  const [, setBuyerProfile] = useState(buyerProfileResponse);
  const { mutate, isSuccess } = usePatchBuyerProfile();
  const [selectedOption, setSelectedOption] = useState('');
  const buttonClass =
    'border-1 rounded py-6 px-8 text-core-gray hover:bg-yellow-100 hover:border-black hover:text-core-black transition ease-in whitespace-nowrap';
  const activeClass = 'text-core-black bg-yellow-100 border-core-black ' + buttonClass;

  useEffect(() => {
    if (buyerProfileResponse) {
      setBuyerProfile(buyerProfileResponse);
      setSelectedOption(buyerProfileResponse?.buyerProfile.buyingSlices);
    }
  }, [buyerProfileResponse]);

  const { handleChange, handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      buyingSlices: '',
      buyingSlicesReason: '',
    },
    onSubmit: (values) => {
      mutate(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      history.push('/onboarding/buyer-profile/description');
    }
  }, [isSuccess, history]);

  const handleButtonClick = (value: string) => {
    setSelectedOption(value);
    setFieldValue('buyingSlices', value);
  };

  return (
    <Onboarding
      handleSubmit={handleSubmit}
      isContinue={true}
      isBack={true}
      isStep={true}
      numOfSteps={7}
      stepPosition={6}
    >
      <div className="w-full md:w-3/5 mx-auto block py-20 px-8 md:px-32 text-left gap-8 mt-10">
        <div className="w-full">
          <p className="text-center text-sm text-gray-600 font-bold mx-0 p-0 mb-8">BUILD YOUR BUYER PROFILE</p>
        </div>

        <div className="w-full mb-12 mt-8">
          <h1 className="text-core-black text-3xl md:text-5xl font-medium text-center">
            Would you buy ‘slices’
            <span
              className="text-core-yellow"
              style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}
            >
              *
            </span>
            of books of business?
          </h1>
        </div>

        <div className="w-full mt-12 grid grid-cols-2 gap-2">
          <div className="flex justify-end">
            <button
              className={selectedOption === 'Yes' ? activeClass : buttonClass}
              onClick={() => handleButtonClick('Yes')}
            >
              Yes
            </button>
          </div>
          <div className="flex justify-start">
            <button
              className={selectedOption === 'No' ? activeClass : buttonClass}
              onClick={() => handleButtonClick('No')}
            >
              No
            </button>
          </div>

          <div className="hidden">
            <input
              type="text"
              value={values.buyingSlices}
              onChange={(e) => {
                setFieldValue('buyingSlices', e.target.value);
              }}
            />
          </div>
        </div>

        <div className="w-full">
          {values.buyingSlices === 'No' && (
            <div className="w-full mt-10">
              <TextareaComponent
                height="h-28"
                labelInput="Reason"
                placeholderText="Enter a Reason here"
                showCharacterCount={false}
                name="buyingSlicesReason"
                value={values.buyingSlicesReason}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
          )}
        </div>

        <div className="w-full md:px-0 px-2 md:w-[510px] block mx-auto mt-10">
          <div className="w-full rounded bg-yellow-50 justify-center mx-auto p-4 flex">
            <div className="w-fit">
              <span
                className="text-core-yellow text-5xl text-center mx-2"
                style={{ textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}
              >
                *
              </span>
            </div>
            <div className="w-full">
              <p className="text-core-black text-base font-medium p-0 m-0">
                We allow agents to list partial books of business on our platform. Those carve outs, which could be
                based around factors like state, line of business, or carrier, are what we refer to as “slices”.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Onboarding>
  );
};

export default BuySlicesBuyerProfile;
