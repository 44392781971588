import { FC, ReactNode, useEffect } from 'react';
import cakeLogo from '../../../styles/assets/logos/cake-logo-black.png';
import { BackIcon, LoadingIcon } from '../../../styles/assets/icons/Icons';
import { useHistory } from 'react-router';
import { useOnboardingStore } from 'src/app/store/store';

interface OnboardingProps {
  children: ReactNode;
  handleSubmit?: () => void;
  isBack?: boolean;
  isLoading?: boolean;
  isContinue?: boolean;
  isStep?: boolean;
  numOfSteps?: number;
  stepPosition?: number;
  isFinish?: boolean;
  isDisabled?: boolean;
}

export const Onboarding: FC<OnboardingProps> = ({
  children,
  handleSubmit,
  isStep,
  numOfSteps,
  isBack,
  isLoading,
  isContinue,
  stepPosition,
  isFinish,
  isDisabled,
}) => {
  const history = useHistory();
  const { setUserInfo } = useOnboardingStore();
  useEffect(() => {
    setUserInfo();
  }, []);

  return (
    <div className="w-full min-h-screen h-fit bg-[#FFFFFF]">
      <div className="w-full absolute top-0">
        <div className="float-left py-4 px-10">
          <img src={cakeLogo} alt="Cake logo" className="w-[60px] h-[24]" />
        </div>
      </div>
      <div className="w-full h-full mx-auto mt-auto">{children}</div>
      {isStep && numOfSteps && stepPosition ? (
        <div className={`w-full flex flex-col fixed bottom-0 ${!isContinue ? '' : 'bg-white'}`}>
          {numOfSteps !== 0 && (
            <div className={`flex gap-3 ${!isContinue ? '' : 'bg-white'}`}>
              {[...Array(numOfSteps)].map((_, index) => (
                <div
                  key={index}
                  className={`flex-1 h-1 ${index < stepPosition ? 'bg-core-yellow' : 'bg-gray-300'}`}
                ></div>
              ))}
            </div>
          )}

          <div className="py-6 px-6 md:px-16 border-t-2 w-full flex">
            <div className="w-2/12 md:w-1/3 flex justify-start my-auto">
              <button className="flex flex-row space-x-1" onClick={() => history.goBack()}>
                <BackIcon className="mt-1.5" /> <span className="px-2 text-sm">BACK</span>
              </button>
            </div>
            <div className="w-2/12 md:w-1/3 flex justify-start md:justify-between my-auto">
              <p className="p-0 my-0 text-gray-600 text-sm font-medium text-center block mx-auto">
                {stepPosition} / {numOfSteps}
              </p>
            </div>
            {isFinish ? (
              <>
                <div className="w-8/12 md:w-1/3 flex justify-end">
                  {isContinue ? (
                    <button
                      className="md:w-44 w-fit px-6 py-3 border-1 border-core-yellow bg-core-yellow rounded"
                      onClick={handleSubmit}
                    >
                      {isLoading ? (
                        <LoadingIcon className="m-auto w-8 h-8 text-gray-200 animate-spin fill-yellow-600" />
                      ) : (
                        'Finish'
                      )}
                    </button>
                  ) : null}
                </div>
              </>
            ) : (
              <>
                <div className="w-8/12 md:w-1/3 flex justify-end">
                  {isContinue ? (
                    <button
                      className={`md:w-44 w-fit px-6 py-3 border-1 font-semibold ${
                        isDisabled
                          ? 'border-gray-200 bg-gray-200 text-gray-800'
                          : 'border-core-yellow bg-core-yellow text-core-black'
                      } rounded`}
                      onClick={handleSubmit}
                      disabled={isDisabled}
                    >
                      {isLoading ? (
                        <LoadingIcon className="m-auto w-4 h-4 text-gray-200 animate-spin fill-yellow-600" />
                      ) : (
                        'Continue'
                      )}
                    </button>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          <div>
            <div
              className={`w-full fixed z-50 ${
                isContinue
                  ? 'bottom-0 border-t px-2 bg-white'
                  : 'bottom-0 md:bottom-5 px-4 border-t md:border-t-0 bg-white md:!bg-transparent'
              } py-2`}
            >
              {isBack ? (
                <div className="float-left">
                  <button className="mt-1 py-4 px-4 flex flex-row space-x-1" onClick={() => history.goBack()}>
                    <BackIcon className="mt-1.5" /> <span className="px-2 text-sm">BACK</span>
                  </button>
                </div>
              ) : null}
              <div className="float-right py-2 px-10">
                {isContinue ? (
                  <button
                    className={`md:w-44 w-fit px-6 py-3 border-1 font-semibold ${
                      isDisabled
                        ? 'border-gray-200 bg-gray-200 text-gray-800'
                        : 'border-core-yellow bg-core-yellow text-core-black'
                    } rounded`}
                    onClick={handleSubmit}
                    disabled={isDisabled}
                  >
                    {isLoading ? (
                      <LoadingIcon className="m-auto w-4 h-4 text-gray-200 animate-spin fill-yellow-600" />
                    ) : (
                      'Continue'
                    )}
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
