import React, { useState } from 'react';
import { Button } from '../Buttons/Button';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import Gif1 from '../../../../styles/assets/gifs/1-revised.gif';
import Gif2 from '../../../../styles/assets/gifs/2-revised.gif';
import Gif3 from '../../../../styles/assets/gifs/3-revised.gif';
import Gif4 from '../../../../styles/assets/gifs/4-revised.gif';
import Gif5 from '../../../../styles/assets/gifs/5-revised.gif';

interface EmptyStateCarousel {
  onClick?: (stepType: string) => void;
}

const EmptyStateCarousel: React.FC<EmptyStateCarousel> = ({ onClick }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [backStep, setBackStep] = useState<number | null>(null);
  const gifs = [Gif1, Gif2, Gif3, Gif4, Gif5];

  const nextStep = () => {
    setBackStep(null);
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 2));
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
    setBackStep(currentStep);
  };

  const getGifIndex = () => {
    if (backStep !== null) {
      if (backStep === 2) {
        return 3;
      }
      if (backStep === 1) {
        return 4;
      }
    }
    return currentStep;
  };

  const handleButtonClick = (stepType: string) => {
    if (onClick) {
      onClick(stepType);
    }
  };

  return (
    <div className="w-full p-4 flex md:flex-row flex-col mb-10 md:mb-0">
      <div className="w-full md:w-1/2 px-3 md:!px-20 text-left pt-8 pb-4 flex-col flex items-center my-auto">
        <div className="w-full relative">
          {currentStep === 0 && (
            <div className={`transition-opacity duration-200 ${currentStep !== 0 ? 'opacity-0' : 'opacity-100'}`}>
              <h1 className="text-core-black font-medium text-3xl md:text-5xl">Unlock your valuation.</h1>
              <p className="mx-0 my-4 p-0 text-[#4D4D4D] text-base md:text-lg font-medium">
                Access your valuation on policies, slices, or your full book of business.
              </p>
            </div>
          )}

          {currentStep === 1 && (
            <div className={`transition-opacity duration-200 ${currentStep !== 1 ? 'opacity-0' : 'opacity-100'}`}>
              <h1 className="text-core-black font-medium text-3xl md:text-5xl">The metrics that matter.</h1>
              <p className="mx-0 my-4 p-0 text-[#4D4D4D] text-base md:text-lg font-medium">
                Get a deeper understanding of the metrics that impact acquisitions.
              </p>
            </div>
          )}

          {currentStep === 2 && (
            <div className={`transition-opacity duration-200 ${currentStep !== 2 ? 'opacity-0' : 'opacity-100'}`}>
              <h1 className="text-core-black font-medium text-3xl md:text-5xl">List slices, or entire books.</h1>
              <p className="mx-0 my-4 p-0 text-[#4D4D4D] text-base md:text-lg font-medium">
                Test the market to uncover the true valuation of your book.{' '}
              </p>
            </div>
          )}
        </div>

        <div className="w-full grid grod-cols-1 md:grid-cols-2 gap-3">
          <div>
            <Button variant="fill" color="border" onClick={() => handleButtonClick('Upload')}>
              Upload my Book
            </Button>
          </div>

          <div>
            <Button variant="fill" color="secondary" onClick={() => handleButtonClick('AMS')}>
              Connect my AMS
            </Button>
          </div>
        </div>

        <div className="w-full flex mt-7 gap-2">
          <button
            className={`rounded-full p-3 ${
              currentStep === 0 ? 'bg-gray-50 disabled text-gray-400' : 'bg-core-black text-white'
            }`}
            onClick={prevStep}
            disabled={currentStep === 0}
          >
            <span>
              <ArrowLeftIcon className="h-6 w-6" />
            </span>
          </button>

          <button
            className={`rounded-full p-3 ${
              currentStep === 2 ? 'bg-gray-50 disabled text-gray-400' : 'bg-core-black text-white'
            }`}
            onClick={nextStep}
            disabled={currentStep === 2}
          >
            <span>
              <ArrowRightIcon className="h-6 w-6" />
            </span>
          </button>
        </div>
      </div>

      {/* Gif */}
      <div className="w-full md:w-1/2">
        <img src={gifs[getGifIndex()]} alt="gif" className="mx-auto block w-10/12 transition-opacity duration-200" />
      </div>
    </div>
  );
};

export default EmptyStateCarousel;
