import React, { FC, useEffect, useState } from 'react';
import { postBookValuation } from 'src/api/valuation';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import ModalEditBookValuation from './ModalEditBookValuation';
import { ValuationLineItem, ValuationReq } from 'src/types/bookValuations';
import { formatCurrency } from 'src/app/shared/utils/formatters';
import useAlert from 'src/app/shared/components/Toast/useAlert';
import Skeleton from 'src/app/shared/components/Skeleton/Skeleton';
import { UsStatesOptions } from 'src/app/shared/ListingEnums';

interface BookValuationProps {
  uniqueValues: any;
  lineItems: {
    state: string;
    lob: string;
    annualPremium: number;
    carrier: string;
    numPolicies: number;
    overrideCommission: number | null | undefined;
    typeOfInsurance: string;
    numCustomers: number;
  }[];
}

const BookValuation: FC<BookValuationProps> = ({ lineItems, uniqueValues }) => {
  const { alerts, createToast } = useAlert();
  const [valuationItemsValues, setValuationItemsValues] = useState<
    {
      amount: string;
      commission: string;
      revenueEstimate: string;
    }[]
  >([]);

  const localStorageAssumedCommission = localStorage.getItem('assumedCommission');

  const [isLoading, setIsLoading] = useState(false);

  const valuationItems: ValuationLineItem[] = lineItems.map((item) => {
    const assumedCommission = localStorageAssumedCommission ? parseFloat(localStorageAssumedCommission) : null;
    const overrideCommission = item.overrideCommission === 0 ? null : item.overrideCommission;

    const finalAssumedCommission =
      assumedCommission && assumedCommission > 0 ? assumedCommission / 100 : assumedCommission;
    const finalOverrideCommission =
      overrideCommission && overrideCommission > 0 ? overrideCommission / 100 : overrideCommission;

    const getShortState = (stateFullName: string): string => {
      for (const key in UsStatesOptions) {
        if (UsStatesOptions[key as keyof typeof UsStatesOptions] === stateFullName) {
          return key;
        }
      }
      return '';
    };

    return {
      state: getShortState(item.state) || '',
      lob: `${item.typeOfInsurance} ${item.lob}`,
      carrier: item.carrier || '',
      annualPremium: item.annualPremium,
      numPolicies: 1,
      numCustomers: 1,
      overrideCommission: finalAssumedCommission || finalOverrideCommission || null,
    };
  });

  const groupAndSumItems = (items: ValuationLineItem[]): ValuationLineItem[] => {
    const groupedItems: { [key: string]: ValuationLineItem } = {};

    items.forEach((item) => {
      const key = `${item.state}-${item.lob}-${item.carrier}`;

      if (groupedItems[key]) {
        groupedItems[key].numPolicies += item.numPolicies;
        if (item.numCustomers) {
          groupedItems[key].numCustomers += item.numCustomers;
        }
        if (item.annualPremium) {
          groupedItems[key].annualPremium += item.annualPremium;
        }
      } else {
        groupedItems[key] = {
          ...item,
          numPolicies: 1,
          numCustomers: item.numCustomers ? item.numCustomers : 1,
          annualPremium: item.annualPremium || 1,
        };
      }
    });
    return Object.values(groupedItems);
  };

  const groupedValuationItems = groupAndSumItems(valuationItems);

  const handleGetBookValuation = async () => {
    setIsLoading(true);
    const localStorageUniqueCustomers = localStorage.getItem('uniqueCustomers');

    try {
      const emptyValuationReq: ValuationReq = {
        uniqueCustomers: localStorageUniqueCustomers ? parseInt(localStorageUniqueCustomers) : uniqueValues,
        lineItems: groupedValuationItems,
      };
      const response = await postBookValuation(emptyValuationReq);
      if (response) {
        const { low, mid, high, assumedRevenue, assumedCommission } = response;
        const newValues = [
          {
            amount: formatCurrency(assumedRevenue),
            commission: `${assumedCommission * 100}%`,
            revenueEstimate: 'revenue estimate',
          },
          {
            amount: formatCurrency(parseFloat(low.dollar)),
            commission: `${low.multiple}x`,
            revenueEstimate: 'low',
          },
          {
            amount: formatCurrency(parseFloat(mid.dollar)),
            commission: `${mid.multiple}x`,
            revenueEstimate: 'medium',
          },
          {
            amount: formatCurrency(parseFloat(high.dollar)),
            commission: `${high.multiple}x`,
            revenueEstimate: 'high',
          },
        ];

        setValuationItemsValues(newValues);
      }
    } catch (error) {
      createToast({
        text: 'Error get book valuations',
        variant: 'danger',
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookValuation();
  }, []);

  const [viewMoreOptions, setViewMoreOptions] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  const handleOpenEdit = () => {
    setViewMoreOptions(!viewMoreOptions);
  };

  const handleOpenModalEdit = () => {
    setViewMoreOptions(false);
    setOpenModalEdit(true);
  };

  const closeModal = () => {
    setOpenModalEdit(false);
  };

  const handleSuccess = (successValue: boolean) => {
    if (successValue) {
      handleGetBookValuation();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (viewMoreOptions && !event.target.closest('.more-options')) {
        setViewMoreOptions(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [viewMoreOptions]);

  return (
    <>
      {isLoading ? (
        <div className="w-full mt-2 mb-4">
          <Skeleton type="book-valuation" />
        </div>
      ) : (
        <div className="w-full pb-3 mb-3 md:mb-0 md:pb-4 overflow-x-auto">
          <div className="grid grid-cols-4 gap-3 min-w-[1400px] md:min-w-full">
            {valuationItemsValues.map((item, index) => (
              <div
                key={index}
                className={`rounded-[4px] p-3 border-1 border-neutral-50 text-left ${
                  index === 0 ? 'bg-neutral-50' : ''
                } `}
              >
                <div className="w-full flex">
                  <div className="w-11/12">
                    <h2 className="text-core-black text-3xl font-medium">{item.amount}</h2>
                  </div>
                  {index === 0 && (
                    <div className="w-1/12 flex justify-end">
                      <EllipsisVerticalIcon className="w-8 h-8 cursor-pointer" onClick={handleOpenEdit} />
                      {viewMoreOptions && (
                        <div className="bg-white rounded-[4px] border-1 w-[170px] border-blue-500 p-2 absolute mt-7 right-10 md:left-[26%] mr-4 more-options">
                          <ul className="p-0 m-0 text-left">
                            <li
                              className="text-base font-medium rounded px-3 py-2 hover:bg-neutral-50 transition ease-in cursor-pointer text-left"
                              onClick={handleOpenModalEdit}
                            >
                              Edit Details
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="w-full mt-3">
                  <p className="p-0 m-0 text-core-black text-sm font-medium">
                    {index === 0
                      ? `${parseFloat(item.commission).toFixed(2)}% Commission`
                      : parseFloat(item.commission).toFixed(2)}
                  </p>
                  <p className="p-0 m-0 text-gray-500 text-sm font-bold uppercase">{item.revenueEstimate}</p>
                </div>
              </div>
            ))}
            {alerts}
            {openModalEdit && <ModalEditBookValuation onClose={closeModal} onSuccess={handleSuccess} />}
          </div>
        </div>
      )}
    </>
  );
};

export default BookValuation;
