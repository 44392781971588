import { FC, useEffect, useState } from 'react';
import { Onboarding } from '../components/Onboarding';
import { BASIC_PROFILE_SECOND_TITLE, BASIC_PROFILE_TITLE } from 'src/constants/labels';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import Input from 'src/app/shared/components/Inputs/Input';
import { usePatchUser } from 'src/hooks';
import { useOnboardingStore } from 'src/app/store/store';
import { basicInfoSchema } from '../schemas/basicInfo.schema';

export const BasicInfo: FC = () => {
  const history = useHistory();
  const { userInfo } = useOnboardingStore();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const { mutate, isSuccess, error, isPending } = usePatchUser();

  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      firstName: userInfo?.userProfile.firstName || '',
      lastName: userInfo?.userProfile.lastName || '',
      phoneNumber: userInfo?.userProfile.phoneNumber || '',
      agencyWebsiteUrl: userInfo?.agencyInfo.agency_website_url || '',
      agencyName: userInfo?.agencyInfo.agency_name || '',
      addressLine1: userInfo?.agencyInfo.address_line1 || '',
      addressLine2: userInfo?.agencyInfo.address_line2 || '',
      addressCity: userInfo?.agencyInfo.address_city || '',
      addressState: userInfo?.agencyInfo.address_state || '',
    },
    validationSchema: basicInfoSchema,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  useEffect(() => {
    const isValid = Object.keys(errors).length === 0;

    const requiredFieldsFilled =
      values.firstName.trim() !== '' &&
      values.lastName.trim() !== '' &&
      values.phoneNumber.trim() !== '' &&
      values.agencyName.trim() !== '';
    setIsDisabled(!(isValid && requiredFieldsFilled));
  }, [errors, values.firstName, values.lastName, values.phoneNumber, values.agencyName]);

  useEffect(() => {
    if (isSuccess) {
      history.push('/onboarding/address');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (userInfo?.userProfile.onboardingComplete) {
      history.push('/onboarding/completed');
    }
  }, [userInfo]);

  return (
    <Onboarding
      handleSubmit={handleSubmit}
      isBack={true}
      isLoading={isPending}
      isContinue={true}
      isDisabled={isDisabled}
    >
      <div className="flex flex-col lg:w-11/12 sm:w-12/12 m-auto h-full">
        <div className="flex flex-col lg:w-8/12 m-auto px-8 md:px-0">
          <div className="flex flex-col lg:w-10/12 sm:w-full mx-auto h-full text-center mt-32 md:mt-0">
            <h3 className="m-auto lg:text-5xl sm:text-4xl">{BASIC_PROFILE_TITLE}</h3>
            <p className="text-base text-core-grey mt-3">{BASIC_PROFILE_SECOND_TITLE}</p>
          </div>
          <form className="w-full mt-4">
            <div className="w-8/12 sm:w-12/12 m-auto flex flex-row justify-center space-x-3">
              <Input
                type="text"
                placeholder="First Name"
                name="firstName"
                onChange={handleChange}
                value={values.firstName}
                errorText={errors.firstName}
                labelInput="First Name"
                isRequiredInput={true}
              />
              <Input
                type="text"
                placeholder="Last Name"
                name="lastName"
                onChange={handleChange}
                value={values.lastName}
                errorText={errors.lastName}
                labelInput="Last Name"
                isRequiredInput={true}
              />
            </div>
            <div className="w-8/12 m-auto flex flex-row  mt-3 justify-center">
              <Input
                type="text"
                placeholder="Phone Number"
                name="phoneNumber"
                onChange={handleChange}
                value={values.phoneNumber}
                errorText={errors.phoneNumber}
                labelInput="Phone Number"
                isRequiredInput={true}
              />
            </div>
            <div className="w-8/12 m-auto flex flex-row justify-center mt-3">
              <Input
                type="text"
                placeholder="Agency Name"
                name="agencyName"
                onChange={handleChange}
                value={values.agencyName}
                errorText={errors.agencyName}
                labelInput="Agency Name"
                isRequiredInput={true}
              />
            </div>
            <div className="w-8/12 m-auto flex flex-row  mt-3 justify-center">
              <Input
                type="text"
                placeholder="Agency URL (optional)"
                name="agencyWebsiteUrl"
                onChange={handleChange}
                value={values.agencyWebsiteUrl}
                labelInput="Agency website url"
              />
            </div>
            <div className="w-8/12 m-auto">
              <p className="text-core-black text-xs text-left my-3">
                Required fields designated with an asterisk (<span className="text-red-500">*</span>)
              </p>
            </div>
          </form>
        </div>
        <div className="flex w-full">
          <span className="text-system-negative text-sm ml-auto mr-auto mt-2">{errorMessage}</span>
        </div>
      </div>
    </Onboarding>
  );
};
