import { BASEREQUESTOPTIONS } from 'src/api/_constants';
import { listingEditResponse } from 'src/types/Listing';
import { ListingPolicies } from 'src/types/ListingPolicies';
import {
  CreateSlicesResponse,
  Policies,
  ResponseDelete,
  Result,
  Slice,
  SliceGroupDetail,
  SlicesGroup,
} from 'src/types/Policies';

export const getPolicies = async (): Promise<Policies> => {
  const url = `/trantor/policy`;

  const options = {
    ...BASEREQUESTOPTIONS,
    method: 'GET',
  };

  const response = await fetch(url, options);
  return response.json();
};

export const getSlicesGroup = async (): Promise<SlicesGroup> => {
  const url = `/trantor/groups`;
  const options = {
    ...BASEREQUESTOPTIONS,
    method: 'GET',
  };

  const response = await fetch(url, options);
  return response.json();
};

export const getSlice = async (uid: string): Promise<Slice> => {
  const url = `/trantor/groups`;
  const queryParams = `${encodeURIComponent(uid)}`;
  const fullUrl = `${url}/${queryParams}`;

  const options = {
    ...BASEREQUESTOPTIONS,
    method: 'GET',
  };

  const response = await fetch(fullUrl, options);
  return response.json();
};

export const getSliceGroupDetail = async (uid: string): Promise<SliceGroupDetail> => {
  const url = `/trantor/groups/detail`;
  const queryParams = `${encodeURIComponent(uid)}`;
  const fullUrl = `${url}/${queryParams}`;

  const options = {
    ...BASEREQUESTOPTIONS,
    method: 'GET',
  };

  const response = await fetch(fullUrl, options);
  return response.json();
};

export const getPoliciesByMarketplaceListingId = async (uid: string): Promise<ListingPolicies> => {
  const url = `/trantor/marketplace-listings`;
  const queryParams = `${encodeURIComponent(uid)}`;
  const fullUrl = `${url}/${queryParams}/policies`;

  const options = {
    ...BASEREQUESTOPTIONS,
    method: 'GET',
  };

  const response = await fetch(fullUrl, options);
  return response.json();
};

export const getPoliciesByListingId = async (uid: string): Promise<ListingPolicies> => {
  const url = `/trantor/listings`;
  const queryParams = `${encodeURIComponent(uid)}`;
  const fullUrl = `${url}/${queryParams}/policies`;

  const options = {
    ...BASEREQUESTOPTIONS,
    method: 'GET',
  };

  const response = await fetch(fullUrl, options);
  return response.json();
};

export const createNewListing = async (name: string, network: string, description: string, groupUids: string[]) => {
  const body = JSON.stringify({
    name,
    network,
    description,
    groupUids,
  });

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body,
  };

  const response = await fetch('/trantor/listings', requestOptions);

  if (!response.ok) {
    throw new Error('Error:' + response.statusText);
  }

  return response.json();
};

export const createSliceGroup = async (
  name: string,
  groupType: string,
  policy_uid: string[]
): Promise<CreateSlicesResponse> => {
  const policies = policy_uid.map((policy_uid) => ({ policy_uid }));
  const body = {
    name,
    groupType,
    policies,
  };

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
    body: JSON.stringify(body),
  };
  const response = await fetch(`/trantor/groups`, requestOptions);

  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(errorResponse.detail || 'Unknown error occurred');
  }

  return response.json();
};

export const EditNameGroup = async (
  groupUid: string,
  name: string,
  groupType: string,
  policy_uid: string[]
): Promise<CreateSlicesResponse> => {
  const policies = policy_uid.map((policy_uid) => ({ policy_uid }));
  const body = {
    name,
    groupType,
    policies,
  };

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'PATCH',
    body: JSON.stringify(body),
  };
  const response = await fetch(`/trantor/groups/${groupUid}`, requestOptions);
  return response.json();
};

export const EditListing = async (
  uid: string,
  name: string,
  network: string,
  description: string,
  isPublished: boolean
): Promise<listingEditResponse> => {
  const url = `/trantor/listings/${encodeURIComponent(uid)}`;
  const body = {
    name,
    description,
    network,
    isPublished,
  };

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    throw new Error('Error editing the listing');
  }
  return response.json();
};

export const EditNameSlices = async (
  groupUid: string,
  name: string,
  groupType: string
): Promise<CreateSlicesResponse> => {
  const url = `/trantor/groups`;
  const queryParams = `${encodeURIComponent(groupUid)}`;
  const fullUrl = `${url}/${queryParams}`;
  const body = {
    name,
    groupType,
  };

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'PATCH',
    body: JSON.stringify(body),
  };
  const response = await fetch(fullUrl, requestOptions);
  return response.json();
};

export const createPolicyData = async (data: any): Promise<{ message: string }> => {
  const url = `/trantor/policy`;

  const body = {
    policies: data.policies,
  };

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
    body: JSON.stringify(body),
  };

  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    throw new Error('Error creating the policy');
  }

  return response.json();
};

export const deleteListingById = async (uid: string): Promise<void> => {
  await fetch(`/trantor/listings/${uid}`, { ...BASEREQUESTOPTIONS, method: 'DELETE' });
};

export const EditPolicyData = async (data: any): Promise<{ message: string }> => {
  const url = `/trantor/policy`;

  const body = {
    policies: data.policies,
  };

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'PATCH',
    body: JSON.stringify(body),
  };

  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    throw new Error('Failed to edit policy data');
  }

  return response.json();
};

export const PostListingView = async (uid: string): Promise<void> => {
  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
    body: JSON.stringify({ uid }),
  };

  const response = await fetch(`/trantor/listings/${uid}/view`, requestOptions);
  return response.json();
};

export const DeletePolicies = async (policyUids: string[]): Promise<ResponseDelete> => {
  const requestBody = {
    policyUids: policyUids,
  };

  const requestOptions = {
    ...BASEREQUESTOPTIONS,
    method: 'POST',
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(`/trantor/policy/delete`, requestOptions);
  return response.json();
};

export const AddSlicesGroup = async (groupUid: string, policyUids: string[]): Promise<Result> => {
  const requestBody = {
    policyUids: policyUids,
  };

  const url = `/trantor/groups/${groupUid}/add-policies`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(`Failed to add slices group. Status: ${response.status}`);
    }

    const result = await response.json();
    return result as Result;
  } catch (error) {
    console.error('Error adding slices group:', error);
    throw error;
  }
};

export const PostAddGroups = async (uid: string, groupUids: string[]): Promise<Result> => {
  const requestBody = {
    groupUids: groupUids,
  };

  const url = `/trantor/listings/${uid}/add-groups`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(`Failed to add slices group. Status: ${response.status}`);
    }

    const result = await response.json();
    return result as Result;
  } catch (error) {
    console.error('Error adding slices group:', error);
    throw error;
  }
};

export const PostRemoveGroups = async (uid: string, groupUids: string[]): Promise<Result> => {
  const requestBody = {
    groupUids: groupUids,
  };

  const url = `/trantor/listings/${uid}/remove-groups`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(`Failed to remove slices group. Status: ${response.status}`);
    }

    const result = await response.json();
    return result as Result;
  } catch (error) {
    console.error('Error removing slices group:', error);
    throw error;
  }
};
